.material-stock-history {

  &__footer {
    margin-top: 15px;
    display: flex;

    button {
      color: var(--text-dark-gray);
    }
  }

  .stock-history-item {
    position: relative;

    &:not(:last-child) {
      .stock-history-item__timeline:after {
        content: "";
        width: 4px;
        height: calc(50% + 10px);
        background-color: var(--medium-gray);
        position: absolute;
        top: 25px;
        left: 8px;
      }
    }

    &:not(:first-child) {
      .stock-history-item__timeline:before {
        content: "";
        width: 4px;
        height: calc(50% + 10px);
        background-color: var(--medium-gray);
        position: absolute;
        bottom: 25px;
        left: 8px;
      }
    }

    &__timeline {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: var(--dark-gray);
      border: solid var(--medium-gray) 5px;
      margin: auto;
    }

    &__content {
      display: flex;
      flex-direction: column;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
    }

    &__user {
      color: var(--text-black);
      text-transform: uppercase;
      font-weight: bold;
    }

    &__datetime {
      color: var(--text-dark-gray);
    }

    &__icon {
      display: flex;
      align-items: center;
      width: 15px;
      height: 100%;

      svg {
        max-height: 15px;
      }
    }
  }
}