#CopyOrRevision{
	width: 320px;
	left: calc(50%);
	button{
		margin:5px;
		font-size: 1.5rem;
	}
	.popup__content{
		min-height: 100px;
		text-align: center;
	}
}

.info__message {
	background-color: var(--primary-medium);
	color: var(--primary);
	font-weight: 600;
	border: 1px solid var(--primary);
	min-height: 50px;
	height: fit-content;
	display: grid;
	align-items: center;
	margin: 0 0 10px 0;
	padding: 5px 0 5px 10px;
}

#microsoft__disabled-warning {
	display: flex;
	position: fixed;
	right: 20px;
	bottom: 20px;

	width: 40px;
	height: 40px;
}