@import 'UI/App/Css/mixins';

.table__wrapper {
	//min-width: fit-content;
	max-width: 100%;
	position: relative;
	overflow-x: visible;
}

table {
	width: 100%;
	border-collapse: collapse;
	table-layout: fixed;
	text-align: left;
	background-color: white;
	border-radius: 5px;
	//overflow: hidden;

	tr {
		&[clickable='true'] {
			cursor: pointer;
			user-select: none;
		}

		th {
			overflow: hidden;
			text-overflow: ellipsis;

			&[clickable='true'] {
				cursor: pointer;
				user-select: none;
				position: relative;

				#sort__container {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					justify-content: flex-start;
					align-items: center;
					gap: 10px;

					#sort__indication__container {
						width: 15px;
						height: 15px;
					}
				}
			}

			.header-icon {
				width: 20px;
				margin: auto
			}
		}

		th,
		td {
			padding: 10px;
			height: 40px;

			select {
				margin: 0;
				min-height: 30px;
			}

			&:first-child {
				padding-left: 20px;
			}

			&:last-child {
				padding-right: 20px;
			}
		}

		th {
			padding: 10px;
			height: 50px;
		}

		&.sub-row {
			border: none !important;
			background-color: var(--light-gray);
		}

		&.header-sub-row {
			background-color: var(--primary-light);
		}
	}

	// hovers
	&.row--hover tbody tr:hover {
		background-color: var(--medium-gray) !important;
	}

	tbody tr.row--link:hover {
		cursor: pointer;
		text-decoration: underline;
	}

	tbody tr td .table__action-buttons {
		display: flex;
		gap: 5px;

		.btn {
			padding: 5px;

			svg {
				width: 20px;
				height: 20px;
				fill: var(--gray);
			}
		}
	}

	// striped rows
	&.row--striped-even tbody tr:nth-child(even) {
		background-color: var(--primary-light);
	}

	&.row--striped-odd tbody tr:nth-child(odd) {
		background-color: var(--primary-light);
	}

	// table borders
	&.table--border-row {
		> tbody > tr {
			border-top: 1px solid var(--border-color);
			&:first-child {
				border: none;
			}
		}
	}

	&.table--border-col {

		th+th,
		td+td {
			border-left: 1px solid var(--border-color);
		}
	}

	&.table--border-both {

		th+th,
		td+td {
			border-left: 1px solid var(--border-color);
		}

		tr {
			border-bottom: 1px solid var(--border-color)
		}
	}

	// header transparency
	thead:not(.head--transparent) {
		background-color: var(--primary);
		color: white;
		text-transform: uppercase;
	}

	// text overflow
	&.cell-overflow tbody td {
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&:not(.cell-overflow) tbody td {
		overflow-wrap: anywhere;
	}

	&.table--padding-l {
		td, th {
			padding: var(--gap-m);
		}
	}

	&.table--padding-m {
		td {
			padding: var(--gap-s);
		}

		th {
			padding: var(--gap-s);
		}
	}

	&.table--padding-s {
		td {
			padding: var(--gap-xs) var(--gap-s);
		}

		th {
			padding: var(--gap-xs) var(--gap-s);
		}
	}

	&.buttons-collapsed {
		.btn--icon-right, .btn--icon-left {
			font-size: 0;
			width: 38px;
			height: 38px;

			svg {
				margin-left: 0;
			}
		}
	}
}

.table__contactperson__content {
	width: 100%;
	position: relative;
	display: flex;
	align-items: center;
}

.table__angle__down__icon {
	height: 17px;
	width: 17px;
	min-width: 17px;
    fill: var(--primary);
	margin: -2px 0 0 5px;
}
