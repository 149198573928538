.confirmation-progress-bar {
	grid-template-columns: 1fr 1fr;
	display: grid;
	gap: 10px;
	position: relative;
	z-index: 1;
}

.progress-notice {
	background-color: #fae1c7;
	border: solid 1px var(--text-warning);
	padding: 10px 20px;
	width: auto;
	margin: 0 20px;
	border-radius: 5px;
	color: black;
}

.confirmation-progress-item {
	text-align: center;
	background-color: var(--dark-gray);
	color: black;
	padding: 10px;
	cursor: pointer;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: center;
	border-radius: var(--rounded-corners-s) var(--rounded-corners-s) 0 0;

	user-select: none;
}

.confirmation-progress-item-active {
	background-color: #FFFFFF !important;
	border-color: gray;
	border-bottom-color: transparent;
	font-weight: normal;
	box-shadow: 0 0 10px 0 lightgrey;
}

.confirmation-progress-item-done{
	background-color: var(--primary-medium);
	font-weight: 600;
}

.progress-container {
	background-color: white;
	position: relative;
	z-index: 2;
	padding-top: 20px;
}

.calculation__tab-buttons {
	display: flex;
	flex-direction: column;
	max-height: calc(100vh - 340px);
	overflow-y: auto;

	.calculation__tab-button, b {
		padding: 15px 5px 15px 25px;
		border-right: 1px solid var(--border-color);
		position: relative;
		background-color: white;
		border-radius: 0;
		color: black;
		text-align: left;
		justify-content: flex-start;
		font-size: 15px;
		transition: none;

		&:focus {
			outline: none;
		}

		&.currentTab {
			background-color: var(--primary);
			color: white;
			font-weight: 600;

		&:hover {
				filter: none;
			}
		}
	}

	b {
		padding-left: 15px;
		background-color: var(--medium-gray);
	}
}