@import 'UI/App/Css/mixins';

/**
 * Title.
 */

h1,
h2,
h3,
h4,
%heading {
	color: var(--text-black);

	&.is-white {
		color: var(--text-white);
	}
}

/**
  * Heading 1.
  */

h1,
%heading-1 {
	@include media('tablet-portrait-down') {
		@include font(25px, 30px);
	}

	@include font(30px, 25px);
	font-weight: 600;
}

/**
  * Heading 2.
  */

h2,
%heading-2 {
	@include media('tablet-portrait-down') {
		@include font(20px, 25px);
	}

	@include font(25px, 30px);
	font-weight: 600;
}


/**
  * Heading 3.
  */

h3,
%heading-3 {
	@include media('tablet-portrait-down') {
		@include font(16px, 20px);
	}

	@include font(20px, 25px);
	font-weight: 600;
}