@import 'UI/App/Css/mixins';


.toggle-switch {
	display: inline-flex;
	align-items: center;
	-webkit-appearance: none;
	appearance: none;
	width: 30px;
	height: 16px;
	min-height: initial;
	border: none;
	border-radius: var(--rounded-corners-l);
	background-color: var(--text-gray);
	outline: none;
	cursor: pointer;
	padding: 0;
	transition: all 300ms ease;

	&::before {
		content: "";
		width: 14px;
		height: 14px;
		clip-path: circle(50%);
		transition: 200ms all ease-in-out;
		background-color: var(--white);
		margin: 2px;
	}

	&:checked {
		background-color: var(--primary);

		&::before {
			margin: 2px 0 2px 14px;
		}
	}
}