@import 'UI/App/Css/mixins';

.input-group {
    margin-bottom: var(--gap);

    label {
        text-transform: uppercase;
        color: var(--grey);
        font-weight: 600;
        font-size: 10pt;
    }

    &.input-group--text {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 0;

        &:focus-within {
            outline: var(--primary-light) solid 3px;
            border-radius: 5px;
        }

        input {
            margin: 0;

            &:focus {
                outline: none;
            }
        }

        .input-group-text {
            height: inherit;
            min-width: fit-content;
            width: fit-content;
            text-align: center;
            padding: 10px;
            box-sizing: border-box;
            white-space: nowrap;

            background-color: #f4f4f4;
            border: solid 1px lightgrey;
        }

        input, .input-group-text {
            border-radius: 0;

            &:first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            &:not(:first-child):not(:last-child) {
                border-left: none;
            }

            &:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                border-left: none;
            }
        }
    }
}

.input-multiple {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    .input-group {
        width: 100%;
    }

    & input:not(.toggle-switch),
    & textarea,
    & select {
        width: 50%;
    }

    label {
        text-transform: uppercase;
        color: var(--grey);
        font-weight: 600;
        font-size: 10pt;
    }
}

.checkbox__wrapper {
    display: flex;
    align-items: center;

    &.row {
        display: inline-block;
        margin-right: 1em;
    }
}

.checkboxes--column {
    display: flex;
    flex-direction: column;
}

label {
    cursor: pointer;

    &.function {
        position: relative;

        &:after {
            content: " ƒ(x)";
            color: #bcbbbb;
            font-size: 12px;
        }
    }

    &.required:after {
        content: " *";
        color: var(--message-red);
    }
}

input,
textarea,
select {
    border: solid 1px lightgrey;
    min-height: 38px;
    text-indent: 5px;

    font-size: 1.2rem;
    font-family: 'Poppins', 'open sans', sans-serif;
    width: 100%;
    margin-top: var(--gap-xs);
    margin-bottom: var(--gap-xs);
    //border: 1px solid var(--primary-light);
    color: var(--input-text-black);
    //box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    border-radius: var(--rounded-corners-s);
    padding: var(--input-padding);
    background-color: white;

    &.margin-xs {
        margin-top: var(--gap-xs);
        margin-bottom: var(--gap-xs);
    }

    &:focus,
    &:focus-visible {
        color: var(--input-text-black-focus);
        outline: var(--primary-light) solid 3px;
    }

    // styling of the placeholder text itself
    *::placeholder {
        color: var(--input-placeholder-text-black);
        // font-size: 80%;
    }

    // styling on element where a placeholder is shown in
    *:placeholder-shown {
        text-overflow: ellipsis;
    }

    &[data-loading=true] {
        transition: color;
        color: transparent;
        background-image: url("/UI/Assets/Spinners/spinner-primary.svg");
        background-size: contain;
        background-origin: content-box;
        background-repeat: no-repeat;
        background-position: center;
    }
}

input[class*="fancy"] {
    &.left {
        border-radius: 0 var(--rounded-corners-s) var(--rounded-corners-s) 0;
    }

    &.right {
        border-radius: var(--rounded-corners-s) 0px 0px var(--rounded-corners-s);
    }
}

select {
    text-indent: 0;
    padding-left: 5px;
}

textarea {
    text-indent: unset;
    padding-left: 10px;
}

// Fix for inputs in react-select
div[class*="css-"][class*="-container"] {
    input {
        box-shadow: none !important;
        min-height: initial !important;
        border-radius: initial !important;
    }
    margin-top: 5px;
    margin-bottom: 10px;

    width: 100%;
}

input[type='radio'],
input[type='checkbox']:not(.toggle-switch) {
    display: inline;
    width: auto;
    box-shadow: none;
    border: none;
    border-radius: 0;
    margin-right: var(--gap-xs);
    min-height: initial;

    &:focus,
    &:focus-visible {
        outline-offset: 2px;
    }
}

.file-upload__preview__container {
    --file-preview-max-size: 400px;
    --file-preview-min-size: 128px;
    --file-preview-padding: var(--gap-xs);
    --file-preview-remove-button-width: 20px;
    --file-preview-remove-button-offset: calc(var(--file-preview-remove-button-width) + var(--gap-xs));

    position: relative;
    min-width: max(var(--file-preview-min-size), 10%);
    min-height: max(var(--file-preview-min-size), 10%);
    width: max-content;
    height: max-content;
    margin-right: var(--file-preview-remove-button-offset);
    max-width: min(var(--file-preview-max-size), 100%);

    &.auto-size {
        max-width: unset;
        min-width: unset;
        min-height: unset;
    }

    .file-upload__preview-icon__wrapper,
    .file-upload__preview__wrapper {
        border: 1px solid var(--border-color);
        box-shadow: 0 0 4px var(--border-color);
        padding: var(--file-preview-padding);
        cursor: pointer;
    }

    .file-upload__preview__wrapper {
        min-width: inherit;
        max-width: inherit;
        min-height: var(--file-preview-min-size);
        max-height: var(--file-preview-max-size);
    }


    .overflow-hidden--center {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        max-width: calc(var(--file-preview-max-size) - var(--file-preview-padding) * 2 - 20px);
        max-height: calc(var(--file-preview-max-size) - var(--file-preview-padding) * 2 - 20px);
    }

    img,
    video,
    > svg {
        display: block; // removes browser inline whitespace below the image
        max-width: unset;
        overflow: auto; // fixes sizing somehow (can also use min-width: 1px)
        
        // transparent files checker background
        background-color: var(--white);
        background-image:
            linear-gradient(45deg, var(--darker-gray) 25%, transparent 25%),
            linear-gradient(-45deg, var(--darker-gray) 25%, transparent 25%),
            linear-gradient(45deg, transparent 75%, var(--darker-gray) 75%),
            linear-gradient(-45deg, transparent 75%, var(--darker-gray) 75%);
        background-size: 20px 20px;
        background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
    }

    .video__wrapper {
        position: relative;

        .video__overlay {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;

            svg {
                fill: var(--primary);
            }
        }
    }

    .file-upload__delete-btn {
        position: absolute;
        top: var(--file-preview-padding);
        right: calc(var(--file-preview-remove-button-offset) * -1);
        opacity: 0.5;
        padding: 0;
        z-index: 1;

        svg {
            width: var(--file-preview-remove-button-width);
            height: auto;
        }
        &:hover {
            opacity: 1;
        }
    }

}

.file-upload {
    .file-upload__no-edit-message {
        color: var(--message-orange);
        font-weight: 600;
        font-size: 10pt;
    }
}

textarea {
    // box-sizing: initial;
    min-height: 20px;
    resize: vertical;

    // styling of the resizer button not fully supported in all browsers
    // applying any style removes the default icon
    // see support table: https://developer.mozilla.org/en-US/docs/Web/CSS/::-webkit-scrollbar#css_scrollbar_selectors
    // &::-webkit-resizer {}
}

.react-select__input {
    box-shadow: none;
}

span.form--error {
    color: var(--message-red);
    margin: 0 10px;
}

fieldset {
    background-color: var(--light-gray);
    border: 1px solid var(--border-color); // fix broken border-top with 1px and groove
    border-radius: var(--rounded-corners-s);
    position: relative;
    
    &.fieldset__transparent {
        background-color: transparent;
    }
    &.fieldset__borderless {
        border: none;
    }

    legend {
        margin-inline-start: 5px;
        margin-inline-end: 5px;

        label {
            text-transform: uppercase !important;
            color: var(--grey) !important;
            font-weight: 600 !important;
            font-size: 1.2rem !important;
        }
    }

    .fieldset__content {
        padding: var(--gap-s);

        & *:last-child {
            margin-bottom: 0;
        }
    }

    &:disabled {
        background-color: #e1e1e1;

        input,
        textarea {
            background-color: var(--disabled-background);
        }

        label,
        input,
        textarea {
            cursor: not-allowed;
        }
    }
}

// fancy checkbox
.switch-container {
    --switch-gap: 5px;
    display: flex;
    align-items: center;
    gap: var(--switch-gap);
}


.switch {
    --switch-padding: 4px;
    display: inline-block;
    height: var(--switchSize);
    position: relative;
    width: var(--switchWidth);
}

.switch-label {
    text-transform: uppercase;
    color: var(--grey);
    font-weight: 600;
    font-size: calc(var(--switchSize)*0.65);
    padding-top: var(--switch-padding);
}

.switch input {
    display: none !important;
}

.slider {
    background-color: var(--dark-gray);
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
}

.slider:before {
    background-color: var(--white);
    bottom: var(--switch-padding);
    content: "";
    height: calc(var(--switchSize) - var(--switch-padding) * 2);
    left: var(--switch-padding);
    position: absolute;
    transition: .4s;
    width: calc(var(--switchSize) - var(--switch-padding) * 2);
}

input:checked+.slider {
    background-color: var(--primary);
}

input:checked+.slider:before {
    transform: translateX(calc(var(--switchWidth) - var(--switchSize)));
}

.slider.round {
    border-radius: calc(var(--switchSize));
}

.slider.round:before {
    border-radius: 50%;
}