.timer__breaks {

    .timer__breaks__total {
        padding: 10px 15px;
        background-color: #efefef;

        span {
            text-align: right;
        }
    }

    .timer__break {
        padding: 10px 15px;
        border: solid transparent 1px;
        border-top-color: #cecece;

        span:last-child {
            text-align: right;
        }
    }
}