@import 'UI/App/Css/mixins';

.company__header {
	@include media('phone') {
		padding: 20px 20px;
	}

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	background-color: var(--primary-light);
	padding: 15px 25px;
}

.company__content {
	@include media('phone') {
		padding: 25px 20px;
	}

	padding: 25px;
	background: var(--white);
}

.company__wrapper {
	margin-bottom: 25px;
}

.company__column {
	@include media('tablet-portrait-down') {
		width: 100%;
	}
}

.company__item {
	display: flex;
	flex-wrap: wrap;
	margin: 5px 0;
	width: 100%;
}

.company-details__title {
	color: var(--text-primary);
	text-transform: uppercase;
	width: 100%;
	margin-bottom: 5px;
	font-weight: bold;
}

.company-details__line {
	width: 100%;
	margin-bottom: 5px;
	img{
		max-height: 200px;
	}
}

.company-details__line a {
	text-decoration: underline;
}

.form-group {
	margin-top: var(--gap);
	margin-bottom: var(--gap);
}

.contactpersons__buttons {
	display: flex;
	justify-content: flex-end;
}

.contactpersons__button {
	margin-left: 10px;
}

.preview-attachment-image {
	max-height: 500px;
	max-width: 100%;
	width: 100%;
	object-fit: contain;
}

.company__list-actions {

	&>a,
	&>button {
		margin-left: 10px;
	}
}

.dialingCode {
	max-width: 30%;

	@include media('phone') {
		min-width: 100% !important;
	}

}

.telephoneNumber {
	width: 70% !important;

	@include media('phone') {
		min-width: 100% !important;
	}
}

.owner-indicator {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: center;
}
