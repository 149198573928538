.confirmation__message__text {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    >span {
      text-align: center;
    }
  }
  
  .popup-editConfirmationMessage {
    min-height: 0;
    top: 20vh;
  
    >.popup__content {
      min-height: 0;
    }
  }
  
  .confirmation__message__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
    gap: 30px;
  
    >button {
      width: 100%;
  
      &.btn--white {
        border: 2px solid var(--primary);
      }
    }
  }