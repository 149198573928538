@import "UI/App/Css/mixins";

.navigation {
  @include media("tablet-landscape-down") {
    border-right: solid 1px var(--border-color);
    overflow: hidden;
  }
  height: 100vh;
  position: fixed;
  overflow-y: auto;
  width: var(--navigation-width);
  user-select: none;

  .navigation__items {
    margin-bottom: 20px;
    overflow-x: hidden;
  }

  .navigation__menu-title {

        @include media("tablet-landscape-down") {
            display: none;
        }

        font-size: 10px;
        color: lightgrey;
        text-transform: uppercase;
        padding: 10px 30px;
    }

    &.opened {
        width: 55px;

        .navigation__menu-title {
            display: none;
        }
        .navigation--open.open {
            position: fixed;
            z-index: 100;
        }
        .navigation__logo {
            display: none;
        }
        .navigation__item {
            padding: 20px 10px 20px 10px;
            margin: 0;
        }
        .navigation__item__name {
            display: none;
        }
        .navigation__title {
            display: none;
        }
        .app-version {
            display: none;
        }
        .navigation__items--submenu {
            display: none;
        }
        + main {
            width: calc(100% - var(--navigation-width-mobile));
            margin-left: var(--navigation-width-mobile);
        }
    }

    &.navigation--open:not(.open) {
        &.opened {
            width: 60px !important;

            .navigation__item .navigation__item__submenu-toggle {
                display: none;
            }
        }
    }
}

.hamburger {
    @include media("tablet-landscape-down") {
        display: flex;
    }

    display: none;
    width: 20px;
    height: 20px;
    position: relative;
    margin: 20px auto;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

.navigation__container {
  position: relative;
}

.hamburger span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: var(--primary);
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
}

.hamburger span:nth-child(1) {
    top: 0px;
}

.hamburger span:nth-child(2),
.hamburger span:nth-child(3) {
    top: 7px;
}

.hamburger span:nth-child(4) {
    top: 14px;
}

.hamburger.open span:nth-child(1) {
    top: 7px;
    width: 0%;
    left: 50%;
}

.hamburger.open span:nth-child(2) {
    transform: rotate(45deg);
}

.hamburger.open span:nth-child(3) {
    transform: rotate(-45deg);
}

.hamburger.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}

.navigation--open {
    @include media("tablet-landscape-down") {
        width: 60px;
    }

    background-color: var(--white);
}

.navigation--open.open {
    @include media("tablet-landscape-down") {
        width: 100%;
        position: fixed;
        z-index: 99;
    }

    .navigation__menu-title {
        display: block;
    }
}

.navigation--open.open .hamburger {
    @include media("tablet-landscape-down") {
        display: inline-flex;
        margin-left: 22px;
    }
}

.navigation--open.open .navigation__item {
    @include media("tablet-landscape-down") {
        padding: 0 0 0 10px;
    }

    position: relative;
    display: flex;
    align-items: center;
}

.navigation--open.open .navigation__items--submenu .navigation__item {
    @include media("tablet-landscape-down") {
        padding-left: 30px;
    }
}

.navigation--open.open .nav--container {
    width: 325px;
    height: 100vh;
    background: var(--white);
}

.navigation.open {
    overflow: auto;
    max-height: 100vh;
}

.item-container {
    position: relative;
    display: block;
    min-height: calc(100vh - 50px);
    height: auto;
    overflow: hidden;
}

.navigation__logo {
    @include media("tablet-landscape-down") {
        display: none;
    }

    margin: 50px 25px 50px 25px;

    @include media("desktop-small") {
        margin: 25px 25px;
        width: 200px;
    }
}

.navigation__item {
    @include media("tablet-landscape-down") {
        padding: 20px 10px 20px 10px;
    }

    @include media("desktop-small") {
        padding: 15px 10px 15px 10px;
    }

    align-items: center;
    display: flex;
    padding: 20px;
    color: var(--text-black);
    border-left: 5px solid transparent;
    position: relative;
}

.navigation__item__name {
    @include media("tablet-landscape-down") {
        display: none;
    }
}

.navigation.open .navigation__item__name {
    @include media("tablet-landscape-down") {
        display: inline-flex;
        padding: 15px 15px 15px 0;
    }
}

.navigation.open .navigation__item__icon {
    margin-right: 15px;
}

.navigation__item:hover,
.navigation__item.active {
    background-color: var(--primary-medium);
    transition: border-left-color 0.1s, background-color 0.1s;
    z-index: 300;
}

.navigation__item .navigation__item__submenu-toggle {
    @include media("tablet-landscape-down") {
        display: none;
    }

    display: inline-flex;
    padding: 10px 20px;
    position: absolute;
    right: 0;
    background: transparent;
}

.open .navigation__item .navigation__item__submenu-toggle {
    @include media("tablet-landscape-down") {
        display: inline-flex;
        padding: 10px 20px;
        position: absolute;
        right: 0;
    }
}

.navigation__item .navigation__item__submenu-toggle svg {
    height: 20px;
    width: 20px;
}

.navigation__title {
    @include media("tablet-landscape-down") {
        display: none;
    }

    font-size: var(--font-small);
    padding: 0 40px;
    color: var(--text-gray);
    display: flex;
}

.navigation__item__icon {
    @include media("tablet-landscape-down") {}

    width: 30px;
    height: 15px;
    text-align: center;
    vertical-align: middle;
}

.navigation.open .navigation__item__icon {
    @include media("tablet-landscape-down") {
        width: auto;
        padding: 0;
        width: 30px;
        height: 15px;
    }
}

.navigation__items--submenu {
    margin-left: 0;
}

.navigation__item__submenu-toggle.rotate {
    transform: scale(1, -1);
}

.navigation__items--submenu a {
    @include media("tablet-landscape-down") {
        padding: 20px 10px;
        border-left: 5px solid var(--primary-light);
    }

    background: var(--primary-light);
    padding: 15px 30px;
}

.navigation__items--submenu--collapsed {
    display: none;
}

.navigation__item--active {
    border-left: solid 5px var(--primary) !important;
    background-color: var(--primary) !important;
    color: white;

    svg {
        fill: white;
    }
}

.navigation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: solid 1px var(--border-color);

    & > div {
        width: 100%;
        height: 100%;
    }

    &:not(.open) {
        .app-version {
            @include media("tablet-landscape-down") {
                display: none;
            }
        }
    }

    .navigation__item--sub-active {
        &:not(.navigation__item--sub-open) {
            background-color: var(--primary) !important;
            color: white;

            svg {
                fill: white;
            }
        }
    }
}

.navigation__arrow__container__medium,
.navigation__arrow__container__small {

    &:hover {
        cursor: pointer;
    }
}

.navigation__arrow__container__medium {
    position: relative;
    display: inline-block;
    margin: 0 20px 0 auto;
    height: 25px;
    width: 25px;

}

.navigation__arrow__container__small {
    position: relative;
    display: inline-block;
    margin: auto 15px;
    padding: 0px;

}

.navigation__arrow__icon {
    width: 25px;
    height: 25px;
}

.navigation {
    .navigation__footer {
        position: relative;
        display: flex;
        align-items: center;
        height: 50px;
        width: 100%;

        .app-version {
            color: lightgray;
            font-size: 12px;
            background-color: transparent;
            padding-left: 20px;
        }

        @include media("tablet-landscape-down") {
            .navigation__arrow__container__small, .navigation__arrow__container__medium {
                display: none;
            }
        }
    }

    &.open {
        .app-version {
            display: initial;
        }
    }

    &.opened {
        .navigation__item--sub-active {
            background-color: var(--primary) !important;
            color: white;

            svg {
                fill: white;
            }
        }
    }

    @include media('tablet-landscape-down') {
        &:not(.open) {
            .navigation__items--submenu {
                display: none;
            }
        }

        &:not(.open) {
            .navigation__item--sub-active {
                background-color: var(--primary) !important;
                color: white;

                svg {
                    fill: white;
                }
            }
        }
    }
}
