.alert {
    padding: 5px 10px;
    border-left: solid transparent 5px;
    display: flex;
    align-items: center;
    gap: 5px;

    &__info {
        background-color: #cbe8fd;
        color: #174565;
        border-color: #157ecb;
    }

    &__warning {
        background-color: #ffebd8;
        color: #a26710;
        border-color: #d08619;
    }

    &__error {
        background-color: #ffdbdb;
        color: #6e0b0b;
        border-color: #cb1919;
    }

    &__success {
        background-color: #defdd4;
        color: #30721c;
        border-color: #3e9823;
    }
}