div.spinner-center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

div.spinner-overlay {
  position: relative;
  width: 100%;
  height: 100%;

  div.spinner-container {
    position: sticky;
    top: 50%;
    left: 0;
    z-index: 421;
  }

  div.spinner-center {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &[data-visible=true] {
    div.spinner-backdrop {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(2px);
      z-index: 420;
    }
    div.spinner-center {
      display: flex;
      z-index: 421;
    }
  }
}