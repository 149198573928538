#draggable-click-popup {
    h4 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin-bottom: 10px;

        &:not(:first-child) {
            margin-top: 10px;
        }

        color: #505050;
        text-transform: uppercase;
    }

    .checklist, .order-checklist {
        margin-bottom: 20px;

        .grid {
            border-bottom: solid lightgrey 1px;
            padding-bottom: 2px;
        }
    }

    textarea {
        margin: 0;
    }

    .planning-popup__buttons {
        button:not(:only-child):first-child {
            background-color: var(--primary-medium);
            color: black;

            svg {
                fill: black;
            }
        }
    }
}