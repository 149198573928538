@import 'UI/App/Css/mixins';

.grid {
	display: grid;
	gap: var(--gap-l);
	align-items: start;
}

@mixin columns($n) {
	grid-template-columns: repeat($n, minmax(0, 1fr));
}

@mixin rows($n) {
	grid-template-rows: repeat($n, minmax(0, 1fr));
}

@for $i from 1 through 20 {
	.columns-#{$i} {
		@include columns($i);
	}

	.rows-#{$i} {
		@include rows($i);
	}
}