@import 'UI/App/Css/mixins';

.greeting__content {
    line-height: normal;
}

.dashboard__child__wrapper {
    padding: var(--gap-s);
    background-color: var(--white);
}

.powerBIReport {
    border: none;
    height: 1100px;

    iframe {
        border: none;
    }
}