.material__item {
    display: flex;
    flex-wrap: wrap;
    margin: 15px 0;
    width: 100%;

    &:last-child {
        margin-bottom: 0;
    }
}

.material-details__title {
    color: var(--text-primary);
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
}


.material-details__line {
    width: 100%;
    margin-bottom: 5px;
}