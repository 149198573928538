.job-ticket-template-form__content {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .job-ticket__group {
    margin-bottom: 15px;

    &:not(:last-of-type) {
      border-bottom: solid lightgrey 1px;
    }

    .job-ticket__group-title {
      display: flex;
      flex-direction: row;

      gap: 10px;
      margin-bottom: 10px;
    }
  }
}

.job-ticket__full-page-warning {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  color: var(--text-error);
  font-size: 14pt;

  svg {
    width: 100px;
    height: 100px;
    fill: var(--text-error);
  }
}

.tln-active,
.tln-wrapper,
.tln-line {
  margin: 0;
  border: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  vertical-align: middle;
  list-style: none;
}

.tln-active {
  padding: 0.625em;
  width: calc(100% - 3em);
  height: 100%;
  font-size: 1em;
  line-height: 1.5;
  font-family: "Roboto Mono", monospace;
  word-break: break-all;
  border: 1px solid #aeaeae;
  background-color: #fff;
  resize: none;
  overflow-wrap: normal;
  overflow-x: auto;
  white-space: pre;
}

.tln-wrapper {
  grid: 1;
  grid-template-columns: auto auto;
  width: 3em;
  padding: 0.6875em 0.3125em 2.1875em;
  height: 100%;
  word-break: break-all;
  overflow: hidden;
  display: inline-block;
  counter-reset: line;
}

.tln-line {
  //width: 100%;
  display: block;
  text-align: right;
  line-height: 1.5;
  font-size: 1em;
  color: #aeaeae;
}

.tln-line::before {
  counter-increment: line;
  content: counter(line);
  font-size: 1em;
  user-select: none;
}

.container_row {
  display: inline-grid;
  //grid-template-columns: auto auto;
  grid-template-columns: min-content auto
}

.xmlLayer,
.xmlOverlay {
  width: 100%;
  grid-column: 2;
  grid-row: 1;
}

.xmlLayer span {
  color: #fff;
  background: #000cf6;
}

.xmlLayer {
  background-color: var(--medium-gray);
}

.xmlOverlay {
  border: 1px solid #aeaeae;
  pointer-events: none;
  padding: 0.625em;
  font-family: "Roboto Mono", monospace;
  font-size: 1em;
  line-height: 1.5;
  color: #333333;
}

.comp {
  font-family: "Roboto Mono", monospace;
  color: var(--primary)
}

.var {
  font-family: "Roboto Mono", monospace;
  color: var(--text-warning)
}

.varName {
  font-family: "Roboto Mono", monospace;
  color: var(--primary-medium)
}

.error {
  font-family: "Roboto Mono", monospace;
  background-color: rgba(255, 0, 0, 0.2);
}

.progress__notice {
  background-color: #fae1c7;
  border: solid 1px var(--text-warning);
  padding: 10px 20px;
  width: auto;
  margin: 2px 0px 0px 40px;
  border-radius: 5px;
  color: black;
}