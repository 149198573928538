.Timer__content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    width: 100%;

    .timer__present-employees {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px 5px;
        padding-bottom: 10px;

        border-bottom: solid #cecece 1px;

        strong {
            grid-column: 1/3;
        }

        div {
            cursor: pointer;
            user-select: none;

            &[data-checked='true'] {
                display: grid;
                grid-template-columns: 1fr 20px;

                span {
                    color: green;
                }
            }
        }
    }

    .timer__schedule {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding-bottom: 10px;

        border-bottom: solid #cecece 1px;
    }

    button.timer__clock-in {
        width: 100%;
    }

    .timer__current-timer {
        &__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            gap: 5px;

            span {
                font-size: 22px;
            }
        }

        .spinner-overlay {
            padding-top: 20px;

            .spinner-container {
                width: 100%;
                position: absolute;
            }
        }

        .timer__row {
            background-color: #efefef;
            padding: 10px 15px;

            span {
                text-align: right;
            }
        }

        .timer__paused {
            grid-column: 1/3;
            text-align: center;
        }

        .timer__runtime {
            margin-top: 10px;
            font-size: 30px;
            text-align: center;
            grid-column: 1/3;
            font-family: monospace;

            .timer__runtime__seconds {
                font-family: inherit;
                color: #c5c5c5;
            }
        }
    }

    .timer__buttons {
        justify-items: center;
        padding: 0 100px;
        margin-bottom: 20px;

        .timer__btn {
            width: 75px;
            height: 75px;
            border-radius: 50%;
            font-size: 30px;

            &.timer__clock-out {
                background-color: #bd1717;
            }
        }
    }

}

.timer__alert {
    display: flex;
    border-left: solid transparent 5px;
    padding: 5px 5px 5px 10px;
    align-items: center;
    gap: 10px;

    &.error {
        background-color: #ffdbdb;
        color: #6e0b0b;
        border-color: #cb1919;
    }

    &.warning {
        background-color: #ffebd8;
        color: #a26710;
        border-color: #d08619;
    }

    &.success {
        background-color: #defdd4;
        color: #30721c;
        border-color: #3e9823;
    }

    &.info {
        background-color: #cbe8fd;
        color: #174565;
        border-color: #157ecb;
    }
}