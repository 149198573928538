// variables
// --------------------------

$fa-css-prefix          : fa !default;
$fa-style               : 900 !default;
$fa-style-family        : "Font Awesome 6 Pro" !default;

$fa-display             : inline-block !default;

$fa-fw-width            : fa-divide(20em, 16) !default;
$fa-inverse             : #fff !default;

$fa-border-color        : #eee !default;
$fa-border-padding      : .2em .25em .15em !default;
$fa-border-radius       : .1em !default;
$fa-border-style        : solid !default;
$fa-border-width        : .08em !default;

$fa-size-scale-2xs      : 10 !default;
$fa-size-scale-xs       : 12 !default;
$fa-size-scale-sm       : 14 !default;
$fa-size-scale-base     : 16 !default;
$fa-size-scale-lg       : 20 !default;
$fa-size-scale-xl       : 24 !default;
$fa-size-scale-2xl      : 32 !default;

$fa-sizes: (
  "2xs"                 : $fa-size-scale-2xs,
  "xs"                  : $fa-size-scale-xs,
  "sm"                  : $fa-size-scale-sm,
  "lg"                  : $fa-size-scale-lg,
  "xl"                  : $fa-size-scale-xl,
  "2xl"                 : $fa-size-scale-2xl
) !default;

$fa-li-width            : 2em !default;
$fa-li-margin           : $fa-li-width * fa-divide(5, 4) !default;

$fa-pull-margin         : .3em !default;

$fa-primary-opacity     : 1 !default;
$fa-secondary-opacity   : .4 !default;

$fa-stack-vertical-align: middle !default;
$fa-stack-width         : ($fa-fw-width * 2) !default;
$fa-stack-z-index       : auto !default;

$fa-font-display        : block !default;
$fa-font-path           : "../webfonts" !default;

$fa-var-0: \30;
$fa-var-00: \e467;
$fa-var-1: \31;
$fa-var-2: \32;
$fa-var-3: \33;
$fa-var-360-degrees: \e2dc;
$fa-var-4: \34;
$fa-var-5: \35;
$fa-var-6: \36;
$fa-var-7: \37;
$fa-var-8: \38;
$fa-var-9: \39;
$fa-var-a: \41;
$fa-var-abacus: \f640;
$fa-var-accent-grave: \60;
$fa-var-acorn: \f6ae;
$fa-var-address-book: \f2b9;
$fa-var-contact-book: \f2b9;
$fa-var-address-card: \f2bb;
$fa-var-contact-card: \f2bb;
$fa-var-vcard: \f2bb;
$fa-var-air-conditioner: \f8f4;
$fa-var-airplay: \e089;
$fa-var-alarm-clock: \f34e;
$fa-var-alarm-exclamation: \f843;
$fa-var-alarm-plus: \f844;
$fa-var-alarm-snooze: \f845;
$fa-var-album: \f89f;
$fa-var-album-circle-plus: \e48c;
$fa-var-album-circle-user: \e48d;
$fa-var-album-collection: \f8a0;
$fa-var-album-collection-circle-plus: \e48e;
$fa-var-album-collection-circle-user: \e48f;
$fa-var-alicorn: \f6b0;
$fa-var-alien: \f8f5;
$fa-var-alien-8bit: \f8f6;
$fa-var-alien-monster: \f8f6;
$fa-var-align-center: \f037;
$fa-var-align-justify: \f039;
$fa-var-align-left: \f036;
$fa-var-align-right: \f038;
$fa-var-align-slash: \f846;
$fa-var-alt: \e08a;
$fa-var-amp-guitar: \f8a1;
$fa-var-ampersand: \26;
$fa-var-anchor: \f13d;
$fa-var-anchor-circle-check: \e4aa;
$fa-var-anchor-circle-exclamation: \e4ab;
$fa-var-anchor-circle-xmark: \e4ac;
$fa-var-anchor-lock: \e4ad;
$fa-var-angel: \f779;
$fa-var-angle: \e08c;
$fa-var-angle-90: \e08d;
$fa-var-angle-down: \f107;
$fa-var-angle-left: \f104;
$fa-var-angle-right: \f105;
$fa-var-angle-up: \f106;
$fa-var-angles-down: \f103;
$fa-var-angle-double-down: \f103;
$fa-var-angles-left: \f100;
$fa-var-angle-double-left: \f100;
$fa-var-angles-right: \f101;
$fa-var-angle-double-right: \f101;
$fa-var-angles-up: \f102;
$fa-var-angle-double-up: \f102;
$fa-var-angles-up-down: \e60d;
$fa-var-ankh: \f644;
$fa-var-ant: \e680;
$fa-var-apartment: \e468;
$fa-var-aperture: \e2df;
$fa-var-apostrophe: \27;
$fa-var-apple-core: \e08f;
$fa-var-apple-whole: \f5d1;
$fa-var-apple-alt: \f5d1;
$fa-var-archway: \f557;
$fa-var-arrow-down: \f063;
$fa-var-arrow-down-1-9: \f162;
$fa-var-sort-numeric-asc: \f162;
$fa-var-sort-numeric-down: \f162;
$fa-var-arrow-down-9-1: \f886;
$fa-var-sort-numeric-desc: \f886;
$fa-var-sort-numeric-down-alt: \f886;
$fa-var-arrow-down-a-z: \f15d;
$fa-var-sort-alpha-asc: \f15d;
$fa-var-sort-alpha-down: \f15d;
$fa-var-arrow-down-arrow-up: \f883;
$fa-var-sort-alt: \f883;
$fa-var-arrow-down-big-small: \f88c;
$fa-var-sort-size-down: \f88c;
$fa-var-arrow-down-from-arc: \e614;
$fa-var-arrow-down-from-bracket: \e667;
$fa-var-arrow-down-from-dotted-line: \e090;
$fa-var-arrow-down-from-line: \f345;
$fa-var-arrow-from-top: \f345;
$fa-var-arrow-down-left: \e091;
$fa-var-arrow-down-left-and-arrow-up-right-to-center: \e092;
$fa-var-arrow-down-long: \f175;
$fa-var-long-arrow-down: \f175;
$fa-var-arrow-down-right: \e093;
$fa-var-arrow-down-short-wide: \f884;
$fa-var-sort-amount-desc: \f884;
$fa-var-sort-amount-down-alt: \f884;
$fa-var-arrow-down-small-big: \f88d;
$fa-var-sort-size-down-alt: \f88d;
$fa-var-arrow-down-square-triangle: \f889;
$fa-var-sort-shapes-down-alt: \f889;
$fa-var-arrow-down-to-arc: \e4ae;
$fa-var-arrow-down-to-bracket: \e094;
$fa-var-arrow-down-to-dotted-line: \e095;
$fa-var-arrow-down-to-line: \f33d;
$fa-var-arrow-to-bottom: \f33d;
$fa-var-arrow-down-to-square: \e096;
$fa-var-arrow-down-triangle-square: \f888;
$fa-var-sort-shapes-down: \f888;
$fa-var-arrow-down-up-across-line: \e4af;
$fa-var-arrow-down-up-lock: \e4b0;
$fa-var-arrow-down-wide-short: \f160;
$fa-var-sort-amount-asc: \f160;
$fa-var-sort-amount-down: \f160;
$fa-var-arrow-down-z-a: \f881;
$fa-var-sort-alpha-desc: \f881;
$fa-var-sort-alpha-down-alt: \f881;
$fa-var-arrow-left: \f060;
$fa-var-arrow-left-from-arc: \e615;
$fa-var-arrow-left-from-bracket: \e668;
$fa-var-arrow-left-from-line: \f344;
$fa-var-arrow-from-right: \f344;
$fa-var-arrow-left-long: \f177;
$fa-var-long-arrow-left: \f177;
$fa-var-arrow-left-long-to-line: \e3d4;
$fa-var-arrow-left-to-arc: \e616;
$fa-var-arrow-left-to-bracket: \e669;
$fa-var-arrow-left-to-line: \f33e;
$fa-var-arrow-to-left: \f33e;
$fa-var-arrow-pointer: \f245;
$fa-var-mouse-pointer: \f245;
$fa-var-arrow-progress: \e5df;
$fa-var-arrow-right: \f061;
$fa-var-arrow-right-arrow-left: \f0ec;
$fa-var-exchange: \f0ec;
$fa-var-arrow-right-from-arc: \e4b1;
$fa-var-arrow-right-from-bracket: \f08b;
$fa-var-sign-out: \f08b;
$fa-var-arrow-right-from-line: \f343;
$fa-var-arrow-from-left: \f343;
$fa-var-arrow-right-long: \f178;
$fa-var-long-arrow-right: \f178;
$fa-var-arrow-right-long-to-line: \e3d5;
$fa-var-arrow-right-to-arc: \e4b2;
$fa-var-arrow-right-to-bracket: \f090;
$fa-var-sign-in: \f090;
$fa-var-arrow-right-to-city: \e4b3;
$fa-var-arrow-right-to-line: \f340;
$fa-var-arrow-to-right: \f340;
$fa-var-arrow-rotate-left: \f0e2;
$fa-var-arrow-left-rotate: \f0e2;
$fa-var-arrow-rotate-back: \f0e2;
$fa-var-arrow-rotate-backward: \f0e2;
$fa-var-undo: \f0e2;
$fa-var-arrow-rotate-right: \f01e;
$fa-var-arrow-right-rotate: \f01e;
$fa-var-arrow-rotate-forward: \f01e;
$fa-var-redo: \f01e;
$fa-var-arrow-trend-down: \e097;
$fa-var-arrow-trend-up: \e098;
$fa-var-arrow-turn-down: \f149;
$fa-var-level-down: \f149;
$fa-var-arrow-turn-down-left: \e2e1;
$fa-var-arrow-turn-down-right: \e3d6;
$fa-var-arrow-turn-left: \e632;
$fa-var-arrow-turn-left-down: \e633;
$fa-var-arrow-turn-left-up: \e634;
$fa-var-arrow-turn-right: \e635;
$fa-var-arrow-turn-up: \f148;
$fa-var-level-up: \f148;
$fa-var-arrow-up: \f062;
$fa-var-arrow-up-1-9: \f163;
$fa-var-sort-numeric-up: \f163;
$fa-var-arrow-up-9-1: \f887;
$fa-var-sort-numeric-up-alt: \f887;
$fa-var-arrow-up-a-z: \f15e;
$fa-var-sort-alpha-up: \f15e;
$fa-var-arrow-up-arrow-down: \e099;
$fa-var-sort-up-down: \e099;
$fa-var-arrow-up-big-small: \f88e;
$fa-var-sort-size-up: \f88e;
$fa-var-arrow-up-from-arc: \e4b4;
$fa-var-arrow-up-from-bracket: \e09a;
$fa-var-arrow-up-from-dotted-line: \e09b;
$fa-var-arrow-up-from-ground-water: \e4b5;
$fa-var-arrow-up-from-line: \f342;
$fa-var-arrow-from-bottom: \f342;
$fa-var-arrow-up-from-square: \e09c;
$fa-var-arrow-up-from-water-pump: \e4b6;
$fa-var-arrow-up-left: \e09d;
$fa-var-arrow-up-left-from-circle: \e09e;
$fa-var-arrow-up-long: \f176;
$fa-var-long-arrow-up: \f176;
$fa-var-arrow-up-right: \e09f;
$fa-var-arrow-up-right-and-arrow-down-left-from-center: \e0a0;
$fa-var-arrow-up-right-dots: \e4b7;
$fa-var-arrow-up-right-from-square: \f08e;
$fa-var-external-link: \f08e;
$fa-var-arrow-up-short-wide: \f885;
$fa-var-sort-amount-up-alt: \f885;
$fa-var-arrow-up-small-big: \f88f;
$fa-var-sort-size-up-alt: \f88f;
$fa-var-arrow-up-square-triangle: \f88b;
$fa-var-sort-shapes-up-alt: \f88b;
$fa-var-arrow-up-to-arc: \e617;
$fa-var-arrow-up-to-bracket: \e66a;
$fa-var-arrow-up-to-dotted-line: \e0a1;
$fa-var-arrow-up-to-line: \f341;
$fa-var-arrow-to-top: \f341;
$fa-var-arrow-up-triangle-square: \f88a;
$fa-var-sort-shapes-up: \f88a;
$fa-var-arrow-up-wide-short: \f161;
$fa-var-sort-amount-up: \f161;
$fa-var-arrow-up-z-a: \f882;
$fa-var-sort-alpha-up-alt: \f882;
$fa-var-arrows-cross: \e0a2;
$fa-var-arrows-down-to-line: \e4b8;
$fa-var-arrows-down-to-people: \e4b9;
$fa-var-arrows-from-dotted-line: \e0a3;
$fa-var-arrows-from-line: \e0a4;
$fa-var-arrows-left-right: \f07e;
$fa-var-arrows-h: \f07e;
$fa-var-arrows-left-right-to-line: \e4ba;
$fa-var-arrows-maximize: \f31d;
$fa-var-expand-arrows: \f31d;
$fa-var-arrows-minimize: \e0a5;
$fa-var-compress-arrows: \e0a5;
$fa-var-arrows-repeat: \f364;
$fa-var-repeat-alt: \f364;
$fa-var-arrows-repeat-1: \f366;
$fa-var-repeat-1-alt: \f366;
$fa-var-arrows-retweet: \f361;
$fa-var-retweet-alt: \f361;
$fa-var-arrows-rotate: \f021;
$fa-var-refresh: \f021;
$fa-var-sync: \f021;
$fa-var-arrows-rotate-reverse: \e630;
$fa-var-arrows-spin: \e4bb;
$fa-var-arrows-split-up-and-left: \e4bc;
$fa-var-arrows-to-circle: \e4bd;
$fa-var-arrows-to-dot: \e4be;
$fa-var-arrows-to-dotted-line: \e0a6;
$fa-var-arrows-to-eye: \e4bf;
$fa-var-arrows-to-line: \e0a7;
$fa-var-arrows-turn-right: \e4c0;
$fa-var-arrows-turn-to-dots: \e4c1;
$fa-var-arrows-up-down: \f07d;
$fa-var-arrows-v: \f07d;
$fa-var-arrows-up-down-left-right: \f047;
$fa-var-arrows: \f047;
$fa-var-arrows-up-to-line: \e4c2;
$fa-var-asterisk: \2a;
$fa-var-at: \40;
$fa-var-atom: \f5d2;
$fa-var-atom-simple: \f5d3;
$fa-var-atom-alt: \f5d3;
$fa-var-audio-description: \f29e;
$fa-var-audio-description-slash: \e0a8;
$fa-var-austral-sign: \e0a9;
$fa-var-avocado: \e0aa;
$fa-var-award: \f559;
$fa-var-award-simple: \e0ab;
$fa-var-axe: \f6b2;
$fa-var-axe-battle: \f6b3;
$fa-var-b: \42;
$fa-var-baby: \f77c;
$fa-var-baby-carriage: \f77d;
$fa-var-carriage-baby: \f77d;
$fa-var-backpack: \f5d4;
$fa-var-backward: \f04a;
$fa-var-backward-fast: \f049;
$fa-var-fast-backward: \f049;
$fa-var-backward-step: \f048;
$fa-var-step-backward: \f048;
$fa-var-bacon: \f7e5;
$fa-var-bacteria: \e059;
$fa-var-bacterium: \e05a;
$fa-var-badge: \f335;
$fa-var-badge-check: \f336;
$fa-var-badge-dollar: \f645;
$fa-var-badge-percent: \f646;
$fa-var-badge-sheriff: \f8a2;
$fa-var-badger-honey: \f6b4;
$fa-var-badminton: \e33a;
$fa-var-bag-seedling: \e5f2;
$fa-var-bag-shopping: \f290;
$fa-var-shopping-bag: \f290;
$fa-var-bag-shopping-minus: \e650;
$fa-var-bag-shopping-plus: \e651;
$fa-var-bagel: \e3d7;
$fa-var-bags-shopping: \f847;
$fa-var-baguette: \e3d8;
$fa-var-bahai: \f666;
$fa-var-haykal: \f666;
$fa-var-baht-sign: \e0ac;
$fa-var-ball-pile: \f77e;
$fa-var-balloon: \e2e3;
$fa-var-balloons: \e2e4;
$fa-var-ballot: \f732;
$fa-var-ballot-check: \f733;
$fa-var-ban: \f05e;
$fa-var-cancel: \f05e;
$fa-var-ban-bug: \f7f9;
$fa-var-debug: \f7f9;
$fa-var-ban-parking: \f616;
$fa-var-parking-circle-slash: \f616;
$fa-var-ban-smoking: \f54d;
$fa-var-smoking-ban: \f54d;
$fa-var-banana: \e2e5;
$fa-var-bandage: \f462;
$fa-var-band-aid: \f462;
$fa-var-bangladeshi-taka-sign: \e2e6;
$fa-var-banjo: \f8a3;
$fa-var-barcode: \f02a;
$fa-var-barcode-read: \f464;
$fa-var-barcode-scan: \f465;
$fa-var-bars: \f0c9;
$fa-var-navicon: \f0c9;
$fa-var-bars-filter: \e0ad;
$fa-var-bars-progress: \f828;
$fa-var-tasks-alt: \f828;
$fa-var-bars-sort: \e0ae;
$fa-var-bars-staggered: \f550;
$fa-var-reorder: \f550;
$fa-var-stream: \f550;
$fa-var-baseball: \f433;
$fa-var-baseball-ball: \f433;
$fa-var-baseball-bat-ball: \f432;
$fa-var-basket-shopping: \f291;
$fa-var-shopping-basket: \f291;
$fa-var-basket-shopping-minus: \e652;
$fa-var-basket-shopping-plus: \e653;
$fa-var-basket-shopping-simple: \e0af;
$fa-var-shopping-basket-alt: \e0af;
$fa-var-basketball: \f434;
$fa-var-basketball-ball: \f434;
$fa-var-basketball-hoop: \f435;
$fa-var-bat: \f6b5;
$fa-var-bath: \f2cd;
$fa-var-bathtub: \f2cd;
$fa-var-battery-bolt: \f376;
$fa-var-battery-empty: \f244;
$fa-var-battery-0: \f244;
$fa-var-battery-exclamation: \e0b0;
$fa-var-battery-full: \f240;
$fa-var-battery: \f240;
$fa-var-battery-5: \f240;
$fa-var-battery-half: \f242;
$fa-var-battery-3: \f242;
$fa-var-battery-low: \e0b1;
$fa-var-battery-1: \e0b1;
$fa-var-battery-quarter: \f243;
$fa-var-battery-2: \f243;
$fa-var-battery-slash: \f377;
$fa-var-battery-three-quarters: \f241;
$fa-var-battery-4: \f241;
$fa-var-bed: \f236;
$fa-var-bed-bunk: \f8f8;
$fa-var-bed-empty: \f8f9;
$fa-var-bed-front: \f8f7;
$fa-var-bed-alt: \f8f7;
$fa-var-bed-pulse: \f487;
$fa-var-procedures: \f487;
$fa-var-bee: \e0b2;
$fa-var-beer-mug: \e0b3;
$fa-var-beer-foam: \e0b3;
$fa-var-beer-mug-empty: \f0fc;
$fa-var-beer: \f0fc;
$fa-var-bell: \f0f3;
$fa-var-bell-concierge: \f562;
$fa-var-concierge-bell: \f562;
$fa-var-bell-exclamation: \f848;
$fa-var-bell-on: \f8fa;
$fa-var-bell-plus: \f849;
$fa-var-bell-ring: \e62c;
$fa-var-bell-school: \f5d5;
$fa-var-bell-school-slash: \f5d6;
$fa-var-bell-slash: \f1f6;
$fa-var-bells: \f77f;
$fa-var-bench-tree: \e2e7;
$fa-var-bezier-curve: \f55b;
$fa-var-bicycle: \f206;
$fa-var-billboard: \e5cd;
$fa-var-bin-bottles: \e5f5;
$fa-var-bin-bottles-recycle: \e5f6;
$fa-var-bin-recycle: \e5f7;
$fa-var-binary: \e33b;
$fa-var-binary-circle-check: \e33c;
$fa-var-binary-lock: \e33d;
$fa-var-binary-slash: \e33e;
$fa-var-binoculars: \f1e5;
$fa-var-biohazard: \f780;
$fa-var-bird: \e469;
$fa-var-bitcoin-sign: \e0b4;
$fa-var-blanket: \f498;
$fa-var-blanket-fire: \e3da;
$fa-var-blender: \f517;
$fa-var-blender-phone: \f6b6;
$fa-var-blinds: \f8fb;
$fa-var-blinds-open: \f8fc;
$fa-var-blinds-raised: \f8fd;
$fa-var-block: \e46a;
$fa-var-block-brick: \e3db;
$fa-var-wall-brick: \e3db;
$fa-var-block-brick-fire: \e3dc;
$fa-var-firewall: \e3dc;
$fa-var-block-question: \e3dd;
$fa-var-block-quote: \e0b5;
$fa-var-blog: \f781;
$fa-var-blueberries: \e2e8;
$fa-var-bold: \f032;
$fa-var-bolt: \f0e7;
$fa-var-zap: \f0e7;
$fa-var-bolt-auto: \e0b6;
$fa-var-bolt-lightning: \e0b7;
$fa-var-bolt-slash: \e0b8;
$fa-var-bomb: \f1e2;
$fa-var-bone: \f5d7;
$fa-var-bone-break: \f5d8;
$fa-var-bong: \f55c;
$fa-var-book: \f02d;
$fa-var-book-arrow-right: \e0b9;
$fa-var-book-arrow-up: \e0ba;
$fa-var-book-atlas: \f558;
$fa-var-atlas: \f558;
$fa-var-book-bible: \f647;
$fa-var-bible: \f647;
$fa-var-book-blank: \f5d9;
$fa-var-book-alt: \f5d9;
$fa-var-book-bookmark: \e0bb;
$fa-var-book-circle-arrow-right: \e0bc;
$fa-var-book-circle-arrow-up: \e0bd;
$fa-var-book-copy: \e0be;
$fa-var-book-font: \e0bf;
$fa-var-book-heart: \f499;
$fa-var-book-journal-whills: \f66a;
$fa-var-journal-whills: \f66a;
$fa-var-book-medical: \f7e6;
$fa-var-book-open: \f518;
$fa-var-book-open-cover: \e0c0;
$fa-var-book-open-alt: \e0c0;
$fa-var-book-open-reader: \f5da;
$fa-var-book-reader: \f5da;
$fa-var-book-quran: \f687;
$fa-var-quran: \f687;
$fa-var-book-section: \e0c1;
$fa-var-book-law: \e0c1;
$fa-var-book-skull: \f6b7;
$fa-var-book-dead: \f6b7;
$fa-var-book-sparkles: \f6b8;
$fa-var-book-spells: \f6b8;
$fa-var-book-tanakh: \f827;
$fa-var-tanakh: \f827;
$fa-var-book-user: \f7e7;
$fa-var-bookmark: \f02e;
$fa-var-bookmark-slash: \e0c2;
$fa-var-books: \f5db;
$fa-var-books-medical: \f7e8;
$fa-var-boombox: \f8a5;
$fa-var-boot: \f782;
$fa-var-boot-heeled: \e33f;
$fa-var-booth-curtain: \f734;
$fa-var-border-all: \f84c;
$fa-var-border-bottom: \f84d;
$fa-var-border-bottom-right: \f854;
$fa-var-border-style-alt: \f854;
$fa-var-border-center-h: \f89c;
$fa-var-border-center-v: \f89d;
$fa-var-border-inner: \f84e;
$fa-var-border-left: \f84f;
$fa-var-border-none: \f850;
$fa-var-border-outer: \f851;
$fa-var-border-right: \f852;
$fa-var-border-top: \f855;
$fa-var-border-top-left: \f853;
$fa-var-border-style: \f853;
$fa-var-bore-hole: \e4c3;
$fa-var-bottle-baby: \e673;
$fa-var-bottle-droplet: \e4c4;
$fa-var-bottle-water: \e4c5;
$fa-var-bow-arrow: \f6b9;
$fa-var-bowl-chopsticks: \e2e9;
$fa-var-bowl-chopsticks-noodles: \e2ea;
$fa-var-bowl-food: \e4c6;
$fa-var-bowl-hot: \f823;
$fa-var-soup: \f823;
$fa-var-bowl-rice: \e2eb;
$fa-var-bowl-scoop: \e3de;
$fa-var-bowl-shaved-ice: \e3de;
$fa-var-bowl-scoops: \e3df;
$fa-var-bowl-soft-serve: \e46b;
$fa-var-bowl-spoon: \e3e0;
$fa-var-bowling-ball: \f436;
$fa-var-bowling-ball-pin: \e0c3;
$fa-var-bowling-pins: \f437;
$fa-var-box: \f466;
$fa-var-box-archive: \f187;
$fa-var-archive: \f187;
$fa-var-box-ballot: \f735;
$fa-var-box-check: \f467;
$fa-var-box-circle-check: \e0c4;
$fa-var-box-dollar: \f4a0;
$fa-var-box-usd: \f4a0;
$fa-var-box-heart: \f49d;
$fa-var-box-open: \f49e;
$fa-var-box-open-full: \f49c;
$fa-var-box-full: \f49c;
$fa-var-box-taped: \f49a;
$fa-var-box-alt: \f49a;
$fa-var-box-tissue: \e05b;
$fa-var-boxes-packing: \e4c7;
$fa-var-boxes-stacked: \f468;
$fa-var-boxes: \f468;
$fa-var-boxes-alt: \f468;
$fa-var-boxing-glove: \f438;
$fa-var-glove-boxing: \f438;
$fa-var-bracket-curly: \7b;
$fa-var-bracket-curly-left: \7b;
$fa-var-bracket-curly-right: \7d;
$fa-var-bracket-round: \28;
$fa-var-parenthesis: \28;
$fa-var-bracket-round-right: \29;
$fa-var-bracket-square: \5b;
$fa-var-bracket: \5b;
$fa-var-bracket-left: \5b;
$fa-var-bracket-square-right: \5d;
$fa-var-brackets-curly: \f7ea;
$fa-var-brackets-round: \e0c5;
$fa-var-parentheses: \e0c5;
$fa-var-brackets-square: \f7e9;
$fa-var-brackets: \f7e9;
$fa-var-braille: \f2a1;
$fa-var-brain: \f5dc;
$fa-var-brain-arrow-curved-right: \f677;
$fa-var-mind-share: \f677;
$fa-var-brain-circuit: \e0c6;
$fa-var-brake-warning: \e0c7;
$fa-var-brazilian-real-sign: \e46c;
$fa-var-bread-loaf: \f7eb;
$fa-var-bread-slice: \f7ec;
$fa-var-bread-slice-butter: \e3e1;
$fa-var-bridge: \e4c8;
$fa-var-bridge-circle-check: \e4c9;
$fa-var-bridge-circle-exclamation: \e4ca;
$fa-var-bridge-circle-xmark: \e4cb;
$fa-var-bridge-lock: \e4cc;
$fa-var-bridge-suspension: \e4cd;
$fa-var-bridge-water: \e4ce;
$fa-var-briefcase: \f0b1;
$fa-var-briefcase-arrow-right: \e2f2;
$fa-var-briefcase-blank: \e0c8;
$fa-var-briefcase-medical: \f469;
$fa-var-brightness: \e0c9;
$fa-var-brightness-low: \e0ca;
$fa-var-bring-forward: \f856;
$fa-var-bring-front: \f857;
$fa-var-broccoli: \e3e2;
$fa-var-broom: \f51a;
$fa-var-broom-ball: \f458;
$fa-var-quidditch: \f458;
$fa-var-quidditch-broom-ball: \f458;
$fa-var-broom-wide: \e5d1;
$fa-var-browser: \f37e;
$fa-var-browsers: \e0cb;
$fa-var-brush: \f55d;
$fa-var-bucket: \e4cf;
$fa-var-bug: \f188;
$fa-var-bug-slash: \e490;
$fa-var-bugs: \e4d0;
$fa-var-building: \f1ad;
$fa-var-building-circle-arrow-right: \e4d1;
$fa-var-building-circle-check: \e4d2;
$fa-var-building-circle-exclamation: \e4d3;
$fa-var-building-circle-xmark: \e4d4;
$fa-var-building-columns: \f19c;
$fa-var-bank: \f19c;
$fa-var-institution: \f19c;
$fa-var-museum: \f19c;
$fa-var-university: \f19c;
$fa-var-building-flag: \e4d5;
$fa-var-building-lock: \e4d6;
$fa-var-building-magnifying-glass: \e61c;
$fa-var-building-memo: \e61e;
$fa-var-building-ngo: \e4d7;
$fa-var-building-shield: \e4d8;
$fa-var-building-un: \e4d9;
$fa-var-building-user: \e4da;
$fa-var-building-wheat: \e4db;
$fa-var-buildings: \e0cc;
$fa-var-bulldozer: \e655;
$fa-var-bullhorn: \f0a1;
$fa-var-bullseye: \f140;
$fa-var-bullseye-arrow: \f648;
$fa-var-bullseye-pointer: \f649;
$fa-var-buoy: \e5b5;
$fa-var-buoy-mooring: \e5b6;
$fa-var-burger: \f805;
$fa-var-hamburger: \f805;
$fa-var-burger-cheese: \f7f1;
$fa-var-cheeseburger: \f7f1;
$fa-var-burger-fries: \e0cd;
$fa-var-burger-glass: \e0ce;
$fa-var-burger-lettuce: \e3e3;
$fa-var-burger-soda: \f858;
$fa-var-burrito: \f7ed;
$fa-var-burst: \e4dc;
$fa-var-bus: \f207;
$fa-var-bus-school: \f5dd;
$fa-var-bus-simple: \f55e;
$fa-var-bus-alt: \f55e;
$fa-var-business-time: \f64a;
$fa-var-briefcase-clock: \f64a;
$fa-var-butter: \e3e4;
$fa-var-c: \43;
$fa-var-cabin: \e46d;
$fa-var-cabinet-filing: \f64b;
$fa-var-cable-car: \f7da;
$fa-var-tram: \f7da;
$fa-var-cactus: \f8a7;
$fa-var-caduceus: \e681;
$fa-var-cake-candles: \f1fd;
$fa-var-birthday-cake: \f1fd;
$fa-var-cake: \f1fd;
$fa-var-cake-slice: \e3e5;
$fa-var-shortcake: \e3e5;
$fa-var-calculator: \f1ec;
$fa-var-calculator-simple: \f64c;
$fa-var-calculator-alt: \f64c;
$fa-var-calendar: \f133;
$fa-var-calendar-arrow-down: \e0d0;
$fa-var-calendar-download: \e0d0;
$fa-var-calendar-arrow-up: \e0d1;
$fa-var-calendar-upload: \e0d1;
$fa-var-calendar-check: \f274;
$fa-var-calendar-circle-exclamation: \e46e;
$fa-var-calendar-circle-minus: \e46f;
$fa-var-calendar-circle-plus: \e470;
$fa-var-calendar-circle-user: \e471;
$fa-var-calendar-clock: \e0d2;
$fa-var-calendar-time: \e0d2;
$fa-var-calendar-day: \f783;
$fa-var-calendar-days: \f073;
$fa-var-calendar-alt: \f073;
$fa-var-calendar-exclamation: \f334;
$fa-var-calendar-heart: \e0d3;
$fa-var-calendar-image: \e0d4;
$fa-var-calendar-lines: \e0d5;
$fa-var-calendar-note: \e0d5;
$fa-var-calendar-lines-pen: \e472;
$fa-var-calendar-minus: \f272;
$fa-var-calendar-pen: \f333;
$fa-var-calendar-edit: \f333;
$fa-var-calendar-plus: \f271;
$fa-var-calendar-range: \e0d6;
$fa-var-calendar-star: \f736;
$fa-var-calendar-users: \e5e2;
$fa-var-calendar-week: \f784;
$fa-var-calendar-xmark: \f273;
$fa-var-calendar-times: \f273;
$fa-var-calendars: \e0d7;
$fa-var-camcorder: \f8a8;
$fa-var-video-handheld: \f8a8;
$fa-var-camera: \f030;
$fa-var-camera-alt: \f030;
$fa-var-camera-cctv: \f8ac;
$fa-var-cctv: \f8ac;
$fa-var-camera-movie: \f8a9;
$fa-var-camera-polaroid: \f8aa;
$fa-var-camera-retro: \f083;
$fa-var-camera-rotate: \e0d8;
$fa-var-camera-security: \f8fe;
$fa-var-camera-home: \f8fe;
$fa-var-camera-slash: \e0d9;
$fa-var-camera-viewfinder: \e0da;
$fa-var-screenshot: \e0da;
$fa-var-camera-web: \f832;
$fa-var-webcam: \f832;
$fa-var-camera-web-slash: \f833;
$fa-var-webcam-slash: \f833;
$fa-var-campfire: \f6ba;
$fa-var-campground: \f6bb;
$fa-var-can-food: \e3e6;
$fa-var-candle-holder: \f6bc;
$fa-var-candy: \e3e7;
$fa-var-candy-bar: \e3e8;
$fa-var-chocolate-bar: \e3e8;
$fa-var-candy-cane: \f786;
$fa-var-candy-corn: \f6bd;
$fa-var-cannabis: \f55f;
$fa-var-cannon: \e642;
$fa-var-capsules: \f46b;
$fa-var-car: \f1b9;
$fa-var-automobile: \f1b9;
$fa-var-car-battery: \f5df;
$fa-var-battery-car: \f5df;
$fa-var-car-bolt: \e341;
$fa-var-car-building: \f859;
$fa-var-car-bump: \f5e0;
$fa-var-car-burst: \f5e1;
$fa-var-car-crash: \f5e1;
$fa-var-car-bus: \f85a;
$fa-var-car-circle-bolt: \e342;
$fa-var-car-garage: \f5e2;
$fa-var-car-mirrors: \e343;
$fa-var-car-on: \e4dd;
$fa-var-car-rear: \f5de;
$fa-var-car-alt: \f5de;
$fa-var-car-side: \f5e4;
$fa-var-car-side-bolt: \e344;
$fa-var-car-tilt: \f5e5;
$fa-var-car-tunnel: \e4de;
$fa-var-car-wash: \f5e6;
$fa-var-car-wrench: \f5e3;
$fa-var-car-mechanic: \f5e3;
$fa-var-caravan: \f8ff;
$fa-var-caravan-simple: \e000;
$fa-var-caravan-alt: \e000;
$fa-var-card-club: \e3e9;
$fa-var-card-diamond: \e3ea;
$fa-var-card-heart: \e3eb;
$fa-var-card-spade: \e3ec;
$fa-var-cards: \e3ed;
$fa-var-cards-blank: \e4df;
$fa-var-caret-down: \f0d7;
$fa-var-caret-left: \f0d9;
$fa-var-caret-right: \f0da;
$fa-var-caret-up: \f0d8;
$fa-var-carrot: \f787;
$fa-var-cars: \f85b;
$fa-var-cart-arrow-down: \f218;
$fa-var-cart-arrow-up: \e3ee;
$fa-var-cart-circle-arrow-down: \e3ef;
$fa-var-cart-circle-arrow-up: \e3f0;
$fa-var-cart-circle-check: \e3f1;
$fa-var-cart-circle-exclamation: \e3f2;
$fa-var-cart-circle-plus: \e3f3;
$fa-var-cart-circle-xmark: \e3f4;
$fa-var-cart-flatbed: \f474;
$fa-var-dolly-flatbed: \f474;
$fa-var-cart-flatbed-boxes: \f475;
$fa-var-dolly-flatbed-alt: \f475;
$fa-var-cart-flatbed-empty: \f476;
$fa-var-dolly-flatbed-empty: \f476;
$fa-var-cart-flatbed-suitcase: \f59d;
$fa-var-luggage-cart: \f59d;
$fa-var-cart-minus: \e0db;
$fa-var-cart-plus: \f217;
$fa-var-cart-shopping: \f07a;
$fa-var-shopping-cart: \f07a;
$fa-var-cart-shopping-fast: \e0dc;
$fa-var-cart-xmark: \e0dd;
$fa-var-cash-register: \f788;
$fa-var-cassette-betamax: \f8a4;
$fa-var-betamax: \f8a4;
$fa-var-cassette-tape: \f8ab;
$fa-var-cassette-vhs: \f8ec;
$fa-var-vhs: \f8ec;
$fa-var-castle: \e0de;
$fa-var-cat: \f6be;
$fa-var-cat-space: \e001;
$fa-var-cauldron: \f6bf;
$fa-var-cedi-sign: \e0df;
$fa-var-cent-sign: \e3f5;
$fa-var-certificate: \f0a3;
$fa-var-chair: \f6c0;
$fa-var-chair-office: \f6c1;
$fa-var-chalkboard: \f51b;
$fa-var-blackboard: \f51b;
$fa-var-chalkboard-user: \f51c;
$fa-var-chalkboard-teacher: \f51c;
$fa-var-champagne-glass: \f79e;
$fa-var-glass-champagne: \f79e;
$fa-var-champagne-glasses: \f79f;
$fa-var-glass-cheers: \f79f;
$fa-var-charging-station: \f5e7;
$fa-var-chart-area: \f1fe;
$fa-var-area-chart: \f1fe;
$fa-var-chart-bar: \f080;
$fa-var-bar-chart: \f080;
$fa-var-chart-bullet: \e0e1;
$fa-var-chart-candlestick: \e0e2;
$fa-var-chart-column: \e0e3;
$fa-var-chart-gantt: \e0e4;
$fa-var-chart-kanban: \e64f;
$fa-var-chart-line: \f201;
$fa-var-line-chart: \f201;
$fa-var-chart-line-down: \f64d;
$fa-var-chart-line-up: \e0e5;
$fa-var-chart-line-up-down: \e5d7;
$fa-var-chart-mixed: \f643;
$fa-var-analytics: \f643;
$fa-var-chart-mixed-up-circle-currency: \e5d8;
$fa-var-chart-mixed-up-circle-dollar: \e5d9;
$fa-var-chart-network: \f78a;
$fa-var-chart-pie: \f200;
$fa-var-pie-chart: \f200;
$fa-var-chart-pie-simple: \f64e;
$fa-var-chart-pie-alt: \f64e;
$fa-var-chart-pie-simple-circle-currency: \e604;
$fa-var-chart-pie-simple-circle-dollar: \e605;
$fa-var-chart-pyramid: \e0e6;
$fa-var-chart-radar: \e0e7;
$fa-var-chart-scatter: \f7ee;
$fa-var-chart-scatter-3d: \e0e8;
$fa-var-chart-scatter-bubble: \e0e9;
$fa-var-chart-simple: \e473;
$fa-var-chart-simple-horizontal: \e474;
$fa-var-chart-tree-map: \e0ea;
$fa-var-chart-user: \f6a3;
$fa-var-user-chart: \f6a3;
$fa-var-chart-waterfall: \e0eb;
$fa-var-check: \f00c;
$fa-var-check-double: \f560;
$fa-var-check-to-slot: \f772;
$fa-var-vote-yea: \f772;
$fa-var-cheese: \f7ef;
$fa-var-cheese-swiss: \f7f0;
$fa-var-cherries: \e0ec;
$fa-var-chess: \f439;
$fa-var-chess-bishop: \f43a;
$fa-var-chess-bishop-piece: \f43b;
$fa-var-chess-bishop-alt: \f43b;
$fa-var-chess-board: \f43c;
$fa-var-chess-clock: \f43d;
$fa-var-chess-clock-flip: \f43e;
$fa-var-chess-clock-alt: \f43e;
$fa-var-chess-king: \f43f;
$fa-var-chess-king-piece: \f440;
$fa-var-chess-king-alt: \f440;
$fa-var-chess-knight: \f441;
$fa-var-chess-knight-piece: \f442;
$fa-var-chess-knight-alt: \f442;
$fa-var-chess-pawn: \f443;
$fa-var-chess-pawn-piece: \f444;
$fa-var-chess-pawn-alt: \f444;
$fa-var-chess-queen: \f445;
$fa-var-chess-queen-piece: \f446;
$fa-var-chess-queen-alt: \f446;
$fa-var-chess-rook: \f447;
$fa-var-chess-rook-piece: \f448;
$fa-var-chess-rook-alt: \f448;
$fa-var-chestnut: \e3f6;
$fa-var-chevron-down: \f078;
$fa-var-chevron-left: \f053;
$fa-var-chevron-right: \f054;
$fa-var-chevron-up: \f077;
$fa-var-chevrons-down: \f322;
$fa-var-chevron-double-down: \f322;
$fa-var-chevrons-left: \f323;
$fa-var-chevron-double-left: \f323;
$fa-var-chevrons-right: \f324;
$fa-var-chevron-double-right: \f324;
$fa-var-chevrons-up: \f325;
$fa-var-chevron-double-up: \f325;
$fa-var-chf-sign: \e602;
$fa-var-child: \f1ae;
$fa-var-child-combatant: \e4e0;
$fa-var-child-rifle: \e4e0;
$fa-var-child-dress: \e59c;
$fa-var-child-reaching: \e59d;
$fa-var-children: \e4e1;
$fa-var-chimney: \f78b;
$fa-var-chopsticks: \e3f7;
$fa-var-church: \f51d;
$fa-var-circle: \f111;
$fa-var-circle-0: \e0ed;
$fa-var-circle-1: \e0ee;
$fa-var-circle-2: \e0ef;
$fa-var-circle-3: \e0f0;
$fa-var-circle-4: \e0f1;
$fa-var-circle-5: \e0f2;
$fa-var-circle-6: \e0f3;
$fa-var-circle-7: \e0f4;
$fa-var-circle-8: \e0f5;
$fa-var-circle-9: \e0f6;
$fa-var-circle-a: \e0f7;
$fa-var-circle-ampersand: \e0f8;
$fa-var-circle-arrow-down: \f0ab;
$fa-var-arrow-circle-down: \f0ab;
$fa-var-circle-arrow-down-left: \e0f9;
$fa-var-circle-arrow-down-right: \e0fa;
$fa-var-circle-arrow-left: \f0a8;
$fa-var-arrow-circle-left: \f0a8;
$fa-var-circle-arrow-right: \f0a9;
$fa-var-arrow-circle-right: \f0a9;
$fa-var-circle-arrow-up: \f0aa;
$fa-var-arrow-circle-up: \f0aa;
$fa-var-circle-arrow-up-left: \e0fb;
$fa-var-circle-arrow-up-right: \e0fc;
$fa-var-circle-b: \e0fd;
$fa-var-circle-bolt: \e0fe;
$fa-var-circle-book-open: \e0ff;
$fa-var-book-circle: \e0ff;
$fa-var-circle-bookmark: \e100;
$fa-var-bookmark-circle: \e100;
$fa-var-circle-c: \e101;
$fa-var-circle-calendar: \e102;
$fa-var-calendar-circle: \e102;
$fa-var-circle-camera: \e103;
$fa-var-camera-circle: \e103;
$fa-var-circle-caret-down: \f32d;
$fa-var-caret-circle-down: \f32d;
$fa-var-circle-caret-left: \f32e;
$fa-var-caret-circle-left: \f32e;
$fa-var-circle-caret-right: \f330;
$fa-var-caret-circle-right: \f330;
$fa-var-circle-caret-up: \f331;
$fa-var-caret-circle-up: \f331;
$fa-var-circle-check: \f058;
$fa-var-check-circle: \f058;
$fa-var-circle-chevron-down: \f13a;
$fa-var-chevron-circle-down: \f13a;
$fa-var-circle-chevron-left: \f137;
$fa-var-chevron-circle-left: \f137;
$fa-var-circle-chevron-right: \f138;
$fa-var-chevron-circle-right: \f138;
$fa-var-circle-chevron-up: \f139;
$fa-var-chevron-circle-up: \f139;
$fa-var-circle-d: \e104;
$fa-var-circle-dashed: \e105;
$fa-var-circle-divide: \e106;
$fa-var-circle-dollar: \f2e8;
$fa-var-dollar-circle: \f2e8;
$fa-var-usd-circle: \f2e8;
$fa-var-circle-dollar-to-slot: \f4b9;
$fa-var-donate: \f4b9;
$fa-var-circle-dot: \f192;
$fa-var-dot-circle: \f192;
$fa-var-circle-down: \f358;
$fa-var-arrow-alt-circle-down: \f358;
$fa-var-circle-down-left: \e107;
$fa-var-circle-down-right: \e108;
$fa-var-circle-e: \e109;
$fa-var-circle-ellipsis: \e10a;
$fa-var-circle-ellipsis-vertical: \e10b;
$fa-var-circle-envelope: \e10c;
$fa-var-envelope-circle: \e10c;
$fa-var-circle-euro: \e5ce;
$fa-var-circle-exclamation: \f06a;
$fa-var-exclamation-circle: \f06a;
$fa-var-circle-exclamation-check: \e10d;
$fa-var-circle-f: \e10e;
$fa-var-circle-g: \e10f;
$fa-var-circle-gf: \e67f;
$fa-var-circle-h: \f47e;
$fa-var-hospital-symbol: \f47e;
$fa-var-circle-half: \e110;
$fa-var-circle-half-stroke: \f042;
$fa-var-adjust: \f042;
$fa-var-circle-heart: \f4c7;
$fa-var-heart-circle: \f4c7;
$fa-var-circle-i: \e111;
$fa-var-circle-info: \f05a;
$fa-var-info-circle: \f05a;
$fa-var-circle-j: \e112;
$fa-var-circle-k: \e113;
$fa-var-circle-l: \e114;
$fa-var-circle-left: \f359;
$fa-var-arrow-alt-circle-left: \f359;
$fa-var-circle-location-arrow: \f602;
$fa-var-location-circle: \f602;
$fa-var-circle-m: \e115;
$fa-var-circle-microphone: \e116;
$fa-var-microphone-circle: \e116;
$fa-var-circle-microphone-lines: \e117;
$fa-var-microphone-circle-alt: \e117;
$fa-var-circle-minus: \f056;
$fa-var-minus-circle: \f056;
$fa-var-circle-n: \e118;
$fa-var-circle-nodes: \e4e2;
$fa-var-circle-notch: \f1ce;
$fa-var-circle-o: \e119;
$fa-var-circle-p: \e11a;
$fa-var-circle-parking: \f615;
$fa-var-parking-circle: \f615;
$fa-var-circle-pause: \f28b;
$fa-var-pause-circle: \f28b;
$fa-var-circle-phone: \e11b;
$fa-var-phone-circle: \e11b;
$fa-var-circle-phone-flip: \e11c;
$fa-var-phone-circle-alt: \e11c;
$fa-var-circle-phone-hangup: \e11d;
$fa-var-phone-circle-down: \e11d;
$fa-var-circle-play: \f144;
$fa-var-play-circle: \f144;
$fa-var-circle-plus: \f055;
$fa-var-plus-circle: \f055;
$fa-var-circle-q: \e11e;
$fa-var-circle-quarter: \e11f;
$fa-var-circle-quarter-stroke: \e5d3;
$fa-var-circle-quarters: \e3f8;
$fa-var-circle-question: \f059;
$fa-var-question-circle: \f059;
$fa-var-circle-r: \e120;
$fa-var-circle-radiation: \f7ba;
$fa-var-radiation-alt: \f7ba;
$fa-var-circle-right: \f35a;
$fa-var-arrow-alt-circle-right: \f35a;
$fa-var-circle-s: \e121;
$fa-var-circle-small: \e122;
$fa-var-circle-sort: \e030;
$fa-var-sort-circle: \e030;
$fa-var-circle-sort-down: \e031;
$fa-var-sort-circle-down: \e031;
$fa-var-circle-sort-up: \e032;
$fa-var-sort-circle-up: \e032;
$fa-var-circle-star: \e123;
$fa-var-star-circle: \e123;
$fa-var-circle-sterling: \e5cf;
$fa-var-circle-stop: \f28d;
$fa-var-stop-circle: \f28d;
$fa-var-circle-t: \e124;
$fa-var-circle-three-quarters: \e125;
$fa-var-circle-three-quarters-stroke: \e5d4;
$fa-var-circle-trash: \e126;
$fa-var-trash-circle: \e126;
$fa-var-circle-u: \e127;
$fa-var-circle-up: \f35b;
$fa-var-arrow-alt-circle-up: \f35b;
$fa-var-circle-up-left: \e128;
$fa-var-circle-up-right: \e129;
$fa-var-circle-user: \f2bd;
$fa-var-user-circle: \f2bd;
$fa-var-circle-v: \e12a;
$fa-var-circle-video: \e12b;
$fa-var-video-circle: \e12b;
$fa-var-circle-w: \e12c;
$fa-var-circle-waveform-lines: \e12d;
$fa-var-waveform-circle: \e12d;
$fa-var-circle-wifi: \e67d;
$fa-var-circle-wifi-circle-wifi: \e67e;
$fa-var-circle-wifi-group: \e67e;
$fa-var-circle-x: \e12e;
$fa-var-circle-xmark: \f057;
$fa-var-times-circle: \f057;
$fa-var-xmark-circle: \f057;
$fa-var-circle-y: \e12f;
$fa-var-circle-yen: \e5d0;
$fa-var-circle-z: \e130;
$fa-var-circles-overlap: \e600;
$fa-var-citrus: \e2f4;
$fa-var-citrus-slice: \e2f5;
$fa-var-city: \f64f;
$fa-var-clapperboard: \e131;
$fa-var-clapperboard-play: \e132;
$fa-var-clarinet: \f8ad;
$fa-var-claw-marks: \f6c2;
$fa-var-clipboard: \f328;
$fa-var-clipboard-check: \f46c;
$fa-var-clipboard-list: \f46d;
$fa-var-clipboard-list-check: \f737;
$fa-var-clipboard-medical: \e133;
$fa-var-clipboard-prescription: \f5e8;
$fa-var-clipboard-question: \e4e3;
$fa-var-clipboard-user: \f7f3;
$fa-var-clock: \f017;
$fa-var-clock-four: \f017;
$fa-var-clock-desk: \e134;
$fa-var-clock-eight: \e345;
$fa-var-clock-eight-thirty: \e346;
$fa-var-clock-eleven: \e347;
$fa-var-clock-eleven-thirty: \e348;
$fa-var-clock-five: \e349;
$fa-var-clock-five-thirty: \e34a;
$fa-var-clock-four-thirty: \e34b;
$fa-var-clock-nine: \e34c;
$fa-var-clock-nine-thirty: \e34d;
$fa-var-clock-one: \e34e;
$fa-var-clock-one-thirty: \e34f;
$fa-var-clock-rotate-left: \f1da;
$fa-var-history: \f1da;
$fa-var-clock-seven: \e350;
$fa-var-clock-seven-thirty: \e351;
$fa-var-clock-six: \e352;
$fa-var-clock-six-thirty: \e353;
$fa-var-clock-ten: \e354;
$fa-var-clock-ten-thirty: \e355;
$fa-var-clock-three: \e356;
$fa-var-clock-three-thirty: \e357;
$fa-var-clock-twelve: \e358;
$fa-var-clock-twelve-thirty: \e359;
$fa-var-clock-two: \e35a;
$fa-var-clock-two-thirty: \e35b;
$fa-var-clone: \f24d;
$fa-var-closed-captioning: \f20a;
$fa-var-closed-captioning-slash: \e135;
$fa-var-clothes-hanger: \e136;
$fa-var-cloud: \f0c2;
$fa-var-cloud-arrow-down: \f0ed;
$fa-var-cloud-download: \f0ed;
$fa-var-cloud-download-alt: \f0ed;
$fa-var-cloud-arrow-up: \f0ee;
$fa-var-cloud-upload: \f0ee;
$fa-var-cloud-upload-alt: \f0ee;
$fa-var-cloud-binary: \e601;
$fa-var-cloud-bolt: \f76c;
$fa-var-thunderstorm: \f76c;
$fa-var-cloud-bolt-moon: \f76d;
$fa-var-thunderstorm-moon: \f76d;
$fa-var-cloud-bolt-sun: \f76e;
$fa-var-thunderstorm-sun: \f76e;
$fa-var-cloud-check: \e35c;
$fa-var-cloud-drizzle: \f738;
$fa-var-cloud-exclamation: \e491;
$fa-var-cloud-fog: \f74e;
$fa-var-fog: \f74e;
$fa-var-cloud-hail: \f739;
$fa-var-cloud-hail-mixed: \f73a;
$fa-var-cloud-meatball: \f73b;
$fa-var-cloud-minus: \e35d;
$fa-var-cloud-moon: \f6c3;
$fa-var-cloud-moon-rain: \f73c;
$fa-var-cloud-music: \f8ae;
$fa-var-cloud-plus: \e35e;
$fa-var-cloud-question: \e492;
$fa-var-cloud-rain: \f73d;
$fa-var-cloud-rainbow: \f73e;
$fa-var-cloud-showers: \f73f;
$fa-var-cloud-showers-heavy: \f740;
$fa-var-cloud-showers-water: \e4e4;
$fa-var-cloud-slash: \e137;
$fa-var-cloud-sleet: \f741;
$fa-var-cloud-snow: \f742;
$fa-var-cloud-sun: \f6c4;
$fa-var-cloud-sun-rain: \f743;
$fa-var-cloud-word: \e138;
$fa-var-cloud-xmark: \e35f;
$fa-var-clouds: \f744;
$fa-var-clouds-moon: \f745;
$fa-var-clouds-sun: \f746;
$fa-var-clover: \e139;
$fa-var-club: \f327;
$fa-var-coconut: \e2f6;
$fa-var-code: \f121;
$fa-var-code-branch: \f126;
$fa-var-code-commit: \f386;
$fa-var-code-compare: \e13a;
$fa-var-code-fork: \e13b;
$fa-var-code-merge: \f387;
$fa-var-code-pull-request: \e13c;
$fa-var-code-pull-request-closed: \e3f9;
$fa-var-code-pull-request-draft: \e3fa;
$fa-var-code-simple: \e13d;
$fa-var-coffee-bean: \e13e;
$fa-var-coffee-beans: \e13f;
$fa-var-coffee-pot: \e002;
$fa-var-coffin: \f6c6;
$fa-var-coffin-cross: \e051;
$fa-var-coin: \f85c;
$fa-var-coin-blank: \e3fb;
$fa-var-coin-front: \e3fc;
$fa-var-coin-vertical: \e3fd;
$fa-var-coins: \f51e;
$fa-var-colon: \3a;
$fa-var-colon-sign: \e140;
$fa-var-columns-3: \e361;
$fa-var-comet: \e003;
$fa-var-comma: \2c;
$fa-var-command: \e142;
$fa-var-comment: \f075;
$fa-var-comment-arrow-down: \e143;
$fa-var-comment-arrow-up: \e144;
$fa-var-comment-arrow-up-right: \e145;
$fa-var-comment-captions: \e146;
$fa-var-comment-check: \f4ac;
$fa-var-comment-code: \e147;
$fa-var-comment-dollar: \f651;
$fa-var-comment-dots: \f4ad;
$fa-var-commenting: \f4ad;
$fa-var-comment-exclamation: \f4af;
$fa-var-comment-heart: \e5c8;
$fa-var-comment-image: \e148;
$fa-var-comment-lines: \f4b0;
$fa-var-comment-medical: \f7f5;
$fa-var-comment-middle: \e149;
$fa-var-comment-middle-top: \e14a;
$fa-var-comment-minus: \f4b1;
$fa-var-comment-music: \f8b0;
$fa-var-comment-pen: \f4ae;
$fa-var-comment-edit: \f4ae;
$fa-var-comment-plus: \f4b2;
$fa-var-comment-question: \e14b;
$fa-var-comment-quote: \e14c;
$fa-var-comment-slash: \f4b3;
$fa-var-comment-smile: \f4b4;
$fa-var-comment-sms: \f7cd;
$fa-var-sms: \f7cd;
$fa-var-comment-text: \e14d;
$fa-var-comment-xmark: \f4b5;
$fa-var-comment-times: \f4b5;
$fa-var-comments: \f086;
$fa-var-comments-dollar: \f653;
$fa-var-comments-question: \e14e;
$fa-var-comments-question-check: \e14f;
$fa-var-compact-disc: \f51f;
$fa-var-compass: \f14e;
$fa-var-compass-drafting: \f568;
$fa-var-drafting-compass: \f568;
$fa-var-compass-slash: \f5e9;
$fa-var-compress: \f066;
$fa-var-compress-wide: \f326;
$fa-var-computer: \e4e5;
$fa-var-computer-classic: \f8b1;
$fa-var-computer-mouse: \f8cc;
$fa-var-mouse: \f8cc;
$fa-var-computer-mouse-scrollwheel: \f8cd;
$fa-var-mouse-alt: \f8cd;
$fa-var-computer-speaker: \f8b2;
$fa-var-container-storage: \f4b7;
$fa-var-conveyor-belt: \f46e;
$fa-var-conveyor-belt-arm: \e5f8;
$fa-var-conveyor-belt-boxes: \f46f;
$fa-var-conveyor-belt-alt: \f46f;
$fa-var-conveyor-belt-empty: \e150;
$fa-var-cookie: \f563;
$fa-var-cookie-bite: \f564;
$fa-var-copy: \f0c5;
$fa-var-copyright: \f1f9;
$fa-var-corn: \f6c7;
$fa-var-corner: \e3fe;
$fa-var-couch: \f4b8;
$fa-var-court-sport: \e643;
$fa-var-cow: \f6c8;
$fa-var-cowbell: \f8b3;
$fa-var-cowbell-circle-plus: \f8b4;
$fa-var-cowbell-more: \f8b4;
$fa-var-crab: \e3ff;
$fa-var-crate-apple: \f6b1;
$fa-var-apple-crate: \f6b1;
$fa-var-crate-empty: \e151;
$fa-var-credit-card: \f09d;
$fa-var-credit-card-alt: \f09d;
$fa-var-credit-card-blank: \f389;
$fa-var-credit-card-front: \f38a;
$fa-var-cricket-bat-ball: \f449;
$fa-var-cricket: \f449;
$fa-var-croissant: \f7f6;
$fa-var-crop: \f125;
$fa-var-crop-simple: \f565;
$fa-var-crop-alt: \f565;
$fa-var-cross: \f654;
$fa-var-crosshairs: \f05b;
$fa-var-crosshairs-simple: \e59f;
$fa-var-crow: \f520;
$fa-var-crown: \f521;
$fa-var-crutch: \f7f7;
$fa-var-crutches: \f7f8;
$fa-var-cruzeiro-sign: \e152;
$fa-var-crystal-ball: \e362;
$fa-var-cube: \f1b2;
$fa-var-cubes: \f1b3;
$fa-var-cubes-stacked: \e4e6;
$fa-var-cucumber: \e401;
$fa-var-cup-straw: \e363;
$fa-var-cup-straw-swoosh: \e364;
$fa-var-cup-togo: \f6c5;
$fa-var-coffee-togo: \f6c5;
$fa-var-cupcake: \e402;
$fa-var-curling-stone: \f44a;
$fa-var-curling: \f44a;
$fa-var-custard: \e403;
$fa-var-d: \44;
$fa-var-dagger: \f6cb;
$fa-var-dash: \e404;
$fa-var-minus-large: \e404;
$fa-var-database: \f1c0;
$fa-var-deer: \f78e;
$fa-var-deer-rudolph: \f78f;
$fa-var-delete-left: \f55a;
$fa-var-backspace: \f55a;
$fa-var-delete-right: \e154;
$fa-var-democrat: \f747;
$fa-var-desktop: \f390;
$fa-var-desktop-alt: \f390;
$fa-var-desktop-arrow-down: \e155;
$fa-var-dharmachakra: \f655;
$fa-var-diagram-cells: \e475;
$fa-var-diagram-lean-canvas: \e156;
$fa-var-diagram-nested: \e157;
$fa-var-diagram-next: \e476;
$fa-var-diagram-predecessor: \e477;
$fa-var-diagram-previous: \e478;
$fa-var-diagram-project: \f542;
$fa-var-project-diagram: \f542;
$fa-var-diagram-sankey: \e158;
$fa-var-diagram-subtask: \e479;
$fa-var-diagram-successor: \e47a;
$fa-var-diagram-venn: \e15a;
$fa-var-dial: \e15b;
$fa-var-dial-med-high: \e15b;
$fa-var-dial-high: \e15c;
$fa-var-dial-low: \e15d;
$fa-var-dial-max: \e15e;
$fa-var-dial-med: \e15f;
$fa-var-dial-med-low: \e160;
$fa-var-dial-min: \e161;
$fa-var-dial-off: \e162;
$fa-var-diamond: \f219;
$fa-var-diamond-exclamation: \e405;
$fa-var-diamond-half: \e5b7;
$fa-var-diamond-half-stroke: \e5b8;
$fa-var-diamond-turn-right: \f5eb;
$fa-var-directions: \f5eb;
$fa-var-dice: \f522;
$fa-var-dice-d10: \f6cd;
$fa-var-dice-d12: \f6ce;
$fa-var-dice-d20: \f6cf;
$fa-var-dice-d4: \f6d0;
$fa-var-dice-d6: \f6d1;
$fa-var-dice-d8: \f6d2;
$fa-var-dice-five: \f523;
$fa-var-dice-four: \f524;
$fa-var-dice-one: \f525;
$fa-var-dice-six: \f526;
$fa-var-dice-three: \f527;
$fa-var-dice-two: \f528;
$fa-var-dinosaur: \e5fe;
$fa-var-diploma: \f5ea;
$fa-var-scroll-ribbon: \f5ea;
$fa-var-disc-drive: \f8b5;
$fa-var-disease: \f7fa;
$fa-var-display: \e163;
$fa-var-display-arrow-down: \e164;
$fa-var-display-chart-up: \e5e3;
$fa-var-display-chart-up-circle-currency: \e5e5;
$fa-var-display-chart-up-circle-dollar: \e5e6;
$fa-var-display-code: \e165;
$fa-var-desktop-code: \e165;
$fa-var-display-medical: \e166;
$fa-var-desktop-medical: \e166;
$fa-var-display-slash: \e2fa;
$fa-var-desktop-slash: \e2fa;
$fa-var-distribute-spacing-horizontal: \e365;
$fa-var-distribute-spacing-vertical: \e366;
$fa-var-ditto: \22;
$fa-var-divide: \f529;
$fa-var-dna: \f471;
$fa-var-do-not-enter: \f5ec;
$fa-var-dog: \f6d3;
$fa-var-dog-leashed: \f6d4;
$fa-var-dollar-sign: \24;
$fa-var-dollar: \24;
$fa-var-usd: \24;
$fa-var-dolly: \f472;
$fa-var-dolly-box: \f472;
$fa-var-dolly-empty: \f473;
$fa-var-dolphin: \e168;
$fa-var-dong-sign: \e169;
$fa-var-donut: \e406;
$fa-var-doughnut: \e406;
$fa-var-door-closed: \f52a;
$fa-var-door-open: \f52b;
$fa-var-dove: \f4ba;
$fa-var-down: \f354;
$fa-var-arrow-alt-down: \f354;
$fa-var-down-from-bracket: \e66b;
$fa-var-down-from-dotted-line: \e407;
$fa-var-down-from-line: \f349;
$fa-var-arrow-alt-from-top: \f349;
$fa-var-down-left: \e16a;
$fa-var-down-left-and-up-right-to-center: \f422;
$fa-var-compress-alt: \f422;
$fa-var-down-long: \f309;
$fa-var-long-arrow-alt-down: \f309;
$fa-var-down-right: \e16b;
$fa-var-down-to-bracket: \e4e7;
$fa-var-down-to-dotted-line: \e408;
$fa-var-down-to-line: \f34a;
$fa-var-arrow-alt-to-bottom: \f34a;
$fa-var-download: \f019;
$fa-var-dragon: \f6d5;
$fa-var-draw-circle: \f5ed;
$fa-var-draw-polygon: \f5ee;
$fa-var-draw-square: \f5ef;
$fa-var-dreidel: \f792;
$fa-var-drone: \f85f;
$fa-var-drone-front: \f860;
$fa-var-drone-alt: \f860;
$fa-var-droplet: \f043;
$fa-var-tint: \f043;
$fa-var-droplet-degree: \f748;
$fa-var-dewpoint: \f748;
$fa-var-droplet-percent: \f750;
$fa-var-humidity: \f750;
$fa-var-droplet-slash: \f5c7;
$fa-var-tint-slash: \f5c7;
$fa-var-drum: \f569;
$fa-var-drum-steelpan: \f56a;
$fa-var-drumstick: \f6d6;
$fa-var-drumstick-bite: \f6d7;
$fa-var-dryer: \f861;
$fa-var-dryer-heat: \f862;
$fa-var-dryer-alt: \f862;
$fa-var-duck: \f6d8;
$fa-var-dumbbell: \f44b;
$fa-var-dumpster: \f793;
$fa-var-dumpster-fire: \f794;
$fa-var-dungeon: \f6d9;
$fa-var-e: \45;
$fa-var-ear: \f5f0;
$fa-var-ear-deaf: \f2a4;
$fa-var-deaf: \f2a4;
$fa-var-deafness: \f2a4;
$fa-var-hard-of-hearing: \f2a4;
$fa-var-ear-listen: \f2a2;
$fa-var-assistive-listening-systems: \f2a2;
$fa-var-ear-muffs: \f795;
$fa-var-earth-africa: \f57c;
$fa-var-globe-africa: \f57c;
$fa-var-earth-americas: \f57d;
$fa-var-earth: \f57d;
$fa-var-earth-america: \f57d;
$fa-var-globe-americas: \f57d;
$fa-var-earth-asia: \f57e;
$fa-var-globe-asia: \f57e;
$fa-var-earth-europe: \f7a2;
$fa-var-globe-europe: \f7a2;
$fa-var-earth-oceania: \e47b;
$fa-var-globe-oceania: \e47b;
$fa-var-eclipse: \f749;
$fa-var-egg: \f7fb;
$fa-var-egg-fried: \f7fc;
$fa-var-eggplant: \e16c;
$fa-var-eject: \f052;
$fa-var-elephant: \f6da;
$fa-var-elevator: \e16d;
$fa-var-ellipsis: \f141;
$fa-var-ellipsis-h: \f141;
$fa-var-ellipsis-stroke: \f39b;
$fa-var-ellipsis-h-alt: \f39b;
$fa-var-ellipsis-stroke-vertical: \f39c;
$fa-var-ellipsis-v-alt: \f39c;
$fa-var-ellipsis-vertical: \f142;
$fa-var-ellipsis-v: \f142;
$fa-var-empty-set: \f656;
$fa-var-engine: \e16e;
$fa-var-engine-warning: \f5f2;
$fa-var-engine-exclamation: \f5f2;
$fa-var-envelope: \f0e0;
$fa-var-envelope-circle-check: \e4e8;
$fa-var-envelope-dot: \e16f;
$fa-var-envelope-badge: \e16f;
$fa-var-envelope-open: \f2b6;
$fa-var-envelope-open-dollar: \f657;
$fa-var-envelope-open-text: \f658;
$fa-var-envelopes: \e170;
$fa-var-envelopes-bulk: \f674;
$fa-var-mail-bulk: \f674;
$fa-var-equals: \3d;
$fa-var-eraser: \f12d;
$fa-var-escalator: \e171;
$fa-var-ethernet: \f796;
$fa-var-euro-sign: \f153;
$fa-var-eur: \f153;
$fa-var-euro: \f153;
$fa-var-excavator: \e656;
$fa-var-exclamation: \21;
$fa-var-expand: \f065;
$fa-var-expand-wide: \f320;
$fa-var-explosion: \e4e9;
$fa-var-eye: \f06e;
$fa-var-eye-dropper: \f1fb;
$fa-var-eye-dropper-empty: \f1fb;
$fa-var-eyedropper: \f1fb;
$fa-var-eye-dropper-full: \e172;
$fa-var-eye-dropper-half: \e173;
$fa-var-eye-evil: \f6db;
$fa-var-eye-low-vision: \f2a8;
$fa-var-low-vision: \f2a8;
$fa-var-eye-slash: \f070;
$fa-var-eyes: \e367;
$fa-var-f: \46;
$fa-var-face-angry: \f556;
$fa-var-angry: \f556;
$fa-var-face-angry-horns: \e368;
$fa-var-face-anguished: \e369;
$fa-var-face-anxious-sweat: \e36a;
$fa-var-face-astonished: \e36b;
$fa-var-face-awesome: \e409;
$fa-var-gave-dandy: \e409;
$fa-var-face-beam-hand-over-mouth: \e47c;
$fa-var-face-clouds: \e47d;
$fa-var-face-confounded: \e36c;
$fa-var-face-confused: \e36d;
$fa-var-face-cowboy-hat: \e36e;
$fa-var-face-diagonal-mouth: \e47e;
$fa-var-face-disappointed: \e36f;
$fa-var-face-disguise: \e370;
$fa-var-face-dizzy: \f567;
$fa-var-dizzy: \f567;
$fa-var-face-dotted: \e47f;
$fa-var-face-downcast-sweat: \e371;
$fa-var-face-drooling: \e372;
$fa-var-face-exhaling: \e480;
$fa-var-face-explode: \e2fe;
$fa-var-exploding-head: \e2fe;
$fa-var-face-expressionless: \e373;
$fa-var-face-eyes-xmarks: \e374;
$fa-var-face-fearful: \e375;
$fa-var-face-flushed: \f579;
$fa-var-flushed: \f579;
$fa-var-face-frown: \f119;
$fa-var-frown: \f119;
$fa-var-face-frown-open: \f57a;
$fa-var-frown-open: \f57a;
$fa-var-face-frown-slight: \e376;
$fa-var-face-glasses: \e377;
$fa-var-face-grimace: \f57f;
$fa-var-grimace: \f57f;
$fa-var-face-grin: \f580;
$fa-var-grin: \f580;
$fa-var-face-grin-beam: \f582;
$fa-var-grin-beam: \f582;
$fa-var-face-grin-beam-sweat: \f583;
$fa-var-grin-beam-sweat: \f583;
$fa-var-face-grin-hearts: \f584;
$fa-var-grin-hearts: \f584;
$fa-var-face-grin-squint: \f585;
$fa-var-grin-squint: \f585;
$fa-var-face-grin-squint-tears: \f586;
$fa-var-grin-squint-tears: \f586;
$fa-var-face-grin-stars: \f587;
$fa-var-grin-stars: \f587;
$fa-var-face-grin-tears: \f588;
$fa-var-grin-tears: \f588;
$fa-var-face-grin-tongue: \f589;
$fa-var-grin-tongue: \f589;
$fa-var-face-grin-tongue-squint: \f58a;
$fa-var-grin-tongue-squint: \f58a;
$fa-var-face-grin-tongue-wink: \f58b;
$fa-var-grin-tongue-wink: \f58b;
$fa-var-face-grin-wide: \f581;
$fa-var-grin-alt: \f581;
$fa-var-face-grin-wink: \f58c;
$fa-var-grin-wink: \f58c;
$fa-var-face-hand-over-mouth: \e378;
$fa-var-face-hand-peeking: \e481;
$fa-var-face-hand-yawn: \e379;
$fa-var-face-head-bandage: \e37a;
$fa-var-face-holding-back-tears: \e482;
$fa-var-face-hushed: \e37b;
$fa-var-face-icicles: \e37c;
$fa-var-face-kiss: \f596;
$fa-var-kiss: \f596;
$fa-var-face-kiss-beam: \f597;
$fa-var-kiss-beam: \f597;
$fa-var-face-kiss-closed-eyes: \e37d;
$fa-var-face-kiss-wink-heart: \f598;
$fa-var-kiss-wink-heart: \f598;
$fa-var-face-laugh: \f599;
$fa-var-laugh: \f599;
$fa-var-face-laugh-beam: \f59a;
$fa-var-laugh-beam: \f59a;
$fa-var-face-laugh-squint: \f59b;
$fa-var-laugh-squint: \f59b;
$fa-var-face-laugh-wink: \f59c;
$fa-var-laugh-wink: \f59c;
$fa-var-face-lying: \e37e;
$fa-var-face-mask: \e37f;
$fa-var-face-meh: \f11a;
$fa-var-meh: \f11a;
$fa-var-face-meh-blank: \f5a4;
$fa-var-meh-blank: \f5a4;
$fa-var-face-melting: \e483;
$fa-var-face-monocle: \e380;
$fa-var-face-nauseated: \e381;
$fa-var-face-nose-steam: \e382;
$fa-var-face-party: \e383;
$fa-var-face-pensive: \e384;
$fa-var-face-persevering: \e385;
$fa-var-face-pleading: \e386;
$fa-var-face-pouting: \e387;
$fa-var-face-raised-eyebrow: \e388;
$fa-var-face-relieved: \e389;
$fa-var-face-rolling-eyes: \f5a5;
$fa-var-meh-rolling-eyes: \f5a5;
$fa-var-face-sad-cry: \f5b3;
$fa-var-sad-cry: \f5b3;
$fa-var-face-sad-sweat: \e38a;
$fa-var-face-sad-tear: \f5b4;
$fa-var-sad-tear: \f5b4;
$fa-var-face-saluting: \e484;
$fa-var-face-scream: \e38b;
$fa-var-face-shush: \e38c;
$fa-var-face-sleeping: \e38d;
$fa-var-face-sleepy: \e38e;
$fa-var-face-smile: \f118;
$fa-var-smile: \f118;
$fa-var-face-smile-beam: \f5b8;
$fa-var-smile-beam: \f5b8;
$fa-var-face-smile-halo: \e38f;
$fa-var-face-smile-hearts: \e390;
$fa-var-face-smile-horns: \e391;
$fa-var-face-smile-plus: \f5b9;
$fa-var-smile-plus: \f5b9;
$fa-var-face-smile-relaxed: \e392;
$fa-var-face-smile-tear: \e393;
$fa-var-face-smile-tongue: \e394;
$fa-var-face-smile-upside-down: \e395;
$fa-var-face-smile-wink: \f4da;
$fa-var-smile-wink: \f4da;
$fa-var-face-smiling-hands: \e396;
$fa-var-face-smirking: \e397;
$fa-var-face-spiral-eyes: \e485;
$fa-var-face-sunglasses: \e398;
$fa-var-face-surprise: \f5c2;
$fa-var-surprise: \f5c2;
$fa-var-face-swear: \e399;
$fa-var-face-thermometer: \e39a;
$fa-var-face-thinking: \e39b;
$fa-var-face-tired: \f5c8;
$fa-var-tired: \f5c8;
$fa-var-face-tissue: \e39c;
$fa-var-face-tongue-money: \e39d;
$fa-var-face-tongue-sweat: \e39e;
$fa-var-face-unamused: \e39f;
$fa-var-face-viewfinder: \e2ff;
$fa-var-face-vomit: \e3a0;
$fa-var-face-weary: \e3a1;
$fa-var-face-woozy: \e3a2;
$fa-var-face-worried: \e3a3;
$fa-var-face-zany: \e3a4;
$fa-var-face-zipper: \e3a5;
$fa-var-falafel: \e40a;
$fa-var-family: \e300;
$fa-var-family-dress: \e301;
$fa-var-family-pants: \e302;
$fa-var-fan: \f863;
$fa-var-fan-table: \e004;
$fa-var-farm: \f864;
$fa-var-barn-silo: \f864;
$fa-var-faucet: \e005;
$fa-var-faucet-drip: \e006;
$fa-var-fax: \f1ac;
$fa-var-feather: \f52d;
$fa-var-feather-pointed: \f56b;
$fa-var-feather-alt: \f56b;
$fa-var-fence: \e303;
$fa-var-ferris-wheel: \e174;
$fa-var-ferry: \e4ea;
$fa-var-field-hockey-stick-ball: \f44c;
$fa-var-field-hockey: \f44c;
$fa-var-file: \f15b;
$fa-var-file-arrow-down: \f56d;
$fa-var-file-download: \f56d;
$fa-var-file-arrow-up: \f574;
$fa-var-file-upload: \f574;
$fa-var-file-audio: \f1c7;
$fa-var-file-binary: \e175;
$fa-var-file-cad: \e672;
$fa-var-file-certificate: \f5f3;
$fa-var-file-award: \f5f3;
$fa-var-file-chart-column: \f659;
$fa-var-file-chart-line: \f659;
$fa-var-file-chart-pie: \f65a;
$fa-var-file-check: \f316;
$fa-var-file-circle-check: \e5a0;
$fa-var-file-circle-exclamation: \e4eb;
$fa-var-file-circle-info: \e493;
$fa-var-file-circle-minus: \e4ed;
$fa-var-file-circle-plus: \e494;
$fa-var-file-circle-question: \e4ef;
$fa-var-file-circle-xmark: \e5a1;
$fa-var-file-code: \f1c9;
$fa-var-file-contract: \f56c;
$fa-var-file-csv: \f6dd;
$fa-var-file-dashed-line: \f877;
$fa-var-page-break: \f877;
$fa-var-file-doc: \e5ed;
$fa-var-file-eps: \e644;
$fa-var-file-excel: \f1c3;
$fa-var-file-exclamation: \f31a;
$fa-var-file-export: \f56e;
$fa-var-arrow-right-from-file: \f56e;
$fa-var-file-gif: \e645;
$fa-var-file-heart: \e176;
$fa-var-file-image: \f1c5;
$fa-var-file-import: \f56f;
$fa-var-arrow-right-to-file: \f56f;
$fa-var-file-invoice: \f570;
$fa-var-file-invoice-dollar: \f571;
$fa-var-file-jpg: \e646;
$fa-var-file-lines: \f15c;
$fa-var-file-alt: \f15c;
$fa-var-file-text: \f15c;
$fa-var-file-lock: \e3a6;
$fa-var-file-magnifying-glass: \f865;
$fa-var-file-search: \f865;
$fa-var-file-medical: \f477;
$fa-var-file-minus: \f318;
$fa-var-file-mov: \e647;
$fa-var-file-mp3: \e648;
$fa-var-file-mp4: \e649;
$fa-var-file-music: \f8b6;
$fa-var-file-pdf: \f1c1;
$fa-var-file-pen: \f31c;
$fa-var-file-edit: \f31c;
$fa-var-file-plus: \f319;
$fa-var-file-plus-minus: \e177;
$fa-var-file-png: \e666;
$fa-var-file-powerpoint: \f1c4;
$fa-var-file-ppt: \e64a;
$fa-var-file-prescription: \f572;
$fa-var-file-shield: \e4f0;
$fa-var-file-signature: \f573;
$fa-var-file-slash: \e3a7;
$fa-var-file-spreadsheet: \f65b;
$fa-var-file-svg: \e64b;
$fa-var-file-user: \f65c;
$fa-var-file-vector: \e64c;
$fa-var-file-video: \f1c8;
$fa-var-file-waveform: \f478;
$fa-var-file-medical-alt: \f478;
$fa-var-file-word: \f1c2;
$fa-var-file-xls: \e64d;
$fa-var-file-xmark: \f317;
$fa-var-file-times: \f317;
$fa-var-file-xml: \e654;
$fa-var-file-zip: \e5ee;
$fa-var-file-zipper: \f1c6;
$fa-var-file-archive: \f1c6;
$fa-var-files: \e178;
$fa-var-files-medical: \f7fd;
$fa-var-fill: \f575;
$fa-var-fill-drip: \f576;
$fa-var-film: \f008;
$fa-var-film-canister: \f8b7;
$fa-var-film-cannister: \f8b7;
$fa-var-film-simple: \f3a0;
$fa-var-film-alt: \f3a0;
$fa-var-film-slash: \e179;
$fa-var-films: \e17a;
$fa-var-filter: \f0b0;
$fa-var-filter-circle-dollar: \f662;
$fa-var-funnel-dollar: \f662;
$fa-var-filter-circle-xmark: \e17b;
$fa-var-filter-list: \e17c;
$fa-var-filter-slash: \e17d;
$fa-var-filters: \e17e;
$fa-var-fingerprint: \f577;
$fa-var-fire: \f06d;
$fa-var-fire-burner: \e4f1;
$fa-var-fire-extinguisher: \f134;
$fa-var-fire-flame: \f6df;
$fa-var-flame: \f6df;
$fa-var-fire-flame-curved: \f7e4;
$fa-var-fire-alt: \f7e4;
$fa-var-fire-flame-simple: \f46a;
$fa-var-burn: \f46a;
$fa-var-fire-hydrant: \e17f;
$fa-var-fire-smoke: \f74b;
$fa-var-fireplace: \f79a;
$fa-var-fish: \f578;
$fa-var-fish-bones: \e304;
$fa-var-fish-cooked: \f7fe;
$fa-var-fish-fins: \e4f2;
$fa-var-fishing-rod: \e3a8;
$fa-var-flag: \f024;
$fa-var-flag-checkered: \f11e;
$fa-var-flag-pennant: \f456;
$fa-var-pennant: \f456;
$fa-var-flag-swallowtail: \f74c;
$fa-var-flag-alt: \f74c;
$fa-var-flag-usa: \f74d;
$fa-var-flashlight: \f8b8;
$fa-var-flask: \f0c3;
$fa-var-flask-gear: \e5f1;
$fa-var-flask-round-poison: \f6e0;
$fa-var-flask-poison: \f6e0;
$fa-var-flask-round-potion: \f6e1;
$fa-var-flask-potion: \f6e1;
$fa-var-flask-vial: \e4f3;
$fa-var-flatbread: \e40b;
$fa-var-flatbread-stuffed: \e40c;
$fa-var-floppy-disk: \f0c7;
$fa-var-save: \f0c7;
$fa-var-floppy-disk-circle-arrow-right: \e180;
$fa-var-save-circle-arrow-right: \e180;
$fa-var-floppy-disk-circle-xmark: \e181;
$fa-var-floppy-disk-times: \e181;
$fa-var-save-circle-xmark: \e181;
$fa-var-save-times: \e181;
$fa-var-floppy-disk-pen: \e182;
$fa-var-floppy-disks: \e183;
$fa-var-florin-sign: \e184;
$fa-var-flower: \f7ff;
$fa-var-flower-daffodil: \f800;
$fa-var-flower-tulip: \f801;
$fa-var-flute: \f8b9;
$fa-var-flux-capacitor: \f8ba;
$fa-var-flying-disc: \e3a9;
$fa-var-folder: \f07b;
$fa-var-folder-blank: \f07b;
$fa-var-folder-arrow-down: \e053;
$fa-var-folder-download: \e053;
$fa-var-folder-arrow-up: \e054;
$fa-var-folder-upload: \e054;
$fa-var-folder-bookmark: \e186;
$fa-var-folder-check: \e64e;
$fa-var-folder-closed: \e185;
$fa-var-folder-gear: \e187;
$fa-var-folder-cog: \e187;
$fa-var-folder-grid: \e188;
$fa-var-folder-heart: \e189;
$fa-var-folder-image: \e18a;
$fa-var-folder-magnifying-glass: \e18b;
$fa-var-folder-search: \e18b;
$fa-var-folder-medical: \e18c;
$fa-var-folder-minus: \f65d;
$fa-var-folder-music: \e18d;
$fa-var-folder-open: \f07c;
$fa-var-folder-plus: \f65e;
$fa-var-folder-tree: \f802;
$fa-var-folder-user: \e18e;
$fa-var-folder-xmark: \f65f;
$fa-var-folder-times: \f65f;
$fa-var-folders: \f660;
$fa-var-fondue-pot: \e40d;
$fa-var-font: \f031;
$fa-var-font-case: \f866;
$fa-var-football: \f44e;
$fa-var-football-ball: \f44e;
$fa-var-football-helmet: \f44f;
$fa-var-fork: \f2e3;
$fa-var-utensil-fork: \f2e3;
$fa-var-fork-knife: \f2e6;
$fa-var-utensils-alt: \f2e6;
$fa-var-forklift: \f47a;
$fa-var-fort: \e486;
$fa-var-forward: \f04e;
$fa-var-forward-fast: \f050;
$fa-var-fast-forward: \f050;
$fa-var-forward-step: \f051;
$fa-var-step-forward: \f051;
$fa-var-frame: \e495;
$fa-var-franc-sign: \e18f;
$fa-var-french-fries: \f803;
$fa-var-frog: \f52e;
$fa-var-function: \f661;
$fa-var-futbol: \f1e3;
$fa-var-futbol-ball: \f1e3;
$fa-var-soccer-ball: \f1e3;
$fa-var-g: \47;
$fa-var-galaxy: \e008;
$fa-var-gallery-thumbnails: \e3aa;
$fa-var-game-board: \f867;
$fa-var-game-board-simple: \f868;
$fa-var-game-board-alt: \f868;
$fa-var-game-console-handheld: \f8bb;
$fa-var-game-console-handheld-crank: \e5b9;
$fa-var-gamepad: \f11b;
$fa-var-gamepad-modern: \e5a2;
$fa-var-gamepad-alt: \e5a2;
$fa-var-garage: \e009;
$fa-var-garage-car: \e00a;
$fa-var-garage-open: \e00b;
$fa-var-garlic: \e40e;
$fa-var-gas-pump: \f52f;
$fa-var-gas-pump-slash: \f5f4;
$fa-var-gauge: \f624;
$fa-var-dashboard: \f624;
$fa-var-gauge-med: \f624;
$fa-var-tachometer-alt-average: \f624;
$fa-var-gauge-circle-bolt: \e496;
$fa-var-gauge-circle-minus: \e497;
$fa-var-gauge-circle-plus: \e498;
$fa-var-gauge-high: \f625;
$fa-var-tachometer-alt: \f625;
$fa-var-tachometer-alt-fast: \f625;
$fa-var-gauge-low: \f627;
$fa-var-tachometer-alt-slow: \f627;
$fa-var-gauge-max: \f626;
$fa-var-tachometer-alt-fastest: \f626;
$fa-var-gauge-min: \f628;
$fa-var-tachometer-alt-slowest: \f628;
$fa-var-gauge-simple: \f629;
$fa-var-gauge-simple-med: \f629;
$fa-var-tachometer-average: \f629;
$fa-var-gauge-simple-high: \f62a;
$fa-var-tachometer: \f62a;
$fa-var-tachometer-fast: \f62a;
$fa-var-gauge-simple-low: \f62c;
$fa-var-tachometer-slow: \f62c;
$fa-var-gauge-simple-max: \f62b;
$fa-var-tachometer-fastest: \f62b;
$fa-var-gauge-simple-min: \f62d;
$fa-var-tachometer-slowest: \f62d;
$fa-var-gavel: \f0e3;
$fa-var-legal: \f0e3;
$fa-var-gear: \f013;
$fa-var-cog: \f013;
$fa-var-gear-code: \e5e8;
$fa-var-gear-complex: \e5e9;
$fa-var-gear-complex-code: \e5eb;
$fa-var-gears: \f085;
$fa-var-cogs: \f085;
$fa-var-gem: \f3a5;
$fa-var-genderless: \f22d;
$fa-var-ghost: \f6e2;
$fa-var-gif: \e190;
$fa-var-gift: \f06b;
$fa-var-gift-card: \f663;
$fa-var-gifts: \f79c;
$fa-var-gingerbread-man: \f79d;
$fa-var-glass: \f804;
$fa-var-glass-citrus: \f869;
$fa-var-glass-empty: \e191;
$fa-var-glass-half: \e192;
$fa-var-glass-half-empty: \e192;
$fa-var-glass-half-full: \e192;
$fa-var-glass-water: \e4f4;
$fa-var-glass-water-droplet: \e4f5;
$fa-var-glasses: \f530;
$fa-var-glasses-round: \f5f5;
$fa-var-glasses-alt: \f5f5;
$fa-var-globe: \f0ac;
$fa-var-globe-pointer: \e60e;
$fa-var-globe-snow: \f7a3;
$fa-var-globe-stand: \f5f6;
$fa-var-goal-net: \e3ab;
$fa-var-golf-ball-tee: \f450;
$fa-var-golf-ball: \f450;
$fa-var-golf-club: \f451;
$fa-var-golf-flag-hole: \e3ac;
$fa-var-gopuram: \f664;
$fa-var-graduation-cap: \f19d;
$fa-var-mortar-board: \f19d;
$fa-var-gramophone: \f8bd;
$fa-var-grapes: \e306;
$fa-var-grate: \e193;
$fa-var-grate-droplet: \e194;
$fa-var-greater-than: \3e;
$fa-var-greater-than-equal: \f532;
$fa-var-grid: \e195;
$fa-var-grid-3: \e195;
$fa-var-grid-2: \e196;
$fa-var-grid-2-plus: \e197;
$fa-var-grid-4: \e198;
$fa-var-grid-5: \e199;
$fa-var-grid-dividers: \e3ad;
$fa-var-grid-horizontal: \e307;
$fa-var-grid-round: \e5da;
$fa-var-grid-round-2: \e5db;
$fa-var-grid-round-2-plus: \e5dc;
$fa-var-grid-round-4: \e5dd;
$fa-var-grid-round-5: \e5de;
$fa-var-grill: \e5a3;
$fa-var-grill-fire: \e5a4;
$fa-var-grill-hot: \e5a5;
$fa-var-grip: \f58d;
$fa-var-grip-horizontal: \f58d;
$fa-var-grip-dots: \e410;
$fa-var-grip-dots-vertical: \e411;
$fa-var-grip-lines: \f7a4;
$fa-var-grip-lines-vertical: \f7a5;
$fa-var-grip-vertical: \f58e;
$fa-var-group-arrows-rotate: \e4f6;
$fa-var-guarani-sign: \e19a;
$fa-var-guitar: \f7a6;
$fa-var-guitar-electric: \f8be;
$fa-var-guitars: \f8bf;
$fa-var-gun: \e19b;
$fa-var-gun-slash: \e19c;
$fa-var-gun-squirt: \e19d;
$fa-var-h: \48;
$fa-var-h1: \f313;
$fa-var-h2: \f314;
$fa-var-h3: \f315;
$fa-var-h4: \f86a;
$fa-var-h5: \e412;
$fa-var-h6: \e413;
$fa-var-hammer: \f6e3;
$fa-var-hammer-brush: \e620;
$fa-var-hammer-crash: \e414;
$fa-var-hammer-war: \f6e4;
$fa-var-hamsa: \f665;
$fa-var-hand: \f256;
$fa-var-hand-paper: \f256;
$fa-var-hand-back-fist: \f255;
$fa-var-hand-rock: \f255;
$fa-var-hand-back-point-down: \e19e;
$fa-var-hand-back-point-left: \e19f;
$fa-var-hand-back-point-ribbon: \e1a0;
$fa-var-hand-back-point-right: \e1a1;
$fa-var-hand-back-point-up: \e1a2;
$fa-var-hand-dots: \f461;
$fa-var-allergies: \f461;
$fa-var-hand-fingers-crossed: \e1a3;
$fa-var-hand-fist: \f6de;
$fa-var-fist-raised: \f6de;
$fa-var-hand-heart: \f4bc;
$fa-var-hand-holding: \f4bd;
$fa-var-hand-holding-box: \f47b;
$fa-var-hand-holding-circle-dollar: \e621;
$fa-var-hand-holding-dollar: \f4c0;
$fa-var-hand-holding-usd: \f4c0;
$fa-var-hand-holding-droplet: \f4c1;
$fa-var-hand-holding-water: \f4c1;
$fa-var-hand-holding-hand: \e4f7;
$fa-var-hand-holding-heart: \f4be;
$fa-var-hand-holding-magic: \f6e5;
$fa-var-hand-holding-medical: \e05c;
$fa-var-hand-holding-seedling: \f4bf;
$fa-var-hand-holding-skull: \e1a4;
$fa-var-hand-horns: \e1a9;
$fa-var-hand-lizard: \f258;
$fa-var-hand-love: \e1a5;
$fa-var-hand-middle-finger: \f806;
$fa-var-hand-peace: \f25b;
$fa-var-hand-point-down: \f0a7;
$fa-var-hand-point-left: \f0a5;
$fa-var-hand-point-ribbon: \e1a6;
$fa-var-hand-point-right: \f0a4;
$fa-var-hand-point-up: \f0a6;
$fa-var-hand-pointer: \f25a;
$fa-var-hand-scissors: \f257;
$fa-var-hand-sparkles: \e05d;
$fa-var-hand-spock: \f259;
$fa-var-hand-wave: \e1a7;
$fa-var-handcuffs: \e4f8;
$fa-var-hands: \f2a7;
$fa-var-sign-language: \f2a7;
$fa-var-signing: \f2a7;
$fa-var-hands-asl-interpreting: \f2a3;
$fa-var-american-sign-language-interpreting: \f2a3;
$fa-var-asl-interpreting: \f2a3;
$fa-var-hands-american-sign-language-interpreting: \f2a3;
$fa-var-hands-bound: \e4f9;
$fa-var-hands-bubbles: \e05e;
$fa-var-hands-wash: \e05e;
$fa-var-hands-clapping: \e1a8;
$fa-var-hands-holding: \f4c2;
$fa-var-hands-holding-child: \e4fa;
$fa-var-hands-holding-circle: \e4fb;
$fa-var-hands-holding-diamond: \f47c;
$fa-var-hand-receiving: \f47c;
$fa-var-hands-holding-dollar: \f4c5;
$fa-var-hands-usd: \f4c5;
$fa-var-hands-holding-heart: \f4c3;
$fa-var-hands-heart: \f4c3;
$fa-var-hands-praying: \f684;
$fa-var-praying-hands: \f684;
$fa-var-handshake: \f2b5;
$fa-var-handshake-angle: \f4c4;
$fa-var-hands-helping: \f4c4;
$fa-var-handshake-simple: \f4c6;
$fa-var-handshake-alt: \f4c6;
$fa-var-handshake-simple-slash: \e05f;
$fa-var-handshake-alt-slash: \e05f;
$fa-var-handshake-slash: \e060;
$fa-var-hanukiah: \f6e6;
$fa-var-hard-drive: \f0a0;
$fa-var-hdd: \f0a0;
$fa-var-hashtag: \23;
$fa-var-hashtag-lock: \e415;
$fa-var-hat-beach: \e606;
$fa-var-hat-chef: \f86b;
$fa-var-hat-cowboy: \f8c0;
$fa-var-hat-cowboy-side: \f8c1;
$fa-var-hat-santa: \f7a7;
$fa-var-hat-winter: \f7a8;
$fa-var-hat-witch: \f6e7;
$fa-var-hat-wizard: \f6e8;
$fa-var-head-side: \f6e9;
$fa-var-head-side-brain: \f808;
$fa-var-head-side-cough: \e061;
$fa-var-head-side-cough-slash: \e062;
$fa-var-head-side-gear: \e611;
$fa-var-head-side-goggles: \f6ea;
$fa-var-head-vr: \f6ea;
$fa-var-head-side-headphones: \f8c2;
$fa-var-head-side-heart: \e1aa;
$fa-var-head-side-mask: \e063;
$fa-var-head-side-medical: \f809;
$fa-var-head-side-virus: \e064;
$fa-var-heading: \f1dc;
$fa-var-header: \f1dc;
$fa-var-headphones: \f025;
$fa-var-headphones-simple: \f58f;
$fa-var-headphones-alt: \f58f;
$fa-var-headset: \f590;
$fa-var-heart: \f004;
$fa-var-heart-circle-bolt: \e4fc;
$fa-var-heart-circle-check: \e4fd;
$fa-var-heart-circle-exclamation: \e4fe;
$fa-var-heart-circle-minus: \e4ff;
$fa-var-heart-circle-plus: \e500;
$fa-var-heart-circle-xmark: \e501;
$fa-var-heart-crack: \f7a9;
$fa-var-heart-broken: \f7a9;
$fa-var-heart-half: \e1ab;
$fa-var-heart-half-stroke: \e1ac;
$fa-var-heart-half-alt: \e1ac;
$fa-var-heart-pulse: \f21e;
$fa-var-heartbeat: \f21e;
$fa-var-heat: \e00c;
$fa-var-helicopter: \f533;
$fa-var-helicopter-symbol: \e502;
$fa-var-helmet-battle: \f6eb;
$fa-var-helmet-safety: \f807;
$fa-var-hard-hat: \f807;
$fa-var-hat-hard: \f807;
$fa-var-helmet-un: \e503;
$fa-var-hexagon: \f312;
$fa-var-hexagon-check: \e416;
$fa-var-hexagon-divide: \e1ad;
$fa-var-hexagon-exclamation: \e417;
$fa-var-hexagon-image: \e504;
$fa-var-hexagon-minus: \f307;
$fa-var-minus-hexagon: \f307;
$fa-var-hexagon-plus: \f300;
$fa-var-plus-hexagon: \f300;
$fa-var-hexagon-vertical-nft: \e505;
$fa-var-hexagon-vertical-nft-slanted: \e506;
$fa-var-hexagon-xmark: \f2ee;
$fa-var-times-hexagon: \f2ee;
$fa-var-xmark-hexagon: \f2ee;
$fa-var-high-definition: \e1ae;
$fa-var-rectangle-hd: \e1ae;
$fa-var-highlighter: \f591;
$fa-var-highlighter-line: \e1af;
$fa-var-hill-avalanche: \e507;
$fa-var-hill-rockslide: \e508;
$fa-var-hippo: \f6ed;
$fa-var-hockey-mask: \f6ee;
$fa-var-hockey-puck: \f453;
$fa-var-hockey-stick-puck: \e3ae;
$fa-var-hockey-sticks: \f454;
$fa-var-holly-berry: \f7aa;
$fa-var-honey-pot: \e418;
$fa-var-hood-cloak: \f6ef;
$fa-var-horizontal-rule: \f86c;
$fa-var-horse: \f6f0;
$fa-var-horse-head: \f7ab;
$fa-var-horse-saddle: \f8c3;
$fa-var-hose: \e419;
$fa-var-hose-reel: \e41a;
$fa-var-hospital: \f0f8;
$fa-var-hospital-alt: \f0f8;
$fa-var-hospital-wide: \f0f8;
$fa-var-hospital-user: \f80d;
$fa-var-hospitals: \f80e;
$fa-var-hot-tub-person: \f593;
$fa-var-hot-tub: \f593;
$fa-var-hotdog: \f80f;
$fa-var-hotel: \f594;
$fa-var-hourglass: \f254;
$fa-var-hourglass-empty: \f254;
$fa-var-hourglass-clock: \e41b;
$fa-var-hourglass-end: \f253;
$fa-var-hourglass-3: \f253;
$fa-var-hourglass-half: \f252;
$fa-var-hourglass-2: \f252;
$fa-var-hourglass-start: \f251;
$fa-var-hourglass-1: \f251;
$fa-var-house: \f015;
$fa-var-home: \f015;
$fa-var-home-alt: \f015;
$fa-var-home-lg-alt: \f015;
$fa-var-house-blank: \e487;
$fa-var-home-blank: \e487;
$fa-var-house-building: \e1b1;
$fa-var-house-chimney: \e3af;
$fa-var-home-lg: \e3af;
$fa-var-house-chimney-blank: \e3b0;
$fa-var-house-chimney-crack: \f6f1;
$fa-var-house-damage: \f6f1;
$fa-var-house-chimney-heart: \e1b2;
$fa-var-house-chimney-medical: \f7f2;
$fa-var-clinic-medical: \f7f2;
$fa-var-house-chimney-user: \e065;
$fa-var-house-chimney-window: \e00d;
$fa-var-house-circle-check: \e509;
$fa-var-house-circle-exclamation: \e50a;
$fa-var-house-circle-xmark: \e50b;
$fa-var-house-crack: \e3b1;
$fa-var-house-day: \e00e;
$fa-var-house-fire: \e50c;
$fa-var-house-flag: \e50d;
$fa-var-house-flood-water: \e50e;
$fa-var-house-flood-water-circle-arrow-right: \e50f;
$fa-var-house-heart: \f4c9;
$fa-var-home-heart: \f4c9;
$fa-var-house-laptop: \e066;
$fa-var-laptop-house: \e066;
$fa-var-house-lock: \e510;
$fa-var-house-medical: \e3b2;
$fa-var-house-medical-circle-check: \e511;
$fa-var-house-medical-circle-exclamation: \e512;
$fa-var-house-medical-circle-xmark: \e513;
$fa-var-house-medical-flag: \e514;
$fa-var-house-night: \e010;
$fa-var-house-person-leave: \e00f;
$fa-var-house-leave: \e00f;
$fa-var-house-person-depart: \e00f;
$fa-var-house-person-return: \e011;
$fa-var-house-person-arrive: \e011;
$fa-var-house-return: \e011;
$fa-var-house-signal: \e012;
$fa-var-house-tree: \e1b3;
$fa-var-house-tsunami: \e515;
$fa-var-house-turret: \e1b4;
$fa-var-house-user: \e1b0;
$fa-var-home-user: \e1b0;
$fa-var-house-water: \f74f;
$fa-var-house-flood: \f74f;
$fa-var-house-window: \e3b3;
$fa-var-hryvnia-sign: \f6f2;
$fa-var-hryvnia: \f6f2;
$fa-var-hundred-points: \e41c;
$fa-var-100: \e41c;
$fa-var-hurricane: \f751;
$fa-var-hyphen: \2d;
$fa-var-i: \49;
$fa-var-i-cursor: \f246;
$fa-var-ice-cream: \f810;
$fa-var-ice-skate: \f7ac;
$fa-var-icicles: \f7ad;
$fa-var-icons: \f86d;
$fa-var-heart-music-camera-bolt: \f86d;
$fa-var-id-badge: \f2c1;
$fa-var-id-card: \f2c2;
$fa-var-drivers-license: \f2c2;
$fa-var-id-card-clip: \f47f;
$fa-var-id-card-alt: \f47f;
$fa-var-igloo: \f7ae;
$fa-var-image: \f03e;
$fa-var-image-landscape: \e1b5;
$fa-var-landscape: \e1b5;
$fa-var-image-polaroid: \f8c4;
$fa-var-image-polaroid-user: \e1b6;
$fa-var-image-portrait: \f3e0;
$fa-var-portrait: \f3e0;
$fa-var-image-slash: \e1b7;
$fa-var-image-user: \e1b8;
$fa-var-images: \f302;
$fa-var-images-user: \e1b9;
$fa-var-inbox: \f01c;
$fa-var-inbox-full: \e1ba;
$fa-var-inbox-in: \f310;
$fa-var-inbox-arrow-down: \f310;
$fa-var-inbox-out: \f311;
$fa-var-inbox-arrow-up: \f311;
$fa-var-inboxes: \e1bb;
$fa-var-indent: \f03c;
$fa-var-indian-rupee-sign: \e1bc;
$fa-var-indian-rupee: \e1bc;
$fa-var-inr: \e1bc;
$fa-var-industry: \f275;
$fa-var-industry-windows: \f3b3;
$fa-var-industry-alt: \f3b3;
$fa-var-infinity: \f534;
$fa-var-info: \f129;
$fa-var-inhaler: \f5f9;
$fa-var-input-numeric: \e1bd;
$fa-var-input-pipe: \e1be;
$fa-var-input-text: \e1bf;
$fa-var-integral: \f667;
$fa-var-interrobang: \e5ba;
$fa-var-intersection: \f668;
$fa-var-island-tropical: \f811;
$fa-var-island-tree-palm: \f811;
$fa-var-italic: \f033;
$fa-var-j: \4a;
$fa-var-jack-o-lantern: \f30e;
$fa-var-jar: \e516;
$fa-var-jar-wheat: \e517;
$fa-var-jedi: \f669;
$fa-var-jet-fighter: \f0fb;
$fa-var-fighter-jet: \f0fb;
$fa-var-jet-fighter-up: \e518;
$fa-var-joint: \f595;
$fa-var-joystick: \f8c5;
$fa-var-jug: \f8c6;
$fa-var-jug-bottle: \e5fb;
$fa-var-jug-detergent: \e519;
$fa-var-k: \4b;
$fa-var-kaaba: \f66b;
$fa-var-kazoo: \f8c7;
$fa-var-kerning: \f86f;
$fa-var-key: \f084;
$fa-var-key-skeleton: \f6f3;
$fa-var-key-skeleton-left-right: \e3b4;
$fa-var-keyboard: \f11c;
$fa-var-keyboard-brightness: \e1c0;
$fa-var-keyboard-brightness-low: \e1c1;
$fa-var-keyboard-down: \e1c2;
$fa-var-keyboard-left: \e1c3;
$fa-var-keynote: \f66c;
$fa-var-khanda: \f66d;
$fa-var-kidneys: \f5fb;
$fa-var-kip-sign: \e1c4;
$fa-var-kit-medical: \f479;
$fa-var-first-aid: \f479;
$fa-var-kitchen-set: \e51a;
$fa-var-kite: \f6f4;
$fa-var-kiwi-bird: \f535;
$fa-var-kiwi-fruit: \e30c;
$fa-var-knife: \f2e4;
$fa-var-utensil-knife: \f2e4;
$fa-var-knife-kitchen: \f6f5;
$fa-var-l: \4c;
$fa-var-lacrosse-stick: \e3b5;
$fa-var-lacrosse-stick-ball: \e3b6;
$fa-var-lambda: \f66e;
$fa-var-lamp: \f4ca;
$fa-var-lamp-desk: \e014;
$fa-var-lamp-floor: \e015;
$fa-var-lamp-street: \e1c5;
$fa-var-land-mine-on: \e51b;
$fa-var-landmark: \f66f;
$fa-var-landmark-dome: \f752;
$fa-var-landmark-alt: \f752;
$fa-var-landmark-flag: \e51c;
$fa-var-landmark-magnifying-glass: \e622;
$fa-var-language: \f1ab;
$fa-var-laptop: \f109;
$fa-var-laptop-arrow-down: \e1c6;
$fa-var-laptop-binary: \e5e7;
$fa-var-laptop-code: \f5fc;
$fa-var-laptop-file: \e51d;
$fa-var-laptop-medical: \f812;
$fa-var-laptop-mobile: \f87a;
$fa-var-phone-laptop: \f87a;
$fa-var-laptop-slash: \e1c7;
$fa-var-lari-sign: \e1c8;
$fa-var-lasso: \f8c8;
$fa-var-lasso-sparkles: \e1c9;
$fa-var-layer-group: \f5fd;
$fa-var-layer-minus: \f5fe;
$fa-var-layer-group-minus: \f5fe;
$fa-var-layer-plus: \f5ff;
$fa-var-layer-group-plus: \f5ff;
$fa-var-leaf: \f06c;
$fa-var-leaf-heart: \f4cb;
$fa-var-leaf-maple: \f6f6;
$fa-var-leaf-oak: \f6f7;
$fa-var-leafy-green: \e41d;
$fa-var-left: \f355;
$fa-var-arrow-alt-left: \f355;
$fa-var-left-from-bracket: \e66c;
$fa-var-left-from-line: \f348;
$fa-var-arrow-alt-from-right: \f348;
$fa-var-left-long: \f30a;
$fa-var-long-arrow-alt-left: \f30a;
$fa-var-left-long-to-line: \e41e;
$fa-var-left-right: \f337;
$fa-var-arrows-alt-h: \f337;
$fa-var-left-to-bracket: \e66d;
$fa-var-left-to-line: \f34b;
$fa-var-arrow-alt-to-left: \f34b;
$fa-var-lemon: \f094;
$fa-var-less-than: \3c;
$fa-var-less-than-equal: \f537;
$fa-var-life-ring: \f1cd;
$fa-var-light-ceiling: \e016;
$fa-var-light-emergency: \e41f;
$fa-var-light-emergency-on: \e420;
$fa-var-light-switch: \e017;
$fa-var-light-switch-off: \e018;
$fa-var-light-switch-on: \e019;
$fa-var-lightbulb: \f0eb;
$fa-var-lightbulb-cfl: \e5a6;
$fa-var-lightbulb-cfl-on: \e5a7;
$fa-var-lightbulb-dollar: \f670;
$fa-var-lightbulb-exclamation: \f671;
$fa-var-lightbulb-exclamation-on: \e1ca;
$fa-var-lightbulb-gear: \e5fd;
$fa-var-lightbulb-on: \f672;
$fa-var-lightbulb-slash: \f673;
$fa-var-lighthouse: \e612;
$fa-var-lights-holiday: \f7b2;
$fa-var-line-columns: \f870;
$fa-var-line-height: \f871;
$fa-var-lines-leaning: \e51e;
$fa-var-link: \f0c1;
$fa-var-chain: \f0c1;
$fa-var-link-horizontal: \e1cb;
$fa-var-chain-horizontal: \e1cb;
$fa-var-link-horizontal-slash: \e1cc;
$fa-var-chain-horizontal-slash: \e1cc;
$fa-var-link-simple: \e1cd;
$fa-var-link-simple-slash: \e1ce;
$fa-var-link-slash: \f127;
$fa-var-chain-broken: \f127;
$fa-var-chain-slash: \f127;
$fa-var-unlink: \f127;
$fa-var-lips: \f600;
$fa-var-lira-sign: \f195;
$fa-var-list: \f03a;
$fa-var-list-squares: \f03a;
$fa-var-list-check: \f0ae;
$fa-var-tasks: \f0ae;
$fa-var-list-dropdown: \e1cf;
$fa-var-list-music: \f8c9;
$fa-var-list-ol: \f0cb;
$fa-var-list-1-2: \f0cb;
$fa-var-list-numeric: \f0cb;
$fa-var-list-radio: \e1d0;
$fa-var-list-timeline: \e1d1;
$fa-var-list-tree: \e1d2;
$fa-var-list-ul: \f0ca;
$fa-var-list-dots: \f0ca;
$fa-var-litecoin-sign: \e1d3;
$fa-var-loader: \e1d4;
$fa-var-lobster: \e421;
$fa-var-location-arrow: \f124;
$fa-var-location-arrow-up: \e63a;
$fa-var-location-check: \f606;
$fa-var-map-marker-check: \f606;
$fa-var-location-crosshairs: \f601;
$fa-var-location: \f601;
$fa-var-location-crosshairs-slash: \f603;
$fa-var-location-slash: \f603;
$fa-var-location-dot: \f3c5;
$fa-var-map-marker-alt: \f3c5;
$fa-var-location-dot-slash: \f605;
$fa-var-map-marker-alt-slash: \f605;
$fa-var-location-exclamation: \f608;
$fa-var-map-marker-exclamation: \f608;
$fa-var-location-minus: \f609;
$fa-var-map-marker-minus: \f609;
$fa-var-location-pen: \f607;
$fa-var-map-marker-edit: \f607;
$fa-var-location-pin: \f041;
$fa-var-map-marker: \f041;
$fa-var-location-pin-lock: \e51f;
$fa-var-location-pin-slash: \f60c;
$fa-var-map-marker-slash: \f60c;
$fa-var-location-plus: \f60a;
$fa-var-map-marker-plus: \f60a;
$fa-var-location-question: \f60b;
$fa-var-map-marker-question: \f60b;
$fa-var-location-smile: \f60d;
$fa-var-map-marker-smile: \f60d;
$fa-var-location-xmark: \f60e;
$fa-var-map-marker-times: \f60e;
$fa-var-map-marker-xmark: \f60e;
$fa-var-lock: \f023;
$fa-var-lock-a: \e422;
$fa-var-lock-hashtag: \e423;
$fa-var-lock-keyhole: \f30d;
$fa-var-lock-alt: \f30d;
$fa-var-lock-keyhole-open: \f3c2;
$fa-var-lock-open-alt: \f3c2;
$fa-var-lock-open: \f3c1;
$fa-var-locust: \e520;
$fa-var-lollipop: \e424;
$fa-var-lollypop: \e424;
$fa-var-loveseat: \f4cc;
$fa-var-couch-small: \f4cc;
$fa-var-luchador-mask: \f455;
$fa-var-luchador: \f455;
$fa-var-mask-luchador: \f455;
$fa-var-lungs: \f604;
$fa-var-lungs-virus: \e067;
$fa-var-m: \4d;
$fa-var-mace: \f6f8;
$fa-var-magnet: \f076;
$fa-var-magnifying-glass: \f002;
$fa-var-search: \f002;
$fa-var-magnifying-glass-arrow-right: \e521;
$fa-var-magnifying-glass-arrows-rotate: \e65e;
$fa-var-magnifying-glass-chart: \e522;
$fa-var-magnifying-glass-dollar: \f688;
$fa-var-search-dollar: \f688;
$fa-var-magnifying-glass-location: \f689;
$fa-var-search-location: \f689;
$fa-var-magnifying-glass-minus: \f010;
$fa-var-search-minus: \f010;
$fa-var-magnifying-glass-music: \e65f;
$fa-var-magnifying-glass-play: \e660;
$fa-var-magnifying-glass-plus: \f00e;
$fa-var-search-plus: \f00e;
$fa-var-magnifying-glass-waveform: \e661;
$fa-var-mailbox: \f813;
$fa-var-mailbox-flag-up: \e5bb;
$fa-var-manat-sign: \e1d5;
$fa-var-mandolin: \f6f9;
$fa-var-mango: \e30f;
$fa-var-manhole: \e1d6;
$fa-var-map: \f279;
$fa-var-map-location: \f59f;
$fa-var-map-marked: \f59f;
$fa-var-map-location-dot: \f5a0;
$fa-var-map-marked-alt: \f5a0;
$fa-var-map-pin: \f276;
$fa-var-marker: \f5a1;
$fa-var-mars: \f222;
$fa-var-mars-and-venus: \f224;
$fa-var-mars-and-venus-burst: \e523;
$fa-var-mars-double: \f227;
$fa-var-mars-stroke: \f229;
$fa-var-mars-stroke-right: \f22b;
$fa-var-mars-stroke-h: \f22b;
$fa-var-mars-stroke-up: \f22a;
$fa-var-mars-stroke-v: \f22a;
$fa-var-martini-glass: \f57b;
$fa-var-glass-martini-alt: \f57b;
$fa-var-martini-glass-citrus: \f561;
$fa-var-cocktail: \f561;
$fa-var-martini-glass-empty: \f000;
$fa-var-glass-martini: \f000;
$fa-var-mask: \f6fa;
$fa-var-mask-face: \e1d7;
$fa-var-mask-snorkel: \e3b7;
$fa-var-mask-ventilator: \e524;
$fa-var-masks-theater: \f630;
$fa-var-theater-masks: \f630;
$fa-var-mattress-pillow: \e525;
$fa-var-maximize: \f31e;
$fa-var-expand-arrows-alt: \f31e;
$fa-var-meat: \f814;
$fa-var-medal: \f5a2;
$fa-var-megaphone: \f675;
$fa-var-melon: \e310;
$fa-var-melon-slice: \e311;
$fa-var-memo: \e1d8;
$fa-var-memo-circle-check: \e1d9;
$fa-var-memo-circle-info: \e49a;
$fa-var-memo-pad: \e1da;
$fa-var-memory: \f538;
$fa-var-menorah: \f676;
$fa-var-mercury: \f223;
$fa-var-merge: \e526;
$fa-var-message: \f27a;
$fa-var-comment-alt: \f27a;
$fa-var-message-arrow-down: \e1db;
$fa-var-comment-alt-arrow-down: \e1db;
$fa-var-message-arrow-up: \e1dc;
$fa-var-comment-alt-arrow-up: \e1dc;
$fa-var-message-arrow-up-right: \e1dd;
$fa-var-message-bot: \e3b8;
$fa-var-message-captions: \e1de;
$fa-var-comment-alt-captions: \e1de;
$fa-var-message-check: \f4a2;
$fa-var-comment-alt-check: \f4a2;
$fa-var-message-code: \e1df;
$fa-var-message-dollar: \f650;
$fa-var-comment-alt-dollar: \f650;
$fa-var-message-dots: \f4a3;
$fa-var-comment-alt-dots: \f4a3;
$fa-var-messaging: \f4a3;
$fa-var-message-exclamation: \f4a5;
$fa-var-comment-alt-exclamation: \f4a5;
$fa-var-message-heart: \e5c9;
$fa-var-message-image: \e1e0;
$fa-var-comment-alt-image: \e1e0;
$fa-var-message-lines: \f4a6;
$fa-var-comment-alt-lines: \f4a6;
$fa-var-message-medical: \f7f4;
$fa-var-comment-alt-medical: \f7f4;
$fa-var-message-middle: \e1e1;
$fa-var-comment-middle-alt: \e1e1;
$fa-var-message-middle-top: \e1e2;
$fa-var-comment-middle-top-alt: \e1e2;
$fa-var-message-minus: \f4a7;
$fa-var-comment-alt-minus: \f4a7;
$fa-var-message-music: \f8af;
$fa-var-comment-alt-music: \f8af;
$fa-var-message-pen: \f4a4;
$fa-var-comment-alt-edit: \f4a4;
$fa-var-message-edit: \f4a4;
$fa-var-message-plus: \f4a8;
$fa-var-comment-alt-plus: \f4a8;
$fa-var-message-question: \e1e3;
$fa-var-message-quote: \e1e4;
$fa-var-comment-alt-quote: \e1e4;
$fa-var-message-slash: \f4a9;
$fa-var-comment-alt-slash: \f4a9;
$fa-var-message-smile: \f4aa;
$fa-var-comment-alt-smile: \f4aa;
$fa-var-message-sms: \e1e5;
$fa-var-message-text: \e1e6;
$fa-var-comment-alt-text: \e1e6;
$fa-var-message-xmark: \f4ab;
$fa-var-comment-alt-times: \f4ab;
$fa-var-message-times: \f4ab;
$fa-var-messages: \f4b6;
$fa-var-comments-alt: \f4b6;
$fa-var-messages-dollar: \f652;
$fa-var-comments-alt-dollar: \f652;
$fa-var-messages-question: \e1e7;
$fa-var-meteor: \f753;
$fa-var-meter: \e1e8;
$fa-var-meter-bolt: \e1e9;
$fa-var-meter-droplet: \e1ea;
$fa-var-meter-fire: \e1eb;
$fa-var-microchip: \f2db;
$fa-var-microchip-ai: \e1ec;
$fa-var-microphone: \f130;
$fa-var-microphone-lines: \f3c9;
$fa-var-microphone-alt: \f3c9;
$fa-var-microphone-lines-slash: \f539;
$fa-var-microphone-alt-slash: \f539;
$fa-var-microphone-slash: \f131;
$fa-var-microphone-stand: \f8cb;
$fa-var-microscope: \f610;
$fa-var-microwave: \e01b;
$fa-var-mill-sign: \e1ed;
$fa-var-minimize: \f78c;
$fa-var-compress-arrows-alt: \f78c;
$fa-var-minus: \f068;
$fa-var-subtract: \f068;
$fa-var-mistletoe: \f7b4;
$fa-var-mitten: \f7b5;
$fa-var-mobile: \f3ce;
$fa-var-mobile-android: \f3ce;
$fa-var-mobile-phone: \f3ce;
$fa-var-mobile-button: \f10b;
$fa-var-mobile-notch: \e1ee;
$fa-var-mobile-iphone: \e1ee;
$fa-var-mobile-retro: \e527;
$fa-var-mobile-screen: \f3cf;
$fa-var-mobile-android-alt: \f3cf;
$fa-var-mobile-screen-button: \f3cd;
$fa-var-mobile-alt: \f3cd;
$fa-var-mobile-signal: \e1ef;
$fa-var-mobile-signal-out: \e1f0;
$fa-var-money-bill: \f0d6;
$fa-var-money-bill-1: \f3d1;
$fa-var-money-bill-alt: \f3d1;
$fa-var-money-bill-1-wave: \f53b;
$fa-var-money-bill-wave-alt: \f53b;
$fa-var-money-bill-simple: \e1f1;
$fa-var-money-bill-simple-wave: \e1f2;
$fa-var-money-bill-transfer: \e528;
$fa-var-money-bill-trend-up: \e529;
$fa-var-money-bill-wave: \f53a;
$fa-var-money-bill-wheat: \e52a;
$fa-var-money-bills: \e1f3;
$fa-var-money-bills-simple: \e1f4;
$fa-var-money-bills-alt: \e1f4;
$fa-var-money-check: \f53c;
$fa-var-money-check-dollar: \f53d;
$fa-var-money-check-alt: \f53d;
$fa-var-money-check-dollar-pen: \f873;
$fa-var-money-check-edit-alt: \f873;
$fa-var-money-check-pen: \f872;
$fa-var-money-check-edit: \f872;
$fa-var-money-from-bracket: \e312;
$fa-var-money-simple-from-bracket: \e313;
$fa-var-monitor-waveform: \f611;
$fa-var-monitor-heart-rate: \f611;
$fa-var-monkey: \f6fb;
$fa-var-monument: \f5a6;
$fa-var-moon: \f186;
$fa-var-moon-cloud: \f754;
$fa-var-moon-over-sun: \f74a;
$fa-var-eclipse-alt: \f74a;
$fa-var-moon-stars: \f755;
$fa-var-moped: \e3b9;
$fa-var-mortar-pestle: \f5a7;
$fa-var-mosque: \f678;
$fa-var-mosquito: \e52b;
$fa-var-mosquito-net: \e52c;
$fa-var-motorcycle: \f21c;
$fa-var-mound: \e52d;
$fa-var-mountain: \f6fc;
$fa-var-mountain-city: \e52e;
$fa-var-mountain-sun: \e52f;
$fa-var-mountains: \f6fd;
$fa-var-mouse-field: \e5a8;
$fa-var-mp3-player: \f8ce;
$fa-var-mug: \f874;
$fa-var-mug-hot: \f7b6;
$fa-var-mug-marshmallows: \f7b7;
$fa-var-mug-saucer: \f0f4;
$fa-var-coffee: \f0f4;
$fa-var-mug-tea: \f875;
$fa-var-mug-tea-saucer: \e1f5;
$fa-var-mushroom: \e425;
$fa-var-music: \f001;
$fa-var-music-magnifying-glass: \e662;
$fa-var-music-note: \f8cf;
$fa-var-music-alt: \f8cf;
$fa-var-music-note-slash: \f8d0;
$fa-var-music-alt-slash: \f8d0;
$fa-var-music-slash: \f8d1;
$fa-var-mustache: \e5bc;
$fa-var-n: \4e;
$fa-var-naira-sign: \e1f6;
$fa-var-narwhal: \f6fe;
$fa-var-nesting-dolls: \e3ba;
$fa-var-network-wired: \f6ff;
$fa-var-neuter: \f22c;
$fa-var-newspaper: \f1ea;
$fa-var-nfc: \e1f7;
$fa-var-nfc-lock: \e1f8;
$fa-var-nfc-magnifying-glass: \e1f9;
$fa-var-nfc-pen: \e1fa;
$fa-var-nfc-signal: \e1fb;
$fa-var-nfc-slash: \e1fc;
$fa-var-nfc-trash: \e1fd;
$fa-var-nose: \e5bd;
$fa-var-not-equal: \f53e;
$fa-var-notdef: \e1fe;
$fa-var-note: \e1ff;
$fa-var-note-medical: \e200;
$fa-var-note-sticky: \f249;
$fa-var-sticky-note: \f249;
$fa-var-notebook: \e201;
$fa-var-notes: \e202;
$fa-var-notes-medical: \f481;
$fa-var-o: \4f;
$fa-var-object-exclude: \e49c;
$fa-var-object-group: \f247;
$fa-var-object-intersect: \e49d;
$fa-var-object-subtract: \e49e;
$fa-var-object-ungroup: \f248;
$fa-var-object-union: \e49f;
$fa-var-objects-align-bottom: \e3bb;
$fa-var-objects-align-center-horizontal: \e3bc;
$fa-var-objects-align-center-vertical: \e3bd;
$fa-var-objects-align-left: \e3be;
$fa-var-objects-align-right: \e3bf;
$fa-var-objects-align-top: \e3c0;
$fa-var-objects-column: \e3c1;
$fa-var-octagon: \f306;
$fa-var-octagon-check: \e426;
$fa-var-octagon-divide: \e203;
$fa-var-octagon-exclamation: \e204;
$fa-var-octagon-minus: \f308;
$fa-var-minus-octagon: \f308;
$fa-var-octagon-plus: \f301;
$fa-var-plus-octagon: \f301;
$fa-var-octagon-xmark: \f2f0;
$fa-var-times-octagon: \f2f0;
$fa-var-xmark-octagon: \f2f0;
$fa-var-oil-can: \f613;
$fa-var-oil-can-drip: \e205;
$fa-var-oil-temperature: \f614;
$fa-var-oil-temp: \f614;
$fa-var-oil-well: \e532;
$fa-var-olive: \e316;
$fa-var-olive-branch: \e317;
$fa-var-om: \f679;
$fa-var-omega: \f67a;
$fa-var-onion: \e427;
$fa-var-option: \e318;
$fa-var-ornament: \f7b8;
$fa-var-otter: \f700;
$fa-var-outdent: \f03b;
$fa-var-dedent: \f03b;
$fa-var-outlet: \e01c;
$fa-var-oven: \e01d;
$fa-var-overline: \f876;
$fa-var-p: \50;
$fa-var-page: \e428;
$fa-var-page-caret-down: \e429;
$fa-var-file-caret-down: \e429;
$fa-var-page-caret-up: \e42a;
$fa-var-file-caret-up: \e42a;
$fa-var-pager: \f815;
$fa-var-paint-roller: \f5aa;
$fa-var-paintbrush: \f1fc;
$fa-var-paint-brush: \f1fc;
$fa-var-paintbrush-fine: \f5a9;
$fa-var-paint-brush-alt: \f5a9;
$fa-var-paint-brush-fine: \f5a9;
$fa-var-paintbrush-alt: \f5a9;
$fa-var-paintbrush-pencil: \e206;
$fa-var-palette: \f53f;
$fa-var-pallet: \f482;
$fa-var-pallet-box: \e208;
$fa-var-pallet-boxes: \f483;
$fa-var-palette-boxes: \f483;
$fa-var-pallet-alt: \f483;
$fa-var-pan-food: \e42b;
$fa-var-pan-frying: \e42c;
$fa-var-pancakes: \e42d;
$fa-var-panel-ews: \e42e;
$fa-var-panel-fire: \e42f;
$fa-var-panorama: \e209;
$fa-var-paper-plane: \f1d8;
$fa-var-paper-plane-top: \e20a;
$fa-var-paper-plane-alt: \e20a;
$fa-var-send: \e20a;
$fa-var-paperclip: \f0c6;
$fa-var-paperclip-vertical: \e3c2;
$fa-var-parachute-box: \f4cd;
$fa-var-paragraph: \f1dd;
$fa-var-paragraph-left: \f878;
$fa-var-paragraph-rtl: \f878;
$fa-var-party-bell: \e31a;
$fa-var-party-horn: \e31b;
$fa-var-passport: \f5ab;
$fa-var-paste: \f0ea;
$fa-var-file-clipboard: \f0ea;
$fa-var-pause: \f04c;
$fa-var-paw: \f1b0;
$fa-var-paw-claws: \f702;
$fa-var-paw-simple: \f701;
$fa-var-paw-alt: \f701;
$fa-var-peace: \f67c;
$fa-var-peach: \e20b;
$fa-var-peanut: \e430;
$fa-var-peanuts: \e431;
$fa-var-peapod: \e31c;
$fa-var-pear: \e20c;
$fa-var-pedestal: \e20d;
$fa-var-pegasus: \f703;
$fa-var-pen: \f304;
$fa-var-pen-circle: \e20e;
$fa-var-pen-clip: \f305;
$fa-var-pen-alt: \f305;
$fa-var-pen-clip-slash: \e20f;
$fa-var-pen-alt-slash: \e20f;
$fa-var-pen-fancy: \f5ac;
$fa-var-pen-fancy-slash: \e210;
$fa-var-pen-field: \e211;
$fa-var-pen-line: \e212;
$fa-var-pen-nib: \f5ad;
$fa-var-pen-nib-slash: \e4a1;
$fa-var-pen-paintbrush: \f618;
$fa-var-pencil-paintbrush: \f618;
$fa-var-pen-ruler: \f5ae;
$fa-var-pencil-ruler: \f5ae;
$fa-var-pen-slash: \e213;
$fa-var-pen-swirl: \e214;
$fa-var-pen-to-square: \f044;
$fa-var-edit: \f044;
$fa-var-pencil: \f303;
$fa-var-pencil-alt: \f303;
$fa-var-pencil-mechanical: \e5ca;
$fa-var-pencil-slash: \e215;
$fa-var-people: \e216;
$fa-var-people-arrows: \e068;
$fa-var-people-arrows-left-right: \e068;
$fa-var-people-carry-box: \f4ce;
$fa-var-people-carry: \f4ce;
$fa-var-people-dress: \e217;
$fa-var-people-dress-simple: \e218;
$fa-var-people-group: \e533;
$fa-var-people-line: \e534;
$fa-var-people-pants: \e219;
$fa-var-people-pants-simple: \e21a;
$fa-var-people-pulling: \e535;
$fa-var-people-robbery: \e536;
$fa-var-people-roof: \e537;
$fa-var-people-simple: \e21b;
$fa-var-pepper: \e432;
$fa-var-pepper-hot: \f816;
$fa-var-percent: \25;
$fa-var-percentage: \25;
$fa-var-period: \2e;
$fa-var-person: \f183;
$fa-var-male: \f183;
$fa-var-person-arrow-down-to-line: \e538;
$fa-var-person-arrow-up-from-line: \e539;
$fa-var-person-biking: \f84a;
$fa-var-biking: \f84a;
$fa-var-person-biking-mountain: \f84b;
$fa-var-biking-mountain: \f84b;
$fa-var-person-booth: \f756;
$fa-var-person-breastfeeding: \e53a;
$fa-var-person-burst: \e53b;
$fa-var-person-cane: \e53c;
$fa-var-person-carry-box: \f4cf;
$fa-var-person-carry: \f4cf;
$fa-var-person-chalkboard: \e53d;
$fa-var-person-circle-check: \e53e;
$fa-var-person-circle-exclamation: \e53f;
$fa-var-person-circle-minus: \e540;
$fa-var-person-circle-plus: \e541;
$fa-var-person-circle-question: \e542;
$fa-var-person-circle-xmark: \e543;
$fa-var-person-digging: \f85e;
$fa-var-digging: \f85e;
$fa-var-person-dolly: \f4d0;
$fa-var-person-dolly-empty: \f4d1;
$fa-var-person-dots-from-line: \f470;
$fa-var-diagnoses: \f470;
$fa-var-person-dress: \f182;
$fa-var-female: \f182;
$fa-var-person-dress-burst: \e544;
$fa-var-person-dress-fairy: \e607;
$fa-var-person-dress-simple: \e21c;
$fa-var-person-drowning: \e545;
$fa-var-person-fairy: \e608;
$fa-var-person-falling: \e546;
$fa-var-person-falling-burst: \e547;
$fa-var-person-from-portal: \e023;
$fa-var-portal-exit: \e023;
$fa-var-person-half-dress: \e548;
$fa-var-person-harassing: \e549;
$fa-var-person-hiking: \f6ec;
$fa-var-hiking: \f6ec;
$fa-var-person-military-pointing: \e54a;
$fa-var-person-military-rifle: \e54b;
$fa-var-person-military-to-person: \e54c;
$fa-var-person-pinball: \e21d;
$fa-var-person-praying: \f683;
$fa-var-pray: \f683;
$fa-var-person-pregnant: \e31e;
$fa-var-person-rays: \e54d;
$fa-var-person-rifle: \e54e;
$fa-var-person-running: \f70c;
$fa-var-running: \f70c;
$fa-var-person-running-fast: \e5ff;
$fa-var-person-seat: \e21e;
$fa-var-person-seat-reclined: \e21f;
$fa-var-person-shelter: \e54f;
$fa-var-person-sign: \f757;
$fa-var-person-simple: \e220;
$fa-var-person-skating: \f7c5;
$fa-var-skating: \f7c5;
$fa-var-person-ski-jumping: \f7c7;
$fa-var-ski-jump: \f7c7;
$fa-var-person-ski-lift: \f7c8;
$fa-var-ski-lift: \f7c8;
$fa-var-person-skiing: \f7c9;
$fa-var-skiing: \f7c9;
$fa-var-person-skiing-nordic: \f7ca;
$fa-var-skiing-nordic: \f7ca;
$fa-var-person-sledding: \f7cb;
$fa-var-sledding: \f7cb;
$fa-var-person-snowboarding: \f7ce;
$fa-var-snowboarding: \f7ce;
$fa-var-person-snowmobiling: \f7d1;
$fa-var-snowmobile: \f7d1;
$fa-var-person-swimming: \f5c4;
$fa-var-swimmer: \f5c4;
$fa-var-person-through-window: \e5a9;
$fa-var-person-to-door: \e433;
$fa-var-person-to-portal: \e022;
$fa-var-portal-enter: \e022;
$fa-var-person-walking: \f554;
$fa-var-walking: \f554;
$fa-var-person-walking-arrow-loop-left: \e551;
$fa-var-person-walking-arrow-right: \e552;
$fa-var-person-walking-dashed-line-arrow-right: \e553;
$fa-var-person-walking-luggage: \e554;
$fa-var-person-walking-with-cane: \f29d;
$fa-var-blind: \f29d;
$fa-var-peseta-sign: \e221;
$fa-var-peso-sign: \e222;
$fa-var-phone: \f095;
$fa-var-phone-arrow-down-left: \e223;
$fa-var-phone-arrow-down: \e223;
$fa-var-phone-incoming: \e223;
$fa-var-phone-arrow-right: \e5be;
$fa-var-phone-arrow-up-right: \e224;
$fa-var-phone-arrow-up: \e224;
$fa-var-phone-outgoing: \e224;
$fa-var-phone-flip: \f879;
$fa-var-phone-alt: \f879;
$fa-var-phone-hangup: \e225;
$fa-var-phone-intercom: \e434;
$fa-var-phone-missed: \e226;
$fa-var-phone-office: \f67d;
$fa-var-phone-plus: \f4d2;
$fa-var-phone-rotary: \f8d3;
$fa-var-phone-slash: \f3dd;
$fa-var-phone-volume: \f2a0;
$fa-var-volume-control-phone: \f2a0;
$fa-var-phone-xmark: \e227;
$fa-var-photo-film: \f87c;
$fa-var-photo-video: \f87c;
$fa-var-photo-film-music: \e228;
$fa-var-pi: \f67e;
$fa-var-piano: \f8d4;
$fa-var-piano-keyboard: \f8d5;
$fa-var-pickaxe: \e5bf;
$fa-var-pickleball: \e435;
$fa-var-pie: \f705;
$fa-var-pig: \f706;
$fa-var-piggy-bank: \f4d3;
$fa-var-pills: \f484;
$fa-var-pinata: \e3c3;
$fa-var-pinball: \e229;
$fa-var-pineapple: \e31f;
$fa-var-pipe: \7c;
$fa-var-pipe-circle-check: \e436;
$fa-var-pipe-collar: \e437;
$fa-var-pipe-section: \e438;
$fa-var-pipe-smoking: \e3c4;
$fa-var-pipe-valve: \e439;
$fa-var-pizza: \f817;
$fa-var-pizza-slice: \f818;
$fa-var-place-of-worship: \f67f;
$fa-var-plane: \f072;
$fa-var-plane-arrival: \f5af;
$fa-var-plane-circle-check: \e555;
$fa-var-plane-circle-exclamation: \e556;
$fa-var-plane-circle-xmark: \e557;
$fa-var-plane-departure: \f5b0;
$fa-var-plane-engines: \f3de;
$fa-var-plane-alt: \f3de;
$fa-var-plane-lock: \e558;
$fa-var-plane-prop: \e22b;
$fa-var-plane-slash: \e069;
$fa-var-plane-tail: \e22c;
$fa-var-plane-up: \e22d;
$fa-var-plane-up-slash: \e22e;
$fa-var-planet-moon: \e01f;
$fa-var-planet-ringed: \e020;
$fa-var-plant-wilt: \e5aa;
$fa-var-plate-utensils: \e43b;
$fa-var-plate-wheat: \e55a;
$fa-var-play: \f04b;
$fa-var-play-pause: \e22f;
$fa-var-plug: \f1e6;
$fa-var-plug-circle-bolt: \e55b;
$fa-var-plug-circle-check: \e55c;
$fa-var-plug-circle-exclamation: \e55d;
$fa-var-plug-circle-minus: \e55e;
$fa-var-plug-circle-plus: \e55f;
$fa-var-plug-circle-xmark: \e560;
$fa-var-plus: \2b;
$fa-var-add: \2b;
$fa-var-plus-large: \e59e;
$fa-var-plus-minus: \e43c;
$fa-var-podcast: \f2ce;
$fa-var-podium: \f680;
$fa-var-podium-star: \f758;
$fa-var-police-box: \e021;
$fa-var-poll-people: \f759;
$fa-var-pompebled: \e43d;
$fa-var-poo: \f2fe;
$fa-var-poo-storm: \f75a;
$fa-var-poo-bolt: \f75a;
$fa-var-pool-8-ball: \e3c5;
$fa-var-poop: \f619;
$fa-var-popcorn: \f819;
$fa-var-popsicle: \e43e;
$fa-var-pot-food: \e43f;
$fa-var-potato: \e440;
$fa-var-power-off: \f011;
$fa-var-prescription: \f5b1;
$fa-var-prescription-bottle: \f485;
$fa-var-prescription-bottle-medical: \f486;
$fa-var-prescription-bottle-alt: \f486;
$fa-var-prescription-bottle-pill: \e5c0;
$fa-var-presentation-screen: \f685;
$fa-var-presentation: \f685;
$fa-var-pretzel: \e441;
$fa-var-print: \f02f;
$fa-var-print-magnifying-glass: \f81a;
$fa-var-print-search: \f81a;
$fa-var-print-slash: \f686;
$fa-var-projector: \f8d6;
$fa-var-pump: \e442;
$fa-var-pump-medical: \e06a;
$fa-var-pump-soap: \e06b;
$fa-var-pumpkin: \f707;
$fa-var-puzzle: \e443;
$fa-var-puzzle-piece: \f12e;
$fa-var-puzzle-piece-simple: \e231;
$fa-var-puzzle-piece-alt: \e231;
$fa-var-q: \51;
$fa-var-qrcode: \f029;
$fa-var-question: \3f;
$fa-var-quote-left: \f10d;
$fa-var-quote-left-alt: \f10d;
$fa-var-quote-right: \f10e;
$fa-var-quote-right-alt: \f10e;
$fa-var-quotes: \e234;
$fa-var-r: \52;
$fa-var-rabbit: \f708;
$fa-var-rabbit-running: \f709;
$fa-var-rabbit-fast: \f709;
$fa-var-raccoon: \e613;
$fa-var-racquet: \f45a;
$fa-var-radar: \e024;
$fa-var-radiation: \f7b9;
$fa-var-radio: \f8d7;
$fa-var-radio-tuner: \f8d8;
$fa-var-radio-alt: \f8d8;
$fa-var-rainbow: \f75b;
$fa-var-raindrops: \f75c;
$fa-var-ram: \f70a;
$fa-var-ramp-loading: \f4d4;
$fa-var-ranking-star: \e561;
$fa-var-raygun: \e025;
$fa-var-receipt: \f543;
$fa-var-record-vinyl: \f8d9;
$fa-var-rectangle: \f2fa;
$fa-var-rectangle-landscape: \f2fa;
$fa-var-rectangle-ad: \f641;
$fa-var-ad: \f641;
$fa-var-rectangle-barcode: \f463;
$fa-var-barcode-alt: \f463;
$fa-var-rectangle-code: \e322;
$fa-var-rectangle-history: \e4a2;
$fa-var-rectangle-history-circle-plus: \e4a3;
$fa-var-rectangle-history-circle-user: \e4a4;
$fa-var-rectangle-list: \f022;
$fa-var-list-alt: \f022;
$fa-var-rectangle-pro: \e235;
$fa-var-pro: \e235;
$fa-var-rectangle-terminal: \e236;
$fa-var-rectangle-vertical: \f2fb;
$fa-var-rectangle-portrait: \f2fb;
$fa-var-rectangle-vertical-history: \e237;
$fa-var-rectangle-wide: \f2fc;
$fa-var-rectangle-xmark: \f410;
$fa-var-rectangle-times: \f410;
$fa-var-times-rectangle: \f410;
$fa-var-window-close: \f410;
$fa-var-rectangles-mixed: \e323;
$fa-var-recycle: \f1b8;
$fa-var-reel: \e238;
$fa-var-reflect-both: \e66f;
$fa-var-reflect-horizontal: \e664;
$fa-var-reflect-vertical: \e665;
$fa-var-refrigerator: \e026;
$fa-var-registered: \f25d;
$fa-var-repeat: \f363;
$fa-var-repeat-1: \f365;
$fa-var-reply: \f3e5;
$fa-var-mail-reply: \f3e5;
$fa-var-reply-all: \f122;
$fa-var-mail-reply-all: \f122;
$fa-var-reply-clock: \e239;
$fa-var-reply-time: \e239;
$fa-var-republican: \f75e;
$fa-var-restroom: \f7bd;
$fa-var-restroom-simple: \e23a;
$fa-var-retweet: \f079;
$fa-var-rhombus: \e23b;
$fa-var-ribbon: \f4d6;
$fa-var-right: \f356;
$fa-var-arrow-alt-right: \f356;
$fa-var-right-from-bracket: \f2f5;
$fa-var-sign-out-alt: \f2f5;
$fa-var-right-from-line: \f347;
$fa-var-arrow-alt-from-left: \f347;
$fa-var-right-left: \f362;
$fa-var-exchange-alt: \f362;
$fa-var-right-left-large: \e5e1;
$fa-var-right-long: \f30b;
$fa-var-long-arrow-alt-right: \f30b;
$fa-var-right-long-to-line: \e444;
$fa-var-right-to-bracket: \f2f6;
$fa-var-sign-in-alt: \f2f6;
$fa-var-right-to-line: \f34c;
$fa-var-arrow-alt-to-right: \f34c;
$fa-var-ring: \f70b;
$fa-var-ring-diamond: \e5ab;
$fa-var-rings-wedding: \f81b;
$fa-var-road: \f018;
$fa-var-road-barrier: \e562;
$fa-var-road-bridge: \e563;
$fa-var-road-circle-check: \e564;
$fa-var-road-circle-exclamation: \e565;
$fa-var-road-circle-xmark: \e566;
$fa-var-road-lock: \e567;
$fa-var-road-spikes: \e568;
$fa-var-robot: \f544;
$fa-var-robot-astromech: \e2d2;
$fa-var-rocket: \f135;
$fa-var-rocket-launch: \e027;
$fa-var-roller-coaster: \e324;
$fa-var-rotate: \f2f1;
$fa-var-sync-alt: \f2f1;
$fa-var-rotate-exclamation: \e23c;
$fa-var-rotate-left: \f2ea;
$fa-var-rotate-back: \f2ea;
$fa-var-rotate-backward: \f2ea;
$fa-var-undo-alt: \f2ea;
$fa-var-rotate-reverse: \e631;
$fa-var-rotate-right: \f2f9;
$fa-var-redo-alt: \f2f9;
$fa-var-rotate-forward: \f2f9;
$fa-var-route: \f4d7;
$fa-var-route-highway: \f61a;
$fa-var-route-interstate: \f61b;
$fa-var-router: \f8da;
$fa-var-rss: \f09e;
$fa-var-feed: \f09e;
$fa-var-ruble-sign: \f158;
$fa-var-rouble: \f158;
$fa-var-rub: \f158;
$fa-var-ruble: \f158;
$fa-var-rug: \e569;
$fa-var-rugby-ball: \e3c6;
$fa-var-ruler: \f545;
$fa-var-ruler-combined: \f546;
$fa-var-ruler-horizontal: \f547;
$fa-var-ruler-triangle: \f61c;
$fa-var-ruler-vertical: \f548;
$fa-var-rupee-sign: \f156;
$fa-var-rupee: \f156;
$fa-var-rupiah-sign: \e23d;
$fa-var-rv: \f7be;
$fa-var-s: \53;
$fa-var-sack: \f81c;
$fa-var-sack-dollar: \f81d;
$fa-var-sack-xmark: \e56a;
$fa-var-sailboat: \e445;
$fa-var-salad: \f81e;
$fa-var-bowl-salad: \f81e;
$fa-var-salt-shaker: \e446;
$fa-var-sandwich: \f81f;
$fa-var-satellite: \f7bf;
$fa-var-satellite-dish: \f7c0;
$fa-var-sausage: \f820;
$fa-var-saxophone: \f8dc;
$fa-var-saxophone-fire: \f8db;
$fa-var-sax-hot: \f8db;
$fa-var-scale-balanced: \f24e;
$fa-var-balance-scale: \f24e;
$fa-var-scale-unbalanced: \f515;
$fa-var-balance-scale-left: \f515;
$fa-var-scale-unbalanced-flip: \f516;
$fa-var-balance-scale-right: \f516;
$fa-var-scalpel: \f61d;
$fa-var-scalpel-line-dashed: \f61e;
$fa-var-scalpel-path: \f61e;
$fa-var-scanner-gun: \f488;
$fa-var-scanner: \f488;
$fa-var-scanner-image: \f8f3;
$fa-var-scanner-keyboard: \f489;
$fa-var-scanner-touchscreen: \f48a;
$fa-var-scarecrow: \f70d;
$fa-var-scarf: \f7c1;
$fa-var-school: \f549;
$fa-var-school-circle-check: \e56b;
$fa-var-school-circle-exclamation: \e56c;
$fa-var-school-circle-xmark: \e56d;
$fa-var-school-flag: \e56e;
$fa-var-school-lock: \e56f;
$fa-var-scissors: \f0c4;
$fa-var-cut: \f0c4;
$fa-var-screen-users: \f63d;
$fa-var-users-class: \f63d;
$fa-var-screencast: \e23e;
$fa-var-screwdriver: \f54a;
$fa-var-screwdriver-wrench: \f7d9;
$fa-var-tools: \f7d9;
$fa-var-scribble: \e23f;
$fa-var-scroll: \f70e;
$fa-var-scroll-old: \f70f;
$fa-var-scroll-torah: \f6a0;
$fa-var-torah: \f6a0;
$fa-var-scrubber: \f2f8;
$fa-var-scythe: \f710;
$fa-var-sd-card: \f7c2;
$fa-var-sd-cards: \e240;
$fa-var-seal: \e241;
$fa-var-seal-exclamation: \e242;
$fa-var-seal-question: \e243;
$fa-var-seat-airline: \e244;
$fa-var-section: \e447;
$fa-var-seedling: \f4d8;
$fa-var-sprout: \f4d8;
$fa-var-semicolon: \3b;
$fa-var-send-back: \f87e;
$fa-var-send-backward: \f87f;
$fa-var-sensor: \e028;
$fa-var-sensor-cloud: \e02c;
$fa-var-sensor-smoke: \e02c;
$fa-var-sensor-fire: \e02a;
$fa-var-sensor-on: \e02b;
$fa-var-sensor-triangle-exclamation: \e029;
$fa-var-sensor-alert: \e029;
$fa-var-server: \f233;
$fa-var-shapes: \f61f;
$fa-var-triangle-circle-square: \f61f;
$fa-var-share: \f064;
$fa-var-mail-forward: \f064;
$fa-var-share-all: \f367;
$fa-var-share-from-square: \f14d;
$fa-var-share-square: \f14d;
$fa-var-share-nodes: \f1e0;
$fa-var-share-alt: \f1e0;
$fa-var-sheep: \f711;
$fa-var-sheet-plastic: \e571;
$fa-var-shekel-sign: \f20b;
$fa-var-ils: \f20b;
$fa-var-shekel: \f20b;
$fa-var-sheqel: \f20b;
$fa-var-sheqel-sign: \f20b;
$fa-var-shelves: \f480;
$fa-var-inventory: \f480;
$fa-var-shelves-empty: \e246;
$fa-var-shield: \f132;
$fa-var-shield-blank: \f132;
$fa-var-shield-cat: \e572;
$fa-var-shield-check: \f2f7;
$fa-var-shield-cross: \f712;
$fa-var-shield-dog: \e573;
$fa-var-shield-exclamation: \e247;
$fa-var-shield-halved: \f3ed;
$fa-var-shield-alt: \f3ed;
$fa-var-shield-heart: \e574;
$fa-var-shield-keyhole: \e248;
$fa-var-shield-minus: \e249;
$fa-var-shield-plus: \e24a;
$fa-var-shield-quartered: \e575;
$fa-var-shield-slash: \e24b;
$fa-var-shield-virus: \e06c;
$fa-var-shield-xmark: \e24c;
$fa-var-shield-times: \e24c;
$fa-var-ship: \f21a;
$fa-var-shirt: \f553;
$fa-var-t-shirt: \f553;
$fa-var-tshirt: \f553;
$fa-var-shirt-long-sleeve: \e3c7;
$fa-var-shirt-running: \e3c8;
$fa-var-shirt-tank-top: \e3c9;
$fa-var-shish-kebab: \f821;
$fa-var-shoe-prints: \f54b;
$fa-var-shop: \f54f;
$fa-var-store-alt: \f54f;
$fa-var-shop-lock: \e4a5;
$fa-var-shop-slash: \e070;
$fa-var-store-alt-slash: \e070;
$fa-var-shovel: \f713;
$fa-var-shovel-snow: \f7c3;
$fa-var-shower: \f2cc;
$fa-var-shower-down: \e24d;
$fa-var-shower-alt: \e24d;
$fa-var-shredder: \f68a;
$fa-var-shrimp: \e448;
$fa-var-shuffle: \f074;
$fa-var-random: \f074;
$fa-var-shutters: \e449;
$fa-var-shuttle-space: \f197;
$fa-var-space-shuttle: \f197;
$fa-var-shuttlecock: \f45b;
$fa-var-sickle: \f822;
$fa-var-sidebar: \e24e;
$fa-var-sidebar-flip: \e24f;
$fa-var-sigma: \f68b;
$fa-var-sign-hanging: \f4d9;
$fa-var-sign: \f4d9;
$fa-var-sign-post: \e624;
$fa-var-sign-posts: \e625;
$fa-var-sign-posts-wrench: \e626;
$fa-var-signal: \f012;
$fa-var-signal-5: \f012;
$fa-var-signal-perfect: \f012;
$fa-var-signal-bars: \f690;
$fa-var-signal-alt: \f690;
$fa-var-signal-alt-4: \f690;
$fa-var-signal-bars-strong: \f690;
$fa-var-signal-bars-fair: \f692;
$fa-var-signal-alt-2: \f692;
$fa-var-signal-bars-good: \f693;
$fa-var-signal-alt-3: \f693;
$fa-var-signal-bars-slash: \f694;
$fa-var-signal-alt-slash: \f694;
$fa-var-signal-bars-weak: \f691;
$fa-var-signal-alt-1: \f691;
$fa-var-signal-fair: \f68d;
$fa-var-signal-2: \f68d;
$fa-var-signal-good: \f68e;
$fa-var-signal-3: \f68e;
$fa-var-signal-slash: \f695;
$fa-var-signal-stream: \f8dd;
$fa-var-signal-stream-slash: \e250;
$fa-var-signal-strong: \f68f;
$fa-var-signal-4: \f68f;
$fa-var-signal-weak: \f68c;
$fa-var-signal-1: \f68c;
$fa-var-signature: \f5b7;
$fa-var-signature-lock: \e3ca;
$fa-var-signature-slash: \e3cb;
$fa-var-signs-post: \f277;
$fa-var-map-signs: \f277;
$fa-var-sim-card: \f7c4;
$fa-var-sim-cards: \e251;
$fa-var-sink: \e06d;
$fa-var-siren: \e02d;
$fa-var-siren-on: \e02e;
$fa-var-sitemap: \f0e8;
$fa-var-skeleton: \f620;
$fa-var-skeleton-ribs: \e5cb;
$fa-var-ski-boot: \e3cc;
$fa-var-ski-boot-ski: \e3cd;
$fa-var-skull: \f54c;
$fa-var-skull-cow: \f8de;
$fa-var-skull-crossbones: \f714;
$fa-var-slash: \f715;
$fa-var-slash-back: \5c;
$fa-var-slash-forward: \2f;
$fa-var-sleigh: \f7cc;
$fa-var-slider: \e252;
$fa-var-sliders: \f1de;
$fa-var-sliders-h: \f1de;
$fa-var-sliders-simple: \e253;
$fa-var-sliders-up: \f3f1;
$fa-var-sliders-v: \f3f1;
$fa-var-slot-machine: \e3ce;
$fa-var-smog: \f75f;
$fa-var-smoke: \f760;
$fa-var-smoking: \f48d;
$fa-var-snake: \f716;
$fa-var-snooze: \f880;
$fa-var-zzz: \f880;
$fa-var-snow-blowing: \f761;
$fa-var-snowflake: \f2dc;
$fa-var-snowflake-droplets: \e5c1;
$fa-var-snowflakes: \f7cf;
$fa-var-snowman: \f7d0;
$fa-var-snowman-head: \f79b;
$fa-var-frosty-head: \f79b;
$fa-var-snowplow: \f7d2;
$fa-var-soap: \e06e;
$fa-var-socks: \f696;
$fa-var-soft-serve: \e400;
$fa-var-creemee: \e400;
$fa-var-solar-panel: \f5ba;
$fa-var-solar-system: \e02f;
$fa-var-sort: \f0dc;
$fa-var-unsorted: \f0dc;
$fa-var-sort-down: \f0dd;
$fa-var-sort-desc: \f0dd;
$fa-var-sort-up: \f0de;
$fa-var-sort-asc: \f0de;
$fa-var-spa: \f5bb;
$fa-var-space-station-moon: \e033;
$fa-var-space-station-moon-construction: \e034;
$fa-var-space-station-moon-alt: \e034;
$fa-var-spade: \f2f4;
$fa-var-spaghetti-monster-flying: \f67b;
$fa-var-pastafarianism: \f67b;
$fa-var-sparkle: \e5d6;
$fa-var-sparkles: \f890;
$fa-var-speaker: \f8df;
$fa-var-speakers: \f8e0;
$fa-var-spell-check: \f891;
$fa-var-spider: \f717;
$fa-var-spider-black-widow: \f718;
$fa-var-spider-web: \f719;
$fa-var-spinner: \f110;
$fa-var-spinner-scale: \e62a;
$fa-var-spinner-third: \f3f4;
$fa-var-split: \e254;
$fa-var-splotch: \f5bc;
$fa-var-spoon: \f2e5;
$fa-var-utensil-spoon: \f2e5;
$fa-var-sportsball: \e44b;
$fa-var-spray-can: \f5bd;
$fa-var-spray-can-sparkles: \f5d0;
$fa-var-air-freshener: \f5d0;
$fa-var-sprinkler: \e035;
$fa-var-sprinkler-ceiling: \e44c;
$fa-var-square: \f0c8;
$fa-var-square-0: \e255;
$fa-var-square-1: \e256;
$fa-var-square-2: \e257;
$fa-var-square-3: \e258;
$fa-var-square-4: \e259;
$fa-var-square-5: \e25a;
$fa-var-square-6: \e25b;
$fa-var-square-7: \e25c;
$fa-var-square-8: \e25d;
$fa-var-square-9: \e25e;
$fa-var-square-a: \e25f;
$fa-var-square-a-lock: \e44d;
$fa-var-square-ampersand: \e260;
$fa-var-square-arrow-down: \f339;
$fa-var-arrow-square-down: \f339;
$fa-var-square-arrow-down-left: \e261;
$fa-var-square-arrow-down-right: \e262;
$fa-var-square-arrow-left: \f33a;
$fa-var-arrow-square-left: \f33a;
$fa-var-square-arrow-right: \f33b;
$fa-var-arrow-square-right: \f33b;
$fa-var-square-arrow-up: \f33c;
$fa-var-arrow-square-up: \f33c;
$fa-var-square-arrow-up-left: \e263;
$fa-var-square-arrow-up-right: \f14c;
$fa-var-external-link-square: \f14c;
$fa-var-square-b: \e264;
$fa-var-square-bolt: \e265;
$fa-var-square-c: \e266;
$fa-var-square-caret-down: \f150;
$fa-var-caret-square-down: \f150;
$fa-var-square-caret-left: \f191;
$fa-var-caret-square-left: \f191;
$fa-var-square-caret-right: \f152;
$fa-var-caret-square-right: \f152;
$fa-var-square-caret-up: \f151;
$fa-var-caret-square-up: \f151;
$fa-var-square-check: \f14a;
$fa-var-check-square: \f14a;
$fa-var-square-chevron-down: \f329;
$fa-var-chevron-square-down: \f329;
$fa-var-square-chevron-left: \f32a;
$fa-var-chevron-square-left: \f32a;
$fa-var-square-chevron-right: \f32b;
$fa-var-chevron-square-right: \f32b;
$fa-var-square-chevron-up: \f32c;
$fa-var-chevron-square-up: \f32c;
$fa-var-square-code: \e267;
$fa-var-square-d: \e268;
$fa-var-square-dashed: \e269;
$fa-var-square-dashed-circle-plus: \e5c2;
$fa-var-square-divide: \e26a;
$fa-var-square-dollar: \f2e9;
$fa-var-dollar-square: \f2e9;
$fa-var-usd-square: \f2e9;
$fa-var-square-down: \f350;
$fa-var-arrow-alt-square-down: \f350;
$fa-var-square-down-left: \e26b;
$fa-var-square-down-right: \e26c;
$fa-var-square-e: \e26d;
$fa-var-square-ellipsis: \e26e;
$fa-var-square-ellipsis-vertical: \e26f;
$fa-var-square-envelope: \f199;
$fa-var-envelope-square: \f199;
$fa-var-square-exclamation: \f321;
$fa-var-exclamation-square: \f321;
$fa-var-square-f: \e270;
$fa-var-square-fragile: \f49b;
$fa-var-box-fragile: \f49b;
$fa-var-square-wine-glass-crack: \f49b;
$fa-var-square-full: \f45c;
$fa-var-square-g: \e271;
$fa-var-square-h: \f0fd;
$fa-var-h-square: \f0fd;
$fa-var-square-heart: \f4c8;
$fa-var-heart-square: \f4c8;
$fa-var-square-i: \e272;
$fa-var-square-info: \f30f;
$fa-var-info-square: \f30f;
$fa-var-square-j: \e273;
$fa-var-square-k: \e274;
$fa-var-square-kanban: \e488;
$fa-var-square-l: \e275;
$fa-var-square-left: \f351;
$fa-var-arrow-alt-square-left: \f351;
$fa-var-square-list: \e489;
$fa-var-square-m: \e276;
$fa-var-square-minus: \f146;
$fa-var-minus-square: \f146;
$fa-var-square-n: \e277;
$fa-var-square-nfi: \e576;
$fa-var-square-o: \e278;
$fa-var-square-p: \e279;
$fa-var-square-parking: \f540;
$fa-var-parking: \f540;
$fa-var-square-parking-slash: \f617;
$fa-var-parking-slash: \f617;
$fa-var-square-pen: \f14b;
$fa-var-pen-square: \f14b;
$fa-var-pencil-square: \f14b;
$fa-var-square-person-confined: \e577;
$fa-var-square-phone: \f098;
$fa-var-phone-square: \f098;
$fa-var-square-phone-flip: \f87b;
$fa-var-phone-square-alt: \f87b;
$fa-var-square-phone-hangup: \e27a;
$fa-var-phone-square-down: \e27a;
$fa-var-square-plus: \f0fe;
$fa-var-plus-square: \f0fe;
$fa-var-square-poll-horizontal: \f682;
$fa-var-poll-h: \f682;
$fa-var-square-poll-vertical: \f681;
$fa-var-poll: \f681;
$fa-var-square-q: \e27b;
$fa-var-square-quarters: \e44e;
$fa-var-square-question: \f2fd;
$fa-var-question-square: \f2fd;
$fa-var-square-quote: \e329;
$fa-var-square-r: \e27c;
$fa-var-square-right: \f352;
$fa-var-arrow-alt-square-right: \f352;
$fa-var-square-ring: \e44f;
$fa-var-square-root: \f697;
$fa-var-square-root-variable: \f698;
$fa-var-square-root-alt: \f698;
$fa-var-square-rss: \f143;
$fa-var-rss-square: \f143;
$fa-var-square-s: \e27d;
$fa-var-square-share-nodes: \f1e1;
$fa-var-share-alt-square: \f1e1;
$fa-var-square-sliders: \f3f0;
$fa-var-sliders-h-square: \f3f0;
$fa-var-square-sliders-vertical: \f3f2;
$fa-var-sliders-v-square: \f3f2;
$fa-var-square-small: \e27e;
$fa-var-square-star: \e27f;
$fa-var-square-t: \e280;
$fa-var-square-terminal: \e32a;
$fa-var-square-this-way-up: \f49f;
$fa-var-box-up: \f49f;
$fa-var-square-u: \e281;
$fa-var-square-up: \f353;
$fa-var-arrow-alt-square-up: \f353;
$fa-var-square-up-left: \e282;
$fa-var-square-up-right: \f360;
$fa-var-external-link-square-alt: \f360;
$fa-var-square-user: \e283;
$fa-var-square-v: \e284;
$fa-var-square-virus: \e578;
$fa-var-square-w: \e285;
$fa-var-square-x: \e286;
$fa-var-square-xmark: \f2d3;
$fa-var-times-square: \f2d3;
$fa-var-xmark-square: \f2d3;
$fa-var-square-y: \e287;
$fa-var-square-z: \e288;
$fa-var-squid: \e450;
$fa-var-squirrel: \f71a;
$fa-var-staff: \f71b;
$fa-var-staff-snake: \e579;
$fa-var-rod-asclepius: \e579;
$fa-var-rod-snake: \e579;
$fa-var-staff-aesculapius: \e579;
$fa-var-stairs: \e289;
$fa-var-stamp: \f5bf;
$fa-var-standard-definition: \e28a;
$fa-var-rectangle-sd: \e28a;
$fa-var-stapler: \e5af;
$fa-var-star: \f005;
$fa-var-star-and-crescent: \f699;
$fa-var-star-christmas: \f7d4;
$fa-var-star-exclamation: \f2f3;
$fa-var-star-half: \f089;
$fa-var-star-half-stroke: \f5c0;
$fa-var-star-half-alt: \f5c0;
$fa-var-star-of-david: \f69a;
$fa-var-star-of-life: \f621;
$fa-var-star-sharp: \e28b;
$fa-var-star-sharp-half: \e28c;
$fa-var-star-sharp-half-stroke: \e28d;
$fa-var-star-sharp-half-alt: \e28d;
$fa-var-star-shooting: \e036;
$fa-var-starfighter: \e037;
$fa-var-starfighter-twin-ion-engine: \e038;
$fa-var-starfighter-alt: \e038;
$fa-var-starfighter-twin-ion-engine-advanced: \e28e;
$fa-var-starfighter-alt-advanced: \e28e;
$fa-var-stars: \f762;
$fa-var-starship: \e039;
$fa-var-starship-freighter: \e03a;
$fa-var-steak: \f824;
$fa-var-steering-wheel: \f622;
$fa-var-sterling-sign: \f154;
$fa-var-gbp: \f154;
$fa-var-pound-sign: \f154;
$fa-var-stethoscope: \f0f1;
$fa-var-stocking: \f7d5;
$fa-var-stomach: \f623;
$fa-var-stop: \f04d;
$fa-var-stopwatch: \f2f2;
$fa-var-stopwatch-20: \e06f;
$fa-var-store: \f54e;
$fa-var-store-lock: \e4a6;
$fa-var-store-slash: \e071;
$fa-var-strawberry: \e32b;
$fa-var-street-view: \f21d;
$fa-var-stretcher: \f825;
$fa-var-strikethrough: \f0cc;
$fa-var-stroopwafel: \f551;
$fa-var-subscript: \f12c;
$fa-var-subtitles: \e60f;
$fa-var-subtitles-slash: \e610;
$fa-var-suitcase: \f0f2;
$fa-var-suitcase-medical: \f0fa;
$fa-var-medkit: \f0fa;
$fa-var-suitcase-rolling: \f5c1;
$fa-var-sun: \f185;
$fa-var-sun-bright: \e28f;
$fa-var-sun-alt: \e28f;
$fa-var-sun-cloud: \f763;
$fa-var-sun-dust: \f764;
$fa-var-sun-haze: \f765;
$fa-var-sun-plant-wilt: \e57a;
$fa-var-sunglasses: \f892;
$fa-var-sunrise: \f766;
$fa-var-sunset: \f767;
$fa-var-superscript: \f12b;
$fa-var-sushi: \e48a;
$fa-var-nigiri: \e48a;
$fa-var-sushi-roll: \e48b;
$fa-var-maki-roll: \e48b;
$fa-var-makizushi: \e48b;
$fa-var-swap: \e609;
$fa-var-swap-arrows: \e60a;
$fa-var-swatchbook: \f5c3;
$fa-var-sword: \f71c;
$fa-var-sword-laser: \e03b;
$fa-var-sword-laser-alt: \e03c;
$fa-var-swords: \f71d;
$fa-var-swords-laser: \e03d;
$fa-var-symbols: \f86e;
$fa-var-icons-alt: \f86e;
$fa-var-synagogue: \f69b;
$fa-var-syringe: \f48e;
$fa-var-t: \54;
$fa-var-t-rex: \e629;
$fa-var-table: \f0ce;
$fa-var-table-cells: \f00a;
$fa-var-th: \f00a;
$fa-var-table-cells-column-lock: \e678;
$fa-var-table-cells-large: \f009;
$fa-var-th-large: \f009;
$fa-var-table-cells-lock: \e679;
$fa-var-table-cells-row-lock: \e67a;
$fa-var-table-columns: \f0db;
$fa-var-columns: \f0db;
$fa-var-table-layout: \e290;
$fa-var-table-list: \f00b;
$fa-var-th-list: \f00b;
$fa-var-table-picnic: \e32d;
$fa-var-table-pivot: \e291;
$fa-var-table-rows: \e292;
$fa-var-rows: \e292;
$fa-var-table-tennis-paddle-ball: \f45d;
$fa-var-ping-pong-paddle-ball: \f45d;
$fa-var-table-tennis: \f45d;
$fa-var-table-tree: \e293;
$fa-var-tablet: \f3fb;
$fa-var-tablet-android: \f3fb;
$fa-var-tablet-button: \f10a;
$fa-var-tablet-rugged: \f48f;
$fa-var-tablet-screen: \f3fc;
$fa-var-tablet-android-alt: \f3fc;
$fa-var-tablet-screen-button: \f3fa;
$fa-var-tablet-alt: \f3fa;
$fa-var-tablets: \f490;
$fa-var-tachograph-digital: \f566;
$fa-var-digital-tachograph: \f566;
$fa-var-taco: \f826;
$fa-var-tag: \f02b;
$fa-var-tags: \f02c;
$fa-var-tally: \f69c;
$fa-var-tally-5: \f69c;
$fa-var-tally-1: \e294;
$fa-var-tally-2: \e295;
$fa-var-tally-3: \e296;
$fa-var-tally-4: \e297;
$fa-var-tamale: \e451;
$fa-var-tank-water: \e452;
$fa-var-tape: \f4db;
$fa-var-tarp: \e57b;
$fa-var-tarp-droplet: \e57c;
$fa-var-taxi: \f1ba;
$fa-var-cab: \f1ba;
$fa-var-taxi-bus: \e298;
$fa-var-teddy-bear: \e3cf;
$fa-var-teeth: \f62e;
$fa-var-teeth-open: \f62f;
$fa-var-telescope: \e03e;
$fa-var-temperature-arrow-down: \e03f;
$fa-var-temperature-down: \e03f;
$fa-var-temperature-arrow-up: \e040;
$fa-var-temperature-up: \e040;
$fa-var-temperature-empty: \f2cb;
$fa-var-temperature-0: \f2cb;
$fa-var-thermometer-0: \f2cb;
$fa-var-thermometer-empty: \f2cb;
$fa-var-temperature-full: \f2c7;
$fa-var-temperature-4: \f2c7;
$fa-var-thermometer-4: \f2c7;
$fa-var-thermometer-full: \f2c7;
$fa-var-temperature-half: \f2c9;
$fa-var-temperature-2: \f2c9;
$fa-var-thermometer-2: \f2c9;
$fa-var-thermometer-half: \f2c9;
$fa-var-temperature-high: \f769;
$fa-var-temperature-list: \e299;
$fa-var-temperature-low: \f76b;
$fa-var-temperature-quarter: \f2ca;
$fa-var-temperature-1: \f2ca;
$fa-var-thermometer-1: \f2ca;
$fa-var-thermometer-quarter: \f2ca;
$fa-var-temperature-snow: \f768;
$fa-var-temperature-frigid: \f768;
$fa-var-temperature-sun: \f76a;
$fa-var-temperature-hot: \f76a;
$fa-var-temperature-three-quarters: \f2c8;
$fa-var-temperature-3: \f2c8;
$fa-var-thermometer-3: \f2c8;
$fa-var-thermometer-three-quarters: \f2c8;
$fa-var-tenge-sign: \f7d7;
$fa-var-tenge: \f7d7;
$fa-var-tennis-ball: \f45e;
$fa-var-tent: \e57d;
$fa-var-tent-arrow-down-to-line: \e57e;
$fa-var-tent-arrow-left-right: \e57f;
$fa-var-tent-arrow-turn-left: \e580;
$fa-var-tent-arrows-down: \e581;
$fa-var-tent-double-peak: \e627;
$fa-var-tents: \e582;
$fa-var-terminal: \f120;
$fa-var-text: \f893;
$fa-var-text-height: \f034;
$fa-var-text-size: \f894;
$fa-var-text-slash: \f87d;
$fa-var-remove-format: \f87d;
$fa-var-text-width: \f035;
$fa-var-thermometer: \f491;
$fa-var-theta: \f69e;
$fa-var-thought-bubble: \e32e;
$fa-var-thumbs-down: \f165;
$fa-var-thumbs-up: \f164;
$fa-var-thumbtack: \f08d;
$fa-var-thumb-tack: \f08d;
$fa-var-tick: \e32f;
$fa-var-ticket: \f145;
$fa-var-ticket-airline: \e29a;
$fa-var-ticket-perforated-plane: \e29a;
$fa-var-ticket-plane: \e29a;
$fa-var-ticket-perforated: \e63e;
$fa-var-ticket-simple: \f3ff;
$fa-var-ticket-alt: \f3ff;
$fa-var-tickets: \e658;
$fa-var-tickets-airline: \e29b;
$fa-var-tickets-perforated-plane: \e29b;
$fa-var-tickets-plane: \e29b;
$fa-var-tickets-perforated: \e63f;
$fa-var-tickets-simple: \e659;
$fa-var-tilde: \7e;
$fa-var-timeline: \e29c;
$fa-var-timeline-arrow: \e29d;
$fa-var-timer: \e29e;
$fa-var-tire: \f631;
$fa-var-tire-flat: \f632;
$fa-var-tire-pressure-warning: \f633;
$fa-var-tire-rugged: \f634;
$fa-var-toggle-large-off: \e5b0;
$fa-var-toggle-large-on: \e5b1;
$fa-var-toggle-off: \f204;
$fa-var-toggle-on: \f205;
$fa-var-toilet: \f7d8;
$fa-var-toilet-paper: \f71e;
$fa-var-toilet-paper-blank: \f71f;
$fa-var-toilet-paper-alt: \f71f;
$fa-var-toilet-paper-blank-under: \e29f;
$fa-var-toilet-paper-reverse-alt: \e29f;
$fa-var-toilet-paper-check: \e5b2;
$fa-var-toilet-paper-slash: \e072;
$fa-var-toilet-paper-under: \e2a0;
$fa-var-toilet-paper-reverse: \e2a0;
$fa-var-toilet-paper-under-slash: \e2a1;
$fa-var-toilet-paper-reverse-slash: \e2a1;
$fa-var-toilet-paper-xmark: \e5b3;
$fa-var-toilet-portable: \e583;
$fa-var-toilets-portable: \e584;
$fa-var-tomato: \e330;
$fa-var-tombstone: \f720;
$fa-var-tombstone-blank: \f721;
$fa-var-tombstone-alt: \f721;
$fa-var-toolbox: \f552;
$fa-var-tooth: \f5c9;
$fa-var-toothbrush: \f635;
$fa-var-torii-gate: \f6a1;
$fa-var-tornado: \f76f;
$fa-var-tower-broadcast: \f519;
$fa-var-broadcast-tower: \f519;
$fa-var-tower-cell: \e585;
$fa-var-tower-control: \e2a2;
$fa-var-tower-observation: \e586;
$fa-var-tractor: \f722;
$fa-var-trademark: \f25c;
$fa-var-traffic-cone: \f636;
$fa-var-traffic-light: \f637;
$fa-var-traffic-light-go: \f638;
$fa-var-traffic-light-slow: \f639;
$fa-var-traffic-light-stop: \f63a;
$fa-var-trailer: \e041;
$fa-var-train: \f238;
$fa-var-train-subway: \f239;
$fa-var-subway: \f239;
$fa-var-train-subway-tunnel: \e2a3;
$fa-var-subway-tunnel: \e2a3;
$fa-var-train-track: \e453;
$fa-var-train-tram: \e5b4;
$fa-var-train-tunnel: \e454;
$fa-var-transformer-bolt: \e2a4;
$fa-var-transgender: \f225;
$fa-var-transgender-alt: \f225;
$fa-var-transporter: \e042;
$fa-var-transporter-1: \e043;
$fa-var-transporter-2: \e044;
$fa-var-transporter-3: \e045;
$fa-var-transporter-4: \e2a5;
$fa-var-transporter-5: \e2a6;
$fa-var-transporter-6: \e2a7;
$fa-var-transporter-7: \e2a8;
$fa-var-transporter-empty: \e046;
$fa-var-trash: \f1f8;
$fa-var-trash-arrow-up: \f829;
$fa-var-trash-restore: \f829;
$fa-var-trash-can: \f2ed;
$fa-var-trash-alt: \f2ed;
$fa-var-trash-can-arrow-up: \f82a;
$fa-var-trash-restore-alt: \f82a;
$fa-var-trash-can-check: \e2a9;
$fa-var-trash-can-clock: \e2aa;
$fa-var-trash-can-list: \e2ab;
$fa-var-trash-can-plus: \e2ac;
$fa-var-trash-can-slash: \e2ad;
$fa-var-trash-alt-slash: \e2ad;
$fa-var-trash-can-undo: \f896;
$fa-var-trash-can-arrow-turn-left: \f896;
$fa-var-trash-undo-alt: \f896;
$fa-var-trash-can-xmark: \e2ae;
$fa-var-trash-check: \e2af;
$fa-var-trash-clock: \e2b0;
$fa-var-trash-list: \e2b1;
$fa-var-trash-plus: \e2b2;
$fa-var-trash-slash: \e2b3;
$fa-var-trash-undo: \f895;
$fa-var-trash-arrow-turn-left: \f895;
$fa-var-trash-xmark: \e2b4;
$fa-var-treasure-chest: \f723;
$fa-var-tree: \f1bb;
$fa-var-tree-christmas: \f7db;
$fa-var-tree-city: \e587;
$fa-var-tree-deciduous: \f400;
$fa-var-tree-alt: \f400;
$fa-var-tree-decorated: \f7dc;
$fa-var-tree-large: \f7dd;
$fa-var-tree-palm: \f82b;
$fa-var-trees: \f724;
$fa-var-triangle: \f2ec;
$fa-var-triangle-exclamation: \f071;
$fa-var-exclamation-triangle: \f071;
$fa-var-warning: \f071;
$fa-var-triangle-instrument: \f8e2;
$fa-var-triangle-music: \f8e2;
$fa-var-triangle-person-digging: \f85d;
$fa-var-construction: \f85d;
$fa-var-tricycle: \e5c3;
$fa-var-tricycle-adult: \e5c4;
$fa-var-trillium: \e588;
$fa-var-trophy: \f091;
$fa-var-trophy-star: \f2eb;
$fa-var-trophy-alt: \f2eb;
$fa-var-trowel: \e589;
$fa-var-trowel-bricks: \e58a;
$fa-var-truck: \f0d1;
$fa-var-truck-arrow-right: \e58b;
$fa-var-truck-bolt: \e3d0;
$fa-var-truck-clock: \f48c;
$fa-var-shipping-timed: \f48c;
$fa-var-truck-container: \f4dc;
$fa-var-truck-container-empty: \e2b5;
$fa-var-truck-droplet: \e58c;
$fa-var-truck-fast: \f48b;
$fa-var-shipping-fast: \f48b;
$fa-var-truck-field: \e58d;
$fa-var-truck-field-un: \e58e;
$fa-var-truck-fire: \e65a;
$fa-var-truck-flatbed: \e2b6;
$fa-var-truck-front: \e2b7;
$fa-var-truck-ladder: \e657;
$fa-var-truck-medical: \f0f9;
$fa-var-ambulance: \f0f9;
$fa-var-truck-monster: \f63b;
$fa-var-truck-moving: \f4df;
$fa-var-truck-pickup: \f63c;
$fa-var-truck-plane: \e58f;
$fa-var-truck-plow: \f7de;
$fa-var-truck-ramp: \f4e0;
$fa-var-truck-ramp-box: \f4de;
$fa-var-truck-loading: \f4de;
$fa-var-truck-ramp-couch: \f4dd;
$fa-var-truck-couch: \f4dd;
$fa-var-truck-tow: \e2b8;
$fa-var-truck-utensils: \e628;
$fa-var-trumpet: \f8e3;
$fa-var-tty: \f1e4;
$fa-var-teletype: \f1e4;
$fa-var-tty-answer: \e2b9;
$fa-var-teletype-answer: \e2b9;
$fa-var-tugrik-sign: \e2ba;
$fa-var-turkey: \f725;
$fa-var-turkish-lira-sign: \e2bb;
$fa-var-try: \e2bb;
$fa-var-turkish-lira: \e2bb;
$fa-var-turn-down: \f3be;
$fa-var-level-down-alt: \f3be;
$fa-var-turn-down-left: \e331;
$fa-var-turn-down-right: \e455;
$fa-var-turn-left: \e636;
$fa-var-turn-left-down: \e637;
$fa-var-turn-left-up: \e638;
$fa-var-turn-right: \e639;
$fa-var-turn-up: \f3bf;
$fa-var-level-up-alt: \f3bf;
$fa-var-turntable: \f8e4;
$fa-var-turtle: \f726;
$fa-var-tv: \f26c;
$fa-var-television: \f26c;
$fa-var-tv-alt: \f26c;
$fa-var-tv-music: \f8e6;
$fa-var-tv-retro: \f401;
$fa-var-typewriter: \f8e7;
$fa-var-u: \55;
$fa-var-ufo: \e047;
$fa-var-ufo-beam: \e048;
$fa-var-umbrella: \f0e9;
$fa-var-umbrella-beach: \f5ca;
$fa-var-umbrella-simple: \e2bc;
$fa-var-umbrella-alt: \e2bc;
$fa-var-underline: \f0cd;
$fa-var-unicorn: \f727;
$fa-var-uniform-martial-arts: \e3d1;
$fa-var-union: \f6a2;
$fa-var-universal-access: \f29a;
$fa-var-unlock: \f09c;
$fa-var-unlock-keyhole: \f13e;
$fa-var-unlock-alt: \f13e;
$fa-var-up: \f357;
$fa-var-arrow-alt-up: \f357;
$fa-var-up-down: \f338;
$fa-var-arrows-alt-v: \f338;
$fa-var-up-down-left-right: \f0b2;
$fa-var-arrows-alt: \f0b2;
$fa-var-up-from-bracket: \e590;
$fa-var-up-from-dotted-line: \e456;
$fa-var-up-from-line: \f346;
$fa-var-arrow-alt-from-bottom: \f346;
$fa-var-up-left: \e2bd;
$fa-var-up-long: \f30c;
$fa-var-long-arrow-alt-up: \f30c;
$fa-var-up-right: \e2be;
$fa-var-up-right-and-down-left-from-center: \f424;
$fa-var-expand-alt: \f424;
$fa-var-up-right-from-square: \f35d;
$fa-var-external-link-alt: \f35d;
$fa-var-up-to-bracket: \e66e;
$fa-var-up-to-dotted-line: \e457;
$fa-var-up-to-line: \f34d;
$fa-var-arrow-alt-to-top: \f34d;
$fa-var-upload: \f093;
$fa-var-usb-drive: \f8e9;
$fa-var-user: \f007;
$fa-var-user-alien: \e04a;
$fa-var-user-astronaut: \f4fb;
$fa-var-user-bounty-hunter: \e2bf;
$fa-var-user-check: \f4fc;
$fa-var-user-chef: \e3d2;
$fa-var-user-clock: \f4fd;
$fa-var-user-cowboy: \f8ea;
$fa-var-user-crown: \f6a4;
$fa-var-user-doctor: \f0f0;
$fa-var-user-md: \f0f0;
$fa-var-user-doctor-hair: \e458;
$fa-var-user-doctor-hair-long: \e459;
$fa-var-user-doctor-message: \f82e;
$fa-var-user-md-chat: \f82e;
$fa-var-user-gear: \f4fe;
$fa-var-user-cog: \f4fe;
$fa-var-user-graduate: \f501;
$fa-var-user-group: \f500;
$fa-var-user-friends: \f500;
$fa-var-user-group-crown: \f6a5;
$fa-var-users-crown: \f6a5;
$fa-var-user-group-simple: \e603;
$fa-var-user-hair: \e45a;
$fa-var-user-hair-buns: \e3d3;
$fa-var-user-hair-long: \e45b;
$fa-var-user-hair-mullet: \e45c;
$fa-var-business-front: \e45c;
$fa-var-party-back: \e45c;
$fa-var-trian-balbot: \e45c;
$fa-var-user-headset: \f82d;
$fa-var-user-helmet-safety: \f82c;
$fa-var-user-construction: \f82c;
$fa-var-user-hard-hat: \f82c;
$fa-var-user-injured: \f728;
$fa-var-user-large: \f406;
$fa-var-user-alt: \f406;
$fa-var-user-large-slash: \f4fa;
$fa-var-user-alt-slash: \f4fa;
$fa-var-user-lock: \f502;
$fa-var-user-magnifying-glass: \e5c5;
$fa-var-user-minus: \f503;
$fa-var-user-music: \f8eb;
$fa-var-user-ninja: \f504;
$fa-var-user-nurse: \f82f;
$fa-var-user-nurse-hair: \e45d;
$fa-var-user-nurse-hair-long: \e45e;
$fa-var-user-pen: \f4ff;
$fa-var-user-edit: \f4ff;
$fa-var-user-pilot: \e2c0;
$fa-var-user-pilot-tie: \e2c1;
$fa-var-user-plus: \f234;
$fa-var-user-police: \e333;
$fa-var-user-police-tie: \e334;
$fa-var-user-robot: \e04b;
$fa-var-user-robot-xmarks: \e4a7;
$fa-var-user-secret: \f21b;
$fa-var-user-shakespeare: \e2c2;
$fa-var-user-shield: \f505;
$fa-var-user-slash: \f506;
$fa-var-user-tag: \f507;
$fa-var-user-tie: \f508;
$fa-var-user-tie-hair: \e45f;
$fa-var-user-tie-hair-long: \e460;
$fa-var-user-unlock: \e058;
$fa-var-user-visor: \e04c;
$fa-var-user-vneck: \e461;
$fa-var-user-vneck-hair: \e462;
$fa-var-user-vneck-hair-long: \e463;
$fa-var-user-xmark: \f235;
$fa-var-user-times: \f235;
$fa-var-users: \f0c0;
$fa-var-users-between-lines: \e591;
$fa-var-users-gear: \f509;
$fa-var-users-cog: \f509;
$fa-var-users-line: \e592;
$fa-var-users-medical: \f830;
$fa-var-users-rays: \e593;
$fa-var-users-rectangle: \e594;
$fa-var-users-slash: \e073;
$fa-var-users-viewfinder: \e595;
$fa-var-utensils: \f2e7;
$fa-var-cutlery: \f2e7;
$fa-var-utensils-slash: \e464;
$fa-var-utility-pole: \e2c3;
$fa-var-utility-pole-double: \e2c4;
$fa-var-v: \56;
$fa-var-vacuum: \e04d;
$fa-var-vacuum-robot: \e04e;
$fa-var-value-absolute: \f6a6;
$fa-var-van-shuttle: \f5b6;
$fa-var-shuttle-van: \f5b6;
$fa-var-vault: \e2c5;
$fa-var-vector-circle: \e2c6;
$fa-var-vector-polygon: \e2c7;
$fa-var-vector-square: \f5cb;
$fa-var-vent-damper: \e465;
$fa-var-venus: \f221;
$fa-var-venus-double: \f226;
$fa-var-venus-mars: \f228;
$fa-var-vest: \e085;
$fa-var-vest-patches: \e086;
$fa-var-vial: \f492;
$fa-var-vial-circle-check: \e596;
$fa-var-vial-virus: \e597;
$fa-var-vials: \f493;
$fa-var-video: \f03d;
$fa-var-video-camera: \f03d;
$fa-var-video-arrow-down-left: \e2c8;
$fa-var-video-arrow-up-right: \e2c9;
$fa-var-video-plus: \f4e1;
$fa-var-video-slash: \f4e2;
$fa-var-vihara: \f6a7;
$fa-var-violin: \f8ed;
$fa-var-virus: \e074;
$fa-var-virus-covid: \e4a8;
$fa-var-virus-covid-slash: \e4a9;
$fa-var-virus-slash: \e075;
$fa-var-viruses: \e076;
$fa-var-voicemail: \f897;
$fa-var-volcano: \f770;
$fa-var-volleyball: \f45f;
$fa-var-volleyball-ball: \f45f;
$fa-var-volume: \f6a8;
$fa-var-volume-medium: \f6a8;
$fa-var-volume-high: \f028;
$fa-var-volume-up: \f028;
$fa-var-volume-low: \f027;
$fa-var-volume-down: \f027;
$fa-var-volume-off: \f026;
$fa-var-volume-slash: \f2e2;
$fa-var-volume-xmark: \f6a9;
$fa-var-volume-mute: \f6a9;
$fa-var-volume-times: \f6a9;
$fa-var-vr-cardboard: \f729;
$fa-var-w: \57;
$fa-var-waffle: \e466;
$fa-var-wagon-covered: \f8ee;
$fa-var-walker: \f831;
$fa-var-walkie-talkie: \f8ef;
$fa-var-wallet: \f555;
$fa-var-wand: \f72a;
$fa-var-wand-magic: \f0d0;
$fa-var-magic: \f0d0;
$fa-var-wand-magic-sparkles: \e2ca;
$fa-var-magic-wand-sparkles: \e2ca;
$fa-var-wand-sparkles: \f72b;
$fa-var-warehouse: \f494;
$fa-var-warehouse-full: \f495;
$fa-var-warehouse-alt: \f495;
$fa-var-washing-machine: \f898;
$fa-var-washer: \f898;
$fa-var-watch: \f2e1;
$fa-var-watch-apple: \e2cb;
$fa-var-watch-calculator: \f8f0;
$fa-var-watch-fitness: \f63e;
$fa-var-watch-smart: \e2cc;
$fa-var-water: \f773;
$fa-var-water-arrow-down: \f774;
$fa-var-water-lower: \f774;
$fa-var-water-arrow-up: \f775;
$fa-var-water-rise: \f775;
$fa-var-water-ladder: \f5c5;
$fa-var-ladder-water: \f5c5;
$fa-var-swimming-pool: \f5c5;
$fa-var-watermelon-slice: \e337;
$fa-var-wave: \e65b;
$fa-var-wave-pulse: \f5f8;
$fa-var-heart-rate: \f5f8;
$fa-var-wave-sine: \f899;
$fa-var-wave-square: \f83e;
$fa-var-wave-triangle: \f89a;
$fa-var-waveform: \f8f1;
$fa-var-waveform-lines: \f8f2;
$fa-var-waveform-path: \f8f2;
$fa-var-waves-sine: \e65d;
$fa-var-webhook: \e5d5;
$fa-var-weight-hanging: \f5cd;
$fa-var-weight-scale: \f496;
$fa-var-weight: \f496;
$fa-var-whale: \f72c;
$fa-var-wheat: \f72d;
$fa-var-wheat-awn: \e2cd;
$fa-var-wheat-alt: \e2cd;
$fa-var-wheat-awn-circle-exclamation: \e598;
$fa-var-wheat-awn-slash: \e338;
$fa-var-wheat-slash: \e339;
$fa-var-wheelchair: \f193;
$fa-var-wheelchair-move: \e2ce;
$fa-var-wheelchair-alt: \e2ce;
$fa-var-whiskey-glass: \f7a0;
$fa-var-glass-whiskey: \f7a0;
$fa-var-whiskey-glass-ice: \f7a1;
$fa-var-glass-whiskey-rocks: \f7a1;
$fa-var-whistle: \f460;
$fa-var-wifi: \f1eb;
$fa-var-wifi-3: \f1eb;
$fa-var-wifi-strong: \f1eb;
$fa-var-wifi-exclamation: \e2cf;
$fa-var-wifi-fair: \f6ab;
$fa-var-wifi-2: \f6ab;
$fa-var-wifi-slash: \f6ac;
$fa-var-wifi-weak: \f6aa;
$fa-var-wifi-1: \f6aa;
$fa-var-wind: \f72e;
$fa-var-wind-turbine: \f89b;
$fa-var-wind-warning: \f776;
$fa-var-wind-circle-exclamation: \f776;
$fa-var-window: \f40e;
$fa-var-window-flip: \f40f;
$fa-var-window-alt: \f40f;
$fa-var-window-frame: \e04f;
$fa-var-window-frame-open: \e050;
$fa-var-window-maximize: \f2d0;
$fa-var-window-minimize: \f2d1;
$fa-var-window-restore: \f2d2;
$fa-var-windsock: \f777;
$fa-var-wine-bottle: \f72f;
$fa-var-wine-glass: \f4e3;
$fa-var-wine-glass-crack: \f4bb;
$fa-var-fragile: \f4bb;
$fa-var-wine-glass-empty: \f5ce;
$fa-var-wine-glass-alt: \f5ce;
$fa-var-won-sign: \f159;
$fa-var-krw: \f159;
$fa-var-won: \f159;
$fa-var-worm: \e599;
$fa-var-wreath: \f7e2;
$fa-var-wreath-laurel: \e5d2;
$fa-var-wrench: \f0ad;
$fa-var-wrench-simple: \e2d1;
$fa-var-x: \58;
$fa-var-x-ray: \f497;
$fa-var-xmark: \f00d;
$fa-var-close: \f00d;
$fa-var-multiply: \f00d;
$fa-var-remove: \f00d;
$fa-var-times: \f00d;
$fa-var-xmark-large: \e59b;
$fa-var-xmark-to-slot: \f771;
$fa-var-times-to-slot: \f771;
$fa-var-vote-nay: \f771;
$fa-var-xmarks-lines: \e59a;
$fa-var-y: \59;
$fa-var-yen-sign: \f157;
$fa-var-cny: \f157;
$fa-var-jpy: \f157;
$fa-var-rmb: \f157;
$fa-var-yen: \f157;
$fa-var-yin-yang: \f6ad;
$fa-var-z: \5a;


$fa-icons: (
  "0": $fa-var-0,
  "00": $fa-var-00,
  "1": $fa-var-1,
  "2": $fa-var-2,
  "3": $fa-var-3,
  "360-degrees": $fa-var-360-degrees,
  "4": $fa-var-4,
  "5": $fa-var-5,
  "6": $fa-var-6,
  "7": $fa-var-7,
  "8": $fa-var-8,
  "9": $fa-var-9,
  "a": $fa-var-a,
  "abacus": $fa-var-abacus,
  "accent-grave": $fa-var-accent-grave,
  "acorn": $fa-var-acorn,
  "address-book": $fa-var-address-book,
  "contact-book": $fa-var-contact-book,
  "address-card": $fa-var-address-card,
  "contact-card": $fa-var-contact-card,
  "vcard": $fa-var-vcard,
  "air-conditioner": $fa-var-air-conditioner,
  "airplay": $fa-var-airplay,
  "alarm-clock": $fa-var-alarm-clock,
  "alarm-exclamation": $fa-var-alarm-exclamation,
  "alarm-plus": $fa-var-alarm-plus,
  "alarm-snooze": $fa-var-alarm-snooze,
  "album": $fa-var-album,
  "album-circle-plus": $fa-var-album-circle-plus,
  "album-circle-user": $fa-var-album-circle-user,
  "album-collection": $fa-var-album-collection,
  "album-collection-circle-plus": $fa-var-album-collection-circle-plus,
  "album-collection-circle-user": $fa-var-album-collection-circle-user,
  "alicorn": $fa-var-alicorn,
  "alien": $fa-var-alien,
  "alien-8bit": $fa-var-alien-8bit,
  "alien-monster": $fa-var-alien-monster,
  "align-center": $fa-var-align-center,
  "align-justify": $fa-var-align-justify,
  "align-left": $fa-var-align-left,
  "align-right": $fa-var-align-right,
  "align-slash": $fa-var-align-slash,
  "alt": $fa-var-alt,
  "amp-guitar": $fa-var-amp-guitar,
  "ampersand": $fa-var-ampersand,
  "anchor": $fa-var-anchor,
  "anchor-circle-check": $fa-var-anchor-circle-check,
  "anchor-circle-exclamation": $fa-var-anchor-circle-exclamation,
  "anchor-circle-xmark": $fa-var-anchor-circle-xmark,
  "anchor-lock": $fa-var-anchor-lock,
  "angel": $fa-var-angel,
  "angle": $fa-var-angle,
  "angle-90": $fa-var-angle-90,
  "angle-down": $fa-var-angle-down,
  "angle-left": $fa-var-angle-left,
  "angle-right": $fa-var-angle-right,
  "angle-up": $fa-var-angle-up,
  "angles-down": $fa-var-angles-down,
  "angle-double-down": $fa-var-angle-double-down,
  "angles-left": $fa-var-angles-left,
  "angle-double-left": $fa-var-angle-double-left,
  "angles-right": $fa-var-angles-right,
  "angle-double-right": $fa-var-angle-double-right,
  "angles-up": $fa-var-angles-up,
  "angle-double-up": $fa-var-angle-double-up,
  "angles-up-down": $fa-var-angles-up-down,
  "ankh": $fa-var-ankh,
  "ant": $fa-var-ant,
  "apartment": $fa-var-apartment,
  "aperture": $fa-var-aperture,
  "apostrophe": $fa-var-apostrophe,
  "apple-core": $fa-var-apple-core,
  "apple-whole": $fa-var-apple-whole,
  "apple-alt": $fa-var-apple-alt,
  "archway": $fa-var-archway,
  "arrow-down": $fa-var-arrow-down,
  "arrow-down-1-9": $fa-var-arrow-down-1-9,
  "sort-numeric-asc": $fa-var-sort-numeric-asc,
  "sort-numeric-down": $fa-var-sort-numeric-down,
  "arrow-down-9-1": $fa-var-arrow-down-9-1,
  "sort-numeric-desc": $fa-var-sort-numeric-desc,
  "sort-numeric-down-alt": $fa-var-sort-numeric-down-alt,
  "arrow-down-a-z": $fa-var-arrow-down-a-z,
  "sort-alpha-asc": $fa-var-sort-alpha-asc,
  "sort-alpha-down": $fa-var-sort-alpha-down,
  "arrow-down-arrow-up": $fa-var-arrow-down-arrow-up,
  "sort-alt": $fa-var-sort-alt,
  "arrow-down-big-small": $fa-var-arrow-down-big-small,
  "sort-size-down": $fa-var-sort-size-down,
  "arrow-down-from-arc": $fa-var-arrow-down-from-arc,
  "arrow-down-from-bracket": $fa-var-arrow-down-from-bracket,
  "arrow-down-from-dotted-line": $fa-var-arrow-down-from-dotted-line,
  "arrow-down-from-line": $fa-var-arrow-down-from-line,
  "arrow-from-top": $fa-var-arrow-from-top,
  "arrow-down-left": $fa-var-arrow-down-left,
  "arrow-down-left-and-arrow-up-right-to-center": $fa-var-arrow-down-left-and-arrow-up-right-to-center,
  "arrow-down-long": $fa-var-arrow-down-long,
  "long-arrow-down": $fa-var-long-arrow-down,
  "arrow-down-right": $fa-var-arrow-down-right,
  "arrow-down-short-wide": $fa-var-arrow-down-short-wide,
  "sort-amount-desc": $fa-var-sort-amount-desc,
  "sort-amount-down-alt": $fa-var-sort-amount-down-alt,
  "arrow-down-small-big": $fa-var-arrow-down-small-big,
  "sort-size-down-alt": $fa-var-sort-size-down-alt,
  "arrow-down-square-triangle": $fa-var-arrow-down-square-triangle,
  "sort-shapes-down-alt": $fa-var-sort-shapes-down-alt,
  "arrow-down-to-arc": $fa-var-arrow-down-to-arc,
  "arrow-down-to-bracket": $fa-var-arrow-down-to-bracket,
  "arrow-down-to-dotted-line": $fa-var-arrow-down-to-dotted-line,
  "arrow-down-to-line": $fa-var-arrow-down-to-line,
  "arrow-to-bottom": $fa-var-arrow-to-bottom,
  "arrow-down-to-square": $fa-var-arrow-down-to-square,
  "arrow-down-triangle-square": $fa-var-arrow-down-triangle-square,
  "sort-shapes-down": $fa-var-sort-shapes-down,
  "arrow-down-up-across-line": $fa-var-arrow-down-up-across-line,
  "arrow-down-up-lock": $fa-var-arrow-down-up-lock,
  "arrow-down-wide-short": $fa-var-arrow-down-wide-short,
  "sort-amount-asc": $fa-var-sort-amount-asc,
  "sort-amount-down": $fa-var-sort-amount-down,
  "arrow-down-z-a": $fa-var-arrow-down-z-a,
  "sort-alpha-desc": $fa-var-sort-alpha-desc,
  "sort-alpha-down-alt": $fa-var-sort-alpha-down-alt,
  "arrow-left": $fa-var-arrow-left,
  "arrow-left-from-arc": $fa-var-arrow-left-from-arc,
  "arrow-left-from-bracket": $fa-var-arrow-left-from-bracket,
  "arrow-left-from-line": $fa-var-arrow-left-from-line,
  "arrow-from-right": $fa-var-arrow-from-right,
  "arrow-left-long": $fa-var-arrow-left-long,
  "long-arrow-left": $fa-var-long-arrow-left,
  "arrow-left-long-to-line": $fa-var-arrow-left-long-to-line,
  "arrow-left-to-arc": $fa-var-arrow-left-to-arc,
  "arrow-left-to-bracket": $fa-var-arrow-left-to-bracket,
  "arrow-left-to-line": $fa-var-arrow-left-to-line,
  "arrow-to-left": $fa-var-arrow-to-left,
  "arrow-pointer": $fa-var-arrow-pointer,
  "mouse-pointer": $fa-var-mouse-pointer,
  "arrow-progress": $fa-var-arrow-progress,
  "arrow-right": $fa-var-arrow-right,
  "arrow-right-arrow-left": $fa-var-arrow-right-arrow-left,
  "exchange": $fa-var-exchange,
  "arrow-right-from-arc": $fa-var-arrow-right-from-arc,
  "arrow-right-from-bracket": $fa-var-arrow-right-from-bracket,
  "sign-out": $fa-var-sign-out,
  "arrow-right-from-line": $fa-var-arrow-right-from-line,
  "arrow-from-left": $fa-var-arrow-from-left,
  "arrow-right-long": $fa-var-arrow-right-long,
  "long-arrow-right": $fa-var-long-arrow-right,
  "arrow-right-long-to-line": $fa-var-arrow-right-long-to-line,
  "arrow-right-to-arc": $fa-var-arrow-right-to-arc,
  "arrow-right-to-bracket": $fa-var-arrow-right-to-bracket,
  "sign-in": $fa-var-sign-in,
  "arrow-right-to-city": $fa-var-arrow-right-to-city,
  "arrow-right-to-line": $fa-var-arrow-right-to-line,
  "arrow-to-right": $fa-var-arrow-to-right,
  "arrow-rotate-left": $fa-var-arrow-rotate-left,
  "arrow-left-rotate": $fa-var-arrow-left-rotate,
  "arrow-rotate-back": $fa-var-arrow-rotate-back,
  "arrow-rotate-backward": $fa-var-arrow-rotate-backward,
  "undo": $fa-var-undo,
  "arrow-rotate-right": $fa-var-arrow-rotate-right,
  "arrow-right-rotate": $fa-var-arrow-right-rotate,
  "arrow-rotate-forward": $fa-var-arrow-rotate-forward,
  "redo": $fa-var-redo,
  "arrow-trend-down": $fa-var-arrow-trend-down,
  "arrow-trend-up": $fa-var-arrow-trend-up,
  "arrow-turn-down": $fa-var-arrow-turn-down,
  "level-down": $fa-var-level-down,
  "arrow-turn-down-left": $fa-var-arrow-turn-down-left,
  "arrow-turn-down-right": $fa-var-arrow-turn-down-right,
  "arrow-turn-left": $fa-var-arrow-turn-left,
  "arrow-turn-left-down": $fa-var-arrow-turn-left-down,
  "arrow-turn-left-up": $fa-var-arrow-turn-left-up,
  "arrow-turn-right": $fa-var-arrow-turn-right,
  "arrow-turn-up": $fa-var-arrow-turn-up,
  "level-up": $fa-var-level-up,
  "arrow-up": $fa-var-arrow-up,
  "arrow-up-1-9": $fa-var-arrow-up-1-9,
  "sort-numeric-up": $fa-var-sort-numeric-up,
  "arrow-up-9-1": $fa-var-arrow-up-9-1,
  "sort-numeric-up-alt": $fa-var-sort-numeric-up-alt,
  "arrow-up-a-z": $fa-var-arrow-up-a-z,
  "sort-alpha-up": $fa-var-sort-alpha-up,
  "arrow-up-arrow-down": $fa-var-arrow-up-arrow-down,
  "sort-up-down": $fa-var-sort-up-down,
  "arrow-up-big-small": $fa-var-arrow-up-big-small,
  "sort-size-up": $fa-var-sort-size-up,
  "arrow-up-from-arc": $fa-var-arrow-up-from-arc,
  "arrow-up-from-bracket": $fa-var-arrow-up-from-bracket,
  "arrow-up-from-dotted-line": $fa-var-arrow-up-from-dotted-line,
  "arrow-up-from-ground-water": $fa-var-arrow-up-from-ground-water,
  "arrow-up-from-line": $fa-var-arrow-up-from-line,
  "arrow-from-bottom": $fa-var-arrow-from-bottom,
  "arrow-up-from-square": $fa-var-arrow-up-from-square,
  "arrow-up-from-water-pump": $fa-var-arrow-up-from-water-pump,
  "arrow-up-left": $fa-var-arrow-up-left,
  "arrow-up-left-from-circle": $fa-var-arrow-up-left-from-circle,
  "arrow-up-long": $fa-var-arrow-up-long,
  "long-arrow-up": $fa-var-long-arrow-up,
  "arrow-up-right": $fa-var-arrow-up-right,
  "arrow-up-right-and-arrow-down-left-from-center": $fa-var-arrow-up-right-and-arrow-down-left-from-center,
  "arrow-up-right-dots": $fa-var-arrow-up-right-dots,
  "arrow-up-right-from-square": $fa-var-arrow-up-right-from-square,
  "external-link": $fa-var-external-link,
  "arrow-up-short-wide": $fa-var-arrow-up-short-wide,
  "sort-amount-up-alt": $fa-var-sort-amount-up-alt,
  "arrow-up-small-big": $fa-var-arrow-up-small-big,
  "sort-size-up-alt": $fa-var-sort-size-up-alt,
  "arrow-up-square-triangle": $fa-var-arrow-up-square-triangle,
  "sort-shapes-up-alt": $fa-var-sort-shapes-up-alt,
  "arrow-up-to-arc": $fa-var-arrow-up-to-arc,
  "arrow-up-to-bracket": $fa-var-arrow-up-to-bracket,
  "arrow-up-to-dotted-line": $fa-var-arrow-up-to-dotted-line,
  "arrow-up-to-line": $fa-var-arrow-up-to-line,
  "arrow-to-top": $fa-var-arrow-to-top,
  "arrow-up-triangle-square": $fa-var-arrow-up-triangle-square,
  "sort-shapes-up": $fa-var-sort-shapes-up,
  "arrow-up-wide-short": $fa-var-arrow-up-wide-short,
  "sort-amount-up": $fa-var-sort-amount-up,
  "arrow-up-z-a": $fa-var-arrow-up-z-a,
  "sort-alpha-up-alt": $fa-var-sort-alpha-up-alt,
  "arrows-cross": $fa-var-arrows-cross,
  "arrows-down-to-line": $fa-var-arrows-down-to-line,
  "arrows-down-to-people": $fa-var-arrows-down-to-people,
  "arrows-from-dotted-line": $fa-var-arrows-from-dotted-line,
  "arrows-from-line": $fa-var-arrows-from-line,
  "arrows-left-right": $fa-var-arrows-left-right,
  "arrows-h": $fa-var-arrows-h,
  "arrows-left-right-to-line": $fa-var-arrows-left-right-to-line,
  "arrows-maximize": $fa-var-arrows-maximize,
  "expand-arrows": $fa-var-expand-arrows,
  "arrows-minimize": $fa-var-arrows-minimize,
  "compress-arrows": $fa-var-compress-arrows,
  "arrows-repeat": $fa-var-arrows-repeat,
  "repeat-alt": $fa-var-repeat-alt,
  "arrows-repeat-1": $fa-var-arrows-repeat-1,
  "repeat-1-alt": $fa-var-repeat-1-alt,
  "arrows-retweet": $fa-var-arrows-retweet,
  "retweet-alt": $fa-var-retweet-alt,
  "arrows-rotate": $fa-var-arrows-rotate,
  "refresh": $fa-var-refresh,
  "sync": $fa-var-sync,
  "arrows-rotate-reverse": $fa-var-arrows-rotate-reverse,
  "arrows-spin": $fa-var-arrows-spin,
  "arrows-split-up-and-left": $fa-var-arrows-split-up-and-left,
  "arrows-to-circle": $fa-var-arrows-to-circle,
  "arrows-to-dot": $fa-var-arrows-to-dot,
  "arrows-to-dotted-line": $fa-var-arrows-to-dotted-line,
  "arrows-to-eye": $fa-var-arrows-to-eye,
  "arrows-to-line": $fa-var-arrows-to-line,
  "arrows-turn-right": $fa-var-arrows-turn-right,
  "arrows-turn-to-dots": $fa-var-arrows-turn-to-dots,
  "arrows-up-down": $fa-var-arrows-up-down,
  "arrows-v": $fa-var-arrows-v,
  "arrows-up-down-left-right": $fa-var-arrows-up-down-left-right,
  "arrows": $fa-var-arrows,
  "arrows-up-to-line": $fa-var-arrows-up-to-line,
  "asterisk": $fa-var-asterisk,
  "at": $fa-var-at,
  "atom": $fa-var-atom,
  "atom-simple": $fa-var-atom-simple,
  "atom-alt": $fa-var-atom-alt,
  "audio-description": $fa-var-audio-description,
  "audio-description-slash": $fa-var-audio-description-slash,
  "austral-sign": $fa-var-austral-sign,
  "avocado": $fa-var-avocado,
  "award": $fa-var-award,
  "award-simple": $fa-var-award-simple,
  "axe": $fa-var-axe,
  "axe-battle": $fa-var-axe-battle,
  "b": $fa-var-b,
  "baby": $fa-var-baby,
  "baby-carriage": $fa-var-baby-carriage,
  "carriage-baby": $fa-var-carriage-baby,
  "backpack": $fa-var-backpack,
  "backward": $fa-var-backward,
  "backward-fast": $fa-var-backward-fast,
  "fast-backward": $fa-var-fast-backward,
  "backward-step": $fa-var-backward-step,
  "step-backward": $fa-var-step-backward,
  "bacon": $fa-var-bacon,
  "bacteria": $fa-var-bacteria,
  "bacterium": $fa-var-bacterium,
  "badge": $fa-var-badge,
  "badge-check": $fa-var-badge-check,
  "badge-dollar": $fa-var-badge-dollar,
  "badge-percent": $fa-var-badge-percent,
  "badge-sheriff": $fa-var-badge-sheriff,
  "badger-honey": $fa-var-badger-honey,
  "badminton": $fa-var-badminton,
  "bag-seedling": $fa-var-bag-seedling,
  "bag-shopping": $fa-var-bag-shopping,
  "shopping-bag": $fa-var-shopping-bag,
  "bag-shopping-minus": $fa-var-bag-shopping-minus,
  "bag-shopping-plus": $fa-var-bag-shopping-plus,
  "bagel": $fa-var-bagel,
  "bags-shopping": $fa-var-bags-shopping,
  "baguette": $fa-var-baguette,
  "bahai": $fa-var-bahai,
  "haykal": $fa-var-haykal,
  "baht-sign": $fa-var-baht-sign,
  "ball-pile": $fa-var-ball-pile,
  "balloon": $fa-var-balloon,
  "balloons": $fa-var-balloons,
  "ballot": $fa-var-ballot,
  "ballot-check": $fa-var-ballot-check,
  "ban": $fa-var-ban,
  "cancel": $fa-var-cancel,
  "ban-bug": $fa-var-ban-bug,
  "debug": $fa-var-debug,
  "ban-parking": $fa-var-ban-parking,
  "parking-circle-slash": $fa-var-parking-circle-slash,
  "ban-smoking": $fa-var-ban-smoking,
  "smoking-ban": $fa-var-smoking-ban,
  "banana": $fa-var-banana,
  "bandage": $fa-var-bandage,
  "band-aid": $fa-var-band-aid,
  "bangladeshi-taka-sign": $fa-var-bangladeshi-taka-sign,
  "banjo": $fa-var-banjo,
  "barcode": $fa-var-barcode,
  "barcode-read": $fa-var-barcode-read,
  "barcode-scan": $fa-var-barcode-scan,
  "bars": $fa-var-bars,
  "navicon": $fa-var-navicon,
  "bars-filter": $fa-var-bars-filter,
  "bars-progress": $fa-var-bars-progress,
  "tasks-alt": $fa-var-tasks-alt,
  "bars-sort": $fa-var-bars-sort,
  "bars-staggered": $fa-var-bars-staggered,
  "reorder": $fa-var-reorder,
  "stream": $fa-var-stream,
  "baseball": $fa-var-baseball,
  "baseball-ball": $fa-var-baseball-ball,
  "baseball-bat-ball": $fa-var-baseball-bat-ball,
  "basket-shopping": $fa-var-basket-shopping,
  "shopping-basket": $fa-var-shopping-basket,
  "basket-shopping-minus": $fa-var-basket-shopping-minus,
  "basket-shopping-plus": $fa-var-basket-shopping-plus,
  "basket-shopping-simple": $fa-var-basket-shopping-simple,
  "shopping-basket-alt": $fa-var-shopping-basket-alt,
  "basketball": $fa-var-basketball,
  "basketball-ball": $fa-var-basketball-ball,
  "basketball-hoop": $fa-var-basketball-hoop,
  "bat": $fa-var-bat,
  "bath": $fa-var-bath,
  "bathtub": $fa-var-bathtub,
  "battery-bolt": $fa-var-battery-bolt,
  "battery-empty": $fa-var-battery-empty,
  "battery-0": $fa-var-battery-0,
  "battery-exclamation": $fa-var-battery-exclamation,
  "battery-full": $fa-var-battery-full,
  "battery": $fa-var-battery,
  "battery-5": $fa-var-battery-5,
  "battery-half": $fa-var-battery-half,
  "battery-3": $fa-var-battery-3,
  "battery-low": $fa-var-battery-low,
  "battery-1": $fa-var-battery-1,
  "battery-quarter": $fa-var-battery-quarter,
  "battery-2": $fa-var-battery-2,
  "battery-slash": $fa-var-battery-slash,
  "battery-three-quarters": $fa-var-battery-three-quarters,
  "battery-4": $fa-var-battery-4,
  "bed": $fa-var-bed,
  "bed-bunk": $fa-var-bed-bunk,
  "bed-empty": $fa-var-bed-empty,
  "bed-front": $fa-var-bed-front,
  "bed-alt": $fa-var-bed-alt,
  "bed-pulse": $fa-var-bed-pulse,
  "procedures": $fa-var-procedures,
  "bee": $fa-var-bee,
  "beer-mug": $fa-var-beer-mug,
  "beer-foam": $fa-var-beer-foam,
  "beer-mug-empty": $fa-var-beer-mug-empty,
  "beer": $fa-var-beer,
  "bell": $fa-var-bell,
  "bell-concierge": $fa-var-bell-concierge,
  "concierge-bell": $fa-var-concierge-bell,
  "bell-exclamation": $fa-var-bell-exclamation,
  "bell-on": $fa-var-bell-on,
  "bell-plus": $fa-var-bell-plus,
  "bell-ring": $fa-var-bell-ring,
  "bell-school": $fa-var-bell-school,
  "bell-school-slash": $fa-var-bell-school-slash,
  "bell-slash": $fa-var-bell-slash,
  "bells": $fa-var-bells,
  "bench-tree": $fa-var-bench-tree,
  "bezier-curve": $fa-var-bezier-curve,
  "bicycle": $fa-var-bicycle,
  "billboard": $fa-var-billboard,
  "bin-bottles": $fa-var-bin-bottles,
  "bin-bottles-recycle": $fa-var-bin-bottles-recycle,
  "bin-recycle": $fa-var-bin-recycle,
  "binary": $fa-var-binary,
  "binary-circle-check": $fa-var-binary-circle-check,
  "binary-lock": $fa-var-binary-lock,
  "binary-slash": $fa-var-binary-slash,
  "binoculars": $fa-var-binoculars,
  "biohazard": $fa-var-biohazard,
  "bird": $fa-var-bird,
  "bitcoin-sign": $fa-var-bitcoin-sign,
  "blanket": $fa-var-blanket,
  "blanket-fire": $fa-var-blanket-fire,
  "blender": $fa-var-blender,
  "blender-phone": $fa-var-blender-phone,
  "blinds": $fa-var-blinds,
  "blinds-open": $fa-var-blinds-open,
  "blinds-raised": $fa-var-blinds-raised,
  "block": $fa-var-block,
  "block-brick": $fa-var-block-brick,
  "wall-brick": $fa-var-wall-brick,
  "block-brick-fire": $fa-var-block-brick-fire,
  "firewall": $fa-var-firewall,
  "block-question": $fa-var-block-question,
  "block-quote": $fa-var-block-quote,
  "blog": $fa-var-blog,
  "blueberries": $fa-var-blueberries,
  "bold": $fa-var-bold,
  "bolt": $fa-var-bolt,
  "zap": $fa-var-zap,
  "bolt-auto": $fa-var-bolt-auto,
  "bolt-lightning": $fa-var-bolt-lightning,
  "bolt-slash": $fa-var-bolt-slash,
  "bomb": $fa-var-bomb,
  "bone": $fa-var-bone,
  "bone-break": $fa-var-bone-break,
  "bong": $fa-var-bong,
  "book": $fa-var-book,
  "book-arrow-right": $fa-var-book-arrow-right,
  "book-arrow-up": $fa-var-book-arrow-up,
  "book-atlas": $fa-var-book-atlas,
  "atlas": $fa-var-atlas,
  "book-bible": $fa-var-book-bible,
  "bible": $fa-var-bible,
  "book-blank": $fa-var-book-blank,
  "book-alt": $fa-var-book-alt,
  "book-bookmark": $fa-var-book-bookmark,
  "book-circle-arrow-right": $fa-var-book-circle-arrow-right,
  "book-circle-arrow-up": $fa-var-book-circle-arrow-up,
  "book-copy": $fa-var-book-copy,
  "book-font": $fa-var-book-font,
  "book-heart": $fa-var-book-heart,
  "book-journal-whills": $fa-var-book-journal-whills,
  "journal-whills": $fa-var-journal-whills,
  "book-medical": $fa-var-book-medical,
  "book-open": $fa-var-book-open,
  "book-open-cover": $fa-var-book-open-cover,
  "book-open-alt": $fa-var-book-open-alt,
  "book-open-reader": $fa-var-book-open-reader,
  "book-reader": $fa-var-book-reader,
  "book-quran": $fa-var-book-quran,
  "quran": $fa-var-quran,
  "book-section": $fa-var-book-section,
  "book-law": $fa-var-book-law,
  "book-skull": $fa-var-book-skull,
  "book-dead": $fa-var-book-dead,
  "book-sparkles": $fa-var-book-sparkles,
  "book-spells": $fa-var-book-spells,
  "book-tanakh": $fa-var-book-tanakh,
  "tanakh": $fa-var-tanakh,
  "book-user": $fa-var-book-user,
  "bookmark": $fa-var-bookmark,
  "bookmark-slash": $fa-var-bookmark-slash,
  "books": $fa-var-books,
  "books-medical": $fa-var-books-medical,
  "boombox": $fa-var-boombox,
  "boot": $fa-var-boot,
  "boot-heeled": $fa-var-boot-heeled,
  "booth-curtain": $fa-var-booth-curtain,
  "border-all": $fa-var-border-all,
  "border-bottom": $fa-var-border-bottom,
  "border-bottom-right": $fa-var-border-bottom-right,
  "border-style-alt": $fa-var-border-style-alt,
  "border-center-h": $fa-var-border-center-h,
  "border-center-v": $fa-var-border-center-v,
  "border-inner": $fa-var-border-inner,
  "border-left": $fa-var-border-left,
  "border-none": $fa-var-border-none,
  "border-outer": $fa-var-border-outer,
  "border-right": $fa-var-border-right,
  "border-top": $fa-var-border-top,
  "border-top-left": $fa-var-border-top-left,
  "border-style": $fa-var-border-style,
  "bore-hole": $fa-var-bore-hole,
  "bottle-baby": $fa-var-bottle-baby,
  "bottle-droplet": $fa-var-bottle-droplet,
  "bottle-water": $fa-var-bottle-water,
  "bow-arrow": $fa-var-bow-arrow,
  "bowl-chopsticks": $fa-var-bowl-chopsticks,
  "bowl-chopsticks-noodles": $fa-var-bowl-chopsticks-noodles,
  "bowl-food": $fa-var-bowl-food,
  "bowl-hot": $fa-var-bowl-hot,
  "soup": $fa-var-soup,
  "bowl-rice": $fa-var-bowl-rice,
  "bowl-scoop": $fa-var-bowl-scoop,
  "bowl-shaved-ice": $fa-var-bowl-shaved-ice,
  "bowl-scoops": $fa-var-bowl-scoops,
  "bowl-soft-serve": $fa-var-bowl-soft-serve,
  "bowl-spoon": $fa-var-bowl-spoon,
  "bowling-ball": $fa-var-bowling-ball,
  "bowling-ball-pin": $fa-var-bowling-ball-pin,
  "bowling-pins": $fa-var-bowling-pins,
  "box": $fa-var-box,
  "box-archive": $fa-var-box-archive,
  "archive": $fa-var-archive,
  "box-ballot": $fa-var-box-ballot,
  "box-check": $fa-var-box-check,
  "box-circle-check": $fa-var-box-circle-check,
  "box-dollar": $fa-var-box-dollar,
  "box-usd": $fa-var-box-usd,
  "box-heart": $fa-var-box-heart,
  "box-open": $fa-var-box-open,
  "box-open-full": $fa-var-box-open-full,
  "box-full": $fa-var-box-full,
  "box-taped": $fa-var-box-taped,
  "box-alt": $fa-var-box-alt,
  "box-tissue": $fa-var-box-tissue,
  "boxes-packing": $fa-var-boxes-packing,
  "boxes-stacked": $fa-var-boxes-stacked,
  "boxes": $fa-var-boxes,
  "boxes-alt": $fa-var-boxes-alt,
  "boxing-glove": $fa-var-boxing-glove,
  "glove-boxing": $fa-var-glove-boxing,
  "bracket-curly": $fa-var-bracket-curly,
  "bracket-curly-left": $fa-var-bracket-curly-left,
  "bracket-curly-right": $fa-var-bracket-curly-right,
  "bracket-round": $fa-var-bracket-round,
  "parenthesis": $fa-var-parenthesis,
  "bracket-round-right": $fa-var-bracket-round-right,
  "bracket-square": $fa-var-bracket-square,
  "bracket": $fa-var-bracket,
  "bracket-left": $fa-var-bracket-left,
  "bracket-square-right": $fa-var-bracket-square-right,
  "brackets-curly": $fa-var-brackets-curly,
  "brackets-round": $fa-var-brackets-round,
  "parentheses": $fa-var-parentheses,
  "brackets-square": $fa-var-brackets-square,
  "brackets": $fa-var-brackets,
  "braille": $fa-var-braille,
  "brain": $fa-var-brain,
  "brain-arrow-curved-right": $fa-var-brain-arrow-curved-right,
  "mind-share": $fa-var-mind-share,
  "brain-circuit": $fa-var-brain-circuit,
  "brake-warning": $fa-var-brake-warning,
  "brazilian-real-sign": $fa-var-brazilian-real-sign,
  "bread-loaf": $fa-var-bread-loaf,
  "bread-slice": $fa-var-bread-slice,
  "bread-slice-butter": $fa-var-bread-slice-butter,
  "bridge": $fa-var-bridge,
  "bridge-circle-check": $fa-var-bridge-circle-check,
  "bridge-circle-exclamation": $fa-var-bridge-circle-exclamation,
  "bridge-circle-xmark": $fa-var-bridge-circle-xmark,
  "bridge-lock": $fa-var-bridge-lock,
  "bridge-suspension": $fa-var-bridge-suspension,
  "bridge-water": $fa-var-bridge-water,
  "briefcase": $fa-var-briefcase,
  "briefcase-arrow-right": $fa-var-briefcase-arrow-right,
  "briefcase-blank": $fa-var-briefcase-blank,
  "briefcase-medical": $fa-var-briefcase-medical,
  "brightness": $fa-var-brightness,
  "brightness-low": $fa-var-brightness-low,
  "bring-forward": $fa-var-bring-forward,
  "bring-front": $fa-var-bring-front,
  "broccoli": $fa-var-broccoli,
  "broom": $fa-var-broom,
  "broom-ball": $fa-var-broom-ball,
  "quidditch": $fa-var-quidditch,
  "quidditch-broom-ball": $fa-var-quidditch-broom-ball,
  "broom-wide": $fa-var-broom-wide,
  "browser": $fa-var-browser,
  "browsers": $fa-var-browsers,
  "brush": $fa-var-brush,
  "bucket": $fa-var-bucket,
  "bug": $fa-var-bug,
  "bug-slash": $fa-var-bug-slash,
  "bugs": $fa-var-bugs,
  "building": $fa-var-building,
  "building-circle-arrow-right": $fa-var-building-circle-arrow-right,
  "building-circle-check": $fa-var-building-circle-check,
  "building-circle-exclamation": $fa-var-building-circle-exclamation,
  "building-circle-xmark": $fa-var-building-circle-xmark,
  "building-columns": $fa-var-building-columns,
  "bank": $fa-var-bank,
  "institution": $fa-var-institution,
  "museum": $fa-var-museum,
  "university": $fa-var-university,
  "building-flag": $fa-var-building-flag,
  "building-lock": $fa-var-building-lock,
  "building-magnifying-glass": $fa-var-building-magnifying-glass,
  "building-memo": $fa-var-building-memo,
  "building-ngo": $fa-var-building-ngo,
  "building-shield": $fa-var-building-shield,
  "building-un": $fa-var-building-un,
  "building-user": $fa-var-building-user,
  "building-wheat": $fa-var-building-wheat,
  "buildings": $fa-var-buildings,
  "bulldozer": $fa-var-bulldozer,
  "bullhorn": $fa-var-bullhorn,
  "bullseye": $fa-var-bullseye,
  "bullseye-arrow": $fa-var-bullseye-arrow,
  "bullseye-pointer": $fa-var-bullseye-pointer,
  "buoy": $fa-var-buoy,
  "buoy-mooring": $fa-var-buoy-mooring,
  "burger": $fa-var-burger,
  "hamburger": $fa-var-hamburger,
  "burger-cheese": $fa-var-burger-cheese,
  "cheeseburger": $fa-var-cheeseburger,
  "burger-fries": $fa-var-burger-fries,
  "burger-glass": $fa-var-burger-glass,
  "burger-lettuce": $fa-var-burger-lettuce,
  "burger-soda": $fa-var-burger-soda,
  "burrito": $fa-var-burrito,
  "burst": $fa-var-burst,
  "bus": $fa-var-bus,
  "bus-school": $fa-var-bus-school,
  "bus-simple": $fa-var-bus-simple,
  "bus-alt": $fa-var-bus-alt,
  "business-time": $fa-var-business-time,
  "briefcase-clock": $fa-var-briefcase-clock,
  "butter": $fa-var-butter,
  "c": $fa-var-c,
  "cabin": $fa-var-cabin,
  "cabinet-filing": $fa-var-cabinet-filing,
  "cable-car": $fa-var-cable-car,
  "tram": $fa-var-tram,
  "cactus": $fa-var-cactus,
  "caduceus": $fa-var-caduceus,
  "cake-candles": $fa-var-cake-candles,
  "birthday-cake": $fa-var-birthday-cake,
  "cake": $fa-var-cake,
  "cake-slice": $fa-var-cake-slice,
  "shortcake": $fa-var-shortcake,
  "calculator": $fa-var-calculator,
  "calculator-simple": $fa-var-calculator-simple,
  "calculator-alt": $fa-var-calculator-alt,
  "calendar": $fa-var-calendar,
  "calendar-arrow-down": $fa-var-calendar-arrow-down,
  "calendar-download": $fa-var-calendar-download,
  "calendar-arrow-up": $fa-var-calendar-arrow-up,
  "calendar-upload": $fa-var-calendar-upload,
  "calendar-check": $fa-var-calendar-check,
  "calendar-circle-exclamation": $fa-var-calendar-circle-exclamation,
  "calendar-circle-minus": $fa-var-calendar-circle-minus,
  "calendar-circle-plus": $fa-var-calendar-circle-plus,
  "calendar-circle-user": $fa-var-calendar-circle-user,
  "calendar-clock": $fa-var-calendar-clock,
  "calendar-time": $fa-var-calendar-time,
  "calendar-day": $fa-var-calendar-day,
  "calendar-days": $fa-var-calendar-days,
  "calendar-alt": $fa-var-calendar-alt,
  "calendar-exclamation": $fa-var-calendar-exclamation,
  "calendar-heart": $fa-var-calendar-heart,
  "calendar-image": $fa-var-calendar-image,
  "calendar-lines": $fa-var-calendar-lines,
  "calendar-note": $fa-var-calendar-note,
  "calendar-lines-pen": $fa-var-calendar-lines-pen,
  "calendar-minus": $fa-var-calendar-minus,
  "calendar-pen": $fa-var-calendar-pen,
  "calendar-edit": $fa-var-calendar-edit,
  "calendar-plus": $fa-var-calendar-plus,
  "calendar-range": $fa-var-calendar-range,
  "calendar-star": $fa-var-calendar-star,
  "calendar-users": $fa-var-calendar-users,
  "calendar-week": $fa-var-calendar-week,
  "calendar-xmark": $fa-var-calendar-xmark,
  "calendar-times": $fa-var-calendar-times,
  "calendars": $fa-var-calendars,
  "camcorder": $fa-var-camcorder,
  "video-handheld": $fa-var-video-handheld,
  "camera": $fa-var-camera,
  "camera-alt": $fa-var-camera-alt,
  "camera-cctv": $fa-var-camera-cctv,
  "cctv": $fa-var-cctv,
  "camera-movie": $fa-var-camera-movie,
  "camera-polaroid": $fa-var-camera-polaroid,
  "camera-retro": $fa-var-camera-retro,
  "camera-rotate": $fa-var-camera-rotate,
  "camera-security": $fa-var-camera-security,
  "camera-home": $fa-var-camera-home,
  "camera-slash": $fa-var-camera-slash,
  "camera-viewfinder": $fa-var-camera-viewfinder,
  "screenshot": $fa-var-screenshot,
  "camera-web": $fa-var-camera-web,
  "webcam": $fa-var-webcam,
  "camera-web-slash": $fa-var-camera-web-slash,
  "webcam-slash": $fa-var-webcam-slash,
  "campfire": $fa-var-campfire,
  "campground": $fa-var-campground,
  "can-food": $fa-var-can-food,
  "candle-holder": $fa-var-candle-holder,
  "candy": $fa-var-candy,
  "candy-bar": $fa-var-candy-bar,
  "chocolate-bar": $fa-var-chocolate-bar,
  "candy-cane": $fa-var-candy-cane,
  "candy-corn": $fa-var-candy-corn,
  "cannabis": $fa-var-cannabis,
  "cannon": $fa-var-cannon,
  "capsules": $fa-var-capsules,
  "car": $fa-var-car,
  "automobile": $fa-var-automobile,
  "car-battery": $fa-var-car-battery,
  "battery-car": $fa-var-battery-car,
  "car-bolt": $fa-var-car-bolt,
  "car-building": $fa-var-car-building,
  "car-bump": $fa-var-car-bump,
  "car-burst": $fa-var-car-burst,
  "car-crash": $fa-var-car-crash,
  "car-bus": $fa-var-car-bus,
  "car-circle-bolt": $fa-var-car-circle-bolt,
  "car-garage": $fa-var-car-garage,
  "car-mirrors": $fa-var-car-mirrors,
  "car-on": $fa-var-car-on,
  "car-rear": $fa-var-car-rear,
  "car-alt": $fa-var-car-alt,
  "car-side": $fa-var-car-side,
  "car-side-bolt": $fa-var-car-side-bolt,
  "car-tilt": $fa-var-car-tilt,
  "car-tunnel": $fa-var-car-tunnel,
  "car-wash": $fa-var-car-wash,
  "car-wrench": $fa-var-car-wrench,
  "car-mechanic": $fa-var-car-mechanic,
  "caravan": $fa-var-caravan,
  "caravan-simple": $fa-var-caravan-simple,
  "caravan-alt": $fa-var-caravan-alt,
  "card-club": $fa-var-card-club,
  "card-diamond": $fa-var-card-diamond,
  "card-heart": $fa-var-card-heart,
  "card-spade": $fa-var-card-spade,
  "cards": $fa-var-cards,
  "cards-blank": $fa-var-cards-blank,
  "caret-down": $fa-var-caret-down,
  "caret-left": $fa-var-caret-left,
  "caret-right": $fa-var-caret-right,
  "caret-up": $fa-var-caret-up,
  "carrot": $fa-var-carrot,
  "cars": $fa-var-cars,
  "cart-arrow-down": $fa-var-cart-arrow-down,
  "cart-arrow-up": $fa-var-cart-arrow-up,
  "cart-circle-arrow-down": $fa-var-cart-circle-arrow-down,
  "cart-circle-arrow-up": $fa-var-cart-circle-arrow-up,
  "cart-circle-check": $fa-var-cart-circle-check,
  "cart-circle-exclamation": $fa-var-cart-circle-exclamation,
  "cart-circle-plus": $fa-var-cart-circle-plus,
  "cart-circle-xmark": $fa-var-cart-circle-xmark,
  "cart-flatbed": $fa-var-cart-flatbed,
  "dolly-flatbed": $fa-var-dolly-flatbed,
  "cart-flatbed-boxes": $fa-var-cart-flatbed-boxes,
  "dolly-flatbed-alt": $fa-var-dolly-flatbed-alt,
  "cart-flatbed-empty": $fa-var-cart-flatbed-empty,
  "dolly-flatbed-empty": $fa-var-dolly-flatbed-empty,
  "cart-flatbed-suitcase": $fa-var-cart-flatbed-suitcase,
  "luggage-cart": $fa-var-luggage-cart,
  "cart-minus": $fa-var-cart-minus,
  "cart-plus": $fa-var-cart-plus,
  "cart-shopping": $fa-var-cart-shopping,
  "shopping-cart": $fa-var-shopping-cart,
  "cart-shopping-fast": $fa-var-cart-shopping-fast,
  "cart-xmark": $fa-var-cart-xmark,
  "cash-register": $fa-var-cash-register,
  "cassette-betamax": $fa-var-cassette-betamax,
  "betamax": $fa-var-betamax,
  "cassette-tape": $fa-var-cassette-tape,
  "cassette-vhs": $fa-var-cassette-vhs,
  "vhs": $fa-var-vhs,
  "castle": $fa-var-castle,
  "cat": $fa-var-cat,
  "cat-space": $fa-var-cat-space,
  "cauldron": $fa-var-cauldron,
  "cedi-sign": $fa-var-cedi-sign,
  "cent-sign": $fa-var-cent-sign,
  "certificate": $fa-var-certificate,
  "chair": $fa-var-chair,
  "chair-office": $fa-var-chair-office,
  "chalkboard": $fa-var-chalkboard,
  "blackboard": $fa-var-blackboard,
  "chalkboard-user": $fa-var-chalkboard-user,
  "chalkboard-teacher": $fa-var-chalkboard-teacher,
  "champagne-glass": $fa-var-champagne-glass,
  "glass-champagne": $fa-var-glass-champagne,
  "champagne-glasses": $fa-var-champagne-glasses,
  "glass-cheers": $fa-var-glass-cheers,
  "charging-station": $fa-var-charging-station,
  "chart-area": $fa-var-chart-area,
  "area-chart": $fa-var-area-chart,
  "chart-bar": $fa-var-chart-bar,
  "bar-chart": $fa-var-bar-chart,
  "chart-bullet": $fa-var-chart-bullet,
  "chart-candlestick": $fa-var-chart-candlestick,
  "chart-column": $fa-var-chart-column,
  "chart-gantt": $fa-var-chart-gantt,
  "chart-kanban": $fa-var-chart-kanban,
  "chart-line": $fa-var-chart-line,
  "line-chart": $fa-var-line-chart,
  "chart-line-down": $fa-var-chart-line-down,
  "chart-line-up": $fa-var-chart-line-up,
  "chart-line-up-down": $fa-var-chart-line-up-down,
  "chart-mixed": $fa-var-chart-mixed,
  "analytics": $fa-var-analytics,
  "chart-mixed-up-circle-currency": $fa-var-chart-mixed-up-circle-currency,
  "chart-mixed-up-circle-dollar": $fa-var-chart-mixed-up-circle-dollar,
  "chart-network": $fa-var-chart-network,
  "chart-pie": $fa-var-chart-pie,
  "pie-chart": $fa-var-pie-chart,
  "chart-pie-simple": $fa-var-chart-pie-simple,
  "chart-pie-alt": $fa-var-chart-pie-alt,
  "chart-pie-simple-circle-currency": $fa-var-chart-pie-simple-circle-currency,
  "chart-pie-simple-circle-dollar": $fa-var-chart-pie-simple-circle-dollar,
  "chart-pyramid": $fa-var-chart-pyramid,
  "chart-radar": $fa-var-chart-radar,
  "chart-scatter": $fa-var-chart-scatter,
  "chart-scatter-3d": $fa-var-chart-scatter-3d,
  "chart-scatter-bubble": $fa-var-chart-scatter-bubble,
  "chart-simple": $fa-var-chart-simple,
  "chart-simple-horizontal": $fa-var-chart-simple-horizontal,
  "chart-tree-map": $fa-var-chart-tree-map,
  "chart-user": $fa-var-chart-user,
  "user-chart": $fa-var-user-chart,
  "chart-waterfall": $fa-var-chart-waterfall,
  "check": $fa-var-check,
  "check-double": $fa-var-check-double,
  "check-to-slot": $fa-var-check-to-slot,
  "vote-yea": $fa-var-vote-yea,
  "cheese": $fa-var-cheese,
  "cheese-swiss": $fa-var-cheese-swiss,
  "cherries": $fa-var-cherries,
  "chess": $fa-var-chess,
  "chess-bishop": $fa-var-chess-bishop,
  "chess-bishop-piece": $fa-var-chess-bishop-piece,
  "chess-bishop-alt": $fa-var-chess-bishop-alt,
  "chess-board": $fa-var-chess-board,
  "chess-clock": $fa-var-chess-clock,
  "chess-clock-flip": $fa-var-chess-clock-flip,
  "chess-clock-alt": $fa-var-chess-clock-alt,
  "chess-king": $fa-var-chess-king,
  "chess-king-piece": $fa-var-chess-king-piece,
  "chess-king-alt": $fa-var-chess-king-alt,
  "chess-knight": $fa-var-chess-knight,
  "chess-knight-piece": $fa-var-chess-knight-piece,
  "chess-knight-alt": $fa-var-chess-knight-alt,
  "chess-pawn": $fa-var-chess-pawn,
  "chess-pawn-piece": $fa-var-chess-pawn-piece,
  "chess-pawn-alt": $fa-var-chess-pawn-alt,
  "chess-queen": $fa-var-chess-queen,
  "chess-queen-piece": $fa-var-chess-queen-piece,
  "chess-queen-alt": $fa-var-chess-queen-alt,
  "chess-rook": $fa-var-chess-rook,
  "chess-rook-piece": $fa-var-chess-rook-piece,
  "chess-rook-alt": $fa-var-chess-rook-alt,
  "chestnut": $fa-var-chestnut,
  "chevron-down": $fa-var-chevron-down,
  "chevron-left": $fa-var-chevron-left,
  "chevron-right": $fa-var-chevron-right,
  "chevron-up": $fa-var-chevron-up,
  "chevrons-down": $fa-var-chevrons-down,
  "chevron-double-down": $fa-var-chevron-double-down,
  "chevrons-left": $fa-var-chevrons-left,
  "chevron-double-left": $fa-var-chevron-double-left,
  "chevrons-right": $fa-var-chevrons-right,
  "chevron-double-right": $fa-var-chevron-double-right,
  "chevrons-up": $fa-var-chevrons-up,
  "chevron-double-up": $fa-var-chevron-double-up,
  "chf-sign": $fa-var-chf-sign,
  "child": $fa-var-child,
  "child-combatant": $fa-var-child-combatant,
  "child-rifle": $fa-var-child-rifle,
  "child-dress": $fa-var-child-dress,
  "child-reaching": $fa-var-child-reaching,
  "children": $fa-var-children,
  "chimney": $fa-var-chimney,
  "chopsticks": $fa-var-chopsticks,
  "church": $fa-var-church,
  "circle": $fa-var-circle,
  "circle-0": $fa-var-circle-0,
  "circle-1": $fa-var-circle-1,
  "circle-2": $fa-var-circle-2,
  "circle-3": $fa-var-circle-3,
  "circle-4": $fa-var-circle-4,
  "circle-5": $fa-var-circle-5,
  "circle-6": $fa-var-circle-6,
  "circle-7": $fa-var-circle-7,
  "circle-8": $fa-var-circle-8,
  "circle-9": $fa-var-circle-9,
  "circle-a": $fa-var-circle-a,
  "circle-ampersand": $fa-var-circle-ampersand,
  "circle-arrow-down": $fa-var-circle-arrow-down,
  "arrow-circle-down": $fa-var-arrow-circle-down,
  "circle-arrow-down-left": $fa-var-circle-arrow-down-left,
  "circle-arrow-down-right": $fa-var-circle-arrow-down-right,
  "circle-arrow-left": $fa-var-circle-arrow-left,
  "arrow-circle-left": $fa-var-arrow-circle-left,
  "circle-arrow-right": $fa-var-circle-arrow-right,
  "arrow-circle-right": $fa-var-arrow-circle-right,
  "circle-arrow-up": $fa-var-circle-arrow-up,
  "arrow-circle-up": $fa-var-arrow-circle-up,
  "circle-arrow-up-left": $fa-var-circle-arrow-up-left,
  "circle-arrow-up-right": $fa-var-circle-arrow-up-right,
  "circle-b": $fa-var-circle-b,
  "circle-bolt": $fa-var-circle-bolt,
  "circle-book-open": $fa-var-circle-book-open,
  "book-circle": $fa-var-book-circle,
  "circle-bookmark": $fa-var-circle-bookmark,
  "bookmark-circle": $fa-var-bookmark-circle,
  "circle-c": $fa-var-circle-c,
  "circle-calendar": $fa-var-circle-calendar,
  "calendar-circle": $fa-var-calendar-circle,
  "circle-camera": $fa-var-circle-camera,
  "camera-circle": $fa-var-camera-circle,
  "circle-caret-down": $fa-var-circle-caret-down,
  "caret-circle-down": $fa-var-caret-circle-down,
  "circle-caret-left": $fa-var-circle-caret-left,
  "caret-circle-left": $fa-var-caret-circle-left,
  "circle-caret-right": $fa-var-circle-caret-right,
  "caret-circle-right": $fa-var-caret-circle-right,
  "circle-caret-up": $fa-var-circle-caret-up,
  "caret-circle-up": $fa-var-caret-circle-up,
  "circle-check": $fa-var-circle-check,
  "check-circle": $fa-var-check-circle,
  "circle-chevron-down": $fa-var-circle-chevron-down,
  "chevron-circle-down": $fa-var-chevron-circle-down,
  "circle-chevron-left": $fa-var-circle-chevron-left,
  "chevron-circle-left": $fa-var-chevron-circle-left,
  "circle-chevron-right": $fa-var-circle-chevron-right,
  "chevron-circle-right": $fa-var-chevron-circle-right,
  "circle-chevron-up": $fa-var-circle-chevron-up,
  "chevron-circle-up": $fa-var-chevron-circle-up,
  "circle-d": $fa-var-circle-d,
  "circle-dashed": $fa-var-circle-dashed,
  "circle-divide": $fa-var-circle-divide,
  "circle-dollar": $fa-var-circle-dollar,
  "dollar-circle": $fa-var-dollar-circle,
  "usd-circle": $fa-var-usd-circle,
  "circle-dollar-to-slot": $fa-var-circle-dollar-to-slot,
  "donate": $fa-var-donate,
  "circle-dot": $fa-var-circle-dot,
  "dot-circle": $fa-var-dot-circle,
  "circle-down": $fa-var-circle-down,
  "arrow-alt-circle-down": $fa-var-arrow-alt-circle-down,
  "circle-down-left": $fa-var-circle-down-left,
  "circle-down-right": $fa-var-circle-down-right,
  "circle-e": $fa-var-circle-e,
  "circle-ellipsis": $fa-var-circle-ellipsis,
  "circle-ellipsis-vertical": $fa-var-circle-ellipsis-vertical,
  "circle-envelope": $fa-var-circle-envelope,
  "envelope-circle": $fa-var-envelope-circle,
  "circle-euro": $fa-var-circle-euro,
  "circle-exclamation": $fa-var-circle-exclamation,
  "exclamation-circle": $fa-var-exclamation-circle,
  "circle-exclamation-check": $fa-var-circle-exclamation-check,
  "circle-f": $fa-var-circle-f,
  "circle-g": $fa-var-circle-g,
  "circle-gf": $fa-var-circle-gf,
  "circle-h": $fa-var-circle-h,
  "hospital-symbol": $fa-var-hospital-symbol,
  "circle-half": $fa-var-circle-half,
  "circle-half-stroke": $fa-var-circle-half-stroke,
  "adjust": $fa-var-adjust,
  "circle-heart": $fa-var-circle-heart,
  "heart-circle": $fa-var-heart-circle,
  "circle-i": $fa-var-circle-i,
  "circle-info": $fa-var-circle-info,
  "info-circle": $fa-var-info-circle,
  "circle-j": $fa-var-circle-j,
  "circle-k": $fa-var-circle-k,
  "circle-l": $fa-var-circle-l,
  "circle-left": $fa-var-circle-left,
  "arrow-alt-circle-left": $fa-var-arrow-alt-circle-left,
  "circle-location-arrow": $fa-var-circle-location-arrow,
  "location-circle": $fa-var-location-circle,
  "circle-m": $fa-var-circle-m,
  "circle-microphone": $fa-var-circle-microphone,
  "microphone-circle": $fa-var-microphone-circle,
  "circle-microphone-lines": $fa-var-circle-microphone-lines,
  "microphone-circle-alt": $fa-var-microphone-circle-alt,
  "circle-minus": $fa-var-circle-minus,
  "minus-circle": $fa-var-minus-circle,
  "circle-n": $fa-var-circle-n,
  "circle-nodes": $fa-var-circle-nodes,
  "circle-notch": $fa-var-circle-notch,
  "circle-o": $fa-var-circle-o,
  "circle-p": $fa-var-circle-p,
  "circle-parking": $fa-var-circle-parking,
  "parking-circle": $fa-var-parking-circle,
  "circle-pause": $fa-var-circle-pause,
  "pause-circle": $fa-var-pause-circle,
  "circle-phone": $fa-var-circle-phone,
  "phone-circle": $fa-var-phone-circle,
  "circle-phone-flip": $fa-var-circle-phone-flip,
  "phone-circle-alt": $fa-var-phone-circle-alt,
  "circle-phone-hangup": $fa-var-circle-phone-hangup,
  "phone-circle-down": $fa-var-phone-circle-down,
  "circle-play": $fa-var-circle-play,
  "play-circle": $fa-var-play-circle,
  "circle-plus": $fa-var-circle-plus,
  "plus-circle": $fa-var-plus-circle,
  "circle-q": $fa-var-circle-q,
  "circle-quarter": $fa-var-circle-quarter,
  "circle-quarter-stroke": $fa-var-circle-quarter-stroke,
  "circle-quarters": $fa-var-circle-quarters,
  "circle-question": $fa-var-circle-question,
  "question-circle": $fa-var-question-circle,
  "circle-r": $fa-var-circle-r,
  "circle-radiation": $fa-var-circle-radiation,
  "radiation-alt": $fa-var-radiation-alt,
  "circle-right": $fa-var-circle-right,
  "arrow-alt-circle-right": $fa-var-arrow-alt-circle-right,
  "circle-s": $fa-var-circle-s,
  "circle-small": $fa-var-circle-small,
  "circle-sort": $fa-var-circle-sort,
  "sort-circle": $fa-var-sort-circle,
  "circle-sort-down": $fa-var-circle-sort-down,
  "sort-circle-down": $fa-var-sort-circle-down,
  "circle-sort-up": $fa-var-circle-sort-up,
  "sort-circle-up": $fa-var-sort-circle-up,
  "circle-star": $fa-var-circle-star,
  "star-circle": $fa-var-star-circle,
  "circle-sterling": $fa-var-circle-sterling,
  "circle-stop": $fa-var-circle-stop,
  "stop-circle": $fa-var-stop-circle,
  "circle-t": $fa-var-circle-t,
  "circle-three-quarters": $fa-var-circle-three-quarters,
  "circle-three-quarters-stroke": $fa-var-circle-three-quarters-stroke,
  "circle-trash": $fa-var-circle-trash,
  "trash-circle": $fa-var-trash-circle,
  "circle-u": $fa-var-circle-u,
  "circle-up": $fa-var-circle-up,
  "arrow-alt-circle-up": $fa-var-arrow-alt-circle-up,
  "circle-up-left": $fa-var-circle-up-left,
  "circle-up-right": $fa-var-circle-up-right,
  "circle-user": $fa-var-circle-user,
  "user-circle": $fa-var-user-circle,
  "circle-v": $fa-var-circle-v,
  "circle-video": $fa-var-circle-video,
  "video-circle": $fa-var-video-circle,
  "circle-w": $fa-var-circle-w,
  "circle-waveform-lines": $fa-var-circle-waveform-lines,
  "waveform-circle": $fa-var-waveform-circle,
  "circle-wifi": $fa-var-circle-wifi,
  "circle-wifi-circle-wifi": $fa-var-circle-wifi-circle-wifi,
  "circle-wifi-group": $fa-var-circle-wifi-group,
  "circle-x": $fa-var-circle-x,
  "circle-xmark": $fa-var-circle-xmark,
  "times-circle": $fa-var-times-circle,
  "xmark-circle": $fa-var-xmark-circle,
  "circle-y": $fa-var-circle-y,
  "circle-yen": $fa-var-circle-yen,
  "circle-z": $fa-var-circle-z,
  "circles-overlap": $fa-var-circles-overlap,
  "citrus": $fa-var-citrus,
  "citrus-slice": $fa-var-citrus-slice,
  "city": $fa-var-city,
  "clapperboard": $fa-var-clapperboard,
  "clapperboard-play": $fa-var-clapperboard-play,
  "clarinet": $fa-var-clarinet,
  "claw-marks": $fa-var-claw-marks,
  "clipboard": $fa-var-clipboard,
  "clipboard-check": $fa-var-clipboard-check,
  "clipboard-list": $fa-var-clipboard-list,
  "clipboard-list-check": $fa-var-clipboard-list-check,
  "clipboard-medical": $fa-var-clipboard-medical,
  "clipboard-prescription": $fa-var-clipboard-prescription,
  "clipboard-question": $fa-var-clipboard-question,
  "clipboard-user": $fa-var-clipboard-user,
  "clock": $fa-var-clock,
  "clock-four": $fa-var-clock-four,
  "clock-desk": $fa-var-clock-desk,
  "clock-eight": $fa-var-clock-eight,
  "clock-eight-thirty": $fa-var-clock-eight-thirty,
  "clock-eleven": $fa-var-clock-eleven,
  "clock-eleven-thirty": $fa-var-clock-eleven-thirty,
  "clock-five": $fa-var-clock-five,
  "clock-five-thirty": $fa-var-clock-five-thirty,
  "clock-four-thirty": $fa-var-clock-four-thirty,
  "clock-nine": $fa-var-clock-nine,
  "clock-nine-thirty": $fa-var-clock-nine-thirty,
  "clock-one": $fa-var-clock-one,
  "clock-one-thirty": $fa-var-clock-one-thirty,
  "clock-rotate-left": $fa-var-clock-rotate-left,
  "history": $fa-var-history,
  "clock-seven": $fa-var-clock-seven,
  "clock-seven-thirty": $fa-var-clock-seven-thirty,
  "clock-six": $fa-var-clock-six,
  "clock-six-thirty": $fa-var-clock-six-thirty,
  "clock-ten": $fa-var-clock-ten,
  "clock-ten-thirty": $fa-var-clock-ten-thirty,
  "clock-three": $fa-var-clock-three,
  "clock-three-thirty": $fa-var-clock-three-thirty,
  "clock-twelve": $fa-var-clock-twelve,
  "clock-twelve-thirty": $fa-var-clock-twelve-thirty,
  "clock-two": $fa-var-clock-two,
  "clock-two-thirty": $fa-var-clock-two-thirty,
  "clone": $fa-var-clone,
  "closed-captioning": $fa-var-closed-captioning,
  "closed-captioning-slash": $fa-var-closed-captioning-slash,
  "clothes-hanger": $fa-var-clothes-hanger,
  "cloud": $fa-var-cloud,
  "cloud-arrow-down": $fa-var-cloud-arrow-down,
  "cloud-download": $fa-var-cloud-download,
  "cloud-download-alt": $fa-var-cloud-download-alt,
  "cloud-arrow-up": $fa-var-cloud-arrow-up,
  "cloud-upload": $fa-var-cloud-upload,
  "cloud-upload-alt": $fa-var-cloud-upload-alt,
  "cloud-binary": $fa-var-cloud-binary,
  "cloud-bolt": $fa-var-cloud-bolt,
  "thunderstorm": $fa-var-thunderstorm,
  "cloud-bolt-moon": $fa-var-cloud-bolt-moon,
  "thunderstorm-moon": $fa-var-thunderstorm-moon,
  "cloud-bolt-sun": $fa-var-cloud-bolt-sun,
  "thunderstorm-sun": $fa-var-thunderstorm-sun,
  "cloud-check": $fa-var-cloud-check,
  "cloud-drizzle": $fa-var-cloud-drizzle,
  "cloud-exclamation": $fa-var-cloud-exclamation,
  "cloud-fog": $fa-var-cloud-fog,
  "fog": $fa-var-fog,
  "cloud-hail": $fa-var-cloud-hail,
  "cloud-hail-mixed": $fa-var-cloud-hail-mixed,
  "cloud-meatball": $fa-var-cloud-meatball,
  "cloud-minus": $fa-var-cloud-minus,
  "cloud-moon": $fa-var-cloud-moon,
  "cloud-moon-rain": $fa-var-cloud-moon-rain,
  "cloud-music": $fa-var-cloud-music,
  "cloud-plus": $fa-var-cloud-plus,
  "cloud-question": $fa-var-cloud-question,
  "cloud-rain": $fa-var-cloud-rain,
  "cloud-rainbow": $fa-var-cloud-rainbow,
  "cloud-showers": $fa-var-cloud-showers,
  "cloud-showers-heavy": $fa-var-cloud-showers-heavy,
  "cloud-showers-water": $fa-var-cloud-showers-water,
  "cloud-slash": $fa-var-cloud-slash,
  "cloud-sleet": $fa-var-cloud-sleet,
  "cloud-snow": $fa-var-cloud-snow,
  "cloud-sun": $fa-var-cloud-sun,
  "cloud-sun-rain": $fa-var-cloud-sun-rain,
  "cloud-word": $fa-var-cloud-word,
  "cloud-xmark": $fa-var-cloud-xmark,
  "clouds": $fa-var-clouds,
  "clouds-moon": $fa-var-clouds-moon,
  "clouds-sun": $fa-var-clouds-sun,
  "clover": $fa-var-clover,
  "club": $fa-var-club,
  "coconut": $fa-var-coconut,
  "code": $fa-var-code,
  "code-branch": $fa-var-code-branch,
  "code-commit": $fa-var-code-commit,
  "code-compare": $fa-var-code-compare,
  "code-fork": $fa-var-code-fork,
  "code-merge": $fa-var-code-merge,
  "code-pull-request": $fa-var-code-pull-request,
  "code-pull-request-closed": $fa-var-code-pull-request-closed,
  "code-pull-request-draft": $fa-var-code-pull-request-draft,
  "code-simple": $fa-var-code-simple,
  "coffee-bean": $fa-var-coffee-bean,
  "coffee-beans": $fa-var-coffee-beans,
  "coffee-pot": $fa-var-coffee-pot,
  "coffin": $fa-var-coffin,
  "coffin-cross": $fa-var-coffin-cross,
  "coin": $fa-var-coin,
  "coin-blank": $fa-var-coin-blank,
  "coin-front": $fa-var-coin-front,
  "coin-vertical": $fa-var-coin-vertical,
  "coins": $fa-var-coins,
  "colon": $fa-var-colon,
  "colon-sign": $fa-var-colon-sign,
  "columns-3": $fa-var-columns-3,
  "comet": $fa-var-comet,
  "comma": $fa-var-comma,
  "command": $fa-var-command,
  "comment": $fa-var-comment,
  "comment-arrow-down": $fa-var-comment-arrow-down,
  "comment-arrow-up": $fa-var-comment-arrow-up,
  "comment-arrow-up-right": $fa-var-comment-arrow-up-right,
  "comment-captions": $fa-var-comment-captions,
  "comment-check": $fa-var-comment-check,
  "comment-code": $fa-var-comment-code,
  "comment-dollar": $fa-var-comment-dollar,
  "comment-dots": $fa-var-comment-dots,
  "commenting": $fa-var-commenting,
  "comment-exclamation": $fa-var-comment-exclamation,
  "comment-heart": $fa-var-comment-heart,
  "comment-image": $fa-var-comment-image,
  "comment-lines": $fa-var-comment-lines,
  "comment-medical": $fa-var-comment-medical,
  "comment-middle": $fa-var-comment-middle,
  "comment-middle-top": $fa-var-comment-middle-top,
  "comment-minus": $fa-var-comment-minus,
  "comment-music": $fa-var-comment-music,
  "comment-pen": $fa-var-comment-pen,
  "comment-edit": $fa-var-comment-edit,
  "comment-plus": $fa-var-comment-plus,
  "comment-question": $fa-var-comment-question,
  "comment-quote": $fa-var-comment-quote,
  "comment-slash": $fa-var-comment-slash,
  "comment-smile": $fa-var-comment-smile,
  "comment-sms": $fa-var-comment-sms,
  "sms": $fa-var-sms,
  "comment-text": $fa-var-comment-text,
  "comment-xmark": $fa-var-comment-xmark,
  "comment-times": $fa-var-comment-times,
  "comments": $fa-var-comments,
  "comments-dollar": $fa-var-comments-dollar,
  "comments-question": $fa-var-comments-question,
  "comments-question-check": $fa-var-comments-question-check,
  "compact-disc": $fa-var-compact-disc,
  "compass": $fa-var-compass,
  "compass-drafting": $fa-var-compass-drafting,
  "drafting-compass": $fa-var-drafting-compass,
  "compass-slash": $fa-var-compass-slash,
  "compress": $fa-var-compress,
  "compress-wide": $fa-var-compress-wide,
  "computer": $fa-var-computer,
  "computer-classic": $fa-var-computer-classic,
  "computer-mouse": $fa-var-computer-mouse,
  "mouse": $fa-var-mouse,
  "computer-mouse-scrollwheel": $fa-var-computer-mouse-scrollwheel,
  "mouse-alt": $fa-var-mouse-alt,
  "computer-speaker": $fa-var-computer-speaker,
  "container-storage": $fa-var-container-storage,
  "conveyor-belt": $fa-var-conveyor-belt,
  "conveyor-belt-arm": $fa-var-conveyor-belt-arm,
  "conveyor-belt-boxes": $fa-var-conveyor-belt-boxes,
  "conveyor-belt-alt": $fa-var-conveyor-belt-alt,
  "conveyor-belt-empty": $fa-var-conveyor-belt-empty,
  "cookie": $fa-var-cookie,
  "cookie-bite": $fa-var-cookie-bite,
  "copy": $fa-var-copy,
  "copyright": $fa-var-copyright,
  "corn": $fa-var-corn,
  "corner": $fa-var-corner,
  "couch": $fa-var-couch,
  "court-sport": $fa-var-court-sport,
  "cow": $fa-var-cow,
  "cowbell": $fa-var-cowbell,
  "cowbell-circle-plus": $fa-var-cowbell-circle-plus,
  "cowbell-more": $fa-var-cowbell-more,
  "crab": $fa-var-crab,
  "crate-apple": $fa-var-crate-apple,
  "apple-crate": $fa-var-apple-crate,
  "crate-empty": $fa-var-crate-empty,
  "credit-card": $fa-var-credit-card,
  "credit-card-alt": $fa-var-credit-card-alt,
  "credit-card-blank": $fa-var-credit-card-blank,
  "credit-card-front": $fa-var-credit-card-front,
  "cricket-bat-ball": $fa-var-cricket-bat-ball,
  "cricket": $fa-var-cricket,
  "croissant": $fa-var-croissant,
  "crop": $fa-var-crop,
  "crop-simple": $fa-var-crop-simple,
  "crop-alt": $fa-var-crop-alt,
  "cross": $fa-var-cross,
  "crosshairs": $fa-var-crosshairs,
  "crosshairs-simple": $fa-var-crosshairs-simple,
  "crow": $fa-var-crow,
  "crown": $fa-var-crown,
  "crutch": $fa-var-crutch,
  "crutches": $fa-var-crutches,
  "cruzeiro-sign": $fa-var-cruzeiro-sign,
  "crystal-ball": $fa-var-crystal-ball,
  "cube": $fa-var-cube,
  "cubes": $fa-var-cubes,
  "cubes-stacked": $fa-var-cubes-stacked,
  "cucumber": $fa-var-cucumber,
  "cup-straw": $fa-var-cup-straw,
  "cup-straw-swoosh": $fa-var-cup-straw-swoosh,
  "cup-togo": $fa-var-cup-togo,
  "coffee-togo": $fa-var-coffee-togo,
  "cupcake": $fa-var-cupcake,
  "curling-stone": $fa-var-curling-stone,
  "curling": $fa-var-curling,
  "custard": $fa-var-custard,
  "d": $fa-var-d,
  "dagger": $fa-var-dagger,
  "dash": $fa-var-dash,
  "minus-large": $fa-var-minus-large,
  "database": $fa-var-database,
  "deer": $fa-var-deer,
  "deer-rudolph": $fa-var-deer-rudolph,
  "delete-left": $fa-var-delete-left,
  "backspace": $fa-var-backspace,
  "delete-right": $fa-var-delete-right,
  "democrat": $fa-var-democrat,
  "desktop": $fa-var-desktop,
  "desktop-alt": $fa-var-desktop-alt,
  "desktop-arrow-down": $fa-var-desktop-arrow-down,
  "dharmachakra": $fa-var-dharmachakra,
  "diagram-cells": $fa-var-diagram-cells,
  "diagram-lean-canvas": $fa-var-diagram-lean-canvas,
  "diagram-nested": $fa-var-diagram-nested,
  "diagram-next": $fa-var-diagram-next,
  "diagram-predecessor": $fa-var-diagram-predecessor,
  "diagram-previous": $fa-var-diagram-previous,
  "diagram-project": $fa-var-diagram-project,
  "project-diagram": $fa-var-project-diagram,
  "diagram-sankey": $fa-var-diagram-sankey,
  "diagram-subtask": $fa-var-diagram-subtask,
  "diagram-successor": $fa-var-diagram-successor,
  "diagram-venn": $fa-var-diagram-venn,
  "dial": $fa-var-dial,
  "dial-med-high": $fa-var-dial-med-high,
  "dial-high": $fa-var-dial-high,
  "dial-low": $fa-var-dial-low,
  "dial-max": $fa-var-dial-max,
  "dial-med": $fa-var-dial-med,
  "dial-med-low": $fa-var-dial-med-low,
  "dial-min": $fa-var-dial-min,
  "dial-off": $fa-var-dial-off,
  "diamond": $fa-var-diamond,
  "diamond-exclamation": $fa-var-diamond-exclamation,
  "diamond-half": $fa-var-diamond-half,
  "diamond-half-stroke": $fa-var-diamond-half-stroke,
  "diamond-turn-right": $fa-var-diamond-turn-right,
  "directions": $fa-var-directions,
  "dice": $fa-var-dice,
  "dice-d10": $fa-var-dice-d10,
  "dice-d12": $fa-var-dice-d12,
  "dice-d20": $fa-var-dice-d20,
  "dice-d4": $fa-var-dice-d4,
  "dice-d6": $fa-var-dice-d6,
  "dice-d8": $fa-var-dice-d8,
  "dice-five": $fa-var-dice-five,
  "dice-four": $fa-var-dice-four,
  "dice-one": $fa-var-dice-one,
  "dice-six": $fa-var-dice-six,
  "dice-three": $fa-var-dice-three,
  "dice-two": $fa-var-dice-two,
  "dinosaur": $fa-var-dinosaur,
  "diploma": $fa-var-diploma,
  "scroll-ribbon": $fa-var-scroll-ribbon,
  "disc-drive": $fa-var-disc-drive,
  "disease": $fa-var-disease,
  "display": $fa-var-display,
  "display-arrow-down": $fa-var-display-arrow-down,
  "display-chart-up": $fa-var-display-chart-up,
  "display-chart-up-circle-currency": $fa-var-display-chart-up-circle-currency,
  "display-chart-up-circle-dollar": $fa-var-display-chart-up-circle-dollar,
  "display-code": $fa-var-display-code,
  "desktop-code": $fa-var-desktop-code,
  "display-medical": $fa-var-display-medical,
  "desktop-medical": $fa-var-desktop-medical,
  "display-slash": $fa-var-display-slash,
  "desktop-slash": $fa-var-desktop-slash,
  "distribute-spacing-horizontal": $fa-var-distribute-spacing-horizontal,
  "distribute-spacing-vertical": $fa-var-distribute-spacing-vertical,
  "ditto": $fa-var-ditto,
  "divide": $fa-var-divide,
  "dna": $fa-var-dna,
  "do-not-enter": $fa-var-do-not-enter,
  "dog": $fa-var-dog,
  "dog-leashed": $fa-var-dog-leashed,
  "dollar-sign": $fa-var-dollar-sign,
  "dollar": $fa-var-dollar,
  "usd": $fa-var-usd,
  "dolly": $fa-var-dolly,
  "dolly-box": $fa-var-dolly-box,
  "dolly-empty": $fa-var-dolly-empty,
  "dolphin": $fa-var-dolphin,
  "dong-sign": $fa-var-dong-sign,
  "donut": $fa-var-donut,
  "doughnut": $fa-var-doughnut,
  "door-closed": $fa-var-door-closed,
  "door-open": $fa-var-door-open,
  "dove": $fa-var-dove,
  "down": $fa-var-down,
  "arrow-alt-down": $fa-var-arrow-alt-down,
  "down-from-bracket": $fa-var-down-from-bracket,
  "down-from-dotted-line": $fa-var-down-from-dotted-line,
  "down-from-line": $fa-var-down-from-line,
  "arrow-alt-from-top": $fa-var-arrow-alt-from-top,
  "down-left": $fa-var-down-left,
  "down-left-and-up-right-to-center": $fa-var-down-left-and-up-right-to-center,
  "compress-alt": $fa-var-compress-alt,
  "down-long": $fa-var-down-long,
  "long-arrow-alt-down": $fa-var-long-arrow-alt-down,
  "down-right": $fa-var-down-right,
  "down-to-bracket": $fa-var-down-to-bracket,
  "down-to-dotted-line": $fa-var-down-to-dotted-line,
  "down-to-line": $fa-var-down-to-line,
  "arrow-alt-to-bottom": $fa-var-arrow-alt-to-bottom,
  "download": $fa-var-download,
  "dragon": $fa-var-dragon,
  "draw-circle": $fa-var-draw-circle,
  "draw-polygon": $fa-var-draw-polygon,
  "draw-square": $fa-var-draw-square,
  "dreidel": $fa-var-dreidel,
  "drone": $fa-var-drone,
  "drone-front": $fa-var-drone-front,
  "drone-alt": $fa-var-drone-alt,
  "droplet": $fa-var-droplet,
  "tint": $fa-var-tint,
  "droplet-degree": $fa-var-droplet-degree,
  "dewpoint": $fa-var-dewpoint,
  "droplet-percent": $fa-var-droplet-percent,
  "humidity": $fa-var-humidity,
  "droplet-slash": $fa-var-droplet-slash,
  "tint-slash": $fa-var-tint-slash,
  "drum": $fa-var-drum,
  "drum-steelpan": $fa-var-drum-steelpan,
  "drumstick": $fa-var-drumstick,
  "drumstick-bite": $fa-var-drumstick-bite,
  "dryer": $fa-var-dryer,
  "dryer-heat": $fa-var-dryer-heat,
  "dryer-alt": $fa-var-dryer-alt,
  "duck": $fa-var-duck,
  "dumbbell": $fa-var-dumbbell,
  "dumpster": $fa-var-dumpster,
  "dumpster-fire": $fa-var-dumpster-fire,
  "dungeon": $fa-var-dungeon,
  "e": $fa-var-e,
  "ear": $fa-var-ear,
  "ear-deaf": $fa-var-ear-deaf,
  "deaf": $fa-var-deaf,
  "deafness": $fa-var-deafness,
  "hard-of-hearing": $fa-var-hard-of-hearing,
  "ear-listen": $fa-var-ear-listen,
  "assistive-listening-systems": $fa-var-assistive-listening-systems,
  "ear-muffs": $fa-var-ear-muffs,
  "earth-africa": $fa-var-earth-africa,
  "globe-africa": $fa-var-globe-africa,
  "earth-americas": $fa-var-earth-americas,
  "earth": $fa-var-earth,
  "earth-america": $fa-var-earth-america,
  "globe-americas": $fa-var-globe-americas,
  "earth-asia": $fa-var-earth-asia,
  "globe-asia": $fa-var-globe-asia,
  "earth-europe": $fa-var-earth-europe,
  "globe-europe": $fa-var-globe-europe,
  "earth-oceania": $fa-var-earth-oceania,
  "globe-oceania": $fa-var-globe-oceania,
  "eclipse": $fa-var-eclipse,
  "egg": $fa-var-egg,
  "egg-fried": $fa-var-egg-fried,
  "eggplant": $fa-var-eggplant,
  "eject": $fa-var-eject,
  "elephant": $fa-var-elephant,
  "elevator": $fa-var-elevator,
  "ellipsis": $fa-var-ellipsis,
  "ellipsis-h": $fa-var-ellipsis-h,
  "ellipsis-stroke": $fa-var-ellipsis-stroke,
  "ellipsis-h-alt": $fa-var-ellipsis-h-alt,
  "ellipsis-stroke-vertical": $fa-var-ellipsis-stroke-vertical,
  "ellipsis-v-alt": $fa-var-ellipsis-v-alt,
  "ellipsis-vertical": $fa-var-ellipsis-vertical,
  "ellipsis-v": $fa-var-ellipsis-v,
  "empty-set": $fa-var-empty-set,
  "engine": $fa-var-engine,
  "engine-warning": $fa-var-engine-warning,
  "engine-exclamation": $fa-var-engine-exclamation,
  "envelope": $fa-var-envelope,
  "envelope-circle-check": $fa-var-envelope-circle-check,
  "envelope-dot": $fa-var-envelope-dot,
  "envelope-badge": $fa-var-envelope-badge,
  "envelope-open": $fa-var-envelope-open,
  "envelope-open-dollar": $fa-var-envelope-open-dollar,
  "envelope-open-text": $fa-var-envelope-open-text,
  "envelopes": $fa-var-envelopes,
  "envelopes-bulk": $fa-var-envelopes-bulk,
  "mail-bulk": $fa-var-mail-bulk,
  "equals": $fa-var-equals,
  "eraser": $fa-var-eraser,
  "escalator": $fa-var-escalator,
  "ethernet": $fa-var-ethernet,
  "euro-sign": $fa-var-euro-sign,
  "eur": $fa-var-eur,
  "euro": $fa-var-euro,
  "excavator": $fa-var-excavator,
  "exclamation": $fa-var-exclamation,
  "expand": $fa-var-expand,
  "expand-wide": $fa-var-expand-wide,
  "explosion": $fa-var-explosion,
  "eye": $fa-var-eye,
  "eye-dropper": $fa-var-eye-dropper,
  "eye-dropper-empty": $fa-var-eye-dropper-empty,
  "eyedropper": $fa-var-eyedropper,
  "eye-dropper-full": $fa-var-eye-dropper-full,
  "eye-dropper-half": $fa-var-eye-dropper-half,
  "eye-evil": $fa-var-eye-evil,
  "eye-low-vision": $fa-var-eye-low-vision,
  "low-vision": $fa-var-low-vision,
  "eye-slash": $fa-var-eye-slash,
  "eyes": $fa-var-eyes,
  "f": $fa-var-f,
  "face-angry": $fa-var-face-angry,
  "angry": $fa-var-angry,
  "face-angry-horns": $fa-var-face-angry-horns,
  "face-anguished": $fa-var-face-anguished,
  "face-anxious-sweat": $fa-var-face-anxious-sweat,
  "face-astonished": $fa-var-face-astonished,
  "face-awesome": $fa-var-face-awesome,
  "gave-dandy": $fa-var-gave-dandy,
  "face-beam-hand-over-mouth": $fa-var-face-beam-hand-over-mouth,
  "face-clouds": $fa-var-face-clouds,
  "face-confounded": $fa-var-face-confounded,
  "face-confused": $fa-var-face-confused,
  "face-cowboy-hat": $fa-var-face-cowboy-hat,
  "face-diagonal-mouth": $fa-var-face-diagonal-mouth,
  "face-disappointed": $fa-var-face-disappointed,
  "face-disguise": $fa-var-face-disguise,
  "face-dizzy": $fa-var-face-dizzy,
  "dizzy": $fa-var-dizzy,
  "face-dotted": $fa-var-face-dotted,
  "face-downcast-sweat": $fa-var-face-downcast-sweat,
  "face-drooling": $fa-var-face-drooling,
  "face-exhaling": $fa-var-face-exhaling,
  "face-explode": $fa-var-face-explode,
  "exploding-head": $fa-var-exploding-head,
  "face-expressionless": $fa-var-face-expressionless,
  "face-eyes-xmarks": $fa-var-face-eyes-xmarks,
  "face-fearful": $fa-var-face-fearful,
  "face-flushed": $fa-var-face-flushed,
  "flushed": $fa-var-flushed,
  "face-frown": $fa-var-face-frown,
  "frown": $fa-var-frown,
  "face-frown-open": $fa-var-face-frown-open,
  "frown-open": $fa-var-frown-open,
  "face-frown-slight": $fa-var-face-frown-slight,
  "face-glasses": $fa-var-face-glasses,
  "face-grimace": $fa-var-face-grimace,
  "grimace": $fa-var-grimace,
  "face-grin": $fa-var-face-grin,
  "grin": $fa-var-grin,
  "face-grin-beam": $fa-var-face-grin-beam,
  "grin-beam": $fa-var-grin-beam,
  "face-grin-beam-sweat": $fa-var-face-grin-beam-sweat,
  "grin-beam-sweat": $fa-var-grin-beam-sweat,
  "face-grin-hearts": $fa-var-face-grin-hearts,
  "grin-hearts": $fa-var-grin-hearts,
  "face-grin-squint": $fa-var-face-grin-squint,
  "grin-squint": $fa-var-grin-squint,
  "face-grin-squint-tears": $fa-var-face-grin-squint-tears,
  "grin-squint-tears": $fa-var-grin-squint-tears,
  "face-grin-stars": $fa-var-face-grin-stars,
  "grin-stars": $fa-var-grin-stars,
  "face-grin-tears": $fa-var-face-grin-tears,
  "grin-tears": $fa-var-grin-tears,
  "face-grin-tongue": $fa-var-face-grin-tongue,
  "grin-tongue": $fa-var-grin-tongue,
  "face-grin-tongue-squint": $fa-var-face-grin-tongue-squint,
  "grin-tongue-squint": $fa-var-grin-tongue-squint,
  "face-grin-tongue-wink": $fa-var-face-grin-tongue-wink,
  "grin-tongue-wink": $fa-var-grin-tongue-wink,
  "face-grin-wide": $fa-var-face-grin-wide,
  "grin-alt": $fa-var-grin-alt,
  "face-grin-wink": $fa-var-face-grin-wink,
  "grin-wink": $fa-var-grin-wink,
  "face-hand-over-mouth": $fa-var-face-hand-over-mouth,
  "face-hand-peeking": $fa-var-face-hand-peeking,
  "face-hand-yawn": $fa-var-face-hand-yawn,
  "face-head-bandage": $fa-var-face-head-bandage,
  "face-holding-back-tears": $fa-var-face-holding-back-tears,
  "face-hushed": $fa-var-face-hushed,
  "face-icicles": $fa-var-face-icicles,
  "face-kiss": $fa-var-face-kiss,
  "kiss": $fa-var-kiss,
  "face-kiss-beam": $fa-var-face-kiss-beam,
  "kiss-beam": $fa-var-kiss-beam,
  "face-kiss-closed-eyes": $fa-var-face-kiss-closed-eyes,
  "face-kiss-wink-heart": $fa-var-face-kiss-wink-heart,
  "kiss-wink-heart": $fa-var-kiss-wink-heart,
  "face-laugh": $fa-var-face-laugh,
  "laugh": $fa-var-laugh,
  "face-laugh-beam": $fa-var-face-laugh-beam,
  "laugh-beam": $fa-var-laugh-beam,
  "face-laugh-squint": $fa-var-face-laugh-squint,
  "laugh-squint": $fa-var-laugh-squint,
  "face-laugh-wink": $fa-var-face-laugh-wink,
  "laugh-wink": $fa-var-laugh-wink,
  "face-lying": $fa-var-face-lying,
  "face-mask": $fa-var-face-mask,
  "face-meh": $fa-var-face-meh,
  "meh": $fa-var-meh,
  "face-meh-blank": $fa-var-face-meh-blank,
  "meh-blank": $fa-var-meh-blank,
  "face-melting": $fa-var-face-melting,
  "face-monocle": $fa-var-face-monocle,
  "face-nauseated": $fa-var-face-nauseated,
  "face-nose-steam": $fa-var-face-nose-steam,
  "face-party": $fa-var-face-party,
  "face-pensive": $fa-var-face-pensive,
  "face-persevering": $fa-var-face-persevering,
  "face-pleading": $fa-var-face-pleading,
  "face-pouting": $fa-var-face-pouting,
  "face-raised-eyebrow": $fa-var-face-raised-eyebrow,
  "face-relieved": $fa-var-face-relieved,
  "face-rolling-eyes": $fa-var-face-rolling-eyes,
  "meh-rolling-eyes": $fa-var-meh-rolling-eyes,
  "face-sad-cry": $fa-var-face-sad-cry,
  "sad-cry": $fa-var-sad-cry,
  "face-sad-sweat": $fa-var-face-sad-sweat,
  "face-sad-tear": $fa-var-face-sad-tear,
  "sad-tear": $fa-var-sad-tear,
  "face-saluting": $fa-var-face-saluting,
  "face-scream": $fa-var-face-scream,
  "face-shush": $fa-var-face-shush,
  "face-sleeping": $fa-var-face-sleeping,
  "face-sleepy": $fa-var-face-sleepy,
  "face-smile": $fa-var-face-smile,
  "smile": $fa-var-smile,
  "face-smile-beam": $fa-var-face-smile-beam,
  "smile-beam": $fa-var-smile-beam,
  "face-smile-halo": $fa-var-face-smile-halo,
  "face-smile-hearts": $fa-var-face-smile-hearts,
  "face-smile-horns": $fa-var-face-smile-horns,
  "face-smile-plus": $fa-var-face-smile-plus,
  "smile-plus": $fa-var-smile-plus,
  "face-smile-relaxed": $fa-var-face-smile-relaxed,
  "face-smile-tear": $fa-var-face-smile-tear,
  "face-smile-tongue": $fa-var-face-smile-tongue,
  "face-smile-upside-down": $fa-var-face-smile-upside-down,
  "face-smile-wink": $fa-var-face-smile-wink,
  "smile-wink": $fa-var-smile-wink,
  "face-smiling-hands": $fa-var-face-smiling-hands,
  "face-smirking": $fa-var-face-smirking,
  "face-spiral-eyes": $fa-var-face-spiral-eyes,
  "face-sunglasses": $fa-var-face-sunglasses,
  "face-surprise": $fa-var-face-surprise,
  "surprise": $fa-var-surprise,
  "face-swear": $fa-var-face-swear,
  "face-thermometer": $fa-var-face-thermometer,
  "face-thinking": $fa-var-face-thinking,
  "face-tired": $fa-var-face-tired,
  "tired": $fa-var-tired,
  "face-tissue": $fa-var-face-tissue,
  "face-tongue-money": $fa-var-face-tongue-money,
  "face-tongue-sweat": $fa-var-face-tongue-sweat,
  "face-unamused": $fa-var-face-unamused,
  "face-viewfinder": $fa-var-face-viewfinder,
  "face-vomit": $fa-var-face-vomit,
  "face-weary": $fa-var-face-weary,
  "face-woozy": $fa-var-face-woozy,
  "face-worried": $fa-var-face-worried,
  "face-zany": $fa-var-face-zany,
  "face-zipper": $fa-var-face-zipper,
  "falafel": $fa-var-falafel,
  "family": $fa-var-family,
  "family-dress": $fa-var-family-dress,
  "family-pants": $fa-var-family-pants,
  "fan": $fa-var-fan,
  "fan-table": $fa-var-fan-table,
  "farm": $fa-var-farm,
  "barn-silo": $fa-var-barn-silo,
  "faucet": $fa-var-faucet,
  "faucet-drip": $fa-var-faucet-drip,
  "fax": $fa-var-fax,
  "feather": $fa-var-feather,
  "feather-pointed": $fa-var-feather-pointed,
  "feather-alt": $fa-var-feather-alt,
  "fence": $fa-var-fence,
  "ferris-wheel": $fa-var-ferris-wheel,
  "ferry": $fa-var-ferry,
  "field-hockey-stick-ball": $fa-var-field-hockey-stick-ball,
  "field-hockey": $fa-var-field-hockey,
  "file": $fa-var-file,
  "file-arrow-down": $fa-var-file-arrow-down,
  "file-download": $fa-var-file-download,
  "file-arrow-up": $fa-var-file-arrow-up,
  "file-upload": $fa-var-file-upload,
  "file-audio": $fa-var-file-audio,
  "file-binary": $fa-var-file-binary,
  "file-cad": $fa-var-file-cad,
  "file-certificate": $fa-var-file-certificate,
  "file-award": $fa-var-file-award,
  "file-chart-column": $fa-var-file-chart-column,
  "file-chart-line": $fa-var-file-chart-line,
  "file-chart-pie": $fa-var-file-chart-pie,
  "file-check": $fa-var-file-check,
  "file-circle-check": $fa-var-file-circle-check,
  "file-circle-exclamation": $fa-var-file-circle-exclamation,
  "file-circle-info": $fa-var-file-circle-info,
  "file-circle-minus": $fa-var-file-circle-minus,
  "file-circle-plus": $fa-var-file-circle-plus,
  "file-circle-question": $fa-var-file-circle-question,
  "file-circle-xmark": $fa-var-file-circle-xmark,
  "file-code": $fa-var-file-code,
  "file-contract": $fa-var-file-contract,
  "file-csv": $fa-var-file-csv,
  "file-dashed-line": $fa-var-file-dashed-line,
  "page-break": $fa-var-page-break,
  "file-doc": $fa-var-file-doc,
  "file-eps": $fa-var-file-eps,
  "file-excel": $fa-var-file-excel,
  "file-exclamation": $fa-var-file-exclamation,
  "file-export": $fa-var-file-export,
  "arrow-right-from-file": $fa-var-arrow-right-from-file,
  "file-gif": $fa-var-file-gif,
  "file-heart": $fa-var-file-heart,
  "file-image": $fa-var-file-image,
  "file-import": $fa-var-file-import,
  "arrow-right-to-file": $fa-var-arrow-right-to-file,
  "file-invoice": $fa-var-file-invoice,
  "file-invoice-dollar": $fa-var-file-invoice-dollar,
  "file-jpg": $fa-var-file-jpg,
  "file-lines": $fa-var-file-lines,
  "file-alt": $fa-var-file-alt,
  "file-text": $fa-var-file-text,
  "file-lock": $fa-var-file-lock,
  "file-magnifying-glass": $fa-var-file-magnifying-glass,
  "file-search": $fa-var-file-search,
  "file-medical": $fa-var-file-medical,
  "file-minus": $fa-var-file-minus,
  "file-mov": $fa-var-file-mov,
  "file-mp3": $fa-var-file-mp3,
  "file-mp4": $fa-var-file-mp4,
  "file-music": $fa-var-file-music,
  "file-pdf": $fa-var-file-pdf,
  "file-pen": $fa-var-file-pen,
  "file-edit": $fa-var-file-edit,
  "file-plus": $fa-var-file-plus,
  "file-plus-minus": $fa-var-file-plus-minus,
  "file-png": $fa-var-file-png,
  "file-powerpoint": $fa-var-file-powerpoint,
  "file-ppt": $fa-var-file-ppt,
  "file-prescription": $fa-var-file-prescription,
  "file-shield": $fa-var-file-shield,
  "file-signature": $fa-var-file-signature,
  "file-slash": $fa-var-file-slash,
  "file-spreadsheet": $fa-var-file-spreadsheet,
  "file-svg": $fa-var-file-svg,
  "file-user": $fa-var-file-user,
  "file-vector": $fa-var-file-vector,
  "file-video": $fa-var-file-video,
  "file-waveform": $fa-var-file-waveform,
  "file-medical-alt": $fa-var-file-medical-alt,
  "file-word": $fa-var-file-word,
  "file-xls": $fa-var-file-xls,
  "file-xmark": $fa-var-file-xmark,
  "file-times": $fa-var-file-times,
  "file-xml": $fa-var-file-xml,
  "file-zip": $fa-var-file-zip,
  "file-zipper": $fa-var-file-zipper,
  "file-archive": $fa-var-file-archive,
  "files": $fa-var-files,
  "files-medical": $fa-var-files-medical,
  "fill": $fa-var-fill,
  "fill-drip": $fa-var-fill-drip,
  "film": $fa-var-film,
  "film-canister": $fa-var-film-canister,
  "film-cannister": $fa-var-film-cannister,
  "film-simple": $fa-var-film-simple,
  "film-alt": $fa-var-film-alt,
  "film-slash": $fa-var-film-slash,
  "films": $fa-var-films,
  "filter": $fa-var-filter,
  "filter-circle-dollar": $fa-var-filter-circle-dollar,
  "funnel-dollar": $fa-var-funnel-dollar,
  "filter-circle-xmark": $fa-var-filter-circle-xmark,
  "filter-list": $fa-var-filter-list,
  "filter-slash": $fa-var-filter-slash,
  "filters": $fa-var-filters,
  "fingerprint": $fa-var-fingerprint,
  "fire": $fa-var-fire,
  "fire-burner": $fa-var-fire-burner,
  "fire-extinguisher": $fa-var-fire-extinguisher,
  "fire-flame": $fa-var-fire-flame,
  "flame": $fa-var-flame,
  "fire-flame-curved": $fa-var-fire-flame-curved,
  "fire-alt": $fa-var-fire-alt,
  "fire-flame-simple": $fa-var-fire-flame-simple,
  "burn": $fa-var-burn,
  "fire-hydrant": $fa-var-fire-hydrant,
  "fire-smoke": $fa-var-fire-smoke,
  "fireplace": $fa-var-fireplace,
  "fish": $fa-var-fish,
  "fish-bones": $fa-var-fish-bones,
  "fish-cooked": $fa-var-fish-cooked,
  "fish-fins": $fa-var-fish-fins,
  "fishing-rod": $fa-var-fishing-rod,
  "flag": $fa-var-flag,
  "flag-checkered": $fa-var-flag-checkered,
  "flag-pennant": $fa-var-flag-pennant,
  "pennant": $fa-var-pennant,
  "flag-swallowtail": $fa-var-flag-swallowtail,
  "flag-alt": $fa-var-flag-alt,
  "flag-usa": $fa-var-flag-usa,
  "flashlight": $fa-var-flashlight,
  "flask": $fa-var-flask,
  "flask-gear": $fa-var-flask-gear,
  "flask-round-poison": $fa-var-flask-round-poison,
  "flask-poison": $fa-var-flask-poison,
  "flask-round-potion": $fa-var-flask-round-potion,
  "flask-potion": $fa-var-flask-potion,
  "flask-vial": $fa-var-flask-vial,
  "flatbread": $fa-var-flatbread,
  "flatbread-stuffed": $fa-var-flatbread-stuffed,
  "floppy-disk": $fa-var-floppy-disk,
  "save": $fa-var-save,
  "floppy-disk-circle-arrow-right": $fa-var-floppy-disk-circle-arrow-right,
  "save-circle-arrow-right": $fa-var-save-circle-arrow-right,
  "floppy-disk-circle-xmark": $fa-var-floppy-disk-circle-xmark,
  "floppy-disk-times": $fa-var-floppy-disk-times,
  "save-circle-xmark": $fa-var-save-circle-xmark,
  "save-times": $fa-var-save-times,
  "floppy-disk-pen": $fa-var-floppy-disk-pen,
  "floppy-disks": $fa-var-floppy-disks,
  "florin-sign": $fa-var-florin-sign,
  "flower": $fa-var-flower,
  "flower-daffodil": $fa-var-flower-daffodil,
  "flower-tulip": $fa-var-flower-tulip,
  "flute": $fa-var-flute,
  "flux-capacitor": $fa-var-flux-capacitor,
  "flying-disc": $fa-var-flying-disc,
  "folder": $fa-var-folder,
  "folder-blank": $fa-var-folder-blank,
  "folder-arrow-down": $fa-var-folder-arrow-down,
  "folder-download": $fa-var-folder-download,
  "folder-arrow-up": $fa-var-folder-arrow-up,
  "folder-upload": $fa-var-folder-upload,
  "folder-bookmark": $fa-var-folder-bookmark,
  "folder-check": $fa-var-folder-check,
  "folder-closed": $fa-var-folder-closed,
  "folder-gear": $fa-var-folder-gear,
  "folder-cog": $fa-var-folder-cog,
  "folder-grid": $fa-var-folder-grid,
  "folder-heart": $fa-var-folder-heart,
  "folder-image": $fa-var-folder-image,
  "folder-magnifying-glass": $fa-var-folder-magnifying-glass,
  "folder-search": $fa-var-folder-search,
  "folder-medical": $fa-var-folder-medical,
  "folder-minus": $fa-var-folder-minus,
  "folder-music": $fa-var-folder-music,
  "folder-open": $fa-var-folder-open,
  "folder-plus": $fa-var-folder-plus,
  "folder-tree": $fa-var-folder-tree,
  "folder-user": $fa-var-folder-user,
  "folder-xmark": $fa-var-folder-xmark,
  "folder-times": $fa-var-folder-times,
  "folders": $fa-var-folders,
  "fondue-pot": $fa-var-fondue-pot,
  "font": $fa-var-font,
  "font-case": $fa-var-font-case,
  "football": $fa-var-football,
  "football-ball": $fa-var-football-ball,
  "football-helmet": $fa-var-football-helmet,
  "fork": $fa-var-fork,
  "utensil-fork": $fa-var-utensil-fork,
  "fork-knife": $fa-var-fork-knife,
  "utensils-alt": $fa-var-utensils-alt,
  "forklift": $fa-var-forklift,
  "fort": $fa-var-fort,
  "forward": $fa-var-forward,
  "forward-fast": $fa-var-forward-fast,
  "fast-forward": $fa-var-fast-forward,
  "forward-step": $fa-var-forward-step,
  "step-forward": $fa-var-step-forward,
  "frame": $fa-var-frame,
  "franc-sign": $fa-var-franc-sign,
  "french-fries": $fa-var-french-fries,
  "frog": $fa-var-frog,
  "function": $fa-var-function,
  "futbol": $fa-var-futbol,
  "futbol-ball": $fa-var-futbol-ball,
  "soccer-ball": $fa-var-soccer-ball,
  "g": $fa-var-g,
  "galaxy": $fa-var-galaxy,
  "gallery-thumbnails": $fa-var-gallery-thumbnails,
  "game-board": $fa-var-game-board,
  "game-board-simple": $fa-var-game-board-simple,
  "game-board-alt": $fa-var-game-board-alt,
  "game-console-handheld": $fa-var-game-console-handheld,
  "game-console-handheld-crank": $fa-var-game-console-handheld-crank,
  "gamepad": $fa-var-gamepad,
  "gamepad-modern": $fa-var-gamepad-modern,
  "gamepad-alt": $fa-var-gamepad-alt,
  "garage": $fa-var-garage,
  "garage-car": $fa-var-garage-car,
  "garage-open": $fa-var-garage-open,
  "garlic": $fa-var-garlic,
  "gas-pump": $fa-var-gas-pump,
  "gas-pump-slash": $fa-var-gas-pump-slash,
  "gauge": $fa-var-gauge,
  "dashboard": $fa-var-dashboard,
  "gauge-med": $fa-var-gauge-med,
  "tachometer-alt-average": $fa-var-tachometer-alt-average,
  "gauge-circle-bolt": $fa-var-gauge-circle-bolt,
  "gauge-circle-minus": $fa-var-gauge-circle-minus,
  "gauge-circle-plus": $fa-var-gauge-circle-plus,
  "gauge-high": $fa-var-gauge-high,
  "tachometer-alt": $fa-var-tachometer-alt,
  "tachometer-alt-fast": $fa-var-tachometer-alt-fast,
  "gauge-low": $fa-var-gauge-low,
  "tachometer-alt-slow": $fa-var-tachometer-alt-slow,
  "gauge-max": $fa-var-gauge-max,
  "tachometer-alt-fastest": $fa-var-tachometer-alt-fastest,
  "gauge-min": $fa-var-gauge-min,
  "tachometer-alt-slowest": $fa-var-tachometer-alt-slowest,
  "gauge-simple": $fa-var-gauge-simple,
  "gauge-simple-med": $fa-var-gauge-simple-med,
  "tachometer-average": $fa-var-tachometer-average,
  "gauge-simple-high": $fa-var-gauge-simple-high,
  "tachometer": $fa-var-tachometer,
  "tachometer-fast": $fa-var-tachometer-fast,
  "gauge-simple-low": $fa-var-gauge-simple-low,
  "tachometer-slow": $fa-var-tachometer-slow,
  "gauge-simple-max": $fa-var-gauge-simple-max,
  "tachometer-fastest": $fa-var-tachometer-fastest,
  "gauge-simple-min": $fa-var-gauge-simple-min,
  "tachometer-slowest": $fa-var-tachometer-slowest,
  "gavel": $fa-var-gavel,
  "legal": $fa-var-legal,
  "gear": $fa-var-gear,
  "cog": $fa-var-cog,
  "gear-code": $fa-var-gear-code,
  "gear-complex": $fa-var-gear-complex,
  "gear-complex-code": $fa-var-gear-complex-code,
  "gears": $fa-var-gears,
  "cogs": $fa-var-cogs,
  "gem": $fa-var-gem,
  "genderless": $fa-var-genderless,
  "ghost": $fa-var-ghost,
  "gif": $fa-var-gif,
  "gift": $fa-var-gift,
  "gift-card": $fa-var-gift-card,
  "gifts": $fa-var-gifts,
  "gingerbread-man": $fa-var-gingerbread-man,
  "glass": $fa-var-glass,
  "glass-citrus": $fa-var-glass-citrus,
  "glass-empty": $fa-var-glass-empty,
  "glass-half": $fa-var-glass-half,
  "glass-half-empty": $fa-var-glass-half-empty,
  "glass-half-full": $fa-var-glass-half-full,
  "glass-water": $fa-var-glass-water,
  "glass-water-droplet": $fa-var-glass-water-droplet,
  "glasses": $fa-var-glasses,
  "glasses-round": $fa-var-glasses-round,
  "glasses-alt": $fa-var-glasses-alt,
  "globe": $fa-var-globe,
  "globe-pointer": $fa-var-globe-pointer,
  "globe-snow": $fa-var-globe-snow,
  "globe-stand": $fa-var-globe-stand,
  "goal-net": $fa-var-goal-net,
  "golf-ball-tee": $fa-var-golf-ball-tee,
  "golf-ball": $fa-var-golf-ball,
  "golf-club": $fa-var-golf-club,
  "golf-flag-hole": $fa-var-golf-flag-hole,
  "gopuram": $fa-var-gopuram,
  "graduation-cap": $fa-var-graduation-cap,
  "mortar-board": $fa-var-mortar-board,
  "gramophone": $fa-var-gramophone,
  "grapes": $fa-var-grapes,
  "grate": $fa-var-grate,
  "grate-droplet": $fa-var-grate-droplet,
  "greater-than": $fa-var-greater-than,
  "greater-than-equal": $fa-var-greater-than-equal,
  "grid": $fa-var-grid,
  "grid-3": $fa-var-grid-3,
  "grid-2": $fa-var-grid-2,
  "grid-2-plus": $fa-var-grid-2-plus,
  "grid-4": $fa-var-grid-4,
  "grid-5": $fa-var-grid-5,
  "grid-dividers": $fa-var-grid-dividers,
  "grid-horizontal": $fa-var-grid-horizontal,
  "grid-round": $fa-var-grid-round,
  "grid-round-2": $fa-var-grid-round-2,
  "grid-round-2-plus": $fa-var-grid-round-2-plus,
  "grid-round-4": $fa-var-grid-round-4,
  "grid-round-5": $fa-var-grid-round-5,
  "grill": $fa-var-grill,
  "grill-fire": $fa-var-grill-fire,
  "grill-hot": $fa-var-grill-hot,
  "grip": $fa-var-grip,
  "grip-horizontal": $fa-var-grip-horizontal,
  "grip-dots": $fa-var-grip-dots,
  "grip-dots-vertical": $fa-var-grip-dots-vertical,
  "grip-lines": $fa-var-grip-lines,
  "grip-lines-vertical": $fa-var-grip-lines-vertical,
  "grip-vertical": $fa-var-grip-vertical,
  "group-arrows-rotate": $fa-var-group-arrows-rotate,
  "guarani-sign": $fa-var-guarani-sign,
  "guitar": $fa-var-guitar,
  "guitar-electric": $fa-var-guitar-electric,
  "guitars": $fa-var-guitars,
  "gun": $fa-var-gun,
  "gun-slash": $fa-var-gun-slash,
  "gun-squirt": $fa-var-gun-squirt,
  "h": $fa-var-h,
  "h1": $fa-var-h1,
  "h2": $fa-var-h2,
  "h3": $fa-var-h3,
  "h4": $fa-var-h4,
  "h5": $fa-var-h5,
  "h6": $fa-var-h6,
  "hammer": $fa-var-hammer,
  "hammer-brush": $fa-var-hammer-brush,
  "hammer-crash": $fa-var-hammer-crash,
  "hammer-war": $fa-var-hammer-war,
  "hamsa": $fa-var-hamsa,
  "hand": $fa-var-hand,
  "hand-paper": $fa-var-hand-paper,
  "hand-back-fist": $fa-var-hand-back-fist,
  "hand-rock": $fa-var-hand-rock,
  "hand-back-point-down": $fa-var-hand-back-point-down,
  "hand-back-point-left": $fa-var-hand-back-point-left,
  "hand-back-point-ribbon": $fa-var-hand-back-point-ribbon,
  "hand-back-point-right": $fa-var-hand-back-point-right,
  "hand-back-point-up": $fa-var-hand-back-point-up,
  "hand-dots": $fa-var-hand-dots,
  "allergies": $fa-var-allergies,
  "hand-fingers-crossed": $fa-var-hand-fingers-crossed,
  "hand-fist": $fa-var-hand-fist,
  "fist-raised": $fa-var-fist-raised,
  "hand-heart": $fa-var-hand-heart,
  "hand-holding": $fa-var-hand-holding,
  "hand-holding-box": $fa-var-hand-holding-box,
  "hand-holding-circle-dollar": $fa-var-hand-holding-circle-dollar,
  "hand-holding-dollar": $fa-var-hand-holding-dollar,
  "hand-holding-usd": $fa-var-hand-holding-usd,
  "hand-holding-droplet": $fa-var-hand-holding-droplet,
  "hand-holding-water": $fa-var-hand-holding-water,
  "hand-holding-hand": $fa-var-hand-holding-hand,
  "hand-holding-heart": $fa-var-hand-holding-heart,
  "hand-holding-magic": $fa-var-hand-holding-magic,
  "hand-holding-medical": $fa-var-hand-holding-medical,
  "hand-holding-seedling": $fa-var-hand-holding-seedling,
  "hand-holding-skull": $fa-var-hand-holding-skull,
  "hand-horns": $fa-var-hand-horns,
  "hand-lizard": $fa-var-hand-lizard,
  "hand-love": $fa-var-hand-love,
  "hand-middle-finger": $fa-var-hand-middle-finger,
  "hand-peace": $fa-var-hand-peace,
  "hand-point-down": $fa-var-hand-point-down,
  "hand-point-left": $fa-var-hand-point-left,
  "hand-point-ribbon": $fa-var-hand-point-ribbon,
  "hand-point-right": $fa-var-hand-point-right,
  "hand-point-up": $fa-var-hand-point-up,
  "hand-pointer": $fa-var-hand-pointer,
  "hand-scissors": $fa-var-hand-scissors,
  "hand-sparkles": $fa-var-hand-sparkles,
  "hand-spock": $fa-var-hand-spock,
  "hand-wave": $fa-var-hand-wave,
  "handcuffs": $fa-var-handcuffs,
  "hands": $fa-var-hands,
  "sign-language": $fa-var-sign-language,
  "signing": $fa-var-signing,
  "hands-asl-interpreting": $fa-var-hands-asl-interpreting,
  "american-sign-language-interpreting": $fa-var-american-sign-language-interpreting,
  "asl-interpreting": $fa-var-asl-interpreting,
  "hands-american-sign-language-interpreting": $fa-var-hands-american-sign-language-interpreting,
  "hands-bound": $fa-var-hands-bound,
  "hands-bubbles": $fa-var-hands-bubbles,
  "hands-wash": $fa-var-hands-wash,
  "hands-clapping": $fa-var-hands-clapping,
  "hands-holding": $fa-var-hands-holding,
  "hands-holding-child": $fa-var-hands-holding-child,
  "hands-holding-circle": $fa-var-hands-holding-circle,
  "hands-holding-diamond": $fa-var-hands-holding-diamond,
  "hand-receiving": $fa-var-hand-receiving,
  "hands-holding-dollar": $fa-var-hands-holding-dollar,
  "hands-usd": $fa-var-hands-usd,
  "hands-holding-heart": $fa-var-hands-holding-heart,
  "hands-heart": $fa-var-hands-heart,
  "hands-praying": $fa-var-hands-praying,
  "praying-hands": $fa-var-praying-hands,
  "handshake": $fa-var-handshake,
  "handshake-angle": $fa-var-handshake-angle,
  "hands-helping": $fa-var-hands-helping,
  "handshake-simple": $fa-var-handshake-simple,
  "handshake-alt": $fa-var-handshake-alt,
  "handshake-simple-slash": $fa-var-handshake-simple-slash,
  "handshake-alt-slash": $fa-var-handshake-alt-slash,
  "handshake-slash": $fa-var-handshake-slash,
  "hanukiah": $fa-var-hanukiah,
  "hard-drive": $fa-var-hard-drive,
  "hdd": $fa-var-hdd,
  "hashtag": $fa-var-hashtag,
  "hashtag-lock": $fa-var-hashtag-lock,
  "hat-beach": $fa-var-hat-beach,
  "hat-chef": $fa-var-hat-chef,
  "hat-cowboy": $fa-var-hat-cowboy,
  "hat-cowboy-side": $fa-var-hat-cowboy-side,
  "hat-santa": $fa-var-hat-santa,
  "hat-winter": $fa-var-hat-winter,
  "hat-witch": $fa-var-hat-witch,
  "hat-wizard": $fa-var-hat-wizard,
  "head-side": $fa-var-head-side,
  "head-side-brain": $fa-var-head-side-brain,
  "head-side-cough": $fa-var-head-side-cough,
  "head-side-cough-slash": $fa-var-head-side-cough-slash,
  "head-side-gear": $fa-var-head-side-gear,
  "head-side-goggles": $fa-var-head-side-goggles,
  "head-vr": $fa-var-head-vr,
  "head-side-headphones": $fa-var-head-side-headphones,
  "head-side-heart": $fa-var-head-side-heart,
  "head-side-mask": $fa-var-head-side-mask,
  "head-side-medical": $fa-var-head-side-medical,
  "head-side-virus": $fa-var-head-side-virus,
  "heading": $fa-var-heading,
  "header": $fa-var-header,
  "headphones": $fa-var-headphones,
  "headphones-simple": $fa-var-headphones-simple,
  "headphones-alt": $fa-var-headphones-alt,
  "headset": $fa-var-headset,
  "heart": $fa-var-heart,
  "heart-circle-bolt": $fa-var-heart-circle-bolt,
  "heart-circle-check": $fa-var-heart-circle-check,
  "heart-circle-exclamation": $fa-var-heart-circle-exclamation,
  "heart-circle-minus": $fa-var-heart-circle-minus,
  "heart-circle-plus": $fa-var-heart-circle-plus,
  "heart-circle-xmark": $fa-var-heart-circle-xmark,
  "heart-crack": $fa-var-heart-crack,
  "heart-broken": $fa-var-heart-broken,
  "heart-half": $fa-var-heart-half,
  "heart-half-stroke": $fa-var-heart-half-stroke,
  "heart-half-alt": $fa-var-heart-half-alt,
  "heart-pulse": $fa-var-heart-pulse,
  "heartbeat": $fa-var-heartbeat,
  "heat": $fa-var-heat,
  "helicopter": $fa-var-helicopter,
  "helicopter-symbol": $fa-var-helicopter-symbol,
  "helmet-battle": $fa-var-helmet-battle,
  "helmet-safety": $fa-var-helmet-safety,
  "hard-hat": $fa-var-hard-hat,
  "hat-hard": $fa-var-hat-hard,
  "helmet-un": $fa-var-helmet-un,
  "hexagon": $fa-var-hexagon,
  "hexagon-check": $fa-var-hexagon-check,
  "hexagon-divide": $fa-var-hexagon-divide,
  "hexagon-exclamation": $fa-var-hexagon-exclamation,
  "hexagon-image": $fa-var-hexagon-image,
  "hexagon-minus": $fa-var-hexagon-minus,
  "minus-hexagon": $fa-var-minus-hexagon,
  "hexagon-plus": $fa-var-hexagon-plus,
  "plus-hexagon": $fa-var-plus-hexagon,
  "hexagon-vertical-nft": $fa-var-hexagon-vertical-nft,
  "hexagon-vertical-nft-slanted": $fa-var-hexagon-vertical-nft-slanted,
  "hexagon-xmark": $fa-var-hexagon-xmark,
  "times-hexagon": $fa-var-times-hexagon,
  "xmark-hexagon": $fa-var-xmark-hexagon,
  "high-definition": $fa-var-high-definition,
  "rectangle-hd": $fa-var-rectangle-hd,
  "highlighter": $fa-var-highlighter,
  "highlighter-line": $fa-var-highlighter-line,
  "hill-avalanche": $fa-var-hill-avalanche,
  "hill-rockslide": $fa-var-hill-rockslide,
  "hippo": $fa-var-hippo,
  "hockey-mask": $fa-var-hockey-mask,
  "hockey-puck": $fa-var-hockey-puck,
  "hockey-stick-puck": $fa-var-hockey-stick-puck,
  "hockey-sticks": $fa-var-hockey-sticks,
  "holly-berry": $fa-var-holly-berry,
  "honey-pot": $fa-var-honey-pot,
  "hood-cloak": $fa-var-hood-cloak,
  "horizontal-rule": $fa-var-horizontal-rule,
  "horse": $fa-var-horse,
  "horse-head": $fa-var-horse-head,
  "horse-saddle": $fa-var-horse-saddle,
  "hose": $fa-var-hose,
  "hose-reel": $fa-var-hose-reel,
  "hospital": $fa-var-hospital,
  "hospital-alt": $fa-var-hospital-alt,
  "hospital-wide": $fa-var-hospital-wide,
  "hospital-user": $fa-var-hospital-user,
  "hospitals": $fa-var-hospitals,
  "hot-tub-person": $fa-var-hot-tub-person,
  "hot-tub": $fa-var-hot-tub,
  "hotdog": $fa-var-hotdog,
  "hotel": $fa-var-hotel,
  "hourglass": $fa-var-hourglass,
  "hourglass-empty": $fa-var-hourglass-empty,
  "hourglass-clock": $fa-var-hourglass-clock,
  "hourglass-end": $fa-var-hourglass-end,
  "hourglass-3": $fa-var-hourglass-3,
  "hourglass-half": $fa-var-hourglass-half,
  "hourglass-2": $fa-var-hourglass-2,
  "hourglass-start": $fa-var-hourglass-start,
  "hourglass-1": $fa-var-hourglass-1,
  "house": $fa-var-house,
  "home": $fa-var-home,
  "home-alt": $fa-var-home-alt,
  "home-lg-alt": $fa-var-home-lg-alt,
  "house-blank": $fa-var-house-blank,
  "home-blank": $fa-var-home-blank,
  "house-building": $fa-var-house-building,
  "house-chimney": $fa-var-house-chimney,
  "home-lg": $fa-var-home-lg,
  "house-chimney-blank": $fa-var-house-chimney-blank,
  "house-chimney-crack": $fa-var-house-chimney-crack,
  "house-damage": $fa-var-house-damage,
  "house-chimney-heart": $fa-var-house-chimney-heart,
  "house-chimney-medical": $fa-var-house-chimney-medical,
  "clinic-medical": $fa-var-clinic-medical,
  "house-chimney-user": $fa-var-house-chimney-user,
  "house-chimney-window": $fa-var-house-chimney-window,
  "house-circle-check": $fa-var-house-circle-check,
  "house-circle-exclamation": $fa-var-house-circle-exclamation,
  "house-circle-xmark": $fa-var-house-circle-xmark,
  "house-crack": $fa-var-house-crack,
  "house-day": $fa-var-house-day,
  "house-fire": $fa-var-house-fire,
  "house-flag": $fa-var-house-flag,
  "house-flood-water": $fa-var-house-flood-water,
  "house-flood-water-circle-arrow-right": $fa-var-house-flood-water-circle-arrow-right,
  "house-heart": $fa-var-house-heart,
  "home-heart": $fa-var-home-heart,
  "house-laptop": $fa-var-house-laptop,
  "laptop-house": $fa-var-laptop-house,
  "house-lock": $fa-var-house-lock,
  "house-medical": $fa-var-house-medical,
  "house-medical-circle-check": $fa-var-house-medical-circle-check,
  "house-medical-circle-exclamation": $fa-var-house-medical-circle-exclamation,
  "house-medical-circle-xmark": $fa-var-house-medical-circle-xmark,
  "house-medical-flag": $fa-var-house-medical-flag,
  "house-night": $fa-var-house-night,
  "house-person-leave": $fa-var-house-person-leave,
  "house-leave": $fa-var-house-leave,
  "house-person-depart": $fa-var-house-person-depart,
  "house-person-return": $fa-var-house-person-return,
  "house-person-arrive": $fa-var-house-person-arrive,
  "house-return": $fa-var-house-return,
  "house-signal": $fa-var-house-signal,
  "house-tree": $fa-var-house-tree,
  "house-tsunami": $fa-var-house-tsunami,
  "house-turret": $fa-var-house-turret,
  "house-user": $fa-var-house-user,
  "home-user": $fa-var-home-user,
  "house-water": $fa-var-house-water,
  "house-flood": $fa-var-house-flood,
  "house-window": $fa-var-house-window,
  "hryvnia-sign": $fa-var-hryvnia-sign,
  "hryvnia": $fa-var-hryvnia,
  "hundred-points": $fa-var-hundred-points,
  "100": $fa-var-100,
  "hurricane": $fa-var-hurricane,
  "hyphen": $fa-var-hyphen,
  "i": $fa-var-i,
  "i-cursor": $fa-var-i-cursor,
  "ice-cream": $fa-var-ice-cream,
  "ice-skate": $fa-var-ice-skate,
  "icicles": $fa-var-icicles,
  "icons": $fa-var-icons,
  "heart-music-camera-bolt": $fa-var-heart-music-camera-bolt,
  "id-badge": $fa-var-id-badge,
  "id-card": $fa-var-id-card,
  "drivers-license": $fa-var-drivers-license,
  "id-card-clip": $fa-var-id-card-clip,
  "id-card-alt": $fa-var-id-card-alt,
  "igloo": $fa-var-igloo,
  "image": $fa-var-image,
  "image-landscape": $fa-var-image-landscape,
  "landscape": $fa-var-landscape,
  "image-polaroid": $fa-var-image-polaroid,
  "image-polaroid-user": $fa-var-image-polaroid-user,
  "image-portrait": $fa-var-image-portrait,
  "portrait": $fa-var-portrait,
  "image-slash": $fa-var-image-slash,
  "image-user": $fa-var-image-user,
  "images": $fa-var-images,
  "images-user": $fa-var-images-user,
  "inbox": $fa-var-inbox,
  "inbox-full": $fa-var-inbox-full,
  "inbox-in": $fa-var-inbox-in,
  "inbox-arrow-down": $fa-var-inbox-arrow-down,
  "inbox-out": $fa-var-inbox-out,
  "inbox-arrow-up": $fa-var-inbox-arrow-up,
  "inboxes": $fa-var-inboxes,
  "indent": $fa-var-indent,
  "indian-rupee-sign": $fa-var-indian-rupee-sign,
  "indian-rupee": $fa-var-indian-rupee,
  "inr": $fa-var-inr,
  "industry": $fa-var-industry,
  "industry-windows": $fa-var-industry-windows,
  "industry-alt": $fa-var-industry-alt,
  "infinity": $fa-var-infinity,
  "info": $fa-var-info,
  "inhaler": $fa-var-inhaler,
  "input-numeric": $fa-var-input-numeric,
  "input-pipe": $fa-var-input-pipe,
  "input-text": $fa-var-input-text,
  "integral": $fa-var-integral,
  "interrobang": $fa-var-interrobang,
  "intersection": $fa-var-intersection,
  "island-tropical": $fa-var-island-tropical,
  "island-tree-palm": $fa-var-island-tree-palm,
  "italic": $fa-var-italic,
  "j": $fa-var-j,
  "jack-o-lantern": $fa-var-jack-o-lantern,
  "jar": $fa-var-jar,
  "jar-wheat": $fa-var-jar-wheat,
  "jedi": $fa-var-jedi,
  "jet-fighter": $fa-var-jet-fighter,
  "fighter-jet": $fa-var-fighter-jet,
  "jet-fighter-up": $fa-var-jet-fighter-up,
  "joint": $fa-var-joint,
  "joystick": $fa-var-joystick,
  "jug": $fa-var-jug,
  "jug-bottle": $fa-var-jug-bottle,
  "jug-detergent": $fa-var-jug-detergent,
  "k": $fa-var-k,
  "kaaba": $fa-var-kaaba,
  "kazoo": $fa-var-kazoo,
  "kerning": $fa-var-kerning,
  "key": $fa-var-key,
  "key-skeleton": $fa-var-key-skeleton,
  "key-skeleton-left-right": $fa-var-key-skeleton-left-right,
  "keyboard": $fa-var-keyboard,
  "keyboard-brightness": $fa-var-keyboard-brightness,
  "keyboard-brightness-low": $fa-var-keyboard-brightness-low,
  "keyboard-down": $fa-var-keyboard-down,
  "keyboard-left": $fa-var-keyboard-left,
  "keynote": $fa-var-keynote,
  "khanda": $fa-var-khanda,
  "kidneys": $fa-var-kidneys,
  "kip-sign": $fa-var-kip-sign,
  "kit-medical": $fa-var-kit-medical,
  "first-aid": $fa-var-first-aid,
  "kitchen-set": $fa-var-kitchen-set,
  "kite": $fa-var-kite,
  "kiwi-bird": $fa-var-kiwi-bird,
  "kiwi-fruit": $fa-var-kiwi-fruit,
  "knife": $fa-var-knife,
  "utensil-knife": $fa-var-utensil-knife,
  "knife-kitchen": $fa-var-knife-kitchen,
  "l": $fa-var-l,
  "lacrosse-stick": $fa-var-lacrosse-stick,
  "lacrosse-stick-ball": $fa-var-lacrosse-stick-ball,
  "lambda": $fa-var-lambda,
  "lamp": $fa-var-lamp,
  "lamp-desk": $fa-var-lamp-desk,
  "lamp-floor": $fa-var-lamp-floor,
  "lamp-street": $fa-var-lamp-street,
  "land-mine-on": $fa-var-land-mine-on,
  "landmark": $fa-var-landmark,
  "landmark-dome": $fa-var-landmark-dome,
  "landmark-alt": $fa-var-landmark-alt,
  "landmark-flag": $fa-var-landmark-flag,
  "landmark-magnifying-glass": $fa-var-landmark-magnifying-glass,
  "language": $fa-var-language,
  "laptop": $fa-var-laptop,
  "laptop-arrow-down": $fa-var-laptop-arrow-down,
  "laptop-binary": $fa-var-laptop-binary,
  "laptop-code": $fa-var-laptop-code,
  "laptop-file": $fa-var-laptop-file,
  "laptop-medical": $fa-var-laptop-medical,
  "laptop-mobile": $fa-var-laptop-mobile,
  "phone-laptop": $fa-var-phone-laptop,
  "laptop-slash": $fa-var-laptop-slash,
  "lari-sign": $fa-var-lari-sign,
  "lasso": $fa-var-lasso,
  "lasso-sparkles": $fa-var-lasso-sparkles,
  "layer-group": $fa-var-layer-group,
  "layer-minus": $fa-var-layer-minus,
  "layer-group-minus": $fa-var-layer-group-minus,
  "layer-plus": $fa-var-layer-plus,
  "layer-group-plus": $fa-var-layer-group-plus,
  "leaf": $fa-var-leaf,
  "leaf-heart": $fa-var-leaf-heart,
  "leaf-maple": $fa-var-leaf-maple,
  "leaf-oak": $fa-var-leaf-oak,
  "leafy-green": $fa-var-leafy-green,
  "left": $fa-var-left,
  "arrow-alt-left": $fa-var-arrow-alt-left,
  "left-from-bracket": $fa-var-left-from-bracket,
  "left-from-line": $fa-var-left-from-line,
  "arrow-alt-from-right": $fa-var-arrow-alt-from-right,
  "left-long": $fa-var-left-long,
  "long-arrow-alt-left": $fa-var-long-arrow-alt-left,
  "left-long-to-line": $fa-var-left-long-to-line,
  "left-right": $fa-var-left-right,
  "arrows-alt-h": $fa-var-arrows-alt-h,
  "left-to-bracket": $fa-var-left-to-bracket,
  "left-to-line": $fa-var-left-to-line,
  "arrow-alt-to-left": $fa-var-arrow-alt-to-left,
  "lemon": $fa-var-lemon,
  "less-than": $fa-var-less-than,
  "less-than-equal": $fa-var-less-than-equal,
  "life-ring": $fa-var-life-ring,
  "light-ceiling": $fa-var-light-ceiling,
  "light-emergency": $fa-var-light-emergency,
  "light-emergency-on": $fa-var-light-emergency-on,
  "light-switch": $fa-var-light-switch,
  "light-switch-off": $fa-var-light-switch-off,
  "light-switch-on": $fa-var-light-switch-on,
  "lightbulb": $fa-var-lightbulb,
  "lightbulb-cfl": $fa-var-lightbulb-cfl,
  "lightbulb-cfl-on": $fa-var-lightbulb-cfl-on,
  "lightbulb-dollar": $fa-var-lightbulb-dollar,
  "lightbulb-exclamation": $fa-var-lightbulb-exclamation,
  "lightbulb-exclamation-on": $fa-var-lightbulb-exclamation-on,
  "lightbulb-gear": $fa-var-lightbulb-gear,
  "lightbulb-on": $fa-var-lightbulb-on,
  "lightbulb-slash": $fa-var-lightbulb-slash,
  "lighthouse": $fa-var-lighthouse,
  "lights-holiday": $fa-var-lights-holiday,
  "line-columns": $fa-var-line-columns,
  "line-height": $fa-var-line-height,
  "lines-leaning": $fa-var-lines-leaning,
  "link": $fa-var-link,
  "chain": $fa-var-chain,
  "link-horizontal": $fa-var-link-horizontal,
  "chain-horizontal": $fa-var-chain-horizontal,
  "link-horizontal-slash": $fa-var-link-horizontal-slash,
  "chain-horizontal-slash": $fa-var-chain-horizontal-slash,
  "link-simple": $fa-var-link-simple,
  "link-simple-slash": $fa-var-link-simple-slash,
  "link-slash": $fa-var-link-slash,
  "chain-broken": $fa-var-chain-broken,
  "chain-slash": $fa-var-chain-slash,
  "unlink": $fa-var-unlink,
  "lips": $fa-var-lips,
  "lira-sign": $fa-var-lira-sign,
  "list": $fa-var-list,
  "list-squares": $fa-var-list-squares,
  "list-check": $fa-var-list-check,
  "tasks": $fa-var-tasks,
  "list-dropdown": $fa-var-list-dropdown,
  "list-music": $fa-var-list-music,
  "list-ol": $fa-var-list-ol,
  "list-1-2": $fa-var-list-1-2,
  "list-numeric": $fa-var-list-numeric,
  "list-radio": $fa-var-list-radio,
  "list-timeline": $fa-var-list-timeline,
  "list-tree": $fa-var-list-tree,
  "list-ul": $fa-var-list-ul,
  "list-dots": $fa-var-list-dots,
  "litecoin-sign": $fa-var-litecoin-sign,
  "loader": $fa-var-loader,
  "lobster": $fa-var-lobster,
  "location-arrow": $fa-var-location-arrow,
  "location-arrow-up": $fa-var-location-arrow-up,
  "location-check": $fa-var-location-check,
  "map-marker-check": $fa-var-map-marker-check,
  "location-crosshairs": $fa-var-location-crosshairs,
  "location": $fa-var-location,
  "location-crosshairs-slash": $fa-var-location-crosshairs-slash,
  "location-slash": $fa-var-location-slash,
  "location-dot": $fa-var-location-dot,
  "map-marker-alt": $fa-var-map-marker-alt,
  "location-dot-slash": $fa-var-location-dot-slash,
  "map-marker-alt-slash": $fa-var-map-marker-alt-slash,
  "location-exclamation": $fa-var-location-exclamation,
  "map-marker-exclamation": $fa-var-map-marker-exclamation,
  "location-minus": $fa-var-location-minus,
  "map-marker-minus": $fa-var-map-marker-minus,
  "location-pen": $fa-var-location-pen,
  "map-marker-edit": $fa-var-map-marker-edit,
  "location-pin": $fa-var-location-pin,
  "map-marker": $fa-var-map-marker,
  "location-pin-lock": $fa-var-location-pin-lock,
  "location-pin-slash": $fa-var-location-pin-slash,
  "map-marker-slash": $fa-var-map-marker-slash,
  "location-plus": $fa-var-location-plus,
  "map-marker-plus": $fa-var-map-marker-plus,
  "location-question": $fa-var-location-question,
  "map-marker-question": $fa-var-map-marker-question,
  "location-smile": $fa-var-location-smile,
  "map-marker-smile": $fa-var-map-marker-smile,
  "location-xmark": $fa-var-location-xmark,
  "map-marker-times": $fa-var-map-marker-times,
  "map-marker-xmark": $fa-var-map-marker-xmark,
  "lock": $fa-var-lock,
  "lock-a": $fa-var-lock-a,
  "lock-hashtag": $fa-var-lock-hashtag,
  "lock-keyhole": $fa-var-lock-keyhole,
  "lock-alt": $fa-var-lock-alt,
  "lock-keyhole-open": $fa-var-lock-keyhole-open,
  "lock-open-alt": $fa-var-lock-open-alt,
  "lock-open": $fa-var-lock-open,
  "locust": $fa-var-locust,
  "lollipop": $fa-var-lollipop,
  "lollypop": $fa-var-lollypop,
  "loveseat": $fa-var-loveseat,
  "couch-small": $fa-var-couch-small,
  "luchador-mask": $fa-var-luchador-mask,
  "luchador": $fa-var-luchador,
  "mask-luchador": $fa-var-mask-luchador,
  "lungs": $fa-var-lungs,
  "lungs-virus": $fa-var-lungs-virus,
  "m": $fa-var-m,
  "mace": $fa-var-mace,
  "magnet": $fa-var-magnet,
  "magnifying-glass": $fa-var-magnifying-glass,
  "search": $fa-var-search,
  "magnifying-glass-arrow-right": $fa-var-magnifying-glass-arrow-right,
  "magnifying-glass-arrows-rotate": $fa-var-magnifying-glass-arrows-rotate,
  "magnifying-glass-chart": $fa-var-magnifying-glass-chart,
  "magnifying-glass-dollar": $fa-var-magnifying-glass-dollar,
  "search-dollar": $fa-var-search-dollar,
  "magnifying-glass-location": $fa-var-magnifying-glass-location,
  "search-location": $fa-var-search-location,
  "magnifying-glass-minus": $fa-var-magnifying-glass-minus,
  "search-minus": $fa-var-search-minus,
  "magnifying-glass-music": $fa-var-magnifying-glass-music,
  "magnifying-glass-play": $fa-var-magnifying-glass-play,
  "magnifying-glass-plus": $fa-var-magnifying-glass-plus,
  "search-plus": $fa-var-search-plus,
  "magnifying-glass-waveform": $fa-var-magnifying-glass-waveform,
  "mailbox": $fa-var-mailbox,
  "mailbox-flag-up": $fa-var-mailbox-flag-up,
  "manat-sign": $fa-var-manat-sign,
  "mandolin": $fa-var-mandolin,
  "mango": $fa-var-mango,
  "manhole": $fa-var-manhole,
  "map": $fa-var-map,
  "map-location": $fa-var-map-location,
  "map-marked": $fa-var-map-marked,
  "map-location-dot": $fa-var-map-location-dot,
  "map-marked-alt": $fa-var-map-marked-alt,
  "map-pin": $fa-var-map-pin,
  "marker": $fa-var-marker,
  "mars": $fa-var-mars,
  "mars-and-venus": $fa-var-mars-and-venus,
  "mars-and-venus-burst": $fa-var-mars-and-venus-burst,
  "mars-double": $fa-var-mars-double,
  "mars-stroke": $fa-var-mars-stroke,
  "mars-stroke-right": $fa-var-mars-stroke-right,
  "mars-stroke-h": $fa-var-mars-stroke-h,
  "mars-stroke-up": $fa-var-mars-stroke-up,
  "mars-stroke-v": $fa-var-mars-stroke-v,
  "martini-glass": $fa-var-martini-glass,
  "glass-martini-alt": $fa-var-glass-martini-alt,
  "martini-glass-citrus": $fa-var-martini-glass-citrus,
  "cocktail": $fa-var-cocktail,
  "martini-glass-empty": $fa-var-martini-glass-empty,
  "glass-martini": $fa-var-glass-martini,
  "mask": $fa-var-mask,
  "mask-face": $fa-var-mask-face,
  "mask-snorkel": $fa-var-mask-snorkel,
  "mask-ventilator": $fa-var-mask-ventilator,
  "masks-theater": $fa-var-masks-theater,
  "theater-masks": $fa-var-theater-masks,
  "mattress-pillow": $fa-var-mattress-pillow,
  "maximize": $fa-var-maximize,
  "expand-arrows-alt": $fa-var-expand-arrows-alt,
  "meat": $fa-var-meat,
  "medal": $fa-var-medal,
  "megaphone": $fa-var-megaphone,
  "melon": $fa-var-melon,
  "melon-slice": $fa-var-melon-slice,
  "memo": $fa-var-memo,
  "memo-circle-check": $fa-var-memo-circle-check,
  "memo-circle-info": $fa-var-memo-circle-info,
  "memo-pad": $fa-var-memo-pad,
  "memory": $fa-var-memory,
  "menorah": $fa-var-menorah,
  "mercury": $fa-var-mercury,
  "merge": $fa-var-merge,
  "message": $fa-var-message,
  "comment-alt": $fa-var-comment-alt,
  "message-arrow-down": $fa-var-message-arrow-down,
  "comment-alt-arrow-down": $fa-var-comment-alt-arrow-down,
  "message-arrow-up": $fa-var-message-arrow-up,
  "comment-alt-arrow-up": $fa-var-comment-alt-arrow-up,
  "message-arrow-up-right": $fa-var-message-arrow-up-right,
  "message-bot": $fa-var-message-bot,
  "message-captions": $fa-var-message-captions,
  "comment-alt-captions": $fa-var-comment-alt-captions,
  "message-check": $fa-var-message-check,
  "comment-alt-check": $fa-var-comment-alt-check,
  "message-code": $fa-var-message-code,
  "message-dollar": $fa-var-message-dollar,
  "comment-alt-dollar": $fa-var-comment-alt-dollar,
  "message-dots": $fa-var-message-dots,
  "comment-alt-dots": $fa-var-comment-alt-dots,
  "messaging": $fa-var-messaging,
  "message-exclamation": $fa-var-message-exclamation,
  "comment-alt-exclamation": $fa-var-comment-alt-exclamation,
  "message-heart": $fa-var-message-heart,
  "message-image": $fa-var-message-image,
  "comment-alt-image": $fa-var-comment-alt-image,
  "message-lines": $fa-var-message-lines,
  "comment-alt-lines": $fa-var-comment-alt-lines,
  "message-medical": $fa-var-message-medical,
  "comment-alt-medical": $fa-var-comment-alt-medical,
  "message-middle": $fa-var-message-middle,
  "comment-middle-alt": $fa-var-comment-middle-alt,
  "message-middle-top": $fa-var-message-middle-top,
  "comment-middle-top-alt": $fa-var-comment-middle-top-alt,
  "message-minus": $fa-var-message-minus,
  "comment-alt-minus": $fa-var-comment-alt-minus,
  "message-music": $fa-var-message-music,
  "comment-alt-music": $fa-var-comment-alt-music,
  "message-pen": $fa-var-message-pen,
  "comment-alt-edit": $fa-var-comment-alt-edit,
  "message-edit": $fa-var-message-edit,
  "message-plus": $fa-var-message-plus,
  "comment-alt-plus": $fa-var-comment-alt-plus,
  "message-question": $fa-var-message-question,
  "message-quote": $fa-var-message-quote,
  "comment-alt-quote": $fa-var-comment-alt-quote,
  "message-slash": $fa-var-message-slash,
  "comment-alt-slash": $fa-var-comment-alt-slash,
  "message-smile": $fa-var-message-smile,
  "comment-alt-smile": $fa-var-comment-alt-smile,
  "message-sms": $fa-var-message-sms,
  "message-text": $fa-var-message-text,
  "comment-alt-text": $fa-var-comment-alt-text,
  "message-xmark": $fa-var-message-xmark,
  "comment-alt-times": $fa-var-comment-alt-times,
  "message-times": $fa-var-message-times,
  "messages": $fa-var-messages,
  "comments-alt": $fa-var-comments-alt,
  "messages-dollar": $fa-var-messages-dollar,
  "comments-alt-dollar": $fa-var-comments-alt-dollar,
  "messages-question": $fa-var-messages-question,
  "meteor": $fa-var-meteor,
  "meter": $fa-var-meter,
  "meter-bolt": $fa-var-meter-bolt,
  "meter-droplet": $fa-var-meter-droplet,
  "meter-fire": $fa-var-meter-fire,
  "microchip": $fa-var-microchip,
  "microchip-ai": $fa-var-microchip-ai,
  "microphone": $fa-var-microphone,
  "microphone-lines": $fa-var-microphone-lines,
  "microphone-alt": $fa-var-microphone-alt,
  "microphone-lines-slash": $fa-var-microphone-lines-slash,
  "microphone-alt-slash": $fa-var-microphone-alt-slash,
  "microphone-slash": $fa-var-microphone-slash,
  "microphone-stand": $fa-var-microphone-stand,
  "microscope": $fa-var-microscope,
  "microwave": $fa-var-microwave,
  "mill-sign": $fa-var-mill-sign,
  "minimize": $fa-var-minimize,
  "compress-arrows-alt": $fa-var-compress-arrows-alt,
  "minus": $fa-var-minus,
  "subtract": $fa-var-subtract,
  "mistletoe": $fa-var-mistletoe,
  "mitten": $fa-var-mitten,
  "mobile": $fa-var-mobile,
  "mobile-android": $fa-var-mobile-android,
  "mobile-phone": $fa-var-mobile-phone,
  "mobile-button": $fa-var-mobile-button,
  "mobile-notch": $fa-var-mobile-notch,
  "mobile-iphone": $fa-var-mobile-iphone,
  "mobile-retro": $fa-var-mobile-retro,
  "mobile-screen": $fa-var-mobile-screen,
  "mobile-android-alt": $fa-var-mobile-android-alt,
  "mobile-screen-button": $fa-var-mobile-screen-button,
  "mobile-alt": $fa-var-mobile-alt,
  "mobile-signal": $fa-var-mobile-signal,
  "mobile-signal-out": $fa-var-mobile-signal-out,
  "money-bill": $fa-var-money-bill,
  "money-bill-1": $fa-var-money-bill-1,
  "money-bill-alt": $fa-var-money-bill-alt,
  "money-bill-1-wave": $fa-var-money-bill-1-wave,
  "money-bill-wave-alt": $fa-var-money-bill-wave-alt,
  "money-bill-simple": $fa-var-money-bill-simple,
  "money-bill-simple-wave": $fa-var-money-bill-simple-wave,
  "money-bill-transfer": $fa-var-money-bill-transfer,
  "money-bill-trend-up": $fa-var-money-bill-trend-up,
  "money-bill-wave": $fa-var-money-bill-wave,
  "money-bill-wheat": $fa-var-money-bill-wheat,
  "money-bills": $fa-var-money-bills,
  "money-bills-simple": $fa-var-money-bills-simple,
  "money-bills-alt": $fa-var-money-bills-alt,
  "money-check": $fa-var-money-check,
  "money-check-dollar": $fa-var-money-check-dollar,
  "money-check-alt": $fa-var-money-check-alt,
  "money-check-dollar-pen": $fa-var-money-check-dollar-pen,
  "money-check-edit-alt": $fa-var-money-check-edit-alt,
  "money-check-pen": $fa-var-money-check-pen,
  "money-check-edit": $fa-var-money-check-edit,
  "money-from-bracket": $fa-var-money-from-bracket,
  "money-simple-from-bracket": $fa-var-money-simple-from-bracket,
  "monitor-waveform": $fa-var-monitor-waveform,
  "monitor-heart-rate": $fa-var-monitor-heart-rate,
  "monkey": $fa-var-monkey,
  "monument": $fa-var-monument,
  "moon": $fa-var-moon,
  "moon-cloud": $fa-var-moon-cloud,
  "moon-over-sun": $fa-var-moon-over-sun,
  "eclipse-alt": $fa-var-eclipse-alt,
  "moon-stars": $fa-var-moon-stars,
  "moped": $fa-var-moped,
  "mortar-pestle": $fa-var-mortar-pestle,
  "mosque": $fa-var-mosque,
  "mosquito": $fa-var-mosquito,
  "mosquito-net": $fa-var-mosquito-net,
  "motorcycle": $fa-var-motorcycle,
  "mound": $fa-var-mound,
  "mountain": $fa-var-mountain,
  "mountain-city": $fa-var-mountain-city,
  "mountain-sun": $fa-var-mountain-sun,
  "mountains": $fa-var-mountains,
  "mouse-field": $fa-var-mouse-field,
  "mp3-player": $fa-var-mp3-player,
  "mug": $fa-var-mug,
  "mug-hot": $fa-var-mug-hot,
  "mug-marshmallows": $fa-var-mug-marshmallows,
  "mug-saucer": $fa-var-mug-saucer,
  "coffee": $fa-var-coffee,
  "mug-tea": $fa-var-mug-tea,
  "mug-tea-saucer": $fa-var-mug-tea-saucer,
  "mushroom": $fa-var-mushroom,
  "music": $fa-var-music,
  "music-magnifying-glass": $fa-var-music-magnifying-glass,
  "music-note": $fa-var-music-note,
  "music-alt": $fa-var-music-alt,
  "music-note-slash": $fa-var-music-note-slash,
  "music-alt-slash": $fa-var-music-alt-slash,
  "music-slash": $fa-var-music-slash,
  "mustache": $fa-var-mustache,
  "n": $fa-var-n,
  "naira-sign": $fa-var-naira-sign,
  "narwhal": $fa-var-narwhal,
  "nesting-dolls": $fa-var-nesting-dolls,
  "network-wired": $fa-var-network-wired,
  "neuter": $fa-var-neuter,
  "newspaper": $fa-var-newspaper,
  "nfc": $fa-var-nfc,
  "nfc-lock": $fa-var-nfc-lock,
  "nfc-magnifying-glass": $fa-var-nfc-magnifying-glass,
  "nfc-pen": $fa-var-nfc-pen,
  "nfc-signal": $fa-var-nfc-signal,
  "nfc-slash": $fa-var-nfc-slash,
  "nfc-trash": $fa-var-nfc-trash,
  "nose": $fa-var-nose,
  "not-equal": $fa-var-not-equal,
  "notdef": $fa-var-notdef,
  "note": $fa-var-note,
  "note-medical": $fa-var-note-medical,
  "note-sticky": $fa-var-note-sticky,
  "sticky-note": $fa-var-sticky-note,
  "notebook": $fa-var-notebook,
  "notes": $fa-var-notes,
  "notes-medical": $fa-var-notes-medical,
  "o": $fa-var-o,
  "object-exclude": $fa-var-object-exclude,
  "object-group": $fa-var-object-group,
  "object-intersect": $fa-var-object-intersect,
  "object-subtract": $fa-var-object-subtract,
  "object-ungroup": $fa-var-object-ungroup,
  "object-union": $fa-var-object-union,
  "objects-align-bottom": $fa-var-objects-align-bottom,
  "objects-align-center-horizontal": $fa-var-objects-align-center-horizontal,
  "objects-align-center-vertical": $fa-var-objects-align-center-vertical,
  "objects-align-left": $fa-var-objects-align-left,
  "objects-align-right": $fa-var-objects-align-right,
  "objects-align-top": $fa-var-objects-align-top,
  "objects-column": $fa-var-objects-column,
  "octagon": $fa-var-octagon,
  "octagon-check": $fa-var-octagon-check,
  "octagon-divide": $fa-var-octagon-divide,
  "octagon-exclamation": $fa-var-octagon-exclamation,
  "octagon-minus": $fa-var-octagon-minus,
  "minus-octagon": $fa-var-minus-octagon,
  "octagon-plus": $fa-var-octagon-plus,
  "plus-octagon": $fa-var-plus-octagon,
  "octagon-xmark": $fa-var-octagon-xmark,
  "times-octagon": $fa-var-times-octagon,
  "xmark-octagon": $fa-var-xmark-octagon,
  "oil-can": $fa-var-oil-can,
  "oil-can-drip": $fa-var-oil-can-drip,
  "oil-temperature": $fa-var-oil-temperature,
  "oil-temp": $fa-var-oil-temp,
  "oil-well": $fa-var-oil-well,
  "olive": $fa-var-olive,
  "olive-branch": $fa-var-olive-branch,
  "om": $fa-var-om,
  "omega": $fa-var-omega,
  "onion": $fa-var-onion,
  "option": $fa-var-option,
  "ornament": $fa-var-ornament,
  "otter": $fa-var-otter,
  "outdent": $fa-var-outdent,
  "dedent": $fa-var-dedent,
  "outlet": $fa-var-outlet,
  "oven": $fa-var-oven,
  "overline": $fa-var-overline,
  "p": $fa-var-p,
  "page": $fa-var-page,
  "page-caret-down": $fa-var-page-caret-down,
  "file-caret-down": $fa-var-file-caret-down,
  "page-caret-up": $fa-var-page-caret-up,
  "file-caret-up": $fa-var-file-caret-up,
  "pager": $fa-var-pager,
  "paint-roller": $fa-var-paint-roller,
  "paintbrush": $fa-var-paintbrush,
  "paint-brush": $fa-var-paint-brush,
  "paintbrush-fine": $fa-var-paintbrush-fine,
  "paint-brush-alt": $fa-var-paint-brush-alt,
  "paint-brush-fine": $fa-var-paint-brush-fine,
  "paintbrush-alt": $fa-var-paintbrush-alt,
  "paintbrush-pencil": $fa-var-paintbrush-pencil,
  "palette": $fa-var-palette,
  "pallet": $fa-var-pallet,
  "pallet-box": $fa-var-pallet-box,
  "pallet-boxes": $fa-var-pallet-boxes,
  "palette-boxes": $fa-var-palette-boxes,
  "pallet-alt": $fa-var-pallet-alt,
  "pan-food": $fa-var-pan-food,
  "pan-frying": $fa-var-pan-frying,
  "pancakes": $fa-var-pancakes,
  "panel-ews": $fa-var-panel-ews,
  "panel-fire": $fa-var-panel-fire,
  "panorama": $fa-var-panorama,
  "paper-plane": $fa-var-paper-plane,
  "paper-plane-top": $fa-var-paper-plane-top,
  "paper-plane-alt": $fa-var-paper-plane-alt,
  "send": $fa-var-send,
  "paperclip": $fa-var-paperclip,
  "paperclip-vertical": $fa-var-paperclip-vertical,
  "parachute-box": $fa-var-parachute-box,
  "paragraph": $fa-var-paragraph,
  "paragraph-left": $fa-var-paragraph-left,
  "paragraph-rtl": $fa-var-paragraph-rtl,
  "party-bell": $fa-var-party-bell,
  "party-horn": $fa-var-party-horn,
  "passport": $fa-var-passport,
  "paste": $fa-var-paste,
  "file-clipboard": $fa-var-file-clipboard,
  "pause": $fa-var-pause,
  "paw": $fa-var-paw,
  "paw-claws": $fa-var-paw-claws,
  "paw-simple": $fa-var-paw-simple,
  "paw-alt": $fa-var-paw-alt,
  "peace": $fa-var-peace,
  "peach": $fa-var-peach,
  "peanut": $fa-var-peanut,
  "peanuts": $fa-var-peanuts,
  "peapod": $fa-var-peapod,
  "pear": $fa-var-pear,
  "pedestal": $fa-var-pedestal,
  "pegasus": $fa-var-pegasus,
  "pen": $fa-var-pen,
  "pen-circle": $fa-var-pen-circle,
  "pen-clip": $fa-var-pen-clip,
  "pen-alt": $fa-var-pen-alt,
  "pen-clip-slash": $fa-var-pen-clip-slash,
  "pen-alt-slash": $fa-var-pen-alt-slash,
  "pen-fancy": $fa-var-pen-fancy,
  "pen-fancy-slash": $fa-var-pen-fancy-slash,
  "pen-field": $fa-var-pen-field,
  "pen-line": $fa-var-pen-line,
  "pen-nib": $fa-var-pen-nib,
  "pen-nib-slash": $fa-var-pen-nib-slash,
  "pen-paintbrush": $fa-var-pen-paintbrush,
  "pencil-paintbrush": $fa-var-pencil-paintbrush,
  "pen-ruler": $fa-var-pen-ruler,
  "pencil-ruler": $fa-var-pencil-ruler,
  "pen-slash": $fa-var-pen-slash,
  "pen-swirl": $fa-var-pen-swirl,
  "pen-to-square": $fa-var-pen-to-square,
  "edit": $fa-var-edit,
  "pencil": $fa-var-pencil,
  "pencil-alt": $fa-var-pencil-alt,
  "pencil-mechanical": $fa-var-pencil-mechanical,
  "pencil-slash": $fa-var-pencil-slash,
  "people": $fa-var-people,
  "people-arrows": $fa-var-people-arrows,
  "people-arrows-left-right": $fa-var-people-arrows-left-right,
  "people-carry-box": $fa-var-people-carry-box,
  "people-carry": $fa-var-people-carry,
  "people-dress": $fa-var-people-dress,
  "people-dress-simple": $fa-var-people-dress-simple,
  "people-group": $fa-var-people-group,
  "people-line": $fa-var-people-line,
  "people-pants": $fa-var-people-pants,
  "people-pants-simple": $fa-var-people-pants-simple,
  "people-pulling": $fa-var-people-pulling,
  "people-robbery": $fa-var-people-robbery,
  "people-roof": $fa-var-people-roof,
  "people-simple": $fa-var-people-simple,
  "pepper": $fa-var-pepper,
  "pepper-hot": $fa-var-pepper-hot,
  "percent": $fa-var-percent,
  "percentage": $fa-var-percentage,
  "period": $fa-var-period,
  "person": $fa-var-person,
  "male": $fa-var-male,
  "person-arrow-down-to-line": $fa-var-person-arrow-down-to-line,
  "person-arrow-up-from-line": $fa-var-person-arrow-up-from-line,
  "person-biking": $fa-var-person-biking,
  "biking": $fa-var-biking,
  "person-biking-mountain": $fa-var-person-biking-mountain,
  "biking-mountain": $fa-var-biking-mountain,
  "person-booth": $fa-var-person-booth,
  "person-breastfeeding": $fa-var-person-breastfeeding,
  "person-burst": $fa-var-person-burst,
  "person-cane": $fa-var-person-cane,
  "person-carry-box": $fa-var-person-carry-box,
  "person-carry": $fa-var-person-carry,
  "person-chalkboard": $fa-var-person-chalkboard,
  "person-circle-check": $fa-var-person-circle-check,
  "person-circle-exclamation": $fa-var-person-circle-exclamation,
  "person-circle-minus": $fa-var-person-circle-minus,
  "person-circle-plus": $fa-var-person-circle-plus,
  "person-circle-question": $fa-var-person-circle-question,
  "person-circle-xmark": $fa-var-person-circle-xmark,
  "person-digging": $fa-var-person-digging,
  "digging": $fa-var-digging,
  "person-dolly": $fa-var-person-dolly,
  "person-dolly-empty": $fa-var-person-dolly-empty,
  "person-dots-from-line": $fa-var-person-dots-from-line,
  "diagnoses": $fa-var-diagnoses,
  "person-dress": $fa-var-person-dress,
  "female": $fa-var-female,
  "person-dress-burst": $fa-var-person-dress-burst,
  "person-dress-fairy": $fa-var-person-dress-fairy,
  "person-dress-simple": $fa-var-person-dress-simple,
  "person-drowning": $fa-var-person-drowning,
  "person-fairy": $fa-var-person-fairy,
  "person-falling": $fa-var-person-falling,
  "person-falling-burst": $fa-var-person-falling-burst,
  "person-from-portal": $fa-var-person-from-portal,
  "portal-exit": $fa-var-portal-exit,
  "person-half-dress": $fa-var-person-half-dress,
  "person-harassing": $fa-var-person-harassing,
  "person-hiking": $fa-var-person-hiking,
  "hiking": $fa-var-hiking,
  "person-military-pointing": $fa-var-person-military-pointing,
  "person-military-rifle": $fa-var-person-military-rifle,
  "person-military-to-person": $fa-var-person-military-to-person,
  "person-pinball": $fa-var-person-pinball,
  "person-praying": $fa-var-person-praying,
  "pray": $fa-var-pray,
  "person-pregnant": $fa-var-person-pregnant,
  "person-rays": $fa-var-person-rays,
  "person-rifle": $fa-var-person-rifle,
  "person-running": $fa-var-person-running,
  "running": $fa-var-running,
  "person-running-fast": $fa-var-person-running-fast,
  "person-seat": $fa-var-person-seat,
  "person-seat-reclined": $fa-var-person-seat-reclined,
  "person-shelter": $fa-var-person-shelter,
  "person-sign": $fa-var-person-sign,
  "person-simple": $fa-var-person-simple,
  "person-skating": $fa-var-person-skating,
  "skating": $fa-var-skating,
  "person-ski-jumping": $fa-var-person-ski-jumping,
  "ski-jump": $fa-var-ski-jump,
  "person-ski-lift": $fa-var-person-ski-lift,
  "ski-lift": $fa-var-ski-lift,
  "person-skiing": $fa-var-person-skiing,
  "skiing": $fa-var-skiing,
  "person-skiing-nordic": $fa-var-person-skiing-nordic,
  "skiing-nordic": $fa-var-skiing-nordic,
  "person-sledding": $fa-var-person-sledding,
  "sledding": $fa-var-sledding,
  "person-snowboarding": $fa-var-person-snowboarding,
  "snowboarding": $fa-var-snowboarding,
  "person-snowmobiling": $fa-var-person-snowmobiling,
  "snowmobile": $fa-var-snowmobile,
  "person-swimming": $fa-var-person-swimming,
  "swimmer": $fa-var-swimmer,
  "person-through-window": $fa-var-person-through-window,
  "person-to-door": $fa-var-person-to-door,
  "person-to-portal": $fa-var-person-to-portal,
  "portal-enter": $fa-var-portal-enter,
  "person-walking": $fa-var-person-walking,
  "walking": $fa-var-walking,
  "person-walking-arrow-loop-left": $fa-var-person-walking-arrow-loop-left,
  "person-walking-arrow-right": $fa-var-person-walking-arrow-right,
  "person-walking-dashed-line-arrow-right": $fa-var-person-walking-dashed-line-arrow-right,
  "person-walking-luggage": $fa-var-person-walking-luggage,
  "person-walking-with-cane": $fa-var-person-walking-with-cane,
  "blind": $fa-var-blind,
  "peseta-sign": $fa-var-peseta-sign,
  "peso-sign": $fa-var-peso-sign,
  "phone": $fa-var-phone,
  "phone-arrow-down-left": $fa-var-phone-arrow-down-left,
  "phone-arrow-down": $fa-var-phone-arrow-down,
  "phone-incoming": $fa-var-phone-incoming,
  "phone-arrow-right": $fa-var-phone-arrow-right,
  "phone-arrow-up-right": $fa-var-phone-arrow-up-right,
  "phone-arrow-up": $fa-var-phone-arrow-up,
  "phone-outgoing": $fa-var-phone-outgoing,
  "phone-flip": $fa-var-phone-flip,
  "phone-alt": $fa-var-phone-alt,
  "phone-hangup": $fa-var-phone-hangup,
  "phone-intercom": $fa-var-phone-intercom,
  "phone-missed": $fa-var-phone-missed,
  "phone-office": $fa-var-phone-office,
  "phone-plus": $fa-var-phone-plus,
  "phone-rotary": $fa-var-phone-rotary,
  "phone-slash": $fa-var-phone-slash,
  "phone-volume": $fa-var-phone-volume,
  "volume-control-phone": $fa-var-volume-control-phone,
  "phone-xmark": $fa-var-phone-xmark,
  "photo-film": $fa-var-photo-film,
  "photo-video": $fa-var-photo-video,
  "photo-film-music": $fa-var-photo-film-music,
  "pi": $fa-var-pi,
  "piano": $fa-var-piano,
  "piano-keyboard": $fa-var-piano-keyboard,
  "pickaxe": $fa-var-pickaxe,
  "pickleball": $fa-var-pickleball,
  "pie": $fa-var-pie,
  "pig": $fa-var-pig,
  "piggy-bank": $fa-var-piggy-bank,
  "pills": $fa-var-pills,
  "pinata": $fa-var-pinata,
  "pinball": $fa-var-pinball,
  "pineapple": $fa-var-pineapple,
  "pipe": $fa-var-pipe,
  "pipe-circle-check": $fa-var-pipe-circle-check,
  "pipe-collar": $fa-var-pipe-collar,
  "pipe-section": $fa-var-pipe-section,
  "pipe-smoking": $fa-var-pipe-smoking,
  "pipe-valve": $fa-var-pipe-valve,
  "pizza": $fa-var-pizza,
  "pizza-slice": $fa-var-pizza-slice,
  "place-of-worship": $fa-var-place-of-worship,
  "plane": $fa-var-plane,
  "plane-arrival": $fa-var-plane-arrival,
  "plane-circle-check": $fa-var-plane-circle-check,
  "plane-circle-exclamation": $fa-var-plane-circle-exclamation,
  "plane-circle-xmark": $fa-var-plane-circle-xmark,
  "plane-departure": $fa-var-plane-departure,
  "plane-engines": $fa-var-plane-engines,
  "plane-alt": $fa-var-plane-alt,
  "plane-lock": $fa-var-plane-lock,
  "plane-prop": $fa-var-plane-prop,
  "plane-slash": $fa-var-plane-slash,
  "plane-tail": $fa-var-plane-tail,
  "plane-up": $fa-var-plane-up,
  "plane-up-slash": $fa-var-plane-up-slash,
  "planet-moon": $fa-var-planet-moon,
  "planet-ringed": $fa-var-planet-ringed,
  "plant-wilt": $fa-var-plant-wilt,
  "plate-utensils": $fa-var-plate-utensils,
  "plate-wheat": $fa-var-plate-wheat,
  "play": $fa-var-play,
  "play-pause": $fa-var-play-pause,
  "plug": $fa-var-plug,
  "plug-circle-bolt": $fa-var-plug-circle-bolt,
  "plug-circle-check": $fa-var-plug-circle-check,
  "plug-circle-exclamation": $fa-var-plug-circle-exclamation,
  "plug-circle-minus": $fa-var-plug-circle-minus,
  "plug-circle-plus": $fa-var-plug-circle-plus,
  "plug-circle-xmark": $fa-var-plug-circle-xmark,
  "plus": $fa-var-plus,
  "add": $fa-var-add,
  "plus-large": $fa-var-plus-large,
  "plus-minus": $fa-var-plus-minus,
  "podcast": $fa-var-podcast,
  "podium": $fa-var-podium,
  "podium-star": $fa-var-podium-star,
  "police-box": $fa-var-police-box,
  "poll-people": $fa-var-poll-people,
  "pompebled": $fa-var-pompebled,
  "poo": $fa-var-poo,
  "poo-storm": $fa-var-poo-storm,
  "poo-bolt": $fa-var-poo-bolt,
  "pool-8-ball": $fa-var-pool-8-ball,
  "poop": $fa-var-poop,
  "popcorn": $fa-var-popcorn,
  "popsicle": $fa-var-popsicle,
  "pot-food": $fa-var-pot-food,
  "potato": $fa-var-potato,
  "power-off": $fa-var-power-off,
  "prescription": $fa-var-prescription,
  "prescription-bottle": $fa-var-prescription-bottle,
  "prescription-bottle-medical": $fa-var-prescription-bottle-medical,
  "prescription-bottle-alt": $fa-var-prescription-bottle-alt,
  "prescription-bottle-pill": $fa-var-prescription-bottle-pill,
  "presentation-screen": $fa-var-presentation-screen,
  "presentation": $fa-var-presentation,
  "pretzel": $fa-var-pretzel,
  "print": $fa-var-print,
  "print-magnifying-glass": $fa-var-print-magnifying-glass,
  "print-search": $fa-var-print-search,
  "print-slash": $fa-var-print-slash,
  "projector": $fa-var-projector,
  "pump": $fa-var-pump,
  "pump-medical": $fa-var-pump-medical,
  "pump-soap": $fa-var-pump-soap,
  "pumpkin": $fa-var-pumpkin,
  "puzzle": $fa-var-puzzle,
  "puzzle-piece": $fa-var-puzzle-piece,
  "puzzle-piece-simple": $fa-var-puzzle-piece-simple,
  "puzzle-piece-alt": $fa-var-puzzle-piece-alt,
  "q": $fa-var-q,
  "qrcode": $fa-var-qrcode,
  "question": $fa-var-question,
  "quote-left": $fa-var-quote-left,
  "quote-left-alt": $fa-var-quote-left-alt,
  "quote-right": $fa-var-quote-right,
  "quote-right-alt": $fa-var-quote-right-alt,
  "quotes": $fa-var-quotes,
  "r": $fa-var-r,
  "rabbit": $fa-var-rabbit,
  "rabbit-running": $fa-var-rabbit-running,
  "rabbit-fast": $fa-var-rabbit-fast,
  "raccoon": $fa-var-raccoon,
  "racquet": $fa-var-racquet,
  "radar": $fa-var-radar,
  "radiation": $fa-var-radiation,
  "radio": $fa-var-radio,
  "radio-tuner": $fa-var-radio-tuner,
  "radio-alt": $fa-var-radio-alt,
  "rainbow": $fa-var-rainbow,
  "raindrops": $fa-var-raindrops,
  "ram": $fa-var-ram,
  "ramp-loading": $fa-var-ramp-loading,
  "ranking-star": $fa-var-ranking-star,
  "raygun": $fa-var-raygun,
  "receipt": $fa-var-receipt,
  "record-vinyl": $fa-var-record-vinyl,
  "rectangle": $fa-var-rectangle,
  "rectangle-landscape": $fa-var-rectangle-landscape,
  "rectangle-ad": $fa-var-rectangle-ad,
  "ad": $fa-var-ad,
  "rectangle-barcode": $fa-var-rectangle-barcode,
  "barcode-alt": $fa-var-barcode-alt,
  "rectangle-code": $fa-var-rectangle-code,
  "rectangle-history": $fa-var-rectangle-history,
  "rectangle-history-circle-plus": $fa-var-rectangle-history-circle-plus,
  "rectangle-history-circle-user": $fa-var-rectangle-history-circle-user,
  "rectangle-list": $fa-var-rectangle-list,
  "list-alt": $fa-var-list-alt,
  "rectangle-pro": $fa-var-rectangle-pro,
  "pro": $fa-var-pro,
  "rectangle-terminal": $fa-var-rectangle-terminal,
  "rectangle-vertical": $fa-var-rectangle-vertical,
  "rectangle-portrait": $fa-var-rectangle-portrait,
  "rectangle-vertical-history": $fa-var-rectangle-vertical-history,
  "rectangle-wide": $fa-var-rectangle-wide,
  "rectangle-xmark": $fa-var-rectangle-xmark,
  "rectangle-times": $fa-var-rectangle-times,
  "times-rectangle": $fa-var-times-rectangle,
  "window-close": $fa-var-window-close,
  "rectangles-mixed": $fa-var-rectangles-mixed,
  "recycle": $fa-var-recycle,
  "reel": $fa-var-reel,
  "reflect-both": $fa-var-reflect-both,
  "reflect-horizontal": $fa-var-reflect-horizontal,
  "reflect-vertical": $fa-var-reflect-vertical,
  "refrigerator": $fa-var-refrigerator,
  "registered": $fa-var-registered,
  "repeat": $fa-var-repeat,
  "repeat-1": $fa-var-repeat-1,
  "reply": $fa-var-reply,
  "mail-reply": $fa-var-mail-reply,
  "reply-all": $fa-var-reply-all,
  "mail-reply-all": $fa-var-mail-reply-all,
  "reply-clock": $fa-var-reply-clock,
  "reply-time": $fa-var-reply-time,
  "republican": $fa-var-republican,
  "restroom": $fa-var-restroom,
  "restroom-simple": $fa-var-restroom-simple,
  "retweet": $fa-var-retweet,
  "rhombus": $fa-var-rhombus,
  "ribbon": $fa-var-ribbon,
  "right": $fa-var-right,
  "arrow-alt-right": $fa-var-arrow-alt-right,
  "right-from-bracket": $fa-var-right-from-bracket,
  "sign-out-alt": $fa-var-sign-out-alt,
  "right-from-line": $fa-var-right-from-line,
  "arrow-alt-from-left": $fa-var-arrow-alt-from-left,
  "right-left": $fa-var-right-left,
  "exchange-alt": $fa-var-exchange-alt,
  "right-left-large": $fa-var-right-left-large,
  "right-long": $fa-var-right-long,
  "long-arrow-alt-right": $fa-var-long-arrow-alt-right,
  "right-long-to-line": $fa-var-right-long-to-line,
  "right-to-bracket": $fa-var-right-to-bracket,
  "sign-in-alt": $fa-var-sign-in-alt,
  "right-to-line": $fa-var-right-to-line,
  "arrow-alt-to-right": $fa-var-arrow-alt-to-right,
  "ring": $fa-var-ring,
  "ring-diamond": $fa-var-ring-diamond,
  "rings-wedding": $fa-var-rings-wedding,
  "road": $fa-var-road,
  "road-barrier": $fa-var-road-barrier,
  "road-bridge": $fa-var-road-bridge,
  "road-circle-check": $fa-var-road-circle-check,
  "road-circle-exclamation": $fa-var-road-circle-exclamation,
  "road-circle-xmark": $fa-var-road-circle-xmark,
  "road-lock": $fa-var-road-lock,
  "road-spikes": $fa-var-road-spikes,
  "robot": $fa-var-robot,
  "robot-astromech": $fa-var-robot-astromech,
  "rocket": $fa-var-rocket,
  "rocket-launch": $fa-var-rocket-launch,
  "roller-coaster": $fa-var-roller-coaster,
  "rotate": $fa-var-rotate,
  "sync-alt": $fa-var-sync-alt,
  "rotate-exclamation": $fa-var-rotate-exclamation,
  "rotate-left": $fa-var-rotate-left,
  "rotate-back": $fa-var-rotate-back,
  "rotate-backward": $fa-var-rotate-backward,
  "undo-alt": $fa-var-undo-alt,
  "rotate-reverse": $fa-var-rotate-reverse,
  "rotate-right": $fa-var-rotate-right,
  "redo-alt": $fa-var-redo-alt,
  "rotate-forward": $fa-var-rotate-forward,
  "route": $fa-var-route,
  "route-highway": $fa-var-route-highway,
  "route-interstate": $fa-var-route-interstate,
  "router": $fa-var-router,
  "rss": $fa-var-rss,
  "feed": $fa-var-feed,
  "ruble-sign": $fa-var-ruble-sign,
  "rouble": $fa-var-rouble,
  "rub": $fa-var-rub,
  "ruble": $fa-var-ruble,
  "rug": $fa-var-rug,
  "rugby-ball": $fa-var-rugby-ball,
  "ruler": $fa-var-ruler,
  "ruler-combined": $fa-var-ruler-combined,
  "ruler-horizontal": $fa-var-ruler-horizontal,
  "ruler-triangle": $fa-var-ruler-triangle,
  "ruler-vertical": $fa-var-ruler-vertical,
  "rupee-sign": $fa-var-rupee-sign,
  "rupee": $fa-var-rupee,
  "rupiah-sign": $fa-var-rupiah-sign,
  "rv": $fa-var-rv,
  "s": $fa-var-s,
  "sack": $fa-var-sack,
  "sack-dollar": $fa-var-sack-dollar,
  "sack-xmark": $fa-var-sack-xmark,
  "sailboat": $fa-var-sailboat,
  "salad": $fa-var-salad,
  "bowl-salad": $fa-var-bowl-salad,
  "salt-shaker": $fa-var-salt-shaker,
  "sandwich": $fa-var-sandwich,
  "satellite": $fa-var-satellite,
  "satellite-dish": $fa-var-satellite-dish,
  "sausage": $fa-var-sausage,
  "saxophone": $fa-var-saxophone,
  "saxophone-fire": $fa-var-saxophone-fire,
  "sax-hot": $fa-var-sax-hot,
  "scale-balanced": $fa-var-scale-balanced,
  "balance-scale": $fa-var-balance-scale,
  "scale-unbalanced": $fa-var-scale-unbalanced,
  "balance-scale-left": $fa-var-balance-scale-left,
  "scale-unbalanced-flip": $fa-var-scale-unbalanced-flip,
  "balance-scale-right": $fa-var-balance-scale-right,
  "scalpel": $fa-var-scalpel,
  "scalpel-line-dashed": $fa-var-scalpel-line-dashed,
  "scalpel-path": $fa-var-scalpel-path,
  "scanner-gun": $fa-var-scanner-gun,
  "scanner": $fa-var-scanner,
  "scanner-image": $fa-var-scanner-image,
  "scanner-keyboard": $fa-var-scanner-keyboard,
  "scanner-touchscreen": $fa-var-scanner-touchscreen,
  "scarecrow": $fa-var-scarecrow,
  "scarf": $fa-var-scarf,
  "school": $fa-var-school,
  "school-circle-check": $fa-var-school-circle-check,
  "school-circle-exclamation": $fa-var-school-circle-exclamation,
  "school-circle-xmark": $fa-var-school-circle-xmark,
  "school-flag": $fa-var-school-flag,
  "school-lock": $fa-var-school-lock,
  "scissors": $fa-var-scissors,
  "cut": $fa-var-cut,
  "screen-users": $fa-var-screen-users,
  "users-class": $fa-var-users-class,
  "screencast": $fa-var-screencast,
  "screwdriver": $fa-var-screwdriver,
  "screwdriver-wrench": $fa-var-screwdriver-wrench,
  "tools": $fa-var-tools,
  "scribble": $fa-var-scribble,
  "scroll": $fa-var-scroll,
  "scroll-old": $fa-var-scroll-old,
  "scroll-torah": $fa-var-scroll-torah,
  "torah": $fa-var-torah,
  "scrubber": $fa-var-scrubber,
  "scythe": $fa-var-scythe,
  "sd-card": $fa-var-sd-card,
  "sd-cards": $fa-var-sd-cards,
  "seal": $fa-var-seal,
  "seal-exclamation": $fa-var-seal-exclamation,
  "seal-question": $fa-var-seal-question,
  "seat-airline": $fa-var-seat-airline,
  "section": $fa-var-section,
  "seedling": $fa-var-seedling,
  "sprout": $fa-var-sprout,
  "semicolon": $fa-var-semicolon,
  "send-back": $fa-var-send-back,
  "send-backward": $fa-var-send-backward,
  "sensor": $fa-var-sensor,
  "sensor-cloud": $fa-var-sensor-cloud,
  "sensor-smoke": $fa-var-sensor-smoke,
  "sensor-fire": $fa-var-sensor-fire,
  "sensor-on": $fa-var-sensor-on,
  "sensor-triangle-exclamation": $fa-var-sensor-triangle-exclamation,
  "sensor-alert": $fa-var-sensor-alert,
  "server": $fa-var-server,
  "shapes": $fa-var-shapes,
  "triangle-circle-square": $fa-var-triangle-circle-square,
  "share": $fa-var-share,
  "mail-forward": $fa-var-mail-forward,
  "share-all": $fa-var-share-all,
  "share-from-square": $fa-var-share-from-square,
  "share-square": $fa-var-share-square,
  "share-nodes": $fa-var-share-nodes,
  "share-alt": $fa-var-share-alt,
  "sheep": $fa-var-sheep,
  "sheet-plastic": $fa-var-sheet-plastic,
  "shekel-sign": $fa-var-shekel-sign,
  "ils": $fa-var-ils,
  "shekel": $fa-var-shekel,
  "sheqel": $fa-var-sheqel,
  "sheqel-sign": $fa-var-sheqel-sign,
  "shelves": $fa-var-shelves,
  "inventory": $fa-var-inventory,
  "shelves-empty": $fa-var-shelves-empty,
  "shield": $fa-var-shield,
  "shield-blank": $fa-var-shield-blank,
  "shield-cat": $fa-var-shield-cat,
  "shield-check": $fa-var-shield-check,
  "shield-cross": $fa-var-shield-cross,
  "shield-dog": $fa-var-shield-dog,
  "shield-exclamation": $fa-var-shield-exclamation,
  "shield-halved": $fa-var-shield-halved,
  "shield-alt": $fa-var-shield-alt,
  "shield-heart": $fa-var-shield-heart,
  "shield-keyhole": $fa-var-shield-keyhole,
  "shield-minus": $fa-var-shield-minus,
  "shield-plus": $fa-var-shield-plus,
  "shield-quartered": $fa-var-shield-quartered,
  "shield-slash": $fa-var-shield-slash,
  "shield-virus": $fa-var-shield-virus,
  "shield-xmark": $fa-var-shield-xmark,
  "shield-times": $fa-var-shield-times,
  "ship": $fa-var-ship,
  "shirt": $fa-var-shirt,
  "t-shirt": $fa-var-t-shirt,
  "tshirt": $fa-var-tshirt,
  "shirt-long-sleeve": $fa-var-shirt-long-sleeve,
  "shirt-running": $fa-var-shirt-running,
  "shirt-tank-top": $fa-var-shirt-tank-top,
  "shish-kebab": $fa-var-shish-kebab,
  "shoe-prints": $fa-var-shoe-prints,
  "shop": $fa-var-shop,
  "store-alt": $fa-var-store-alt,
  "shop-lock": $fa-var-shop-lock,
  "shop-slash": $fa-var-shop-slash,
  "store-alt-slash": $fa-var-store-alt-slash,
  "shovel": $fa-var-shovel,
  "shovel-snow": $fa-var-shovel-snow,
  "shower": $fa-var-shower,
  "shower-down": $fa-var-shower-down,
  "shower-alt": $fa-var-shower-alt,
  "shredder": $fa-var-shredder,
  "shrimp": $fa-var-shrimp,
  "shuffle": $fa-var-shuffle,
  "random": $fa-var-random,
  "shutters": $fa-var-shutters,
  "shuttle-space": $fa-var-shuttle-space,
  "space-shuttle": $fa-var-space-shuttle,
  "shuttlecock": $fa-var-shuttlecock,
  "sickle": $fa-var-sickle,
  "sidebar": $fa-var-sidebar,
  "sidebar-flip": $fa-var-sidebar-flip,
  "sigma": $fa-var-sigma,
  "sign-hanging": $fa-var-sign-hanging,
  "sign": $fa-var-sign,
  "sign-post": $fa-var-sign-post,
  "sign-posts": $fa-var-sign-posts,
  "sign-posts-wrench": $fa-var-sign-posts-wrench,
  "signal": $fa-var-signal,
  "signal-5": $fa-var-signal-5,
  "signal-perfect": $fa-var-signal-perfect,
  "signal-bars": $fa-var-signal-bars,
  "signal-alt": $fa-var-signal-alt,
  "signal-alt-4": $fa-var-signal-alt-4,
  "signal-bars-strong": $fa-var-signal-bars-strong,
  "signal-bars-fair": $fa-var-signal-bars-fair,
  "signal-alt-2": $fa-var-signal-alt-2,
  "signal-bars-good": $fa-var-signal-bars-good,
  "signal-alt-3": $fa-var-signal-alt-3,
  "signal-bars-slash": $fa-var-signal-bars-slash,
  "signal-alt-slash": $fa-var-signal-alt-slash,
  "signal-bars-weak": $fa-var-signal-bars-weak,
  "signal-alt-1": $fa-var-signal-alt-1,
  "signal-fair": $fa-var-signal-fair,
  "signal-2": $fa-var-signal-2,
  "signal-good": $fa-var-signal-good,
  "signal-3": $fa-var-signal-3,
  "signal-slash": $fa-var-signal-slash,
  "signal-stream": $fa-var-signal-stream,
  "signal-stream-slash": $fa-var-signal-stream-slash,
  "signal-strong": $fa-var-signal-strong,
  "signal-4": $fa-var-signal-4,
  "signal-weak": $fa-var-signal-weak,
  "signal-1": $fa-var-signal-1,
  "signature": $fa-var-signature,
  "signature-lock": $fa-var-signature-lock,
  "signature-slash": $fa-var-signature-slash,
  "signs-post": $fa-var-signs-post,
  "map-signs": $fa-var-map-signs,
  "sim-card": $fa-var-sim-card,
  "sim-cards": $fa-var-sim-cards,
  "sink": $fa-var-sink,
  "siren": $fa-var-siren,
  "siren-on": $fa-var-siren-on,
  "sitemap": $fa-var-sitemap,
  "skeleton": $fa-var-skeleton,
  "skeleton-ribs": $fa-var-skeleton-ribs,
  "ski-boot": $fa-var-ski-boot,
  "ski-boot-ski": $fa-var-ski-boot-ski,
  "skull": $fa-var-skull,
  "skull-cow": $fa-var-skull-cow,
  "skull-crossbones": $fa-var-skull-crossbones,
  "slash": $fa-var-slash,
  "slash-back": $fa-var-slash-back,
  "slash-forward": $fa-var-slash-forward,
  "sleigh": $fa-var-sleigh,
  "slider": $fa-var-slider,
  "sliders": $fa-var-sliders,
  "sliders-h": $fa-var-sliders-h,
  "sliders-simple": $fa-var-sliders-simple,
  "sliders-up": $fa-var-sliders-up,
  "sliders-v": $fa-var-sliders-v,
  "slot-machine": $fa-var-slot-machine,
  "smog": $fa-var-smog,
  "smoke": $fa-var-smoke,
  "smoking": $fa-var-smoking,
  "snake": $fa-var-snake,
  "snooze": $fa-var-snooze,
  "zzz": $fa-var-zzz,
  "snow-blowing": $fa-var-snow-blowing,
  "snowflake": $fa-var-snowflake,
  "snowflake-droplets": $fa-var-snowflake-droplets,
  "snowflakes": $fa-var-snowflakes,
  "snowman": $fa-var-snowman,
  "snowman-head": $fa-var-snowman-head,
  "frosty-head": $fa-var-frosty-head,
  "snowplow": $fa-var-snowplow,
  "soap": $fa-var-soap,
  "socks": $fa-var-socks,
  "soft-serve": $fa-var-soft-serve,
  "creemee": $fa-var-creemee,
  "solar-panel": $fa-var-solar-panel,
  "solar-system": $fa-var-solar-system,
  "sort": $fa-var-sort,
  "unsorted": $fa-var-unsorted,
  "sort-down": $fa-var-sort-down,
  "sort-desc": $fa-var-sort-desc,
  "sort-up": $fa-var-sort-up,
  "sort-asc": $fa-var-sort-asc,
  "spa": $fa-var-spa,
  "space-station-moon": $fa-var-space-station-moon,
  "space-station-moon-construction": $fa-var-space-station-moon-construction,
  "space-station-moon-alt": $fa-var-space-station-moon-alt,
  "spade": $fa-var-spade,
  "spaghetti-monster-flying": $fa-var-spaghetti-monster-flying,
  "pastafarianism": $fa-var-pastafarianism,
  "sparkle": $fa-var-sparkle,
  "sparkles": $fa-var-sparkles,
  "speaker": $fa-var-speaker,
  "speakers": $fa-var-speakers,
  "spell-check": $fa-var-spell-check,
  "spider": $fa-var-spider,
  "spider-black-widow": $fa-var-spider-black-widow,
  "spider-web": $fa-var-spider-web,
  "spinner": $fa-var-spinner,
  "spinner-scale": $fa-var-spinner-scale,
  "spinner-third": $fa-var-spinner-third,
  "split": $fa-var-split,
  "splotch": $fa-var-splotch,
  "spoon": $fa-var-spoon,
  "utensil-spoon": $fa-var-utensil-spoon,
  "sportsball": $fa-var-sportsball,
  "spray-can": $fa-var-spray-can,
  "spray-can-sparkles": $fa-var-spray-can-sparkles,
  "air-freshener": $fa-var-air-freshener,
  "sprinkler": $fa-var-sprinkler,
  "sprinkler-ceiling": $fa-var-sprinkler-ceiling,
  "square": $fa-var-square,
  "square-0": $fa-var-square-0,
  "square-1": $fa-var-square-1,
  "square-2": $fa-var-square-2,
  "square-3": $fa-var-square-3,
  "square-4": $fa-var-square-4,
  "square-5": $fa-var-square-5,
  "square-6": $fa-var-square-6,
  "square-7": $fa-var-square-7,
  "square-8": $fa-var-square-8,
  "square-9": $fa-var-square-9,
  "square-a": $fa-var-square-a,
  "square-a-lock": $fa-var-square-a-lock,
  "square-ampersand": $fa-var-square-ampersand,
  "square-arrow-down": $fa-var-square-arrow-down,
  "arrow-square-down": $fa-var-arrow-square-down,
  "square-arrow-down-left": $fa-var-square-arrow-down-left,
  "square-arrow-down-right": $fa-var-square-arrow-down-right,
  "square-arrow-left": $fa-var-square-arrow-left,
  "arrow-square-left": $fa-var-arrow-square-left,
  "square-arrow-right": $fa-var-square-arrow-right,
  "arrow-square-right": $fa-var-arrow-square-right,
  "square-arrow-up": $fa-var-square-arrow-up,
  "arrow-square-up": $fa-var-arrow-square-up,
  "square-arrow-up-left": $fa-var-square-arrow-up-left,
  "square-arrow-up-right": $fa-var-square-arrow-up-right,
  "external-link-square": $fa-var-external-link-square,
  "square-b": $fa-var-square-b,
  "square-bolt": $fa-var-square-bolt,
  "square-c": $fa-var-square-c,
  "square-caret-down": $fa-var-square-caret-down,
  "caret-square-down": $fa-var-caret-square-down,
  "square-caret-left": $fa-var-square-caret-left,
  "caret-square-left": $fa-var-caret-square-left,
  "square-caret-right": $fa-var-square-caret-right,
  "caret-square-right": $fa-var-caret-square-right,
  "square-caret-up": $fa-var-square-caret-up,
  "caret-square-up": $fa-var-caret-square-up,
  "square-check": $fa-var-square-check,
  "check-square": $fa-var-check-square,
  "square-chevron-down": $fa-var-square-chevron-down,
  "chevron-square-down": $fa-var-chevron-square-down,
  "square-chevron-left": $fa-var-square-chevron-left,
  "chevron-square-left": $fa-var-chevron-square-left,
  "square-chevron-right": $fa-var-square-chevron-right,
  "chevron-square-right": $fa-var-chevron-square-right,
  "square-chevron-up": $fa-var-square-chevron-up,
  "chevron-square-up": $fa-var-chevron-square-up,
  "square-code": $fa-var-square-code,
  "square-d": $fa-var-square-d,
  "square-dashed": $fa-var-square-dashed,
  "square-dashed-circle-plus": $fa-var-square-dashed-circle-plus,
  "square-divide": $fa-var-square-divide,
  "square-dollar": $fa-var-square-dollar,
  "dollar-square": $fa-var-dollar-square,
  "usd-square": $fa-var-usd-square,
  "square-down": $fa-var-square-down,
  "arrow-alt-square-down": $fa-var-arrow-alt-square-down,
  "square-down-left": $fa-var-square-down-left,
  "square-down-right": $fa-var-square-down-right,
  "square-e": $fa-var-square-e,
  "square-ellipsis": $fa-var-square-ellipsis,
  "square-ellipsis-vertical": $fa-var-square-ellipsis-vertical,
  "square-envelope": $fa-var-square-envelope,
  "envelope-square": $fa-var-envelope-square,
  "square-exclamation": $fa-var-square-exclamation,
  "exclamation-square": $fa-var-exclamation-square,
  "square-f": $fa-var-square-f,
  "square-fragile": $fa-var-square-fragile,
  "box-fragile": $fa-var-box-fragile,
  "square-wine-glass-crack": $fa-var-square-wine-glass-crack,
  "square-full": $fa-var-square-full,
  "square-g": $fa-var-square-g,
  "square-h": $fa-var-square-h,
  "h-square": $fa-var-h-square,
  "square-heart": $fa-var-square-heart,
  "heart-square": $fa-var-heart-square,
  "square-i": $fa-var-square-i,
  "square-info": $fa-var-square-info,
  "info-square": $fa-var-info-square,
  "square-j": $fa-var-square-j,
  "square-k": $fa-var-square-k,
  "square-kanban": $fa-var-square-kanban,
  "square-l": $fa-var-square-l,
  "square-left": $fa-var-square-left,
  "arrow-alt-square-left": $fa-var-arrow-alt-square-left,
  "square-list": $fa-var-square-list,
  "square-m": $fa-var-square-m,
  "square-minus": $fa-var-square-minus,
  "minus-square": $fa-var-minus-square,
  "square-n": $fa-var-square-n,
  "square-nfi": $fa-var-square-nfi,
  "square-o": $fa-var-square-o,
  "square-p": $fa-var-square-p,
  "square-parking": $fa-var-square-parking,
  "parking": $fa-var-parking,
  "square-parking-slash": $fa-var-square-parking-slash,
  "parking-slash": $fa-var-parking-slash,
  "square-pen": $fa-var-square-pen,
  "pen-square": $fa-var-pen-square,
  "pencil-square": $fa-var-pencil-square,
  "square-person-confined": $fa-var-square-person-confined,
  "square-phone": $fa-var-square-phone,
  "phone-square": $fa-var-phone-square,
  "square-phone-flip": $fa-var-square-phone-flip,
  "phone-square-alt": $fa-var-phone-square-alt,
  "square-phone-hangup": $fa-var-square-phone-hangup,
  "phone-square-down": $fa-var-phone-square-down,
  "square-plus": $fa-var-square-plus,
  "plus-square": $fa-var-plus-square,
  "square-poll-horizontal": $fa-var-square-poll-horizontal,
  "poll-h": $fa-var-poll-h,
  "square-poll-vertical": $fa-var-square-poll-vertical,
  "poll": $fa-var-poll,
  "square-q": $fa-var-square-q,
  "square-quarters": $fa-var-square-quarters,
  "square-question": $fa-var-square-question,
  "question-square": $fa-var-question-square,
  "square-quote": $fa-var-square-quote,
  "square-r": $fa-var-square-r,
  "square-right": $fa-var-square-right,
  "arrow-alt-square-right": $fa-var-arrow-alt-square-right,
  "square-ring": $fa-var-square-ring,
  "square-root": $fa-var-square-root,
  "square-root-variable": $fa-var-square-root-variable,
  "square-root-alt": $fa-var-square-root-alt,
  "square-rss": $fa-var-square-rss,
  "rss-square": $fa-var-rss-square,
  "square-s": $fa-var-square-s,
  "square-share-nodes": $fa-var-square-share-nodes,
  "share-alt-square": $fa-var-share-alt-square,
  "square-sliders": $fa-var-square-sliders,
  "sliders-h-square": $fa-var-sliders-h-square,
  "square-sliders-vertical": $fa-var-square-sliders-vertical,
  "sliders-v-square": $fa-var-sliders-v-square,
  "square-small": $fa-var-square-small,
  "square-star": $fa-var-square-star,
  "square-t": $fa-var-square-t,
  "square-terminal": $fa-var-square-terminal,
  "square-this-way-up": $fa-var-square-this-way-up,
  "box-up": $fa-var-box-up,
  "square-u": $fa-var-square-u,
  "square-up": $fa-var-square-up,
  "arrow-alt-square-up": $fa-var-arrow-alt-square-up,
  "square-up-left": $fa-var-square-up-left,
  "square-up-right": $fa-var-square-up-right,
  "external-link-square-alt": $fa-var-external-link-square-alt,
  "square-user": $fa-var-square-user,
  "square-v": $fa-var-square-v,
  "square-virus": $fa-var-square-virus,
  "square-w": $fa-var-square-w,
  "square-x": $fa-var-square-x,
  "square-xmark": $fa-var-square-xmark,
  "times-square": $fa-var-times-square,
  "xmark-square": $fa-var-xmark-square,
  "square-y": $fa-var-square-y,
  "square-z": $fa-var-square-z,
  "squid": $fa-var-squid,
  "squirrel": $fa-var-squirrel,
  "staff": $fa-var-staff,
  "staff-snake": $fa-var-staff-snake,
  "rod-asclepius": $fa-var-rod-asclepius,
  "rod-snake": $fa-var-rod-snake,
  "staff-aesculapius": $fa-var-staff-aesculapius,
  "stairs": $fa-var-stairs,
  "stamp": $fa-var-stamp,
  "standard-definition": $fa-var-standard-definition,
  "rectangle-sd": $fa-var-rectangle-sd,
  "stapler": $fa-var-stapler,
  "star": $fa-var-star,
  "star-and-crescent": $fa-var-star-and-crescent,
  "star-christmas": $fa-var-star-christmas,
  "star-exclamation": $fa-var-star-exclamation,
  "star-half": $fa-var-star-half,
  "star-half-stroke": $fa-var-star-half-stroke,
  "star-half-alt": $fa-var-star-half-alt,
  "star-of-david": $fa-var-star-of-david,
  "star-of-life": $fa-var-star-of-life,
  "star-sharp": $fa-var-star-sharp,
  "star-sharp-half": $fa-var-star-sharp-half,
  "star-sharp-half-stroke": $fa-var-star-sharp-half-stroke,
  "star-sharp-half-alt": $fa-var-star-sharp-half-alt,
  "star-shooting": $fa-var-star-shooting,
  "starfighter": $fa-var-starfighter,
  "starfighter-twin-ion-engine": $fa-var-starfighter-twin-ion-engine,
  "starfighter-alt": $fa-var-starfighter-alt,
  "starfighter-twin-ion-engine-advanced": $fa-var-starfighter-twin-ion-engine-advanced,
  "starfighter-alt-advanced": $fa-var-starfighter-alt-advanced,
  "stars": $fa-var-stars,
  "starship": $fa-var-starship,
  "starship-freighter": $fa-var-starship-freighter,
  "steak": $fa-var-steak,
  "steering-wheel": $fa-var-steering-wheel,
  "sterling-sign": $fa-var-sterling-sign,
  "gbp": $fa-var-gbp,
  "pound-sign": $fa-var-pound-sign,
  "stethoscope": $fa-var-stethoscope,
  "stocking": $fa-var-stocking,
  "stomach": $fa-var-stomach,
  "stop": $fa-var-stop,
  "stopwatch": $fa-var-stopwatch,
  "stopwatch-20": $fa-var-stopwatch-20,
  "store": $fa-var-store,
  "store-lock": $fa-var-store-lock,
  "store-slash": $fa-var-store-slash,
  "strawberry": $fa-var-strawberry,
  "street-view": $fa-var-street-view,
  "stretcher": $fa-var-stretcher,
  "strikethrough": $fa-var-strikethrough,
  "stroopwafel": $fa-var-stroopwafel,
  "subscript": $fa-var-subscript,
  "subtitles": $fa-var-subtitles,
  "subtitles-slash": $fa-var-subtitles-slash,
  "suitcase": $fa-var-suitcase,
  "suitcase-medical": $fa-var-suitcase-medical,
  "medkit": $fa-var-medkit,
  "suitcase-rolling": $fa-var-suitcase-rolling,
  "sun": $fa-var-sun,
  "sun-bright": $fa-var-sun-bright,
  "sun-alt": $fa-var-sun-alt,
  "sun-cloud": $fa-var-sun-cloud,
  "sun-dust": $fa-var-sun-dust,
  "sun-haze": $fa-var-sun-haze,
  "sun-plant-wilt": $fa-var-sun-plant-wilt,
  "sunglasses": $fa-var-sunglasses,
  "sunrise": $fa-var-sunrise,
  "sunset": $fa-var-sunset,
  "superscript": $fa-var-superscript,
  "sushi": $fa-var-sushi,
  "nigiri": $fa-var-nigiri,
  "sushi-roll": $fa-var-sushi-roll,
  "maki-roll": $fa-var-maki-roll,
  "makizushi": $fa-var-makizushi,
  "swap": $fa-var-swap,
  "swap-arrows": $fa-var-swap-arrows,
  "swatchbook": $fa-var-swatchbook,
  "sword": $fa-var-sword,
  "sword-laser": $fa-var-sword-laser,
  "sword-laser-alt": $fa-var-sword-laser-alt,
  "swords": $fa-var-swords,
  "swords-laser": $fa-var-swords-laser,
  "symbols": $fa-var-symbols,
  "icons-alt": $fa-var-icons-alt,
  "synagogue": $fa-var-synagogue,
  "syringe": $fa-var-syringe,
  "t": $fa-var-t,
  "t-rex": $fa-var-t-rex,
  "table": $fa-var-table,
  "table-cells": $fa-var-table-cells,
  "th": $fa-var-th,
  "table-cells-column-lock": $fa-var-table-cells-column-lock,
  "table-cells-large": $fa-var-table-cells-large,
  "th-large": $fa-var-th-large,
  "table-cells-lock": $fa-var-table-cells-lock,
  "table-cells-row-lock": $fa-var-table-cells-row-lock,
  "table-columns": $fa-var-table-columns,
  "columns": $fa-var-columns,
  "table-layout": $fa-var-table-layout,
  "table-list": $fa-var-table-list,
  "th-list": $fa-var-th-list,
  "table-picnic": $fa-var-table-picnic,
  "table-pivot": $fa-var-table-pivot,
  "table-rows": $fa-var-table-rows,
  "rows": $fa-var-rows,
  "table-tennis-paddle-ball": $fa-var-table-tennis-paddle-ball,
  "ping-pong-paddle-ball": $fa-var-ping-pong-paddle-ball,
  "table-tennis": $fa-var-table-tennis,
  "table-tree": $fa-var-table-tree,
  "tablet": $fa-var-tablet,
  "tablet-android": $fa-var-tablet-android,
  "tablet-button": $fa-var-tablet-button,
  "tablet-rugged": $fa-var-tablet-rugged,
  "tablet-screen": $fa-var-tablet-screen,
  "tablet-android-alt": $fa-var-tablet-android-alt,
  "tablet-screen-button": $fa-var-tablet-screen-button,
  "tablet-alt": $fa-var-tablet-alt,
  "tablets": $fa-var-tablets,
  "tachograph-digital": $fa-var-tachograph-digital,
  "digital-tachograph": $fa-var-digital-tachograph,
  "taco": $fa-var-taco,
  "tag": $fa-var-tag,
  "tags": $fa-var-tags,
  "tally": $fa-var-tally,
  "tally-5": $fa-var-tally-5,
  "tally-1": $fa-var-tally-1,
  "tally-2": $fa-var-tally-2,
  "tally-3": $fa-var-tally-3,
  "tally-4": $fa-var-tally-4,
  "tamale": $fa-var-tamale,
  "tank-water": $fa-var-tank-water,
  "tape": $fa-var-tape,
  "tarp": $fa-var-tarp,
  "tarp-droplet": $fa-var-tarp-droplet,
  "taxi": $fa-var-taxi,
  "cab": $fa-var-cab,
  "taxi-bus": $fa-var-taxi-bus,
  "teddy-bear": $fa-var-teddy-bear,
  "teeth": $fa-var-teeth,
  "teeth-open": $fa-var-teeth-open,
  "telescope": $fa-var-telescope,
  "temperature-arrow-down": $fa-var-temperature-arrow-down,
  "temperature-down": $fa-var-temperature-down,
  "temperature-arrow-up": $fa-var-temperature-arrow-up,
  "temperature-up": $fa-var-temperature-up,
  "temperature-empty": $fa-var-temperature-empty,
  "temperature-0": $fa-var-temperature-0,
  "thermometer-0": $fa-var-thermometer-0,
  "thermometer-empty": $fa-var-thermometer-empty,
  "temperature-full": $fa-var-temperature-full,
  "temperature-4": $fa-var-temperature-4,
  "thermometer-4": $fa-var-thermometer-4,
  "thermometer-full": $fa-var-thermometer-full,
  "temperature-half": $fa-var-temperature-half,
  "temperature-2": $fa-var-temperature-2,
  "thermometer-2": $fa-var-thermometer-2,
  "thermometer-half": $fa-var-thermometer-half,
  "temperature-high": $fa-var-temperature-high,
  "temperature-list": $fa-var-temperature-list,
  "temperature-low": $fa-var-temperature-low,
  "temperature-quarter": $fa-var-temperature-quarter,
  "temperature-1": $fa-var-temperature-1,
  "thermometer-1": $fa-var-thermometer-1,
  "thermometer-quarter": $fa-var-thermometer-quarter,
  "temperature-snow": $fa-var-temperature-snow,
  "temperature-frigid": $fa-var-temperature-frigid,
  "temperature-sun": $fa-var-temperature-sun,
  "temperature-hot": $fa-var-temperature-hot,
  "temperature-three-quarters": $fa-var-temperature-three-quarters,
  "temperature-3": $fa-var-temperature-3,
  "thermometer-3": $fa-var-thermometer-3,
  "thermometer-three-quarters": $fa-var-thermometer-three-quarters,
  "tenge-sign": $fa-var-tenge-sign,
  "tenge": $fa-var-tenge,
  "tennis-ball": $fa-var-tennis-ball,
  "tent": $fa-var-tent,
  "tent-arrow-down-to-line": $fa-var-tent-arrow-down-to-line,
  "tent-arrow-left-right": $fa-var-tent-arrow-left-right,
  "tent-arrow-turn-left": $fa-var-tent-arrow-turn-left,
  "tent-arrows-down": $fa-var-tent-arrows-down,
  "tent-double-peak": $fa-var-tent-double-peak,
  "tents": $fa-var-tents,
  "terminal": $fa-var-terminal,
  "text": $fa-var-text,
  "text-height": $fa-var-text-height,
  "text-size": $fa-var-text-size,
  "text-slash": $fa-var-text-slash,
  "remove-format": $fa-var-remove-format,
  "text-width": $fa-var-text-width,
  "thermometer": $fa-var-thermometer,
  "theta": $fa-var-theta,
  "thought-bubble": $fa-var-thought-bubble,
  "thumbs-down": $fa-var-thumbs-down,
  "thumbs-up": $fa-var-thumbs-up,
  "thumbtack": $fa-var-thumbtack,
  "thumb-tack": $fa-var-thumb-tack,
  "tick": $fa-var-tick,
  "ticket": $fa-var-ticket,
  "ticket-airline": $fa-var-ticket-airline,
  "ticket-perforated-plane": $fa-var-ticket-perforated-plane,
  "ticket-plane": $fa-var-ticket-plane,
  "ticket-perforated": $fa-var-ticket-perforated,
  "ticket-simple": $fa-var-ticket-simple,
  "ticket-alt": $fa-var-ticket-alt,
  "tickets": $fa-var-tickets,
  "tickets-airline": $fa-var-tickets-airline,
  "tickets-perforated-plane": $fa-var-tickets-perforated-plane,
  "tickets-plane": $fa-var-tickets-plane,
  "tickets-perforated": $fa-var-tickets-perforated,
  "tickets-simple": $fa-var-tickets-simple,
  "tilde": $fa-var-tilde,
  "timeline": $fa-var-timeline,
  "timeline-arrow": $fa-var-timeline-arrow,
  "timer": $fa-var-timer,
  "tire": $fa-var-tire,
  "tire-flat": $fa-var-tire-flat,
  "tire-pressure-warning": $fa-var-tire-pressure-warning,
  "tire-rugged": $fa-var-tire-rugged,
  "toggle-large-off": $fa-var-toggle-large-off,
  "toggle-large-on": $fa-var-toggle-large-on,
  "toggle-off": $fa-var-toggle-off,
  "toggle-on": $fa-var-toggle-on,
  "toilet": $fa-var-toilet,
  "toilet-paper": $fa-var-toilet-paper,
  "toilet-paper-blank": $fa-var-toilet-paper-blank,
  "toilet-paper-alt": $fa-var-toilet-paper-alt,
  "toilet-paper-blank-under": $fa-var-toilet-paper-blank-under,
  "toilet-paper-reverse-alt": $fa-var-toilet-paper-reverse-alt,
  "toilet-paper-check": $fa-var-toilet-paper-check,
  "toilet-paper-slash": $fa-var-toilet-paper-slash,
  "toilet-paper-under": $fa-var-toilet-paper-under,
  "toilet-paper-reverse": $fa-var-toilet-paper-reverse,
  "toilet-paper-under-slash": $fa-var-toilet-paper-under-slash,
  "toilet-paper-reverse-slash": $fa-var-toilet-paper-reverse-slash,
  "toilet-paper-xmark": $fa-var-toilet-paper-xmark,
  "toilet-portable": $fa-var-toilet-portable,
  "toilets-portable": $fa-var-toilets-portable,
  "tomato": $fa-var-tomato,
  "tombstone": $fa-var-tombstone,
  "tombstone-blank": $fa-var-tombstone-blank,
  "tombstone-alt": $fa-var-tombstone-alt,
  "toolbox": $fa-var-toolbox,
  "tooth": $fa-var-tooth,
  "toothbrush": $fa-var-toothbrush,
  "torii-gate": $fa-var-torii-gate,
  "tornado": $fa-var-tornado,
  "tower-broadcast": $fa-var-tower-broadcast,
  "broadcast-tower": $fa-var-broadcast-tower,
  "tower-cell": $fa-var-tower-cell,
  "tower-control": $fa-var-tower-control,
  "tower-observation": $fa-var-tower-observation,
  "tractor": $fa-var-tractor,
  "trademark": $fa-var-trademark,
  "traffic-cone": $fa-var-traffic-cone,
  "traffic-light": $fa-var-traffic-light,
  "traffic-light-go": $fa-var-traffic-light-go,
  "traffic-light-slow": $fa-var-traffic-light-slow,
  "traffic-light-stop": $fa-var-traffic-light-stop,
  "trailer": $fa-var-trailer,
  "train": $fa-var-train,
  "train-subway": $fa-var-train-subway,
  "subway": $fa-var-subway,
  "train-subway-tunnel": $fa-var-train-subway-tunnel,
  "subway-tunnel": $fa-var-subway-tunnel,
  "train-track": $fa-var-train-track,
  "train-tram": $fa-var-train-tram,
  "train-tunnel": $fa-var-train-tunnel,
  "transformer-bolt": $fa-var-transformer-bolt,
  "transgender": $fa-var-transgender,
  "transgender-alt": $fa-var-transgender-alt,
  "transporter": $fa-var-transporter,
  "transporter-1": $fa-var-transporter-1,
  "transporter-2": $fa-var-transporter-2,
  "transporter-3": $fa-var-transporter-3,
  "transporter-4": $fa-var-transporter-4,
  "transporter-5": $fa-var-transporter-5,
  "transporter-6": $fa-var-transporter-6,
  "transporter-7": $fa-var-transporter-7,
  "transporter-empty": $fa-var-transporter-empty,
  "trash": $fa-var-trash,
  "trash-arrow-up": $fa-var-trash-arrow-up,
  "trash-restore": $fa-var-trash-restore,
  "trash-can": $fa-var-trash-can,
  "trash-alt": $fa-var-trash-alt,
  "trash-can-arrow-up": $fa-var-trash-can-arrow-up,
  "trash-restore-alt": $fa-var-trash-restore-alt,
  "trash-can-check": $fa-var-trash-can-check,
  "trash-can-clock": $fa-var-trash-can-clock,
  "trash-can-list": $fa-var-trash-can-list,
  "trash-can-plus": $fa-var-trash-can-plus,
  "trash-can-slash": $fa-var-trash-can-slash,
  "trash-alt-slash": $fa-var-trash-alt-slash,
  "trash-can-undo": $fa-var-trash-can-undo,
  "trash-can-arrow-turn-left": $fa-var-trash-can-arrow-turn-left,
  "trash-undo-alt": $fa-var-trash-undo-alt,
  "trash-can-xmark": $fa-var-trash-can-xmark,
  "trash-check": $fa-var-trash-check,
  "trash-clock": $fa-var-trash-clock,
  "trash-list": $fa-var-trash-list,
  "trash-plus": $fa-var-trash-plus,
  "trash-slash": $fa-var-trash-slash,
  "trash-undo": $fa-var-trash-undo,
  "trash-arrow-turn-left": $fa-var-trash-arrow-turn-left,
  "trash-xmark": $fa-var-trash-xmark,
  "treasure-chest": $fa-var-treasure-chest,
  "tree": $fa-var-tree,
  "tree-christmas": $fa-var-tree-christmas,
  "tree-city": $fa-var-tree-city,
  "tree-deciduous": $fa-var-tree-deciduous,
  "tree-alt": $fa-var-tree-alt,
  "tree-decorated": $fa-var-tree-decorated,
  "tree-large": $fa-var-tree-large,
  "tree-palm": $fa-var-tree-palm,
  "trees": $fa-var-trees,
  "triangle": $fa-var-triangle,
  "triangle-exclamation": $fa-var-triangle-exclamation,
  "exclamation-triangle": $fa-var-exclamation-triangle,
  "warning": $fa-var-warning,
  "triangle-instrument": $fa-var-triangle-instrument,
  "triangle-music": $fa-var-triangle-music,
  "triangle-person-digging": $fa-var-triangle-person-digging,
  "construction": $fa-var-construction,
  "tricycle": $fa-var-tricycle,
  "tricycle-adult": $fa-var-tricycle-adult,
  "trillium": $fa-var-trillium,
  "trophy": $fa-var-trophy,
  "trophy-star": $fa-var-trophy-star,
  "trophy-alt": $fa-var-trophy-alt,
  "trowel": $fa-var-trowel,
  "trowel-bricks": $fa-var-trowel-bricks,
  "truck": $fa-var-truck,
  "truck-arrow-right": $fa-var-truck-arrow-right,
  "truck-bolt": $fa-var-truck-bolt,
  "truck-clock": $fa-var-truck-clock,
  "shipping-timed": $fa-var-shipping-timed,
  "truck-container": $fa-var-truck-container,
  "truck-container-empty": $fa-var-truck-container-empty,
  "truck-droplet": $fa-var-truck-droplet,
  "truck-fast": $fa-var-truck-fast,
  "shipping-fast": $fa-var-shipping-fast,
  "truck-field": $fa-var-truck-field,
  "truck-field-un": $fa-var-truck-field-un,
  "truck-fire": $fa-var-truck-fire,
  "truck-flatbed": $fa-var-truck-flatbed,
  "truck-front": $fa-var-truck-front,
  "truck-ladder": $fa-var-truck-ladder,
  "truck-medical": $fa-var-truck-medical,
  "ambulance": $fa-var-ambulance,
  "truck-monster": $fa-var-truck-monster,
  "truck-moving": $fa-var-truck-moving,
  "truck-pickup": $fa-var-truck-pickup,
  "truck-plane": $fa-var-truck-plane,
  "truck-plow": $fa-var-truck-plow,
  "truck-ramp": $fa-var-truck-ramp,
  "truck-ramp-box": $fa-var-truck-ramp-box,
  "truck-loading": $fa-var-truck-loading,
  "truck-ramp-couch": $fa-var-truck-ramp-couch,
  "truck-couch": $fa-var-truck-couch,
  "truck-tow": $fa-var-truck-tow,
  "truck-utensils": $fa-var-truck-utensils,
  "trumpet": $fa-var-trumpet,
  "tty": $fa-var-tty,
  "teletype": $fa-var-teletype,
  "tty-answer": $fa-var-tty-answer,
  "teletype-answer": $fa-var-teletype-answer,
  "tugrik-sign": $fa-var-tugrik-sign,
  "turkey": $fa-var-turkey,
  "turkish-lira-sign": $fa-var-turkish-lira-sign,
  "try": $fa-var-try,
  "turkish-lira": $fa-var-turkish-lira,
  "turn-down": $fa-var-turn-down,
  "level-down-alt": $fa-var-level-down-alt,
  "turn-down-left": $fa-var-turn-down-left,
  "turn-down-right": $fa-var-turn-down-right,
  "turn-left": $fa-var-turn-left,
  "turn-left-down": $fa-var-turn-left-down,
  "turn-left-up": $fa-var-turn-left-up,
  "turn-right": $fa-var-turn-right,
  "turn-up": $fa-var-turn-up,
  "level-up-alt": $fa-var-level-up-alt,
  "turntable": $fa-var-turntable,
  "turtle": $fa-var-turtle,
  "tv": $fa-var-tv,
  "television": $fa-var-television,
  "tv-alt": $fa-var-tv-alt,
  "tv-music": $fa-var-tv-music,
  "tv-retro": $fa-var-tv-retro,
  "typewriter": $fa-var-typewriter,
  "u": $fa-var-u,
  "ufo": $fa-var-ufo,
  "ufo-beam": $fa-var-ufo-beam,
  "umbrella": $fa-var-umbrella,
  "umbrella-beach": $fa-var-umbrella-beach,
  "umbrella-simple": $fa-var-umbrella-simple,
  "umbrella-alt": $fa-var-umbrella-alt,
  "underline": $fa-var-underline,
  "unicorn": $fa-var-unicorn,
  "uniform-martial-arts": $fa-var-uniform-martial-arts,
  "union": $fa-var-union,
  "universal-access": $fa-var-universal-access,
  "unlock": $fa-var-unlock,
  "unlock-keyhole": $fa-var-unlock-keyhole,
  "unlock-alt": $fa-var-unlock-alt,
  "up": $fa-var-up,
  "arrow-alt-up": $fa-var-arrow-alt-up,
  "up-down": $fa-var-up-down,
  "arrows-alt-v": $fa-var-arrows-alt-v,
  "up-down-left-right": $fa-var-up-down-left-right,
  "arrows-alt": $fa-var-arrows-alt,
  "up-from-bracket": $fa-var-up-from-bracket,
  "up-from-dotted-line": $fa-var-up-from-dotted-line,
  "up-from-line": $fa-var-up-from-line,
  "arrow-alt-from-bottom": $fa-var-arrow-alt-from-bottom,
  "up-left": $fa-var-up-left,
  "up-long": $fa-var-up-long,
  "long-arrow-alt-up": $fa-var-long-arrow-alt-up,
  "up-right": $fa-var-up-right,
  "up-right-and-down-left-from-center": $fa-var-up-right-and-down-left-from-center,
  "expand-alt": $fa-var-expand-alt,
  "up-right-from-square": $fa-var-up-right-from-square,
  "external-link-alt": $fa-var-external-link-alt,
  "up-to-bracket": $fa-var-up-to-bracket,
  "up-to-dotted-line": $fa-var-up-to-dotted-line,
  "up-to-line": $fa-var-up-to-line,
  "arrow-alt-to-top": $fa-var-arrow-alt-to-top,
  "upload": $fa-var-upload,
  "usb-drive": $fa-var-usb-drive,
  "user": $fa-var-user,
  "user-alien": $fa-var-user-alien,
  "user-astronaut": $fa-var-user-astronaut,
  "user-bounty-hunter": $fa-var-user-bounty-hunter,
  "user-check": $fa-var-user-check,
  "user-chef": $fa-var-user-chef,
  "user-clock": $fa-var-user-clock,
  "user-cowboy": $fa-var-user-cowboy,
  "user-crown": $fa-var-user-crown,
  "user-doctor": $fa-var-user-doctor,
  "user-md": $fa-var-user-md,
  "user-doctor-hair": $fa-var-user-doctor-hair,
  "user-doctor-hair-long": $fa-var-user-doctor-hair-long,
  "user-doctor-message": $fa-var-user-doctor-message,
  "user-md-chat": $fa-var-user-md-chat,
  "user-gear": $fa-var-user-gear,
  "user-cog": $fa-var-user-cog,
  "user-graduate": $fa-var-user-graduate,
  "user-group": $fa-var-user-group,
  "user-friends": $fa-var-user-friends,
  "user-group-crown": $fa-var-user-group-crown,
  "users-crown": $fa-var-users-crown,
  "user-group-simple": $fa-var-user-group-simple,
  "user-hair": $fa-var-user-hair,
  "user-hair-buns": $fa-var-user-hair-buns,
  "user-hair-long": $fa-var-user-hair-long,
  "user-hair-mullet": $fa-var-user-hair-mullet,
  "business-front": $fa-var-business-front,
  "party-back": $fa-var-party-back,
  "trian-balbot": $fa-var-trian-balbot,
  "user-headset": $fa-var-user-headset,
  "user-helmet-safety": $fa-var-user-helmet-safety,
  "user-construction": $fa-var-user-construction,
  "user-hard-hat": $fa-var-user-hard-hat,
  "user-injured": $fa-var-user-injured,
  "user-large": $fa-var-user-large,
  "user-alt": $fa-var-user-alt,
  "user-large-slash": $fa-var-user-large-slash,
  "user-alt-slash": $fa-var-user-alt-slash,
  "user-lock": $fa-var-user-lock,
  "user-magnifying-glass": $fa-var-user-magnifying-glass,
  "user-minus": $fa-var-user-minus,
  "user-music": $fa-var-user-music,
  "user-ninja": $fa-var-user-ninja,
  "user-nurse": $fa-var-user-nurse,
  "user-nurse-hair": $fa-var-user-nurse-hair,
  "user-nurse-hair-long": $fa-var-user-nurse-hair-long,
  "user-pen": $fa-var-user-pen,
  "user-edit": $fa-var-user-edit,
  "user-pilot": $fa-var-user-pilot,
  "user-pilot-tie": $fa-var-user-pilot-tie,
  "user-plus": $fa-var-user-plus,
  "user-police": $fa-var-user-police,
  "user-police-tie": $fa-var-user-police-tie,
  "user-robot": $fa-var-user-robot,
  "user-robot-xmarks": $fa-var-user-robot-xmarks,
  "user-secret": $fa-var-user-secret,
  "user-shakespeare": $fa-var-user-shakespeare,
  "user-shield": $fa-var-user-shield,
  "user-slash": $fa-var-user-slash,
  "user-tag": $fa-var-user-tag,
  "user-tie": $fa-var-user-tie,
  "user-tie-hair": $fa-var-user-tie-hair,
  "user-tie-hair-long": $fa-var-user-tie-hair-long,
  "user-unlock": $fa-var-user-unlock,
  "user-visor": $fa-var-user-visor,
  "user-vneck": $fa-var-user-vneck,
  "user-vneck-hair": $fa-var-user-vneck-hair,
  "user-vneck-hair-long": $fa-var-user-vneck-hair-long,
  "user-xmark": $fa-var-user-xmark,
  "user-times": $fa-var-user-times,
  "users": $fa-var-users,
  "users-between-lines": $fa-var-users-between-lines,
  "users-gear": $fa-var-users-gear,
  "users-cog": $fa-var-users-cog,
  "users-line": $fa-var-users-line,
  "users-medical": $fa-var-users-medical,
  "users-rays": $fa-var-users-rays,
  "users-rectangle": $fa-var-users-rectangle,
  "users-slash": $fa-var-users-slash,
  "users-viewfinder": $fa-var-users-viewfinder,
  "utensils": $fa-var-utensils,
  "cutlery": $fa-var-cutlery,
  "utensils-slash": $fa-var-utensils-slash,
  "utility-pole": $fa-var-utility-pole,
  "utility-pole-double": $fa-var-utility-pole-double,
  "v": $fa-var-v,
  "vacuum": $fa-var-vacuum,
  "vacuum-robot": $fa-var-vacuum-robot,
  "value-absolute": $fa-var-value-absolute,
  "van-shuttle": $fa-var-van-shuttle,
  "shuttle-van": $fa-var-shuttle-van,
  "vault": $fa-var-vault,
  "vector-circle": $fa-var-vector-circle,
  "vector-polygon": $fa-var-vector-polygon,
  "vector-square": $fa-var-vector-square,
  "vent-damper": $fa-var-vent-damper,
  "venus": $fa-var-venus,
  "venus-double": $fa-var-venus-double,
  "venus-mars": $fa-var-venus-mars,
  "vest": $fa-var-vest,
  "vest-patches": $fa-var-vest-patches,
  "vial": $fa-var-vial,
  "vial-circle-check": $fa-var-vial-circle-check,
  "vial-virus": $fa-var-vial-virus,
  "vials": $fa-var-vials,
  "video": $fa-var-video,
  "video-camera": $fa-var-video-camera,
  "video-arrow-down-left": $fa-var-video-arrow-down-left,
  "video-arrow-up-right": $fa-var-video-arrow-up-right,
  "video-plus": $fa-var-video-plus,
  "video-slash": $fa-var-video-slash,
  "vihara": $fa-var-vihara,
  "violin": $fa-var-violin,
  "virus": $fa-var-virus,
  "virus-covid": $fa-var-virus-covid,
  "virus-covid-slash": $fa-var-virus-covid-slash,
  "virus-slash": $fa-var-virus-slash,
  "viruses": $fa-var-viruses,
  "voicemail": $fa-var-voicemail,
  "volcano": $fa-var-volcano,
  "volleyball": $fa-var-volleyball,
  "volleyball-ball": $fa-var-volleyball-ball,
  "volume": $fa-var-volume,
  "volume-medium": $fa-var-volume-medium,
  "volume-high": $fa-var-volume-high,
  "volume-up": $fa-var-volume-up,
  "volume-low": $fa-var-volume-low,
  "volume-down": $fa-var-volume-down,
  "volume-off": $fa-var-volume-off,
  "volume-slash": $fa-var-volume-slash,
  "volume-xmark": $fa-var-volume-xmark,
  "volume-mute": $fa-var-volume-mute,
  "volume-times": $fa-var-volume-times,
  "vr-cardboard": $fa-var-vr-cardboard,
  "w": $fa-var-w,
  "waffle": $fa-var-waffle,
  "wagon-covered": $fa-var-wagon-covered,
  "walker": $fa-var-walker,
  "walkie-talkie": $fa-var-walkie-talkie,
  "wallet": $fa-var-wallet,
  "wand": $fa-var-wand,
  "wand-magic": $fa-var-wand-magic,
  "magic": $fa-var-magic,
  "wand-magic-sparkles": $fa-var-wand-magic-sparkles,
  "magic-wand-sparkles": $fa-var-magic-wand-sparkles,
  "wand-sparkles": $fa-var-wand-sparkles,
  "warehouse": $fa-var-warehouse,
  "warehouse-full": $fa-var-warehouse-full,
  "warehouse-alt": $fa-var-warehouse-alt,
  "washing-machine": $fa-var-washing-machine,
  "washer": $fa-var-washer,
  "watch": $fa-var-watch,
  "watch-apple": $fa-var-watch-apple,
  "watch-calculator": $fa-var-watch-calculator,
  "watch-fitness": $fa-var-watch-fitness,
  "watch-smart": $fa-var-watch-smart,
  "water": $fa-var-water,
  "water-arrow-down": $fa-var-water-arrow-down,
  "water-lower": $fa-var-water-lower,
  "water-arrow-up": $fa-var-water-arrow-up,
  "water-rise": $fa-var-water-rise,
  "water-ladder": $fa-var-water-ladder,
  "ladder-water": $fa-var-ladder-water,
  "swimming-pool": $fa-var-swimming-pool,
  "watermelon-slice": $fa-var-watermelon-slice,
  "wave": $fa-var-wave,
  "wave-pulse": $fa-var-wave-pulse,
  "heart-rate": $fa-var-heart-rate,
  "wave-sine": $fa-var-wave-sine,
  "wave-square": $fa-var-wave-square,
  "wave-triangle": $fa-var-wave-triangle,
  "waveform": $fa-var-waveform,
  "waveform-lines": $fa-var-waveform-lines,
  "waveform-path": $fa-var-waveform-path,
  "waves-sine": $fa-var-waves-sine,
  "webhook": $fa-var-webhook,
  "weight-hanging": $fa-var-weight-hanging,
  "weight-scale": $fa-var-weight-scale,
  "weight": $fa-var-weight,
  "whale": $fa-var-whale,
  "wheat": $fa-var-wheat,
  "wheat-awn": $fa-var-wheat-awn,
  "wheat-alt": $fa-var-wheat-alt,
  "wheat-awn-circle-exclamation": $fa-var-wheat-awn-circle-exclamation,
  "wheat-awn-slash": $fa-var-wheat-awn-slash,
  "wheat-slash": $fa-var-wheat-slash,
  "wheelchair": $fa-var-wheelchair,
  "wheelchair-move": $fa-var-wheelchair-move,
  "wheelchair-alt": $fa-var-wheelchair-alt,
  "whiskey-glass": $fa-var-whiskey-glass,
  "glass-whiskey": $fa-var-glass-whiskey,
  "whiskey-glass-ice": $fa-var-whiskey-glass-ice,
  "glass-whiskey-rocks": $fa-var-glass-whiskey-rocks,
  "whistle": $fa-var-whistle,
  "wifi": $fa-var-wifi,
  "wifi-3": $fa-var-wifi-3,
  "wifi-strong": $fa-var-wifi-strong,
  "wifi-exclamation": $fa-var-wifi-exclamation,
  "wifi-fair": $fa-var-wifi-fair,
  "wifi-2": $fa-var-wifi-2,
  "wifi-slash": $fa-var-wifi-slash,
  "wifi-weak": $fa-var-wifi-weak,
  "wifi-1": $fa-var-wifi-1,
  "wind": $fa-var-wind,
  "wind-turbine": $fa-var-wind-turbine,
  "wind-warning": $fa-var-wind-warning,
  "wind-circle-exclamation": $fa-var-wind-circle-exclamation,
  "window": $fa-var-window,
  "window-flip": $fa-var-window-flip,
  "window-alt": $fa-var-window-alt,
  "window-frame": $fa-var-window-frame,
  "window-frame-open": $fa-var-window-frame-open,
  "window-maximize": $fa-var-window-maximize,
  "window-minimize": $fa-var-window-minimize,
  "window-restore": $fa-var-window-restore,
  "windsock": $fa-var-windsock,
  "wine-bottle": $fa-var-wine-bottle,
  "wine-glass": $fa-var-wine-glass,
  "wine-glass-crack": $fa-var-wine-glass-crack,
  "fragile": $fa-var-fragile,
  "wine-glass-empty": $fa-var-wine-glass-empty,
  "wine-glass-alt": $fa-var-wine-glass-alt,
  "won-sign": $fa-var-won-sign,
  "krw": $fa-var-krw,
  "won": $fa-var-won,
  "worm": $fa-var-worm,
  "wreath": $fa-var-wreath,
  "wreath-laurel": $fa-var-wreath-laurel,
  "wrench": $fa-var-wrench,
  "wrench-simple": $fa-var-wrench-simple,
  "x": $fa-var-x,
  "x-ray": $fa-var-x-ray,
  "xmark": $fa-var-xmark,
  "close": $fa-var-close,
  "multiply": $fa-var-multiply,
  "remove": $fa-var-remove,
  "times": $fa-var-times,
  "xmark-large": $fa-var-xmark-large,
  "xmark-to-slot": $fa-var-xmark-to-slot,
  "times-to-slot": $fa-var-times-to-slot,
  "vote-nay": $fa-var-vote-nay,
  "xmarks-lines": $fa-var-xmarks-lines,
  "y": $fa-var-y,
  "yen-sign": $fa-var-yen-sign,
  "cny": $fa-var-cny,
  "jpy": $fa-var-jpy,
  "rmb": $fa-var-rmb,
  "yen": $fa-var-yen,
  "yin-yang": $fa-var-yin-yang,
  "z": $fa-var-z,
);

$fa-brand-icons: (
);

$fa-custom-icons: (
);
