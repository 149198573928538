// temp
.calculationFormWrapper div {
    .conditionalValue {
        margin-top: var(--gap-l);
    }
}

#selectInputs input {
    display: inline-block;
    width: 50%;
}

.resultContainer .sticky {
    position: sticky;
    top: calc(0px - var(--container-padding));
}

.collection__consts {

    .collection__consts__content {
        max-height: 70vh;
        overflow-y: auto;

        #const--storage div {
            padding-bottom: var(--gap);
            border-bottom: 1px solid var(--border-color);
        }
    }
}


.widget {
    display: flex;
    align-items: center; // both needed
    text-align: center; // both needed
    justify-content: center;
    overflow-wrap: anywhere;
    hyphens: auto;
    cursor: pointer;
    border: 1px solid var(--border-color);
    border-radius: var(--round);
    overflow: hidden;
    width: 120px;
    height: 120px;
    padding: 20px;
    margin: 10px;
    font-size: 1.25rem;
    font-weight: 700;
    transition: background-color .1s;

    &:hover {
        color: var(--text-primary);
        background-color: var(--primary-light);
    }
}

.collection__widgets__content {
    overflow-x: auto;
}

.widget--container {
    display: flex;
    flex-wrap: nowrap;
    gap: var(--gap)
}

.flow_widget {
    position: relative;
    border: 1px solid lightgray;
    padding: var(--content-padding);
    width: 350px;
    min-width: 350px;
    max-width: 350px;
    max-height: 200px;
    min-height: 100px;
    overflow-y: auto;
    overflow-wrap: anywhere;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    justify-content: space-between;

    & > span {
        display: flex;
        height: 100%;
        align-items: center;
        width: 20px;
        justify-content: center;
        font-weight: normal;
        font-size: 35px;
        cursor: pointer;

        &:hover {
            background-color: #eeeeee;
        }
    }

    .widget__content__wrapper {
        width: 100%;
        height: 100%;
    }
}

.widget__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-direction: row;

    .widget__actions {
        display: flex;
        align-items: center;
        font-size: 20px;

        & > * {
            cursor: pointer;

            &:hover {
                background-color: #eeeeee;
            }
        }
    }

    .widget__actions>* {
        cursor: pointer;
        display: block;
        min-width: 30px;
        height: 30px;
        text-align: center;
        line-height: 20px;
        padding: 5px;
    }
}

.widget_content {
    width: 100%;

    label {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        .widget__information-popup {
            display: inline-flex;
            align-items: center;
            position: relative;

            svg {
                width: 15px;
                height: 15px;
                fill: var(--primary);
            }

            &:hover {
                &:before {
                    display: block;
                    position: absolute;
                    content: "";
                    width: 0;
                    height: 0;
                    left: 25px;
                    top: -2px;
                    z-index: 1;

                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                    border-right: 10px solid #c3c3c3;
                }

                &:after {
                    display: block;
                    position: absolute;
                    content: attr(data-information-content);
                    background-color: #fcfcfc;
                    border: solid #c3c3c3 1px;
                    border-radius: 5px;
                    padding: 10px;
                    max-width: 500px;
                    width: max-content;
                    top: -11px;
                    left: 35px;
                    white-space: break-spaces;
                    box-shadow: 5px 5px 20px -5px gray;
                    z-index: 1;
                }
            }
        }

        .chooseInputValue {
            width: 100%;

            .select2-container {
                width: 100% !important;
            }
        }

        pre {
            width: 100%;
        }
    }
}

.widget__number {
    font-size: 1rem;
    color: var(--text-dark-gray);
}

ß .visitedTab {
    background-color: var(--primary-light);
}

.optionalTab {
    background-color: #2c7dc3;
}

.calculationTablinks {
    border-radius: 5px 5px 0 0;
    background-color: var(--primary-light);
    color: black;
    transition: background-color .1s;
    position: relative;
    overflow: hidden;
    min-width: fit-content;

    button {
        background-color: transparent;
        padding: 0 5px;
        margin: 0 5px;
        display: none;
        width: auto;
        transition: all .2s ease;

        &:nth-child(2) {
            margin-right: 0;
        }

        &:last-child {
            margin-left: 0;
        }

        &:focus {
            outline: none;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &:focus {
        outline: none;
    }

    &:has(.btn__delete-tab) {
        padding-right: 30px;
    }

    .btn__delete-tab {
        position: absolute;
        right: 0;
        height: 100%;
        border-radius: 0 5px 0 0;
        transition: all .2s ease;

        &:hover {
            transform: scale(1.3);
        }
    }
}

.currentTab {
    background-color: var(--primary);
    color: white;
    font-weight: 600;
    position: sticky;
    left: 0;
    right: 0;
}

.calculationTabParent {
    height: 100%;
}

.calculationTab {
    overflow: hidden;
    height: fit-content;
    position: relative;
}

.requiredTab {
    &:after {
        width: 20px;
        content: "*";
        color: red;
        justify-content: center;
        font-size: 20pt;
        line-height: 15px;
        height: 15px;
    }
}

.calculationTabButtons {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    overflow-x: auto;
    //transform: rotateX(180deg);
    width: 100%;
    //border-top: solid 2px var(--primary);

    .calculationTablinks {
        border-radius: 0;
    }

    //div {
    //    width: 100%;
    //    display: flex;
    //    transform: rotateX(180deg);
    //    padding-top: 1px;
    //    gap: 2px;
    //}
    //
    //&.editing {
    //    button > button {
    //        display: flex;
    //    }
    //}
    //
    ///* width */
    //&::-webkit-scrollbar {
    //    position: absolute;
    //    top: 0;
    //    width: 5px;
    //    height: 10px;
    //}
    //
    ///* Track */
    //&::-webkit-scrollbar-track {
    //    background: transparent;
    //    width: 5px;
    //    height: 5px;
    //}
    //
    ///* Handle */
    //&::-webkit-scrollbar-thumb {
    //    background: #b2b2b2;
    //    border-radius: 5px;
    //}
    //
    ///* Handle on hover */
    //&::-webkit-scrollbar-thumb:hover {
    //    background: #555;
    //}
}

.tabcontent {
    div[datatype="widget"] {
        line-height: 1.2em;
    }

    .tab-settings {
        align-items: end;

        div {
            display: flex;
            flex-direction: column;

            &:first-child {
                align-items: center;
            }

            label {
                font-size: 12px;
                color: gray;
            }

            input {
                margin: 0;
            }

            input[type="checkbox"] {
                appearance: none;
                padding: 5px;
                background-color: white;
                border: solid #e2e2e2 1px;
                border-radius: 5px;
                width: 28px;
                height: 28px;
                margin: 5px;
                cursor: pointer;

                &:focus {
                    outline: none;
                }

                &:checked {
                    background-color: var(--primary);
                    border-color: var(--primary);

                    &:after {
                        content: "";
                        background-color: white;
                        mask-image: url("/UI/Assets/Icons/check.svg");
                        mask-repeat: no-repeat;
                        mask-size: 15px;
                        mask-position: center;
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                }
            }
        }
    }
}

span.toggleActive {
    display: flex;
    padding-bottom: 10px;
    margin-bottom: 10px;
    user-select: none;
    //box-shadow: 0px 0px 4px #a4a4a4;
}

.calculationWidget input:not(:valid) {
    border: 1px solid red;
}

// Result templates
.result-template__values {
    margin-top: 10px;
    background-color: #f3f3f3;
    padding: 10px;
    border-radius: 5px;

    .result-template__inputs {
        margin-top: 10px;
        background-color: #fafafa;
        padding: 10px;
        border-radius: 5px;

        label {
            display: block;
            margin-bottom: 3px;
        }
    }
}

ul.checklist {
    list-style: none;
    padding-left: 0;
    margin-top: 10px;

    li {
        display: flex;
        margin-bottom: 5px;
        padding-bottom: 5px;
        align-items: flex-start;
        border-bottom: solid lightgrey 1px;

        span.name {
            min-width: 200px;
            font-weight: 600;
        }

        span.value {
            margin-left: 10px;
            white-space: break-spaces;
        }
    }
}

.popup__content__flex.widgets {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.select2-container {
    .select2-results__option {
        span.category {
            font-weight: bold;
        }
    }
}