@import 'UI/App/Css/mixins';

.block {
	border-radius: 5px;
	//overflow: hidden;

	.block__header {
		// Same height as headers that contain buttons
		height: 50px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		align-content: center;
		background-color: var(--primary);
		color: white;
		padding: var(--container-inline-padding) 15px;
		border-radius: 5px 5px 0 0;
		overflow: hidden;

		.block__header__title {
			text-transform: uppercase;
			display: flex;
			align-items: center;

			a {
				padding: 5px;
				margin-right: 5px;
				background-color: transparent;

				& > svg {
					fill: white;
					width: 15px;
					height: 15px;
				}
			}
		}

		.block__header__right {
			display: flex;
			gap: 15px;
			align-items: center;
		}

		select {
			border: none;
			padding: 5px;
			height: 30px;
			min-height: 30px;
            font-size: 14px;
		}

		button, a, .btn {
			font-weight: normal;
			background-color: var(--black);
			color: var(--white);
		}

		span.boolean {
			background-color: var(--dark-grey);

			.boolean__value {
				height: 30px;
				line-height: 25px;
				padding: 1px 5px;
				margin: 5px;

				&:not([data-selected=true]) {
					color: var(--grey);
				}
			}
		}
	}

	.block__content {
		padding: var(--container-padding);
		position: relative;
		background-color: var(--white);
		border-radius: 0 0 5px 5px;

		&.reduce-padding {
			padding: var(--container-inline-padding);
		}

		&.block__content--center {
			display: flex;
			align-items: center;
			flex-direction: column;
		}

		&.content-loading {
			&:after {
				content: "";
				display: block;
				width: 100%;
				height: 100%;
				backdrop-filter: blur(3px) grayscale(1);

				position: absolute;
				top: 0;
				left: 0;

				background-image: url("/UI/Assets/Spinners/spinner-primary.svg");
				background-repeat: no-repeat;
				background-position: center;
				background-size: 30px;
				background-attachment: fixed;

				z-index: 10;
			}
		}

		span.boolean {
			background-color: var(--dark-gray);
		}
	}

	@media (max-width: 403px) {
		.block__header {
			height: auto;
		}
	}
}
