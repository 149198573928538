@import 'UI/App/Css/mixins';

.login-container {
	height: 100vh;
	width: 100%;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.login__logo {
	width: 300px;
	height: 300px;
	margin-bottom: 50px;
}

.login__btn {
	width: fit-content;
	background: var(--primary);
	padding: 20px;
	border-radius: 7px;
	text-align: center;
	color: var(--text-white);
	display: flex;
	justify-content: center;

	svg {
		fill: var(--white);
	}
}

.login__btn__icon {
	margin-right: 10px;
	height: 20px;
	width: 20px;
}

.login {
	@include media('tablet-portrait-down') {
		flex: auto;
		flex-wrap: wrap;
	}

	flex: 1 1;
	display: flex;
	align-items: center;
}

.login__left {
	@include media('tablet-portrait-down') {
		display: none;
	}

	flex-wrap: wrap;
	color: var(--white);
	width: auto;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	padding: 0 100px;
	align-items: center;
	background-size: cover;
	background-position: center;
	background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
	url('../../../Assets/Images/loginpage.jpeg');

	h1 {
		@include font(72px, 72px);
		width: 100%;
		margin-bottom: 20px;
	}
}

.login__right {
	@include media('tablet-portrait-down') {
		width: 100%;
	}

	width: 600px;
	height: 100%;
	justify-content: center;

	h2 {
		@include font(52px, 52px);
		margin-bottom: 30px;
		width: 100%;
		text-align: center;
	}

	.login__content {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		flex-direction: column;
		align-items: center;

		span.login__message {
			padding: 10px;
		}
	}
}