@import 'UI/App/Css/mixins';

.bar-code-wrapper {
    height: fit-content;
    width: fit-content;
}

.qr-code-wrapper {
    height: fit-content;
    width: fit-content;
}