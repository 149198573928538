.order-fatal-error {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    &>svg {
        height: 75px;
        width: 75px;
        fill: var(--text-warning);
    }

    span.title {
        font-size: 2rem;
        font-weight: bold;
    }
}

.download_button {
    min-width: 210mm;
    max-width: 210mm;
    position: relative;
}

// add borders to the first and last th
// creates the same style for headers as blocks
.table {
    th:first-child {
        border-radius: 5px 0 0 0;
    }

    th:last-child {
        border-radius: 0 5px 0 0;
    }

    &.block {
        .block__content {
            padding: 0;
        }
    }
}