@import 'UI/App/Css/_variables';

.planning__container {

    >.planning__header {
        width: 100%;
        height: 50px;
        background-color: var(--primary);
        color: white;
        display: flex;
        flex-direction: row;
        padding: 0 calc(20px + var(--gap-xxs) * 2);
        border-radius: var(--rounded-corners-s) var(--rounded-corners-s) 0 0;

        .boolean {
            .boolean__value {
                margin: 2px;
            }
        }

        >.planning__header__filters {
            height: 100%;
            width: fit-content;
            max-width: 500px;
            display: flex;
            align-items: center;
            gap: var(--gap-s);

            >.planning__header__filter__search {
                >input {
                    color: var(--primary);

                    &::placeholder {
                        color: var(--primary);
                    }
                }
            }

            .planning__header__filter__time{
                width: 180px;
            }

            >.planning__header__filter__time,
            >.planning__header__filter__type {
                >select {
                    color: var(--primary);
                }
            }
        }
        >.planning__header__date {
            display: flex;
            align-items: center;
            margin-left: auto;

            >.planning__header__date__content {
                display: flex;
                align-items: center;
                width: fit-content;
                position: relative;
                z-index: 101;

                >.planning__header__save {
                    margin-left: 20px;
                }

                >.planning__header__date__content__text {
                    font-weight: var(--semi-bold);
                    font-size: var(--font-regular);
                    margin: 0 var(--gap-s) 0 0;
                    white-space: nowrap;
                    margin: 0;
                    padding: 0;
                    pointer-events: none;
                }

                >div>.planning__header__date__content__icon {
                    fill: var(--white);
                    margin: 0;
                    padding: 0;
                    height: var(--font-regular);

                    &.chevron-left {
                        margin: 0 var(--gap-xs) 0 0;
                    }

                    &.chevron-right {
                        margin: 0 0 0 var(--gap-xs);
                    }

                    &.calendar {
                        margin: 0 0 0 var(--gap-xs)
                    }
                }

                >div>.planning__header__date__content__icon__button {
                    @extend .planning__header__date__content__icon;

                    &:hover {
                        cursor: pointer;
                    }
                }

                >.datepicker__input{
                    position: absolute;
                    width: 100%;
                    opacity: 0;
                    cursor: pointer;
                    box-sizing: border-box;
                }

                >.datepicker__input::-webkit-calendar-picker-indicator {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    padding: 0;
                    cursor: pointer;
                }
            }
        }
    }

    >.planning__content {
        height: fit-content;
        width: 100%;
        background-color: #D9D9D9;
        display: flex;
        flex-direction: column;
        gap: var(--gap-xxs);
        padding: 0 0 var(--gap-xxs) 0;
        align-items: center;
        position: relative;

        >.planning__content__header {
            height: 40px;
            width: calc(100% - (var(--gap-xxs) * 2));
            display: grid;
            grid-template-columns: 4fr 8fr;
            gap: var(--gap-xxs);

            >.planning__content__header__info__container {
                display: flex;
                align-items: center;
                padding: 0 30px;
                background-color: var(--white);
                gap: 10px;

                >.planning__content__header__info__item {
                    font-weight: var(--semi-bold);
                    font-size: var(--font-regular);
                }

                >.planning__button {
                    padding: 0;
                    height: 15px;

                    >.planning__icon {
                        height: 15px;
                    }

                    &:hover {
                        >.planning__icon {
                            fill: var(--primary);
                        }
                    }
                }

                >.weekly__item__icon__container {
                    margin-left: auto;
                    height: 14px;

                    >.weekly__item__icon {
                        height: 15px;
                        cursor: pointer;
                        transition: transform 0.5s ease;

                        &.open {
                            transform: rotateX(180deg);
                        }
                    }
                }
            }

            >.planning__content__header__date__container {
                display: grid;
                grid-template-columns: calc(230px + var(--gap-xxs)) repeat(7, 1fr) 30px;
                gap: var(--gap-xxs);

                >.planning__content__header__date__filler {
                    background-color: var(--white);
                }

                >.planning__content__header__date__item {
                    background-color: var(--white);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: var(--font-regular);
                    position: relative;

                    >.planning__content__header__date__item__date {
                        display: flex;
                        position: absolute;
                        left: var(--gap-xxs);
                        font-weight: 600;
                        font-size: 40px;
                        opacity: 0.1;
                        height: 100%;
                        padding: 0;
                        margin: 0;
                        align-items: center;
                    }
                }
            }
            >.planning__content__header__date__container__month {
                @extend .planning__content__header__date__container;
                grid-template-columns: repeat(7, 1fr);
            }
        }

        >.planning__content__header__date__container__all {
            border-right: var(--gap-xxs) solid #D9D9D9;
            border-left: var(--gap-xxs) solid #D9D9D9;
            display: grid;
            height: 40px;
            width: 100%;
            grid-template-columns: 200px 30px repeat(7, 1fr) 30px;
            gap: var(--gap-xxs);
        
            >.planning__content__header__date__filler {
                background-color: var(--white);
            }
        
            >.planning__content__header__date__item {
                background-color: var(--white);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: var(--font-regular);
                position: relative;
        
                >.planning__content__header__date__item__date {
                    display: flex;
                    position: absolute;
                    left: var(--gap-xxs);
                    font-weight: 600;
                    font-size: 40px;
                    opacity: 0.1;
                    height: 100%;
                    padding: 0;
                    margin: 0;
                    align-items: center;
                }
            }
        }

        >.planning__content__header__month {
            @extend .planning__content__header;
            grid-template-columns: 1fr;
            height: 80px;

            &.closed {
                height: 39px
            }
        }

        >.planning__content__header__week {
            @extend .planning__content__header;
            grid-template-columns: 1fr;
            height: 80px;

            &.closed {
                height: 39px
            }
        }

        >.planning__content__header__week-all {
            @extend .planning__content__header__week;
            height: 40px;
        }

        >.planning__content__header__day {
            @extend .planning__content__header;
            grid-template-columns: 1fr;
        }

        >.planning__content__item {
            @extend .planning__content__header;
            height: 85px;

            >.planning__content__name {
                background-color: var(--white);
                padding: 0 0 0 var(--gap-l);
                display: flex;
                align-items: center;

                >.planning__content__name__content {
                    font-size: var(--font-small);
                }
            }

            >.planning__content__bar__container {
                background-color: var(--white);
                position: relative;
                display: grid;
                grid-template-columns: repeat(7, minmax(0, 1fr));

                .conflict {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    height: 30px;
                }

                .conflictPopup {
                    background-color: #FFFFFF;
                    padding: 5px;
                    border-radius: 5px;
                    display: none;
                    border: 1px solid red;
                    position: absolute;
                    z-index: 100;
                    min-width: 350px;
                    color: black;
                    bottom: 35px;
                    left: 0;
                }

                .planning__content__item__content__text__tooltip{
                    background-color: #FFFFFF;
                    padding: 5px;
                    border-radius: 5px;
                    display: none;
                    position: absolute;
                    z-index: 101;
                    color: black;
                    width: 100%;
                    top: 100%;
                    left: 0;
                    box-shadow: 0 0 4px 0 grey;
                }

                .conflict__icon {
                    position: absolute;
                    height: 20px;
                    background-color: red;
                    border-radius: 50px;
                    top: 4px;
                }

                &:hover {
                    >.dependency__dot__full {
                        display: flex;
                    }
                }

                >.planning__content__bar__item {
                    position: relative;
                    width: 100%;
                    height: 80px;
                    margin: auto;
                    cursor: grab;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    &:active {
                        cursor: grabbing;
                    }

                    &:hover {
                        >.dependency__dot {
                            display: flex;
                        }

                        .planning__content__item__content__text__tooltip{
                            display: block !important;
                        }
                        .conflictPopup {
                            display: block !important;
                        }
                    }

                    >.information__icon__container {
                        position: absolute;
                        right: 0;
                        top: 0;
                        margin-top: 3px;
                        margin-right: 3px;
                        height: 12px;
                        width: 12px;

                        &:hover {
                            cursor: pointer;
                        }

                        >svg>path {
                            fill: white;
                        }
                    }

                    >.info__popup__container {
                        z-index: 100;
                        position: absolute;
                        bottom: calc(100% + 5px);
                        left: calc(0% + 10px);
                        background-color: var(--primary);
                        padding: 5px 10px;
                        width: inherit;
                        color: white;
                        border-radius: 2px;

                        &::before {
                            content: '';
                            z-index: 100;
                            position: absolute;
                            bottom: calc(-5px - 5px);
                            right: 15px;
                            width: 0px;
                            height: 0px;
                            border-left: 5px solid transparent;
                            border-bottom: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-top: 5px solid var(--primary);
                        }
                    }

                    >.conflict + .planning__content__bar__item__content {
                        margin: 0;
                    }

                    >.planning__content__bar__item__content {
                        color: var(--white);
                        font-size: 1.4rem;
                        position: relative;
                        overflow: hidden;
                        z-index: 10;
                        pointer-events: none;
                        user-select: none;
                        height: 100%;
                        width: 100%;
                        padding: 5px;
                        display: grid;
                        grid-template-rows: 20px 30px 30px;

                        >.row {
                            padding-top: 1px;
                            text-wrap: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }

                        // .planning__content__bar__item__content__text {
                        //     text-wrap: none;
                        //     overflow: hidden;
                        //     text-overflow: ellipsis;
                        //     white-space: nowrap;
                        //     font-size: 11px;
                        //     z-index: 10;
                        // }

                        // >.planning__content__bar__item__content__time {
                        //     font-size: 11px;
                        //     margin: 2px;
                        // }
                    }

                    .arrow{
                        position: absolute;
                        cursor: pointer;
                        z-index: 20;
                        left: 100%;

                        &::after {
                            content: '';
                            position: absolute;
                            border-top: 7px solid transparent;
                            border-right: 7px solid transparent;
                            border-bottom: 7px solid transparent;
                            border-left: 7px solid transparent;
                        }
                    }

                    >.dependency__arrow {
                        @extend .arrow;
                        bottom: calc(50% + 3.5px);
                        border-bottom: 2px solid var(--primary);
                        border-right: 2px solid var(--primary);

                        &::after {
                            right: -7px;
                            top: -1px;
                            border-bottom: 7px solid var(--primary);
                            border-top: none;
                        }
                    }

                    >.dependency__arrow__negative {
                        @extend .arrow;
                        top: calc(50% + 3.5px);
                        border-top: 2px solid var(--primary);
                        border-right: 2px solid var(--primary);

                        &::after {
                            right: -8px;
                            bottom: -1px;
                            border-top: 7px solid var(--primary);
                            border-bottom: none;
                        }
                    }

                    >.dependency__arrow__reverse {
                        @extend .arrow;
                        border-bottom: 2px solid var(--primary);
                        border-left: 2px solid var(--primary);
                        right: 100%;
                        left: unset;
                        bottom: calc(50% - 5.5px);

                        &::after {
                            left: -8px;
                            top: -9px;
                            border-bottom: 7px solid var(--primary)
                        }
                    }

                    >.dependency__arrow__double__reverse {
                        @extend .arrow;
                        border-top: 2px solid var(--primary);
                        border-left: 2px solid var(--primary);
                        right: 100%;
                        left: unset;
                        top: calc(50% - 5.5px);

                        &::after {
                            left: -8px;
                            bottom: -9px;
                            border-top: 7px solid var(--primary)
                        }
                    }

                    >.dependency__arrow__direct {
                        @extend .arrow;
                        width: 5px;
                        height: 2px;
                        background-color: var(--primary);

                        &::after {
                            right: -7px;
                            top: -6px;
                            border-left: 7px solid var(--primary);
                            border-right: none;
                        }
                    }

                    >.dependency__arrow__direct__negative {
                        @extend .arrow;
                        width: 5px;
                        height: 2px;
                        background-color: var(--primary);

                        &::after {
                            left: -7px;
                            top: -6px;
                            border-right: 7px solid var(--primary);
                            border-left: none;
                        }
                    }

                    >.dependency__arrow__leveled {
                        @extend .arrow;
                        bottom: calc(50% + 3.5px);
                        right: -40px;
                        width: 40px;
                        height: 100%;
                        border-right: 2px solid var(--primary);
                        border-top: 2px solid var(--primary);
                        border-bottom: 2px solid var(--primary);

                        &::after {
                            left: -1px;
                            top: -8px;
                            border-right: 7px solid var(--primary);
                            border-left: none;
                        }
                    }

                    >.dependency__arrow__leveled__negative {
                        @extend .arrow;
                        top: calc(50% + 3.5px);
                        right: -40px;
                        width: 40px;
                        height: 100%;
                        border-right: 2px solid var(--primary);
                        border-top: 2px solid var(--primary);
                        border-bottom: 2px solid var(--primary);

                        &::after {
                            left: -1px;
                            bottom: -8px;
                            border-right: 7px solid var(--primary);
                            border-left: none;
                        }
                    }

                    >.dependency__arrow__horizontal {
                        @extend .arrow;
                        bottom: calc(50% + 3.5px);
                        height: 2px;
                        background-color: var(--primary);

                        &::after {
                            right: -1px;
                            top: -6px;
                            border-right: 7px solid var(--primary);
                            border-left: none;
                        }
                    }

                    >.dependency__arrow__top {
                        position: absolute;
                        top: 0;
                        height: calc(50% - 5px);
                        border-bottom: 2px solid var(--primary);
                        cursor: pointer;
                    }

                    >.dependency__arrow__bottom {
                        position: absolute;
                        bottom: 0;
                        height: calc(50% - 5px);
                        border-top: 2px solid var(--primary);
                        cursor: pointer;
                    }

                    >.dependency__dot {
                        display: none;
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        background-color: var(--black);
                        border: 2px solid var(--primary);
                        top: calc(50% - 5px);
                        transform: translate(-50%, -50%);
                        border-radius: 100%;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    >.dependency__dot__next {
                        @extend .dependency__dot;
                        right: 0;
                        transform: translate(50%, 50%)
                    }

                    .clickable__resize {
                        background-color: transparent;
                        width: 10px;
                        height: 100%;
                        position: absolute;
                        left: 100%;
                        cursor: ew-resize;
                        color: white;
                        font-size: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-bottom-right-radius: 5px;
                        border-top-right-radius: 5px;

                        svg {
                            fill: white;
                        }
                    }
                }

                >.dependency__arrow__full {
                    position: absolute;
                    border-top: 1px solid var(--primary);
                    border-bottom: 1px solid var(--primary);
                    width: calc(100% + (80px + var(--gap-xxs)) * 2);
                    height: 0;
                    left: -32px;
                    top: 50%;
                    transform: translateY(-50%);

                    &:hover {
                        cursor: pointer;
                    }
                }

                >.dependency__dot__full {
                    display: none;
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    background-color: var(--black);
                    border: 2px solid var(--primary);
                    top: calc(50% - 5px);
                    transform: translate(-50%, -50%);
                    border-radius: 100%;

                    &:hover {
                        cursor: pointer;
                    }
                }

                >.dependency__dot__full__next {
                    @extend .dependency__dot__full;
                    right: 0;
                    transform: translate(50%, 50%);

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            >.planning__content__text__container {
                @extend .planning__content__bar__container;

                >.planning__content__text__container__content {
                    margin: auto 0 auto 20px;
                    font-size: var(--font-small)
                }
            }
        }
        >.planning__content__item__weekly {
            @extend .planning__content__item;
            grid-template-columns: 200px 30px 1fr 30px;

            >.planning__content__item__filler {
                background-color: var(--white);
                height: 100%;

                >.planning__content__name__container {
                    padding: var(--gap-xxs) var(--gap-s);
                    display: grid;
                    grid-template-columns: calc(10px + var(--gap-xxs)) 1fr;
                    grid-template-rows: 1fr;
                    height: inherit;
                    width: 100%;
                    gap: var(--gap-xxs);
                    align-items: center;

                    >.planning__content__color__indicator {
                        height: 10px;
                        width: 10px;
                        border-radius: 100%;
                    }

                    >.planning__content__name__content {
                        height: fit-content;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        padding: var(--gap-xxs) 0 var(--gap-xxs) 5px;
                    }
                }
            }
        }

        >.planning__content__container {
            @extend .planning__content__header;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            height: fit-content;

            >.planning__content__item {
                position: relative;
                background-color: var(--white);
                text-align: center;
                min-height: calc(180px + var(--gap-xxs) * 5);

                @for $i from 1 through 50 {
                    &.containing-#{$i} {
                        height: calc((30px + var(--gap-xxs)) * #{$i});
                    }
                }

                >.planning__content__item__content {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: calc(100% + var(--gap-xxs));
                    background-color: var(--primary);
                    color: var(--white);
                    line-height: 30px;
                    height: 30px;
                    max-height: 30px;
                    padding: 0 10px;

                    .planning__content__item__content__text {
                        text-wrap: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        pointer-events: none;
                        max-width: 100%;
                        display: block;
                        user-select: none;
                    }

                    &:hover {
                        cursor: grab;
                    }

                    &:active {
                        cursor: grabbing;
                    }
                }

                >.planning__content__item__content__number {
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding: 10px;
                    opacity: 0.5;
                    pointer-events: none;
                }
            }

            >.planning__content__filler {
                @extend .planning__content__item;
                &::before {
                    content: '';
                    display: block;
                    height: 100%;
                    pointer-events: none;
                    position: relative;
                    background-color: black;
                    opacity: 0.10;
                    pointer-events: none;
                }
            }

            >.planning__content__transparent {
                @extend .planning__content__item;
                background-color: transparent;
                height: 30px;
                min-height: 0;
                top: calc(30px + var(--gap-xxs));


                @for $i from 1 through 50 {
                    &.same-position-#{$i} {
                        top: calc((30px + var(--gap-xxs)) * #{$i});
                    }
                }

                .dependency__icon__container {
                    display: flex;
                    align-items: center;
	                justify-content: center;
                    height: 30px;
                    cursor: pointer;

                    .dependency__icon {
                        height: 22px;
                        width: 22px;
                        fill: var(--white);
                    }
                }

                .arrow {
                    position: absolute;
                    border: none;
                    z-index: 1;
                    cursor: pointer;

                    &::after {
                        content: '';
                        position: absolute;
                        border-top: 7px solid transparent;
                        border-right: 7px solid transparent;
                        border-bottom: 7px solid transparent;
                        border-left: 7px solid transparent;
                    }
                }

                .dependency__arrow__top__left {
                    @extend .arrow;
                    border-right: 2px solid var(--primary);
                    border-top: 2px solid var(--primary);

                    &::after {
                        border-right: 7px solid var(--primary);
                        left: -8px;
                        top: -8px;
                    }
                }

                .dependency__arrow__top__right {
                    @extend .arrow;
                    border-left: 2px solid var(--primary);
                    border-top: 2px solid var(--primary);

                    &::after {
                        border-left: 7px solid var(--primary);
                        right: -8px;
                        top: -8px;
                    }
                }

                .dependency__arrow__bottom__left {
                    @extend .arrow;
                    border-right: 2px solid var(--primary);
                    border-bottom: 2px solid var(--primary);

                    &::after {
                        border-right: 7px solid var(--primary);
                        left: -8px;
                        bottom: -8px;
                    }
                }

                .dependency__arrow__bottom__right {
                    @extend .arrow;
                    border-left: 2px solid var(--primary);
                    border-bottom: 2px solid var(--primary);

                    &::after {
                        border-left: 7px solid var(--primary);
                        right: -8px;
                        bottom: -8px;
                    }
                }

                .dependency__arrow__middle__left {
                    @extend .arrow;
                    background-color: var(--primary);

                    &::after {
                        border-right: 7px solid var(--primary);
                        left: -8px;
                        top: -6px;
                    }
                }

                .dependency__arrow__middle__right {
                    @extend .arrow;
                    background-color: var(--primary);

                    &::after {
                        border-left: 7px solid var(--primary);
                        right: -8px;
                        top: -6px;
                    }
                }

                .dependency__arrow__middle__up {
                    @extend .arrow;
                    background-color: var(--primary);

                    &::after {
                        border-bottom: 7px solid var(--primary);
                        left: -6px;
                        top: -8px;
                    }
                }

                .dependency__arrow__middle__down {
                    @extend .arrow;
                    background-color: var(--primary);

                    &::after {
                        border-top: 7px solid var(--primary);
                        left: -6px;
                        bottom: -8px;
                    }
                }
            }

        }

        .planning__content__conflicts {
            background-color: var(--message-red-light);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            padding: var(--container-inline-padding);
            text-align: left;
            cursor: auto;
        }

        .planning__content__conflict__icon__container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            cursor: help;

            svg {
                height: 22px;
                width: 22px;
                fill: var(--white);
                background-color: var(--message-red);
                border-radius: var(--round);
            }
        }
    }
}

.custom__drag__image {
    position: absolute;
    width: 1px;
    height: 1px;
    background-color: transparent;
    cursor: grabbing;
}

@for $i from 1 through 20 {
    .bg-#{$i} {
        background-color: nth($list: $planningProjectColors, $n: $i) !important;
    }

    .bg-#{$i}-two-tone {
        & > :nth-child(1) {
            background-color: nth($list: $planningProjectColors, $n: $i) !important;
        }

        & > :nth-child(2) {
            background-color: var(--background-body);
            border: lighten(nth($list: $planningProjectColors, $n: $i), 10%) solid 1px !important;
        }
    }

    .bg-#{$i}-dark {
        background-color: darken(nth($list: $planningProjectColors, $n: $i), 20%) !important;
    }
}

.unplanned__machines {
    margin-top: var(--gap-l);

    >.block__header {
        padding: 0 calc(30px + var(--gap-xxs) * 2);
    }

    .unplanned__machines__toggle {
        display: flex;

        svg {
            fill: var(--white);
            height: 30px;
            cursor: pointer;
            transition: transform 0.5s ease;

            &.open {
                transform: rotateX(180deg);
            }
        }
    }

    .unplanned__machines__content .toggled_content {
        transition: max-height 0.5s ease;
        border-left: 2px solid #D9D9D9;
        border-right: 2px solid #D9D9D9;

        &.open {
            max-height: calc(58.875px * 6);
            overflow: auto;
        }

        &.closed {
            max-height: 0;
            overflow: hidden;
        }
    }

    .machine__name {
        padding: 10px 30px;
        background-color: var(--white);
        border-bottom: 2px solid #D9D9D9;
        display: grid;
        grid-template-columns: 1fr 35px;
        align-items: center;
    }
}

.show__overlay {
    width: 100%;
    height: 100%;
    background: var(--primary-transparent);
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    cursor: pointer;
    backdrop-filter: blur(4px);
}

.time-input-with-preview {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 0;

    input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    span.time-preview {
        height: 38px;
        min-width: 70px;
        text-align: center;
        padding: 10px;
        margin: 5px 0;
        box-sizing: border-box;

        background-color: #f4f4f4;
        border: solid 1px lightgrey;
        border-left: none;
        border-top-right-radius: var(--rounded-corners-s);
        border-bottom-right-radius: var(--rounded-corners-s);
    }
}
