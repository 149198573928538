// styling for printing with ctrl + p
@media print {

    header,
    .navigation,
    button {
        display: none !important;
    }

    main {
        margin-left: 0 !important;
        width: unset !important;
        overflow-y: initial !important;
    }

    section.content {
        overflow: initial !important;
        height: unset !important;
    }

    div[class*="columns-"] {
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    }

    .table__wrapper {
        margin-bottom: var(--container-margin-seperation) !important;

        &::-webkit-scrollbar {
            display: none !important;
        }
    }

    table {
        page-break-inside: auto !important;
    }

    tr {
        page-break-inside: avoid !important;
        page-break-after: auto !important;
    }
}