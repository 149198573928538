.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;

  background-color: red;

  display: block;

  &-hjmg {
    background-color: #76B83A;
  }

  &-pretore {
    background-color: #E6A530;
  }
}

.circle__big {
  @extend .circle;
  width: 15px;
  height: 15px;
}