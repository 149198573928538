@import 'UI/App/Css/mixins';

.header {
	margin: 0;
	min-height: var(--header-height);
	padding: 0 var(--container-padding);
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid var(--dark-gray);
	background-color: white;
}

.header__title-wrap {
	color: var(--text-primary);
}

.header__breadcrumb {
	&:not(:first-child) {
		margin-left: 5px;
	}

	@include font(15px, 18px);
	color: var(--text-primary);
	text-decoration: none;

	svg {
		height: 15px;
		width: 6px;
		margin-bottom: -2px;

		path {
			fill: var(--black);
		}
	}

	&.emily {
		font-weight: 600;
	}

	&:last-child {
		color: var(--text-dark-gray);
		pointer-events: none;
	}
}

.header__title {
	margin-top: 20px;
	margin-bottom: 0;
}

.header__account-card {
	@include media('tablet-portrait-down') {
		display: none;
	}

	display: flex;
	gap: 10px;
	align-items: center;
}

.header__account-info {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
}

.header__account-name {
	color: var(--text-primary);
	font-weight: 600;
	width: fit-content;
}

.header__account-role {
	font-size: 11px;
	text-transform: lowercase;
	color: var(--grey);
}

.header__account-picture {
	background-color: var(--primary);
	color: var(--text-white);
	font-weight: var(--semi-bold);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	border-radius: var(--round);
}