@import "UI/App/Css/mixins";

.progress__container {
	background-color: var(--white);
	max-height: calc(100vh - 140px);
	height: calc(100vh - 140px);
	overflow: auto;

	.machine__buttons, .machines {
		max-height: calc(100vh - 180px);
		height: 100%;
		overflow-y: auto;

		.admin-options {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			border-right: solid 1px var(--border-color);
			padding: 10px 20px 10px 0;

			span {
				width: 130px !important;
			}
		}

		b {
			display: block;
			padding: 15px 10px;
			border-right: 1px solid var(--border-color);
			background-color: var(--medium-grey);
			color: black;
		}
	}

	>.progress__step__container {

		padding: var(--container-padding);

		>.form__content {
			display: grid;
			grid-template-columns: repeat(12, 1fr);
			grid-template-rows: 1fr;
			gap: 5px var(--container-padding);

			>.form__item {
				position: relative;
				// set collumns based on the class -> grid__1 takes up 1 column, grid__6 takes up 6 etc.
				@for $i from 1 through 12 {
					&.grid__#{$i} {
						grid-column: span $i;

						// on small screens span always 12 columns
						@include media("tablet-portrait-down") {
							// @if($i < 7) {
							// 	grid-column: span calc($i * 2);
							// } @else {
							grid-column: span 12;
							// }
						}
					}
				}

				&.form__item__with__button {
					position: relative;
					>input {
						width: calc(100% - 50px);
						display: inline-block;
					}
					>.add__amount__button {
						position: absolute;
						right: 0;
						top: 0;
					}
				}
			}
		}
	}

	.checklist-popup {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}
}
