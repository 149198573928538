@import 'UI/App/Css/mixins';

.timeTr {
	border: outset gray;
	border-width: 1px 0;
}

.timeTd {
	padding: 0 20px;

}

.timeTrCurrent {
	border: groove #0089b4;
	border-width: 1px 0;
}

.timeTr:first-child {
	border-top: none;

}

.timeTr:last-child {
	border-bottom: none;
}

.timeTable {
	border-collapse: collapse;
}

// company sets this to something unsable, unable to find where company is getting imported atm.
.TimeAccouting__grid {
	grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.TimeAccouting__form {
	@include media('tablet-portrait') {
		grid-column: 1;
	}

	grid-column: 1;
}

.TimeAccouting__table {
	@include media('tablet-portrait') {
		grid-column: 2 / 4;
	}

	grid-column: 2 / 4;
}

.TimeAccouting__pauze,
.TimeAccouting__time {
	display: flex;
	justify-content: space-between;
}

.timeAccounting__week {
	display: flex;
	align-items: center;

	input {
		width: 150px;
		margin: 0 15px 0 0;
	}
}

.TimeAccouting__tab {
	@include font(15px, 15px);
	width: calc(100% / 7);
	background-color: transparent;
	color: var(--text-black);
	border-radius: 0;
	padding: 30px 0;
	font-weight: 600;
	outline: none;

	&:hover,
	&:disabled {
		background-color: transparent;
		color: var(--primary);
		border-bottom: var(--primary) 2px solid;
	}
}

.TimeAccouting__tab_Button {
	color: white;
	border-bottom: var(--primary) 2px solid;
}

.TimeAccouting__modButton {
	@include font(15px, 15px);
	//width: calc(100% / 5);
	background-color: transparent;
	color: var(--text-black);
	border-radius: 7px;
	border: var(--primary) 1px solid;
	padding: 10px 2px;
	font-weight: 600;
	outline: none;
	margin: 3px 1px;

	&:hover,
	&:disabled {
		background-color: transparent;
		color: var(--primary);
		border-bottom: var(--primary) 2px solid;
		padding: 9px 2px;
	}
}

.TimeAccouting__pausetime,
.TimeAccouting__stoptime,
.TimeAccouting__starttime {
	width: calc(50% - 7.5px);
}

.timeTable {
	width: 100% !important;
	height: 100px !important;

	.timeTrCurrent,
	.timeTr {
		height: 30px;
		border-bottom: 1px solid var(--border-color);

		&:nth-child(odd) {
			border-bottom: 1px dashed var(--border-color);
		}
	}
}

.timeTable__wrapper {
	width: 100%;
	height: 100vh;
}

.timeTable__tb {
	min-width: 300%;
	//height: 100vh;
	display: inline-block;
	overflow-y: auto;
	max-height: 100vh !important;
	max-width: 100%;
	table-layout: unset;
}

.timeTable__tr {
	min-width: 400px !important;
	width: 500px !important;
	box-sizing: content-box;

}

.timeIndicatorTd {
	width: 0px;
}

.timeDisplayTd {
	min-width: 82px;


}

.timePacketTd {
	width: 100%;
}

// Week table Css
//#region

.weekTable {
	border-collapse: collapse;
	min-width: 100%;
}

.weekTable__tr {
	border: outset gray;
	border-width: 1px 0;
	box-sizing: content-box;
	min-width: 100%;
}

.weekTable__tr:first-child {
	border-top: none;

}

.weekTable__td {
	border-right: 1px outset gray;
	min-width: 100%;

	padding: 0px;
}

.weekTable__td:last-child {
	border-right: none;

}

.weekSubTable {
	border-collapse: separate;
	border-spacing: 2px 0px;


	min-height: 100%;
}

.weekTable__th {
	//width: 12.5%;
}

.weekSubTable__tr {}

.weekSubTable__tr:first-child {
	border-top: none;
}

.weekSubTable__tr:last-child {
	border-bottom: none;
}

.weekSubTable__td {
	min-width: 100%;

	height: 15px;
	min-height: 10px;
	padding: 0px;
	border-left: 5px outset white;
	border-bottom: 1px dashed var(--border-color);
}

.weekSubTable__td_time {}

.weekSubTable__td_active {

	border-left: 5px solid var(--primary);
	border-bottom: none;


}

.weekSubTable__td_top {
	max-height: 10px;
	height: 5px;

	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	color: black;

}

.weekSubTable__td_bot {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	border-bottom: 1px dashed var(--border-color);
}

.weekTable__subMenu {
	position: absolute;
	display: block;
	//transition: all 0.5s ease;
	z-index: 0;
	height: 100px;
	width: 135px;
	padding: 5px;
	background-color: #0089b4;
}

.weekTable__subMenu_hover {
	width: 30px;
	height: 22px;
	padding-top: 2px;
	padding-left: 5px;
	padding-right: 5px;
	position: absolute;
	display: block;
	background-color: #0089b4;
	color: white;
	transform: translateX(-30px);
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	animation-name: move;
	animation-duration: 0.125s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

.submenuActive {

	width: 120px;
	height: 120px;
	padding-top: 2px;
	padding-left: 5px;
	padding-right: 5px;
	position: absolute;
	display: block;

	background-color: #0089b4;
	color: white;
	transform: translateX(-120px);

	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	animation-name: move;
	animation-duration: 1s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;

}

.weekTable__subMenu_hover_icon {
	fill: white;
	width: 20px;
	height: 18px;

}

@keyframes move {
	0% {
		transform: translateX(-10px);
		background-color: transparent;
		width: 0px;


	}

	50% {
		transform: translateX(-100%);
		opacity: 0;
	}


	50% {
		transform: translateX(-100%);
		opacity: 1;
	}
}


//#endregion

// month styling
//#region
.monthTable__tr {}

.monthTable__td {}

.monthTable__block {
	padding: 1px;
	border: 2px solid #0089b4;
	color: white;
	border-radius: 6px;
	background-color: #0089b4;
}

.monthTable__popUpBlock {
	padding: 1px;
	border: 1px solid #0089b4;

	margin-top: 5px;
	min-height: 30px;
	border-radius: 2px;
	background-color: #d9f2fa;
}

//#endRegion