@import 'UI/App/Css/mixins';

.pagination {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    z-index: 50;
    width: 100%;
    height: 57px;
    background-color: var(--white);

    input,
    select {
        margin: 0 !important;
    }
}

.pagination.filtering {
    padding: 0 var(--gap-l);
    display: flex;
    gap: var(--gap-l);
    margin: 0 0 10px 0;
    position: sticky;
    top: -20px;
    border-bottom: solid 1px var(--dark-grey);

    .pagination__filters {
        display: flex;
        gap: var(--gap-l);
        width: 100%;
        justify-content: space-between;

        .pagination__section, .pagination__section__left{
            display: flex;
            gap: var(--gap-l);

            @media only screen and (max-width: 1516px) {
                gap: var(--gap-xs);
            }
        }

        .pagination__section__right{
            @extend .pagination__section;
        }

        &.buttons-collapsed {
            .btn--icon-right,
            .btn--icon-left,
            .btn--icon,
            .btn--collapsable {
                font-size: 0;
                width: 38px;
                height: 38px;
                gap: 0
            }
        }

        &.results-collapsed {
            .pagination__select__labeled {
                min-width: 60px;
                width: 60px;

                &:before {
                    content: '';
                }
            }
        }
    }

    @include media('desktop-small') {
        .pagination__results__content {
            margin-right: 10px;

            //.pagination__results__label {
            //    display: none;
            //}
        }
    }
}

.pagination__search {
    min-width: 200px;
}

.paging {
    bottom: -20px;
    position: sticky;
    justify-content: center;
    align-content: center;
    padding: var(--container-inline-padding);
    background-color: var(--white);
    border-top: solid 1px var(--medium-grey);
    margin-top: 10px;
    z-index: 1;
}

.pagination .pagination__paging__label {
    @include media('tablet-portrait-down') {
        display: none;
    }
}

.pagination__label {
    font-size: 12px
}

.pagination__results {
    display: flex;
    font-weight: 600;
    color: var(--black);
}

.pagination__results__content{
    display: flex;
    flex-direction: column;

    .pagination__results__label {
        font-size: 10px;
        align-self: center;
        text-transform: uppercase;
        color: var(--grey);
        font-weight: normal;
    }
}

.pagination__filter,
.pagination__results,
.pagination__search {
    display: inline-flex;
    align-items: center;

    a,
    button {
        @include media('phone') {
            margin-left: 0;
        }

        @include font(15px, 20px);
        padding: 12px;
        box-shadow: 4px 2px 10px #e4e4e4,
        0 2px 2px #e4e4e4;
        border: 1px solid #e4e4e4;
        transition: all 300ms ease;

        &:hover {
            @include media('tablet-landscape-up') {
                box-shadow: 0 0 10px rgb(0 0 0 / 20%);
            }
        }
    }
}

.pagination__filter .btn {
    height: 38px !important;
    font-size: 14px;
    font-weight: 600;
}

.pagination__search input {
    //min-width: 300px;
    border-color: var(--primary-light);

    @include media('phone') {
        width: 100%;
        min-width: 100%;
    }

    &::-webkit-input-placeholder {
        color: var(--primary);
        font-size: 12px;
    }
    &:-ms-input-placeholder {
        color: var(--primary);
        font-size: 12px;
    }

    &::placeholder {
        color: var(--primary);
        font-size: 12px;
    }


}

.pagination__filter {
    @include media('phone') {
        display: flex;
        flex-wrap: wrap;
    }

    > span {
        width: fit-content !important;
    }

    label {
        margin-right: 10px;
    }

    user-select: none;
}

.pagination__page-control {
    padding: 10px;
    text-align: center;
}

.pagination__button {
    border: none;

    &:hover {
        cursor: pointer;
    }

    &:first-of-type {
        margin-right: var(--gap-l);
    }

    svg {
        width: 12px;
        height: 12px;
    }
}

.pagination__select__filter {
    position: relative;

    .filter__select{
        background-color: var(--primary-light);
        border: none;
        color: var(--primary);
        padding: 0 10px;
        font-size: 12px;
        min-width: 60px;

        &:hover {
            cursor: pointer
        }
    }

    &.pagination__select__labeled {
        min-width: 160px;
        width: 170px;

        .filter__select{
            text-align: end;
        }

        &:before{
            content: 'Rijen per pagina: ';
            font-size: 12px;
            position: absolute;
            left: 5%;
            color: var(--primary);
            pointer-events: none;
        }
    }
}

.pagination-page-select {
    //min-width: 120px;
    margin-left: 10px;

    > span {
        margin-right: 10px;
    }

    @include media('desktop-small') {
        .pagination__paging__label {
            display: none;
        }
    }
}
