:root {
    /* COLORS: */
    // main colors
    --primary: #0089B4;
    --primary-medium: #C4E2EC;
    --primary-light: #E8F4F8;
    --primary-transparent: #E8F4F876;
    --border-color: #D0D0D0;
    --black: #000;
    --white: #FFF;
    --gray: #808080;
    --grey: var(--gray);
    --light-gray: #fafafa;
    --light-grey: var(--light-gray);
    --medium-gray: #f0f0f0;
    --medium-grey: var(--medium-gray);
    --dark-gray: #e1e1e1;
    --dark-grey: var(--dark-gray);
    --darker-gray: #cdcdcd;
    --darker-grey: var(--darker-gray);
    --background-body: #F6F6F6;
    --primary-hjmg: #76B83A;
    --primary-pretore: #E6A530;

    // font colors
    --text-black: #000000;
    --text-dark-gray: #757575; // 117,117,117
    --text-dark-grey: var(--text-dark-gray);
    --text-gray: #c8c8c8; // 200,200,200
    --text-grey: var(--text-gray);
    --text-white: #FFF;
    --text-primary: #0089B4; // \
    --text-primary-medium: #C4E2EC; // set as seperate variable if they need to be changed in the future without changing the main colors
    --text-primary-light: #E8F4F8; // /
    --text-good: #76B83A;
    --text-warning: #ffa500;
    --text-error: #D60E14;


    // inputs
    --input-text-black: #555;
    --input-placeholder-text-black: var(--text-dark-gray);
    --input-text-black-focus: var(--black);
    --disabled-background: #f0f0f0;

    // validation colors
    --message-green: #76B83A;
    --message-orange: #ffa500;
    --message-red: #D60E14;
    --message-red-light: #f3a2a5;
    --message-black: #000;
    --message-empty: #C8C8C8;

    // checklist colors?
    --gold: #CCA811;
    --silver: #A5A5A5;
    --bronze: #C97D04;

    // zoom value
    --zoom: 1;

    /* CONST VARIABLES: */
    // header height.
    --header-height: 60px;
    --header-height-mobile: 50px;

    // navigation width.
    --navigation-width: 300px;
    --navigation-width-mobile: 60px;

    // font sizes
    --font-small: 12px;
    --font-regular: 15px;
    --font-big: 30px;

    // font weights (must be supported by font family)
    --light: 200;
    --normal: 400;
    --semi-bold: 600;
    --bold: 700;

    // border radius.
    --rounded-corners-s: 5px;
    --rounded-corners-m: 15px;
    --rounded-corners-l: 30px;
    --rounded-corners: var(--rounded-corners-m); // default
    --round: 50%;

    // paddings & margins
    --container-padding: 20px;
    --container-margin-seperation: 20px;
    --container-inline-padding: 10px;
    --content-padding: 10px;
    --input-padding: 5px;
    --gap-xxs: 2px;
    --gap-xs: 5px;
    --gap-s: 10px;
    --gap-m: 15px;
    --gap-l: 20px;
    --gap-xl: 25px;
    --gap: var(--gap-m); // default
}

$planningProjectColors: (
    #4c566b,
    #88c0d0,
    #81a1c1,
    #5e6357,
    #9c9287,
    #6c7156,
    #d08770,
    #bf616a,
    #b48ead,
    #a3be8c,
    #6b6b6b,
    #8a93a6,
    #3f523b,
    #876582,
    #739a5a,
    #9988b6,
    #9bac5e,
    #8fbcbb,
    #ebcb8b,
    #2e3440,
);
