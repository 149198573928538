.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 500px;
    background: var(--white);
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    z-index: 1000;
  
    &.sidebar-left {
        left: 0;
        transform: translateX(-100%);
    }
  
    &.sidebar-right {
        right: 0;
        transform: translateX(100%);
    }
  
    &.open {
        transform: translateX(0);
    }
  
    &__header {
        display: flex;
        justify-content: space-between;
        background-color: var(--primary);
        text-align: center;
        padding: 10px 10px 10px 20px;
        min-height: 60px;

        .sidebar__title {
            margin-top: 0;
            flex-shrink: 3;
            align-self: center;
            color: var(--white);
        }

        .sidebar__button {
            width: 45px;
            height: 45px;
            padding: 10px;
            cursor: pointer;

            svg {
                width: 20px;
                height: 20px;
                fill: var(--white);
            }
        }
    }
  
    &__content {
        height: calc(100% - 60px);
        padding: 20px;
        overflow-x: hidden;
        overflow-y: auto;

        label {
            color: var(--black);
        }

        span {
            color: var(--black);
        }
    }
}