@import "UI/App/Css/mixins";

.hjmg__calculation {
	min-height: calc(100% - 80px);

	&__tab-bar {
		display: flex;
		gap: 5px;

		& > .prev-tab, & > .next-tab {
			display: none;

			@include media(1500) {
				display: block;
			}
		}

		&__handles {
			display: flex;
			gap: 5px;
			overflow: hidden;
			width: 100%;

			@include media(1500) {
				overflow-x: scroll;
			}
		}
	}

	&__tab-handle {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px 10px;

		background-color: var(--primary);
		color: white;
		font-weight: 600;
		width: 100%;
		max-width: 100%;

		cursor: pointer;

		border-radius: var(--rounded-corners-s) var(--rounded-corners-s) 0 0;

		min-width: fit-content;

		&#resultaat {
			width: 120px;

			@include media(1500) {
				overflow-x: scroll;
			}

			&:not([data-active=true]) {
				background-color: var(--black);
				color: var(--white);
			}
		}

		&[data-done=true] {
			background-color: white;
		}

		&[data-active=true] {
			background-color: white;
			color: black;

			&:hover {
				background-color: white;
				transform: none;
				filter: none;
			}
		}

		&:focus {
			outline: none;
		}

		@include media("desktop-small") {
			font-size: 12pt;
			padding: 10px 15px;
		}

		@include media(1500) {
			font-size: 10pt;
			padding: 10px 15px;
		}
	}

	&__tab-container {
		height: 100%;
		background-color: white;
		padding-top: 10px;
	}

	&__feedback {
		box-sizing: border-box;
		margin: 0 20px;
		padding: 10px 20px;
		display: block;
		width: calc(100% - 40px);
		border: solid 1px;
		border-radius: 5px;
		height: 40px;

		border-color: var(--text-warning);
		color: black;
		background-color: #fae1c7;
	}

	&__tab {
		padding: 10px 20px 20px;
		gap: 20px;
		height: 100%;

		display: none;
		flex-direction: column;
		background-color: white;

		&[data-active=true] {
			display: flex;
		}
	}

	&__activity {
		display: flex;
		flex-direction: column;
		border: solid grey 1px;
		padding: 20px;
		position: relative;
	}

	&__remove-activity {
		position: absolute;
		right: 0;
		top: 0;

		svg {
			fill: red !important;
		}
	}

	&__result {
		display: flex;
		gap: 10px;
		width: 100%;
		flex-direction: column;
	}

	&__result-block {
		display: flex;
		gap: 5px;
		width: 100%;
		flex-direction: column;
	}

	&__result-block-title {
		padding: 10px 10px;
		background: var(--primary);
		color: white;
		display: flex;
		border-radius: 5px;
		width: 100%;
		font-weight: bold;
		font-size: 16pt;
	}

	&__summary {
		display: flex;
		flex-direction: column;
		gap: 0;
		width: 100%;
		margin-bottom: 20px;

		& > span {
			display: grid;
			grid-template-columns: 300px 1fr;
			align-items: center;
		}

		h3, h4 {
			line-height: 20px;
		}

		&.col-1 {
			span {
				grid-template-columns: 1fr;
			}
		}

		.hjmg__calculation__summary-row {
			display: grid;
			grid-template-columns: 300px repeat(4, 1fr);
			gap: 5px;

			span {
				display: flex;
				align-items: center;
			}
		}
	}

	&__summary-col {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 30px;
		align-items: center;

		& > span {
			height: 48px;
			display: flex;
			align-items: center;
		}
	}

	.calculationTab {
		display: block;
		min-height: 40px;
		padding-top: 30px;
	}
}
