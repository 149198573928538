html {
	font-size: 70%;
}

body {
	@include font(14px, 16px);

	@include media(1700) {
		@include font(12px, 14px);
	}
}