.block__content.quoteEditor__content {
    background-color: transparent;
}

.row-error{
    border:1px solid red!important;
    background-color: #ffefef !important;
    :hover{

    }
}

.quill-editor__container {
    background-position: top;
    background-size: contain;
    background-repeat: repeat-y;
    background-position-y: 50px;
    min-height: calc(297mm + 45px);
    width: 210mm;
    margin: auto;
    border: solid;
    background-color: white;

    .ql-toolbar {
        background-color: white;
    }

    .ql-container {
        border: none;
        min-height: 297mm;
        padding: 0 21mm 21mm !important;
        width: 210mm;

        .ql-editor {
            height: inherit;
            padding: 0;
            line-height: 1.11;

            // margin-top: 10%;
            &::before {
                left: 0;
            }
        }
    }
    pre.ql-container {
        max-height: unset;
        overflow: unset;
        background-color: unset;
    }
}

// inherit,
.pdf-setting {
    gap: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: solid 1px lightgrey;
    }

    span {
        width: fit-content !important;
    }

    &.pdf-setting--disabled {
        span:nth-child(2) {
            font-weight: 600;
            font-style: italic;
            color: darkgrey;

            display: block;
            padding: 10px 20px;
            height: 50px;
            line-height: 30px;
            background-color: #f2f2f2;
            border-radius: 5px;
        }
    }
}

.quote-info__item {
    & > span:first-child {
        text-transform: uppercase;
        //color: var(--primary);
        font-weight: bold;
        display: block;
        margin-bottom: 5px;
    }
}

.quote-settings {
  div[class*=css-][class*=-container] {
    margin: 0;
  }
}
