.request-access__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .request-access__container {
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;

    .request-access__logo {
      width: 300px;
      margin-bottom: 10px;
    }

    .request-access__request-blocked {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        gap: 10px;
        color: var(--gray);

        &:first-child {
          color: var(--message-red);
        }

        .cross {
          display: flex;
          align-items: center;
          justify-content: center;
          border: solid var(--message-red) 2px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          font-size: 30px;
          margin: 0;
        }
      }
    }

    .request-access__message {
      margin-bottom: 20px;
      font-size: 12pt;

      .emily {
        color: var(--black);
        font-weight: bold;

        span {
          color: var(--primary);
        }
      }
    }

    .spinner__container {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 20px;
    }

    .request-access__actions {
      display: flex;
      gap: 10px;
      width: 100%;

      button, a {
        width: 100%;
        font-size: 12pt;
      }
    }
  }
}