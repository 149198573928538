.btn__toggle-order-timers {
    position: absolute;
    right: 30px;
    bottom: 20px;

    width: 40px;
    height: 40px;

    z-index: 99;

    &:not(.hidden) {
        & ~ #microsoft__disabled-warning {
            right: 80px;
        }
    }
}

.global__order-timers-popup {
    position: absolute;
    right: 20px;
    bottom: 70px;
    width: 400px;

    display: none;

    &.visible {
        display: block;
    }

    background-color: white;
    box-shadow: 0 0 20px -10px black;
    border-radius: 5px;
    overflow: hidden;

    .order-timers__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 15px 20px;
        color: white;
        background-color: var(--primary);

        &__title {
            color: white;
            font-size: 25px;
            font-weight: 600;
        }

        button {
            font-size: 20pt;
            padding: 0;
        }
    }

    .order-timers__content {
        min-height: 50px;
        max-height: calc(100vh - 200px);
        overflow-y: auto;


        .order-timers__order {
            display: flex;
            flex-direction: column;

            .order-timer__order-header {
                background-color: #efefef;
                padding: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                cursor: pointer;

                position: sticky;
                top: 0;

                &:hover {
                    text-decoration: underline;
                }
            }

            .running-timer {
                padding: 10px;
                align-items: center;
                position: relative;
                overflow: hidden;

                &:not(:last-child) {
                    border-bottom: solid #dedede 1px;
                }

                div {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: flex;
                    flex-direction: column;

                    & > * {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .timer__runtime {
                    font-size: 25px;
                    text-align: right;
                    font-family: monospace;

                    .timer__runtime__seconds {
                        color: grey;
                        font-family: monospace;
                    }
                }

                .order-timer__stop-timer {
                    width: 100px;
                    height: 100px;
                    background-color: #ffd4d4;
                    position: absolute;
                    right: -100px;
                    border-radius: 50%;
                    justify-content: flex-start;
                    padding: 10px;
                    transition: right .2s ease;

                    i {
                        color: #541414;
                    }

                    &:hover {
                        right: -70px !important;
                    }
                }

                &:hover {
                    .order-timer__stop-timer {
                        right: -90px;
                    }
                }
            }
        }
    }
}