.material__item__input {
    min-height: 10px;
    border: none;
    border-radius: 0;
    border-bottom: 1px dashed lightgrey;
    color: black;
    padding: 0;
}


.pdf-mockup__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5mm;
    font-size: 1.2rem;
    font-family: Helvetica, sans-serif;

    // For overwriting the * selector in global.scss
    * {
        font-family: Helvetica, sans-serif;
    }

    padding-bottom: 50px;

    .pdf-mockup__page {
        line-height: 1.15;
        --pdf-page-border-size: 1px;
        --pdf-page-content-padding: 21mm;


        width: calc(210mm + (var(--pdf-page-border-size) * 2));
        min-width: calc(210mm + (var(--pdf-page-border-size) * 2));
        min-height: calc(297mm + (var(--pdf-page-border-size) * 2));
        border: var(--pdf-page-border-size) solid #bbb;
        box-shadow: 2px 4px 8px #ddd, 0 2px 2px #ddd;
        padding: var(--pdf-page-content-padding);

        background-color: white;
        background-position: top;
        background-size: contain;
        background-repeat: repeat-y;
        background-position-y: 0;

        // relative for offsetParent in children from .pdf-mockup__page__content
        position: relative;

        .pdf-mockup__page__loading {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            text-align: center;
            background: var(--primary-transparent);
            padding: var(--pdf-page-content-padding);
            z-index: 1;

            .spinner {
                position: sticky;
                top: calc(100% / 3);
                transform: translateY(-50%);
            }
        }

        hr {
            margin-block-start: 1em;
            margin-block-end: 1em;
        }

        .pdf-mockup__row {
            display: flex;
            align-items: center;
            max-width: 100%; // dont set width to 100% for the top-right float block where the background image may have one
            overflow-wrap: anywhere;
            hyphens: auto;

            &.space-between {
                justify-content: space-between;
            }

            &.column {
                flex-direction: column;
            }

            &.flex-start {
                align-items: flex-start;
            }

            &.gap {
                gap: 5px;
            }

            &.pdf__signature {
                height: 4lh;

                img {
                    height: 100%;
                }
            }

            .grid {
                width: 100%;

                span {
                    white-space: break-spaces;
                    word-wrap: break-word;

                    &:focus {
                        outline: none;
                    }

                    input {
                        width: fit-content;
                        padding: 0;
                        margin: 0;
                        min-height: unset;
                        text-indent: 0;
                        color: black;
                    }
                }
            }

            & > span {
                display: flex;
                width: fit-content;
                white-space: nowrap;
                align-items: center;
            }

            input,
            textarea {
                min-height: initial;
                margin: 0 10px;
                border-radius: 0;
                border: none;
                border-bottom: dashed lightgrey 1px;
                font-family: inherit;
                font-size: 1.2rem;
                padding: 0;
                color: black;
                text-indent: 0;
                // Because JSPDF also doesn't do hyphens
                hyphens: none;

                &::-webkit-inner-spin-button {
                    opacity: 1;
                    cursor: pointer;
                }

                &.strong {
                    font-weight: bold;
                }

                &.no-border {
                    border: none;
                }

                &:focus {
                    outline: none;
                }
            }

            textarea {
                margin: 0;
                resize: none;
                overflow: hidden;
                height: 100%;
                max-height: 100%;
                border-bottom: 0;

                background-color: #e5e5f7;
                opacity: 0.8;
                background-size: 16px 16px;
                background-image: repeating-linear-gradient(0deg, #000, #000 1px, #ffffff 1px, #ffffff);
                line-height: 1.193;

                &.transport {
                    height: 253px;
                    min-height: 230px;
                    max-height: 780px;
                }
            }
        }
    }

    &[data-pdf-for=packing-slip],
    &[data-pdf-for=purchase-quote],
    &[data-pdf-for=purchase-slip] {
        --pdf-font-size: 10pt;
        --pdf-line-height-factor: 1.193;
        --pdf-line-height: calc(var(--pdf-font-size) * var(--pdf-line-height-factor));
        font-size: var(--pdf-font-size) !important;
        line-height: var(--pdf-line-height) !important;

        .pdf-mockup__page {
            max-height: calc(297mm + (var(--pdf-page-border-size) * 2));
            //overflow-y: hidden;
        }

        input,
        textarea {
            font-size: var(--pdf-font-size) !important;
            line-height: var(--pdf-line-height) !important;
            margin: 0;
            padding: 0;
            text-indent: 0;
            height: fit-content;
            background-color: transparent;
            background-size: 100% var(--pdf-line-height) !important;

            resize: none;
            overflow: hidden;

            &:focus {
                outline: none;
            }
        }

        hr {
            margin-block-start: var(--pdf-line-height);
            margin-block-end: var(--pdf-line-height);
            width: 100%;
        }
    }

    &[data-pdf-for=packing-slip] {

        input,
        textarea {
            margin: 0 !important;
            border-bottom: 1px dashed lightgrey !important;
            background-image: none !important;
            background-color: transparent !important;

        }
    }

    &[data-pdf-for=order] {
        input,
        textarea {
            font-size: inherit !important;
        }
    }
}