.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 57px;
  padding: var(--container-inline-padding) 20px;

  font-weight: bold;
  text-transform: uppercase;

  border-radius: 0px;
  border-bottom: 1px solid var(--dark-grey);

  margin-bottom: 10px;

  &--primary {
    @extend .page-header;
    background-color: var(--primary);
    color: var(--white);
  }

  &--primary-medium {
    @extend .page-header;
    background-color: var(--primary-medium);
    color: var(--black);
  }

  &--primary-light {
    @extend .page-header;
    background-color: var(--primary-light);
    color: var(--black);
  }

  &--white {
    @extend .page-header;
    background-color: var(--white);
    color: var(--black);
  }

  &--sticky {
    position: sticky;
    top: -25px;
    z-index: 98;
    height: 55px;
  }

  &__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: fit-content;
  }

  &__title-edit {
    @extend .page-header__title;

    overflow: visible;
  }

  &__content {
    display: flex;
    gap: 10px;
  }
}