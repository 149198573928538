@import 'UI/App/Css/mixins';

#details {
    .block__header {
        flex-wrap: nowrap !important;
        gap: var(--gap-s);

        .block__header__right {
            white-space: nowrap;
        }
    }
}