@import 'UI/App/Css/mixins';

.popup {
    z-index: 101;
    width: 600px;
    min-height: 400px;
    max-height: calc(100vh - 200px);
    position: fixed;
    display: none;
    top: calc(var(--header-height) + 30px);
    left: calc(50% - 300px);
    overflow-y: hidden;
    //box-shadow: 4px 8px 10px #ddd, 0 2px 2px #ddd;
    border-radius: 5px;

    @include media('phone') {
        width: auto;
        top: 20px;
        left: 20px;
        bottom: 20px;
        right: 20px;
        max-height: fit-content;
    }

    .popup__header {
        display: flex;
        justify-content: space-between;
        background-color: var(--primary);
        text-align: center;
        padding: 5px 10px;
        min-height: 60px;

        .popup__title {
            margin-top: 0;
            flex-shrink: 3;
            align-self: center;
            color: white;
        }
    }

    .popup__content {
        max-height: calc(100vh - 200px - 100px);
        min-height: calc(400px - 60px);
        background-color: var(--text-white);
        overflow: auto;
        padding: 20px;

        .popup__content__flex {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .popup__button {
        width: 45px;
        height: 45px;
        padding: 10px;
        cursor: pointer;

        svg {
            width: 20px;
            height: 20px;
            fill: white;
        }
    }
}

.emily-overlay {
    width: 100vw;
    height: 100vh;
    background: var(--primary-transparent);
    display: none;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    cursor: pointer;
    backdrop-filter: blur(4px);
}

.emily-overlay__content {
    display: none;
    justify-content: center;
    align-items: center;
    font-size: large;
    color: var(--black);
    flex-direction: column;
    gap: 20px;

    svg {
        height: 100px;
        fill: var(--black);
    }
}