#handwerk, #werk-derden {
  position: relative;
  height: fit-content;
  min-height: 50px;
  padding-top: 50px;

  // Not fully supported (90% atm) but just a fix to optimize space
  &:has(embed) {
    padding-top: 0;
  }
}

.handwerk--btn-add, .werk-derden--btn-add {
  position: absolute;
  right: 0;
  top: 0;
}

.handwerk-entry, .werk-derden-entry {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 20px 5px;
  box-shadow: #757575 0 0 4px -1px;
  padding: 15px;
  border-radius: 5px;
  position: relative;
  background-color: var(--background-body);

  & > div {
    width: 100%;
    display: flex;
    gap: 5px;

    & > div {
      width: 100%;
    }
  }

  .handwerk--btn-remove, .werk-derden--btn-remove {
    position: absolute;
    right: 0px;
    top: 0px;
  }

    .handwerk--quantities,
    .werk-derden--quantities {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;

    & > div {
      display: flex;
      gap: 5px;
      width: 100%;

      & > div {
        width: 100%;
      }
    }
  }
}
