.sticker__content {
	height: 300px;
	resize: none;
	overflow: hidden;
}

.sticker__order__number {
	position: absolute;
	color: var(--input-text-black);
}

.sticker__order__number, .sticker__content {
	font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
	font-size: 14pt;
}

.ql-editor * {
	font-size: 15pt;
	font-family: "Helvetica Neue",
		"Helvetica",
		"Arial",
		sans-serif;
}

.ql-toolbar {
	background: #eaecec;
	border-top-left-radius: 0.5em;
	border-top-right-radius: 0.5em;
	border-bottom: none;
}