@import 'UI/App/Css/mixins';

.chat__content {
	padding: 25px 25px 15px 25px;
}

.company__chat {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	max-height: 390px;
	overflow: hidden auto;
	//background-color: var(--white);
	min-height: 100px;
	border-top: solid 1px lightgrey;
	border-bottom: solid 1px lightgrey;
}

.company__message {
	@include media('phone') {
		padding: 10px;
	}

	@include media('tablet-portrait-down') {
		flex-wrap: wrap;
	}

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
	padding: 10px 15px;
	border-bottom:1px solid var(--dark-gray);
}

.company__message__tags {
	gap: 10px;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 10px;
	width: 100%;

	.message__tag__preview {
		width: 50px;
		height: 16px;
		border-radius: 10px;
		display: inline-flex;
		align-items: center;
		transition: height .3s ease;
		cursor: pointer;
	}

	&[data-collapsed=false] {
		.message__tag__preview {
			height: 26px;
			width: fit-content;
			padding: 0 10px;

			&:after {
				content: attr(content);
				display: block;
			}
		}
	}
}

.company__message__content {
	display: flex;
	width: 100%;
	flex-direction: column;
}

.company__message-left {
	@include media('tablet-portrait-down') {
		width: 100%;
	}

	overflow: hidden;
	vertical-align: top;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	padding-right: 30px;
	width: 100%;
}

.company__message-text {
	display: inline-block;
	white-space: break-spaces;
	word-break: break-word;
}

.company__message-content {
	display: flex;
	flex-wrap: wrap;

	.read-more {
		color: var(--primary);
		cursor: pointer;
		margin-top: 10px;
	}
}

.company__message-actions {
	@include media('tablet-portrait-down') {
		margin-top: 10px;
	}

	padding: 0 10px;

	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 10px;
	min-width: fit-content;


	@include media('phone') {
		gap: 5px;

		// TODO: Better responsiveness for proper UX
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
}

.company__message-header {
	margin-bottom: 10px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.company__message-header-content {
		display: flex;
		flex-direction: column;
		gap: 5px;

		.company__message-header-content-date {
			//white-space: nowrap;
			color: grey;
		}
	}
}

.company__add {
	@include media('phone') {
		padding: 0;

		a {
			margin-left: 0 !important;
			margin-top: 10px;
		}
	}

	display: flex;
	flex-wrap: wrap;
	align-items: center;

	button {
		z-index: 1;
	}

	&.company__fileupload {
		border-top: 1px solid var(--medium-gray);
	}
}

//Fileinput
.company__message__file-upload {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	z-index: 0;
	padding: 10px 0;

	button {
		z-index: 1;
		margin: 0 10px;
	}

	input {
		position: absolute;
		box-shadow: none !important;
		left: -42px;
		top: -4px;
		height: 39px;
		z-index: 0;

		&::before {
			content: '';
			position: absolute;
			width: 105px;
			height: 39px;
			left: 0;
			top: 0;
		}
	}
}

.company__message__labels {
	list-style: none;
	display: flex;
	margin-bottom: 5px;
	margin-top: 5px;
	flex-wrap: wrap;

	li {
		line-height: 33px;
		margin-right: 10px;
		width: fit-content;

		input[type=checkbox]:checked ~ label {
			padding: 3px 10px 3px 25px;

			&::before {
				opacity: 100;
			}
		}

		input {
			display: none !important;
		}

		opacity: 100;

		label {
			border-radius: 30px;
			padding: 3px 10px;
			position: relative;
			transition: .1s ease-in-out;
			user-select: none;
			white-space: nowrap;

			&::before {
				content: '✖';
				position: absolute;
				top: -3px;
				left: 10px;
				color: inherit;
				width: 15px;
				height: 15px;
				opacity: 0;
				transition: .3s ease-in-out;
			}
		}
	}
}