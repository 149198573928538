.gridSelector{
	width: 100%;
	height: 100px;
	border:1px solid lightgrey;
	background-color: #f6f6f6;
	:hover{
		cursor: pointer;
	}
}

.gridColumn:not(:last-child){
	border-right:1px solid lightgrey;
	width: 100%;
	height: 100px;
}
