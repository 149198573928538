.boolean {
    display: flex;
    border-radius: 5px;
    overflow: hidden;
    width: fit-content;
    background-Color: #f2f2f2;
    align-items: baseline;

    .boolean__value {
        display: inline-block;
        height: 40px;
        width: calc(100% - 25px);
        padding: 5px 10px;
        margin: 5px;
        background-color: transparent;
        border: solid transparent 2px;
        border-radius: 5px;
        font-size: 1.4rem;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        text-align: center;
        color: var(--black);
        line-height: 30px;
        cursor: default;
        user-select: none;
        white-space: nowrap;
        outline: 0;
        filter: none !important;
        transition: all 0.2s ease;

        &:hover:not([data-selected=true]):not(:disabled) {
            background-color: #e2e2e2;
            border: solid #e8e8e8 2px;
        }

        &[data-selected=true] {
            background-color: var(--white);
            color: var(--text-primary);
            border: solid #e8e8e8 2px;

            &.boolean__value--red {
                color: var(--message-red);
            }
        }
        &[data-selected=false] {
            cursor: pointer;
        }

        &:disabled {
            cursor: not-allowed;
        }
    }

    .boolean__unset {
        display: inline-block;
        width: 40px;
        height: 40px;
        font-size: 20px;
        margin-right: 5px;
        padding: 0;
        border: solid 2px transparent;
        border-radius: 5px;
        background-color: transparent;
        color: #D60E14;
        cursor: pointer;
        user-select: none;
        outline: 0;

        &:hover {
            border: solid #d8d8d8 2px;
            background-color: #e2e2e2;
        }
    }
}
