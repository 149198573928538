/*
    This file holds global styling for basic html elements and default set divs.
    For global elements styling (e.g: h1,h2, etc.) see component's .scss
*/

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

* {
    font-family: 'Poppins', 'open sans', sans-serif;

    &[data-title-left]:hover:after {
        overflow: visible;
        content: attr(data-title-left);
        //text-transform: capitalize;
        position: absolute;

        left: 0px;

        transform: (translate(0px, 22px));
        margin-top: 25px;
        padding-top: 25px;
        min-width: 10px;
        box-shadow: 0 0 5px 0 grey;
        border-radius: 5px;
        background-color: white;
        margin: 5px;
        padding: 4px 5px 4px 5px;
        color: #000000;
        font-size: 14px;
        //white-space: normal;
        z-index: 5;
    }
}

html {
    height: 100vh;
    overflow-y: hidden;
}

body {
    max-width: 100vw;
    max-height: 100vh;
    background-color: var(--background-body);
    color: var(--text-black);
    font-family: 'Poppins', 'open sans', sans-serif;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

#root {
    width: 100vw;
    height: 100vh;
}

nav {
    overflow-y: auto;
}

main {
    @include media('tablet-landscape-down') {
        width: calc(100% - var(--navigation-width-mobile));
        margin-left: var(--navigation-width-mobile);
    }

    width: calc(100% - var(--navigation-width));
    height: 100vh;
    overflow-y: hidden;
    margin-left: var(--navigation-width);
}

.content {
    position: relative;
    max-height: calc(100vh - var(--header-height));
    height: calc(100vh - var(--header-height));
    padding: 20px var(--container-padding);
    overflow: hidden auto;
}

.emily-toast {
    border-radius: 5px;
}

.loading {
    display: flex;
    justify-content: center;
}

a {
    color: var(--text-primary);
    text-decoration: none;

    // &:visited {}
    main &:not(.btn):hover {
        text-decoration: underline;
    }
}

img {
    max-width: 100%;
    height: auto;
}

strong,
b {
    font-weight: var(--bold);
}

code,
kbd,
samp,
var,
pre {
    font-family: monospace;
    background-color: var(--medium-gray);
    padding: 0.125rem 0.25rem;
}

pre {
    max-height: 300px;
    overflow: auto;
}

*:disabled,
[disabled],
.disabled {
    // background-color: var(--disabled-background);
    cursor: not-allowed;
}

[hidden],
.hidden {
    display: none !important;
}

[invisible],
.invisible {
    visibility: hidden !important;
}

[loading],
[data-loading="true"],
.loading {
    pointer-events: none;
    filter: blur(2px);
    cursor: wait;
}

.margin-0 {
    margin: 0 !important;
}
