@import "UI/App/Css/mixins";

.progress__bar {
	display: grid;
	grid-template-columns: repeat(5, 2fr);
	grid-template-rows: 1fr;
	gap: var(--gap-xs);

	> .progress__item {
		text-align: center;
		background-color: var(--primary);
		color: var(--white);
		border-top-left-radius: var(--rounded-corners-s);
		border-top-right-radius: var(--rounded-corners-s);
		padding: 10px 0;

		&:last-child {
			background-color: var(--black);
		}

		&.active {
			background-color: var(--white);
			color: black;
		}

		&.done {
			background-color: var(--primary);
			color: var(--white);
			font-weight: bold;
		}

		&:hover {
			cursor: pointer;
		}
	}
}

.calculate__content {
	min-width: 0px !important;
}

.progress__container {
	background-color: var(--white);
	padding-top: 10px;

	.progress__notice {
		background-color: #fae1c7;
		border: solid 1px var(--text-warning);
		padding: 10px 20px;
		width: auto;
		margin: 0 20px;
		border-radius: 5px;
		color: black;
	}

	>.progress__step__container {
		padding: var(--container-padding);
		padding-top: 10px !important;

		>.form__content {
			display: grid;
			grid-template-columns: repeat(12, 1fr);
			grid-template-rows: 1fr;
			gap: 5px var(--container-padding);

			>.form__item {
				position: relative;
				// set collumns based on the class -> grid__1 takes up 1 column, grid__6 takes up 6 etc.
				@for $i from 1 through 12 {
					&.grid__#{$i} {
						grid-column: span $i;

						// on small screens span always 12 columns
						@include media("tablet-portrait-down") {
							// @if($i < 7) {
							// 	grid-column: span calc($i * 2);
							// } @else {
								grid-column: span 12;
							// }
						}
					}
				}

				&.form__item__with__button {
					position: relative;
					>input {
						width: 90%;
						display: inline-block;
					}
					>.add__amount__button {
						position: absolute;
						right: 0;
						top: 0;
					}
				}
			}
		}
	}
}

.machine__nav {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;

	>.machine__item {
		padding: 15px 5px 15px 25px;
		border-right: 1px solid var(--border-color);
		position: relative;

		&.current {
			background-color: var(--primary-medium);

			&::before {
				content: '';
				height: 100%;
				width: 3px;
				background-color: var(--primary);
				position: absolute;
				left: 0;
				top: 0;
			}
		}

		&.visited {
			&:not(.current) {
				text-decoration: line-through;
			}
		}

		&:hover {
			cursor: pointer;

			&:not(.currentTab) {
				background-color: var(--medium-gray);
			}
		}
	}
}

.add__amount__button {
	height: 38px;
	width: 38px;
	margin: var(--gap-xs) 0 0 0;
	position: relative;

	&:hover {
		cursor: pointer;
	}

	>svg {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		fill: var(--message-red);
	}
}

.bottom__button {
	padding-top: 10px;
	text-decoration: underline;
	color: var(--primary);
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);

	&:hover {
		cursor: pointer;
	}
}

.info__content {
	max-width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.table__row__container {
	display: grid;
	grid-template-columns: 500px 1fr;

	@include media('desktop-small') {
		grid-template-columns: 400px 1fr;
	}

	@include media('tablet-landscape-down') {
		grid-template-columns: 300px 1fr;
	}

	>.result__table__container {

		>div {
			padding: 0;
			margin: 0;
			overflow-y: hidden;
		}
	}
}

.block__result__container {
	>.result__content {
		min-width: 0px !important;

		>.result__table__header {
			padding: 10px 0 15px 0;
		}

		>.table__row__container {
			overflow-x: scroll;
			overflow-y: hidden;
		}
	}
}

.recap__container {
	display: grid;
	grid-template-rows: repeat(7, 40px);
	gap: var(--gap-xs);
	margin-top: var(--gap-s);
	margin-left: 20px;

	&:first-child {
		margin-left: 0;
	}

	&:nth-child(2) {
		margin-left: 20px;
	}

	>input {
		padding: 0;
		margin: 0;
	}

	>.recap__label {
		margin: auto 20px;
	}
}