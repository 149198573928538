@use 'sass:color';
@import 'UI/App/Css/mixins';
@import 'UI/App/Css/_variables';

@for $i from 1 through 20 {
    .person__avatar-bg-#{$i} {
        background-color: color.adjust(nth($list: $planningProjectColors, $n: $i), $hue: 180) !important;
    }

    .person__image-bg-#{$i}-dark {
        background-color: darken(nth($list: $planningProjectColors, $n: $i), 20%) !important;
    }
}


.machineName {
    //margin-bottom: 50px;
    //color: red;
    font-weight: bold;
}

.dailyPlanningContainer {
    overflow: auto;
    background-color: white;

    .date {
        background-color: white;
        border-right: 2px solid darkgrey;
    }

    div {
        border-bottom: 1px solid #F6F6F6;
        height: 70px;
        min-width: 100%;

        .sunday {
            background-color: #F6F6F6;
        }

        div {
            border: 1px solid #F6F6F6;
        }
    }
}

.dailyHeaderWeekSelector {
    //height: 50px;
    margin: 5px;
    width: 450px;
    display: grid;
    grid-template-columns: 20px 1fr 2px;

    div:hover {
        cursor: pointer;
    }

    div {
        svg {
            height: 25px;
            color: white;
        }
    }
}

.daily-planning__container {
    & > .grid {
        overflow-x: auto;
        overflow-y: auto;
        position: relative;
        max-height: calc(100vh*(1/var(--zoom)) - 200px);
    }

    .daily-planning__project {
        cursor: pointer;
        color: #FFFFFF;
        border-radius: 5px;

        a {
            color: #ffffff;
            padding: 5px;
            border-radius: 5px 5px 0 0;
        }

        & > div {
            padding: 5px;
            border-radius: 0 0 5px 5px;
        }
    }

    .daily-planning__machine:first-child {
        position: sticky;
        top: 0;
        left: 0;
        background-color: #FFFFFF;
        z-index: 12;

        @include media('tablet-portrait-down') {
            left: unset;
        }
    }

    .daily-planning__machine {
        font-weight: 600;
        border-bottom: solid 1px grey;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 5px;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 11;
    }

    .daily-planning__date {
        font-weight: 600;
        border-right: solid 1px grey;
        border-bottom: 1px solid black;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 5px;
        min-height: 50px;
        height: 100%;
        position: sticky;
        left: 0;
        background-color: white;
        z-index: 10;

        @include media('tablet-portrait-down') {
            position: static;
        }

        &.sunday {
            background-color: #f6f6f6;
        }
    }

    .daily-planning__day {
        --border: solid 1px lightgrey;
        border-bottom: 1px solid black;
        height: 100%;
        display: flex;
        gap: 4px;
        flex-direction: column;
        border-right: var(--border);
        //border-bottom: var(--border);
        white-space: nowrap;
        min-width: 200px;
        padding: 5px 5px;


        &.sunday {
            background-color: #f6f6f6;
        }
    }
}

.daily-planning__date-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    .daily-planning__date-button {
        display: flex;
        width: 20px;
        height: 20px;
        justify-content: center;
        cursor: pointer;

        svg {
            fill: white;
        }
    }

    .daily-planning__date-text {
        white-space: nowrap;
        flex-direction: row;
        gap: 5px;
        display: flex;
        font-weight: 600;
        font-size: 12pt;
        text-align: center;
        justify-content: center;
        user-select: none;
    }
}

#day-planning__department {
    margin: 0;
    color: black;
    width: 50%;
        max-width: 50%;
        min-width: 250px;
    @include media('tablet-portrait-down') {
        max-width: 200px;
    }
}

#day-planning__status {
    margin: 0;
    color: black;
    width: 50%;
    max-width: 50%;

    @include media('tablet-portrait-down') {
        max-width: 200px;
    }
}

#daily-planning__user, #daily-planning__progress-filter {
    margin: 0 !important;
    width: 100%;
    color: black;
}

#daily-planning__filters {
    #daily-planning__close-filters {
        display: none;
    }

    @media only screen and (max-width: 750px) {
        display: none;

        position: absolute;
        top: calc(100% - 5px);
        right: 165px;
        z-index: 101;

        background-color: white;
        border-radius: 5px;
        box-shadow: 0px 0px 4px grey;
        width: 300px;
        padding: 10px;

        &.open {
            display: grid;

            #daily-planning__close-filters {
                display: block;
            }
        }
    }
}

.block#dagPlanning .block__header {
    position: relative;
}

.daily-planning__project__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    color: var(--text-black);
    gap: 4px;

    .daily-planning__middle-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        gap: 4px;

        width: 100%;

    }

    .daily-planning__bottom-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        gap: 4px;

        width: 100%;
        min-height: 34px;

        & > div:nth-child(1) {
            display: flex;
            gap: 4px;

            & * {
                font-size: 10pt;
            }
        }

        .daily-planning__progress {
            padding: 5px 10px;

            svg, .circle {
                width: 20px;
                height: 20px;
                background-color: transparent;
                color: black;
            }

            &.not-started {
                background-color: #e3e3e3;
                color: #626262;
            }

            &.in-progress {
                background-color: #a3e6ff;
                color: #005672;
            }

            &.completed {
                background-color: #9beea9;
                color: #004b0f;
            }
        }

        .daily-planning__priority {
            padding: 5px 10px;
            background-color: grey;

            &.highest {
                background-color: #ffafaf;
                color: #a90000;
            }

            &.high {
                background-color: #facc94;
                color: #8c5a00;
            }

            &.medium {
                background-color: #ffeb9d;
                color: #7e6601;
            }

            &.low {
                background-color: #9be4fa;
                color: #006d8c;
            }

            &.lowest {
                background-color: #9bd2fa;
                color: #004779;
            }
        }

        .daily-planning__quantity {
            padding: 5px 10px;
            background-color: #e3e3e3;
            color: #626262;

            &.not-completed {
                background-color: #ffafaf;
                color: #a90000;
            }

            &.completed {
                background-color: #9beea9;
                color: #004b0f;
            }
        }

        .daily-planning__icon {
            background-color: transparent;
            color: black;
            padding: 5px 0 0 10px;
        }

        .planning__person__avatar__container {
            display: inline-flex;
            justify-content: flex-end;
            flex-wrap: nowrap;

            .planning__person__avatar {
                color: white;
                padding: 4px;
                background-color: var(--primary);
                border-radius: 50px;
                border: 2px solid transparent;
                margin: 3px;
                width: 27px;
            }
        }
    }
}