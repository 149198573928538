@import 'UI/App/Css/mixins';

button,
.btn {
    display: inline-flex;
    flex-wrap: nowrap;
    text-align: center;
    align-content: center;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: var(--primary);
    color: var(--white);
    padding: 10px;
    border: none;
    border-radius: var(--rounded-corners-s);
    outline: none;
    user-select: none;
    cursor: pointer;
    white-space: nowrap;
    transition: color 0.2s, filter 0.2s ease;
    @include font(12px, 14px);
    font-family: 'Poppins', "open sans", sans-serif;
    font-weight: normal;
    gap: 5px;
    position: relative;
    text-transform: none;

    &:disabled {
        filter: grayscale(0.7) brightness(0.9);
        cursor: not-allowed;
    }

    @include media('tablet-portrait-down') {
        @include font(12px, 14px);
        padding: 10px 10px;
    }

    svg,
    i {
        width: 14px;
        height: 14px;
        fill: var(--white);
        margin-bottom: 1px;
        font-size: 15px;
    }

    /* modifiers */
    &.btn--small {
        padding: 6px;
    }

    &.btn--round {
        border-radius: var(--round);
    }

    &.btn--margin-l {
        margin-left: var(--gap-s);
    }

    &.btn--margin-r {
        margin-right: var(--gap-s);
    }

    &.btn--margin-lr {
        margin: 0 var(--gap-s);
    }

    &.btn--padding-0 {
        padding: 0;
    }

    // COLORS
    &.btn--transparent {
        background-color: transparent;
        border: none;

        svg,
        i {
            fill: var(--black);
        }
    }

    &[data-title]:hover:after {
        overflow: visible;
        content: attr(data-title);
        position: absolute;
        top: 100%;
        min-width: 80px;
        width: max-content;
        max-width: 250px;
        box-shadow: 0 0 5px 0 grey;
        border-radius: 5px;
        background-color: white;
        margin: 5px;
        padding: 4px 5px 4px 5px;
        color: #000000;
        font-size: 14px;
        line-height: 14px;
        z-index: 11;
        white-space: normal;
    }

    &[data-bubble]:after {
        content: attr(data-bubble);
        background-color: red;
        font-family: monospace;

        width: 20px;
        height: 20px;
        border-radius: 50%;

        position: absolute;
        right: -10%;
        top: -10%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.btn--error {
        background-color: var(--text-error);
        border: none;

        svg,
        i {
            fill: white;
        }
    }

    &.btn--icon-red {

        svg,
        i {
            fill: var(--message-red);
        }
    }

    &.btn--icon-green {

        svg,
        i {
            fill: var(--message-green);
        }
    }

    &.btn--black {
        background-color: var(--black);
        color: var(--white);
    }

    &.btn--white {
        background-color: var(--white);
        color: var(--primary);

        svg,
        i {
            fill: var(--primary);
        }
    }

    &.btn--hjmg {
        background-color: var(--primary-hjmg)!important;
        color: var(--white);
    }

    &.btn--pretore {
        background-color: var(--primary-pretore)!important;
        color: var(--white);
    }

    &.btn--gray {
        background-color: var(--silver);
    }

    &.btn--light-blue {
        background-color: var(--primary-light);
        color: var(--text-primary);
    }

    &.btn--icon-right {

        svg,
        i {
            //margin-left: 5px;
        }
    }

    &.btn--icon-left {

        svg,
        i {
            //margin-right: 5px;
        }
    }

    &.btn--no-focus {
        &:focus {
            outline: none;
        }
    }

    &[data-loading=true] {
        filter: none;
        pointer-events: none;
        cursor: wait;
        position: relative;

        &:after {
            position: absolute;
            left: -2px;
            top: -2px;
            display: block;
            content: '';
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            backdrop-filter: blur(2px);
            background-image: url("/UI/Assets/Spinners/spinner.svg");
            background-size: 30px;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

// slider
.btn__slide {
    height: 40px;
    z-index: 50;

    &:focus {
        outline: none;
    }

    & > :first-child {
        padding: 0 20px;
        display: none;
        overflow: hidden;
        top: 0;
        width: 0;
        height: inherit;
        background: inherit;
        transition: all .2s ease-in-out;
        z-index: 49;

        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    & > :not(:first-child) {
        z-index: 50;
    }

    &:hover {
        & > :first-child {
            position: absolute;
            width: fit-content !important;
            display: flex;
        }
    }

    &--left {
        @extend .btn__slide;

        :first-child {
            right: calc(100% - 20px);
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
        }
    }

    &--right {
        @extend .btn__slide;
    }
}
