.version-container {
	.version {
		//border-bottom: 1px solid lightgrey;
		padding: 0 5px 5px 5px;

		.version-title {
			font-weight: bold;
			font-size: 20pt;
			text-align: center;
			padding: 15px 0 15px 0;
		}

		ul {
			padding: 0 20px;
		}

		b {
			font-size: 14pt;
			display: block;
			margin: 10px 0 10px;
		}
	}
}
