@import 'mixins';
@import 'base';


@import 'fonts';
@import 'variables';
@import 'global';

// node packages styles
@import 'react-toastify/dist/ReactToastify.css';
@import '/src/UI/Assets/FontAwesome/scss/fontawesome';
@import '/src/UI/Assets/FontAwesome/scss/solid';
@import '/src/UI/Assets/FontAwesome/scss/regular';
@import '/src/UI/Assets/FontAwesome/scss/thin';
@import '/src/UI/Assets/FontAwesome/scss/light';