label.upload__multiple {
  display: grid;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 250px;
  border: dashed lightgrey 4px;
  border-radius: 5px;
  text-align: center;
  color: grey;

  overflow-x: auto;

  svg {
    width: 100px;
    height: 100px;
    fill: grey;
  }

  img {
    max-width: 200px;
    max-height: 200px;
  }

  .upload__wrapper {
    border: solid grey 2px;
    padding-bottom: 5px;
  }

  .popup__content img {
    max-width: 100%;
    max-height: 100%;
  }

  span {
    max-width: calc(100% - 50px);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  input {
    display: none;
  }
}
label.upload__placeholder {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 250px;
  border: dashed lightgrey 4px;
  border-radius: 5px;
  text-align: center;
  color: grey;

  overflow-x: auto;
    svg {
        width: 100px;
        height: 100px;
        fill: grey;
      }
    
      img {
        max-width: 200px;
        max-height: 200px;
      }
    
      .popup__content img {
        max-width: 100%;
        max-height: 100%;
      }
    
      span {
        max-width: calc(100% - 50px);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    
      input {
        display: none;
      }
    }
  