.task {
    padding: var(--container-inline-padding);
    display: inline-flex;
    align-items: center;
    background-color: var(--medium-gray);
    border-radius: var(--rounded-corners-s);
    width: 100%;
    margin-bottom: var(--gap-s);
    border-left: 8px transparent solid;

    &:last-child {
        margin-bottom: 0;
    }

    .task__status-checkbox {
        width: 30px;
        margin-left: -6px;
    }

    .task__content {
        display: flex;
        flex-direction: column;
        gap: var(--gap-xs);
        width: calc(100% - 30px);
        font-size: var(--font-regular);
        position: relative;

        .task__header {
            width: 90%;
            display: block;
            justify-content: space-between;
            align-items: center;

            .task__header__title {
                display: flex;
                flex-direction: column;
                gap: var(--gap-xs);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                strong {
                    max-width: calc(100% - 100px);
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .task__header__date {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: baseline;
                    gap: var(--gap-xs);
                    color: var(--gray) !important;

                    span {
                        white-space: break-spaces;
                    }

                    svg {
                        height: var(--font-regular);
                    }
                }
            }
        }

        .task__recipients {
            display: flex;
            gap: var(--gap-xs);

            .task__recipients__icon svg {
                height: var(--font-regular);
            }

            .task__recipients__names {
                display: flex;
                flex-wrap: wrap;
                gap: var(--gap-xs);

                .task__recipients__nametag {
                    background-color: var(--darker-gray);
                    border-radius: var(--rounded-corners-m);
                    padding: 0 10px;
                }
            }
        }

        .task__content__body {
            white-space: break-spaces;
            overflow-wrap: anywhere;
            hyphens: auto;
        }
    }
}

.priority_low {
    border-left: 8px lightgreen solid;
}

.priority_normal {
    border-left: 8px orange solid;
}

.priority_high {
    border-left: 8px red solid;
}
