.highlight {
    color: #0089b4;
}

.pulseHighlight {
    color: rgb(0, 137, 180);

    transform: scale(1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        color: rgba(0, 137, 180, 1);
    }

    50% {
        transform: scale(1);
        color: rgba(0, 137, 180, 0.5);
    }

    100% {
        transform: scale(0.95);
        color: rgba(0, 137, 180, 1);
    }
}