.popup-order-timer {
    .popup__title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.order--timer_popup--content {
    display: flex;
    flex-direction: column;

    .timer__runtime {
        margin-top: 10px;
        font-size: 30px;
        text-align: center;
        grid-column: 1/3;
        font-family: monospace;

        .timer__runtime__seconds {
            font-family: inherit;
            color: #c5c5c5;
        }

        &.paused {
            grid-column: unset;
        }
    }

    .timer__paused {
        grid-column: 1/4;
        text-align: center;
    }

    .timer_popup--total_time {
        background-color: #efefef;
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;
    }

    .timer_popup--buttons {
        width: 200px;
        margin: 25px auto;
        justify-items: center;

        .timer_popup--button {
            width: 65px;
            height: 65px;

            svg {
                width: 20px;
                height: 20px;
            }

            &.stop {
                background-color: #9f1f1f;
            }
        }
    }
}

#editTimerPopup .popup__content {
    .timer-card {
        display: flex;
        flex-direction: column;
        border: solid var(--medium-gray) 2px;

        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 10px;

        .card-header {
            padding: 10px;
            background-color: var(--medium-gray);

            a {
                color: var(--text-black);
            }
        }

        .card-body {
            display: flex;
            flex-direction: column;
            padding: 10px;
        }

        span:last-child {
            margin-top: 5px;
        }
    }

    button {
        width: 100%;
        margin-top: 10px;
    }
}