body>[class="skiptranslate"] {
    display: none;
}

body {
    top: 0 !important;
}

.goog-te-gadget-icon {
    display: none;
}

.goog-te-gadget-simple {
    background-color: #ecebf0 !important;
    border: 0 !important;
    font-size: 10pt;
    font-weight: 800;
    display: inline-block;
    padding: 10px 10px !important;
    cursor: pointer;
    zoom: 1;
}

#google_translate_element {
    display: none;
}
.goog-te-gadget-simple span {
    color: var(--primary) !important;
}
